import React, { useState } from 'react';
import { editAdminIcon } from '../login/loginConstants';
import EditProfile from './editProfile';
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";

function Security() {
    const [editState, setEditState] = useState({
        active: false,
        header: localStorage.getItem('i18nextLng') === 'en' ? 'Change ' : 'Wijziging ',
        inputName: '',
        inputValue: '',
        index: 0
    });
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [strength, setStrength] = useState(0);
    const [validate, setValidate] = useState('');

    const handlePassword = (value, type) => {
        if (value !== undefined && type === 'password') {
            let strength = 0;
            if (value.length >= 8) {
                strength++;
            }
            if (value.match(/[1-9]/g) !== null) {
                strength++;
            }
            if (value.match(/[a-z]/g) !== null) {
                strength++;
            }
            if (value.match(/[^a-zA-Z\d]/) !== null) {
                strength++;
            }
            setStrength(strength)
        }
        if (type === 'oldPassword') {
            setOldPassword(value)
        }
        if (type === 'password') {
            setPassword(value)
        }
        if (type === 'confirmPassword') {
            setConfirmPassword(value)
        }
    }

    const handleEdit = (open, name, value, index) => {
        setEditState({
            active: open,
            header: localStorage.getItem('i18nextLng') === 'en' ? 'Change ' : 'Wijziging ',
            inputName: name,
            inputValue: value,
            index: index
        });
    };

    const update = () => {
        if (strength < 4) {
            setValidate('The password is too weak!');
        } else {
            save();
        }
    };

    const save = () => {
        let body = {
            oldPassword: oldPassword,
            newPassword: password,
            repeatPassword: confirmPassword
        };
        Connect(`${Endpoint.changePassword}`, 'PUT', body).then(response => {
            if (response.status === 400) {
                setValidate('The old password is incorrect, or the confirmation password is different!')
            } else {
                handleEdit(false, '', '', 0);
            }
        });
    };

    return (
        <>
            <div className="profile">
                <div className="row margin">
                    <div className="box">
                        <div className="profile__header">{localStorage.getItem('i18nextLng') === 'en' ? 'Login details' : 'Logingegevens'}</div>
                        <div className="profile__box long">
                            <div className="profile__input main">
                                <div className="text light">{localStorage.getItem('i18nextLng') === 'en' ? 'Current password' : 'Huidig wachtwoord'}</div>
                                <input className="text" type="password" value={'********'} />
                                <div className="edit" onClick={() => handleEdit(true, 'password', password, 'password')}>
                                    {editAdminIcon}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EditProfile
                active={editState.active}
                handleActive={(open, name, value, index) => handleEdit(open, name, value, index)}
                handleSave={update}
                header={editState.header}
                inputName={editState.inputName}
                inputValue={editState.inputValue}
                index={editState.index}
                type={editState.type}
                strength={strength}
                handlePassword={handlePassword}
                password={password}
                oldPassword={oldPassword}
                confirmPassword={confirmPassword}
                validate={validate}
            />
        </>
    );
}

export default Security;
