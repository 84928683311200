import React, { useState } from 'react';
import { arrowDropdown } from "../login/loginConstants";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import { TextareaAutosize } from "@material-ui/core";
import {Trans} from "react-i18next";

function AdminFaq(props) {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');

    const handleFaq = (e) => {
        e.currentTarget.closest('.faq__item').classList.toggle('active');
    }

    const send = () => {
        let body = {
            'question': question,
            'answer': answer,
            'lang': localStorage.getItem('i18nextLng') == null ? 'en' : localStorage.getItem('i18nextLng')
        }
        Connect(`${Endpoint.postFAQ}`, 'POST', body).then(response => {
            props.handleData();
            setQuestion('');
            setAnswer('');
        });
    }

    return (
        <div className="support">
            <div className="faq">
                <div className="support__header"><Trans>admin.faq</Trans></div>
                {props.data.map((faq) => (
                    <div key={faq.id} className="faq__item">
                        <div className="faq__header">
                            <div className="text">{faq.question}</div>
                            <div className="svg" onClick={(e) => handleFaq(e)}>{arrowDropdown}</div>
                        </div>
                        <div className="faq__extend">
                            <div className="text">{faq.answer}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="support__form">
                <div className="container__body-textarea">
                    <p id="supportMessage" className="container__body-text"><Trans>admin.question</Trans></p>
                    <TextareaAutosize
                        minRows={3}
                        maxRows={3}
                        placeholder={'...'}
                        name={'question'}
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                    />
                </div>
                <div className="container__body-textarea">
                    <p id="supportMessage" className="container__body-text"><Trans>admin.answer</Trans></p>
                    <TextareaAutosize
                        minRows={3}
                        maxRows={3}
                        placeholder={'...'}
                        name={'answer'}
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                    />
                </div>
                <div className="row">
                    <button className="btn btn--primary" onClick={() => send()}><Trans>admin.addFaq</Trans></button>
                </div>
            </div>
        </div>
    );
}

export default AdminFaq;
