import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from 'react-router-dom';
import './_login.scss'
import Logo from "../../assets/icon/logo.svg";
import GoogleIcon from "../../assets/google.png";
import MicrosoftIcon from "../../assets/icon/microsoft.png";
import validate from "../validate/validate";
import {Link} from "react-router-dom";
import * as Endpoint from '../../configFile';
import Slider from 'infinite-react-carousel';
import {messagesIcon, lockIcon, checkIcon, passwordEye} from './loginConstants'
import Cookies from 'universal-cookie';
import { withCookies } from 'react-cookie';
import {Trans, withTranslation, useTranslation} from "react-i18next";
import {SelectCustom} from "../select/select";
import {languageOptions} from "../mainContainer/mainContainer";
import Welcome from '../../assets/welcome.png';
import RememberMe from "../rememberMe/rememberMe";

export const slider = () => {
    const settings = {
        arrows: true,
        autoplay: true,
        duration: 500,
        shift: 20,
        autoplaySpeed: 5000,
        dots: true
    };

    return <Slider {...settings} className="login__slider">
        <div>
            <h3 className="login__slider--item slide">
                <Trans>homepage.slider.financial</Trans>
            </h3>
        </div>
        <div>
            <h3 className="login__slider--item slide">
                <Trans>homepage.slider.document</Trans>
            </h3>
        </div>
        <div>
            <h3 className="login__slider--item slide">
                <Trans>homepage.slider.source</Trans>
            </h3>
        </div>
    </Slider>
}

const Login = (props) => {
    const {t} = useTranslation();
    const [forgot, setForgot] = useState(false),
        [isChecked, setIsChecked] = useState(false),
        [rememberMe, setRememberMe] = useState(false),
        [language, setLanguage] = useState({ label: (typeof window !== 'undefined') ? localStorage.getItem('i18nextLng') == null ? 'en' : localStorage.getItem('i18nextLng') : 'en', value: (typeof window !== 'undefined') ? localStorage.getItem('i18nextLng') == null ? 'en' : localStorage.getItem('i18nextLng') : 'en' }),
        isMobile = window.innerWidth <= 768;
    const history = useHistory();

    localStorage.removeItem('login');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('permissions');
    sessionStorage.removeItem('avatar');
    sessionStorage.removeItem('companyType');
    sessionStorage.removeItem('companyTypeData');
    sessionStorage.removeItem('userEmail');
    sessionStorage.removeItem('userID');
    const cookies = new Cookies(),
        data = cookies.get('loginData');
    //
    // if(data !== undefined && !rememberMe){
    //     setRememberMe(true)
    // }

    const location = useLocation();
    const path = location.state ? location.state.from.pathname : null;

    localStorage.setItem("redirect", path);

    useEffect(() => {
        if(data !== undefined) {
            if (document.getElementById('password') !== null) {
                document.getElementById('login').value = data.email;
                document.getElementById('password').value = data.password;
            }
            setIsChecked(true);
        }
        sessionStorage.removeItem('companyTypeStep');
    }, []);

    const sendLogin = (e) => {
        e.preventDefault()
        if(validate() !== 1) return true;
        const sendData = {
            email: document.getElementById('login').value,
            password: document.getElementById('password').value,
            // remember: true,
            language
        }

        return(
            fetch( Endpoint.sendLogin , {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(sendData),
                headers: {
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': props.cookies.get('XSRF-TOKEN')
                },
            })
                .then(response => {if (!response.ok) {
                    throw response
                }
                    return response.json()
                })
                .then(data => {
                    // document.querySelector('.login--error').classList.add('d-none');
                    localStorage.setItem('login', 'true');
                    localStorage.setItem('role', data.user.role);
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('user', JSON.stringify(data.user))
                    localStorage.setItem('permissions', JSON.stringify(data.permissions))
                    if (isChecked === true) {
                        localStorage.setItem('username', '');
                    }

                    if(path != null){
                        localStorage.removeItem("redirect");
                        window.location.pathname = path
                    }else{
                        data.user.role === 'admin' ? history.push('/admin') : history.push('/dashboard')
                    }
                    // create a cookie if user want be remembered
                    const rememberMeCookies = {email: document.getElementById('login').value, token: data.token, password: document.getElementById('password').value}

                    if(document.getElementById('rememberMe').checked){
                        cookies.set('loginData', rememberMeCookies, { path: '/' });
                    } else {
                        cookies.remove('loginData', { path: '/' });
                    }
                })
                .catch((error) => {
                    console.log('Error:', error);
                    if (error.status === 401) {
                        document.querySelector('#label--error') && document.querySelector('#label--error').classList.remove('d-none');
                    } else {
                        document.querySelector('#label--error-server') && document.querySelector('#label--error-server').classList.remove('d-none');
                    }
                    // document.querySelector('.login--error').classList.remove('d-none');
                })
        )
    };

    const forgotPassword = (e) => {
        e.preventDefault()
        if(validate() !== 1) return true;

        fetch( Endpoint.forgotPassword , {
            method: 'POST',
            credentials: 'include',
            headers: {
                'X-XSRF-TOKEN': props.cookies.get('XSRF-TOKEN')
            },
            body: document.getElementById('login').value,
        }).then(response => {if (!response.ok) {
            throw response
        }
            return response
        })
        .then(() => {
            console.log('success')
            document.getElementById("login--form").style.display = "none"
            document.getElementById("finish-msg-password").classList.add('show')
        })
        .catch((error) => {
            console.log('Error:', error);
            error.status === 400 && (document.querySelector('.email--error').innerHTML = "This email address doesn't exist in the database")
            error.status === 401 && (document.querySelector('.email--error').innerHTML = "There is no such email")
            if (error.status === 400) {
                document.querySelector('#label--error-forgot').classList.remove('d-none');
                document.querySelector('#label--error-server').classList.add('d-none');
                document.querySelector('#label--error-forgotMail').classList.add('d-none');
            } else if (error.status === 401) {
                document.querySelector('#label--error-forgotMail').classList.remove('d-none');
                document.querySelector('#label--error-server').classList.add('d-none');
                document.querySelector('#label--error-forgot').classList.add('d-none');
            } else {
                document.querySelector('#label--error-server').classList.remove('d-none');
                document.querySelector('#label--error-forgot').classList.add('d-none');
                document.querySelector('#label--error-forgotMail').classList.add('d-none');
            }
        })
    };

    const changeForgot = () => {
        setForgot(true);
        // document.querySelector('.login--error').classList.add('d-none');
        document.querySelector('#label--error').classList.add('d-none');
        document.querySelector('#label--error-server').classList.add('d-none');
    }

    const checkEmail = (value) => {
        const checkEmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        const validEmail = checkEmailPattern.test(String(value).toLowerCase());
        const mailCorrectElement = document.querySelector('.email--error').style;
        validEmail ? mailCorrectElement.display = "none" : mailCorrectElement.display = "block"
    }

    const loginRememberMe = (e) => {
        e.preventDefault()
        localStorage.setItem('token', data.token);

        fetch(Endpoint.getPersonData,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + data.token
            },
        }).then(response => {if (!response.ok) {
            throw response
        }
            return response.json()
        })
            .then(data => {
                localStorage.setItem('login', 'true');
                localStorage.setItem('role', data.user.role);
                localStorage.setItem('unseen', data.unseen)
                localStorage.setItem('unseenShow', 'false')
                localStorage.setItem('user', JSON.stringify(data.user))
                if(path != null){
                    localStorage.removeItem("redirect");
                    window.location.pathname = path
                }else{
                    data.user.role === 'admin' ? window.location.href = '/clients' : window.location.href = '/company'
                }
            })
            .catch((error) => {
                console.log('Error:', error);
                document.querySelector('.login--error').classList.remove('d-none');
            })
    }

    const changeLanguage = (event) => {
        let newLang = event.value;
        props.i18n.changeLanguage(newLang);
        setLanguage(event)
        sessionStorage.setItem('i18nextLng', newLang);
    };

    const handlePasswordEye = (event) => {
        let target = event.target.closest('.input--value').querySelector('input');
        if (target.getAttribute('type') === 'password') {
            target.setAttribute('type', 'text');
        } else {
            target.setAttribute('type', 'password');
        }
    }

    return (
        <main className="login">
            <section className="login__left">
                <div className="login__box">
                    <img src={Logo} alt="logo Bizantium" className="logo"/>
                    <div className="change-language">
                        <SelectCustom selectedValue={language}
                                      isEdit={true}
                                      options={languageOptions}
                                      onChange={(event)=> changeLanguage(event)} />
                    </div>
                </div>
                <div className="login--form" id="login--form">
                    {forgot && <h2 className="login__right--title">{t("homepage.forgot-password")}</h2>}
                    {!forgot && !isMobile && <h2 className="login__right--title">{t("homepage.login-title")}</h2>}
                    {!forgot && isMobile && <h2 className="login__right--title">
                        {t("homepage.create")}
                        <br/>
                        <br/>
                        <Link to='/signup' className="btn btn--primary"><Trans>homepage.sign-up</Trans></Link>
                        <br/>
                        <br/>
                        {t("homepage.or")}</h2>}

                    <span className="login__left--subtitle">{t("homepage.login-description")}</span>
                    {rememberMe ?
                        <form
                              className="login__right--container rememberMe">
                            <div className="inputs-wrapper">
                                <div className={`input-wrapper item item--color ${forgot && "forgot"}`}>
                                    <div className="input--icon">{messagesIcon}</div>
                                    <div className="input--value">
                                        <label htmlFor="login">{t("homepage.email")}</label>
                                        <input type="text" id="login" placeholder="mail" required
                                               onChange={e => checkEmail(e.target.value)}/>
                                        <label className="label--error d-none">{t("basic.required")}</label>
                                    </div>
                                    <div className="input--check d-none">{checkIcon}</div>
                                </div>
                            </div>
                            <div className="options-wrapper">
                                <div className="option">
                                    <input type="checkbox" id="rememberMe" name="rememberMe" value="rememberMe"
                                           onChange={() => {
                                               setRememberMe(false)
                                               cookies.remove('loginData', { path: '/' });
                                           }}/>
                                    <label htmlFor="rememberMe">{t("homepage.remember")}</label>
                                </div>
                            </div>

                            <p className={`login--error d-none`}><Trans>homepage.incorrect-email</Trans></p>
                            <input className="d-none" id="password"/>
                            <span className={"label--error global-validation email--error"}/>
                            <button type="submit" className="btn btn--primary"><Trans>homepage.login</Trans></button>
                        </form>
                        :
                        <form onSubmit={forgot ? forgotPassword : sendLogin} autoComplete="off"
                              className="login__right--container notRememberMe">
                            {!forgot &&
                                <div className="login__box hide">
                                    {!forgot && <>
                                        <a href={`${Endpoint.loginGoogle}?redirect_uri=/loginGoogle`} target="__blank"
                                           className="login__right--google"><img src={GoogleIcon} alt="google icon"/>
                                            <p>Sign in with google</p></a></>}
                                    {!forgot && <>
                                        <a href={`${Endpoint.loginGoogle}?redirect_uri=/loginGoogle`} target="__blank"
                                           className="login__right--google"><img src={MicrosoftIcon} alt="google icon"/>
                                            <p>Sign in with Microsoft</p></a></>}
                                </div>
                            }
                            {!forgot &&
                                <div className="login__box hide">
                                    <div className="login__line"/>
                                    <p className="login__alternative">OR</p>
                                    <div className="login__line"/>
                                </div>
                            }
                            <div className="inputs-wrapper">
                                <div className={`input-wrapper item item--color ${forgot && "forgot"}`}>
                                    <div className="input--icon">{messagesIcon}</div>
                                    <div className="input--value">
                                        {/*<label htmlFor="login">{t("homepage.email")}</label>*/}
                                        <input type="text" id="login" placeholder={t("homepage.email")} autoComplete="off"
                                               onChange={e => checkEmail(e.target.value)}/>
                                        <label className="label--error d-none">{t("basic.required")}</label>
                                    </div>
                                    <div className="input--check d-none">{checkIcon}</div>
                                </div>
                                {!forgot && <div className="input-wrapper item item--white">
                                    <div className="input--icon">{lockIcon}</div>
                                    <div className="input--value">
                                        {/*<label htmlFor="password">{t("homepage.password")}</label>*/}
                                        <input type="password" id="password" placeholder={t("homepage.password")} autoComplete="new-password" />
                                        <div className="passwordEye" onClick={(e) => handlePasswordEye(e)}>
                                            {passwordEye}
                                        </div>
                                        <label className="label--error d-none">{t("basic.required")}</label>
                                    </div>
                                </div>}
                            </div>
                            {!forgot && <div className="options-wrapper">
                                <RememberMe isChecked={isChecked} handleChecked={setIsChecked} />
                                {/*<div className="option">*/}
                                {/*    <input type="checkbox" id="rememberMe" name="rememberMe" value="rememberMe"/>*/}
                                {/*    <label htmlFor="rememberMe">{t("homepage.remember")}</label>*/}
                                {/*</div>*/}
                                {/*<div className="login__checkbox d-none">*/}
                                {/*    <Checkbox className="checkbox" color={'#301164'} name={'rememberMe'}/>*/}
                                {/*    <lanel htmlFor={'rememberMe'}>{t("homepage.remember")}</lanel>*/}
                                {/*</div>*/}
                                <span className="option" onClick={changeForgot}>{t("homepage.forgot?")}</span>
                            </div>}

                            {window.location.href.split('?error=').length > 1 && window.location.href.split('?error=')[1] &&
                                <p className="provider"><Trans>homepage.incorrect-provider</Trans></p>}

                            {/*<p className={`login--error d-none`}>{forgot ? 'The message has been sent to the supplied address' : 'Supplied email or password is not register in out service.'}</p>*/}
                            <span className={"label--error global-validation email--error"} style={{display: "none"}}></span>
                            <div className="login__box">
                                <button type="submit" className="btn btn--primary">{forgot ? <Trans>basic.send</Trans> : <Trans>homepage.login</Trans>}</button>
                                <Link to='/signup' type="button" className="btn btn--secondary">{t("homepage.sign-up")}</Link>
                                <label id="label--error" className="label--error d-none">{localStorage.getItem('i18nextLng') === 'en' ? 'Wrong Email or password. Try again!' : '\n' +
                                    'Verkeerde email of wachtwoord. Probeer het nog eens!'}</label>
                                <label id="label--error-forgot" className="label--error d-none">{localStorage.getItem('i18nextLng') === 'en' ? 'This email address doesnt exist in the database!' : 'Dit e-mailadres bestaat niet in de database!'}</label>
                                <label id="label--error-forgotMail" className="label--error d-none">{localStorage.getItem('i18nextLng') === 'en' ? 'There is no such email!' : 'Een dergelijke e-mail bestaat niet!'}</label>
                                <label id="label--error-server" className="label--error d-none">{localStorage.getItem('i18nextLng') === 'en' ? 'The server is unavailable. Try later.' : 'De server is niet beschikbaar. Probeer later.'}</label>
                            </div>

                        </form>
                    }

                </div>

                <div className="login--finish" id="finish-msg-password">
                    <svg width="181" height="126" viewBox="0 0 181 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_5033_38247)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M174.613 124.389C185.347 97.5733 182.1 67.6308 168.256 44.6342L23.3348 64.6844L162.215 35.7636C103.683 -37.1694 -10.2415 11.2061 0.740232 103.289L174.613 124.389Z" fill="#EBF2FF"/>
                            <circle cx="89.5" cy="88.889" r="36.5" fill="#4A5BCF"/>
                            <path d="M86.5 94.3109L81.5781 89.389L79.9922 91.0296L86.5 97.5374L100.5 83.5374L98.8594 81.8968L86.5 94.3109Z" fill="white"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_5033_38247">
                                <rect width="181" height="125" fill="white" transform="translate(0 0.388977)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <div className="login__finish--title">{localStorage.getItem('i18nextLng') === 'en' ? 'Forgot password!' : 'Bedankt voor het aanmelden!'}</div>
                    <div className="login__finish--subtitle">
                        {localStorage.getItem('i18nextLng') === 'en' ?
                            'The email to change your password has been sent' :
                            <>
                                Klik op de bevestigingslink in de e-mail om je account te verifiëren en aan de slag te gaan.
                            </>
                        }
                    </div>
                    <div className="login__box finish">
                        <Link to='/login' type="button" className="btn btn--primary" onClick={() => window.location.reload()}><Trans>homepage.login</Trans></Link>
                    </div>
                </div>
            </section>
            <section className="login__right">
                <h1 className="login__left--title">{t("homepage.welcome")}</h1>
                <span className="login__left--subtitle award">{t("homepage.ready")}</span>

                <img src={Welcome} alt="Welcome" className="login__right--picture"/>

                {/*<span className="login__left--subtitle bold">{t("homepage.no_account")}</span>*/}
                {/*<Link to='/signup' className="btn btn--primary">{t("homepage.sign-up")}</Link>*/}

                <div className="login__welcome">
                    <span className="login__left--subtitle bold">{t("homepage.bizantium")}</span>
                    {/*<span className="login__left--description">{t("homepage.bizantium-description")}</span>*/}
                </div>

                {slider()}

            </section>
        </main>
    )
}
export default withTranslation()(withCookies(Login));