import React, { useEffect, useState } from "react";
import './footer.scss';
import { NavLink } from "react-router-dom";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import {Trans} from "react-i18next";
import {Output} from "../companyType/output";
import { toast } from 'react-toastify';

function Footer(props) {
    const [admin] = useState(props.admin);

    useEffect(() => {
        return () => {
            changeStep('+', true);
        };
    }, []);

    const handleSaveCompanyDetails = () => {
        let body = JSON.parse(sessionStorage.getItem('companyDetails'));
        Connect(`${Endpoint.companyDetails}`, 'PATCH', body).then(response => {
            // if (response.length > 0) {
            //     let body = {
            //         checkpointCategory: JSON.parse(sessionStorage.getItem('checkpointCategory'))[page],
            //         completed: 10,
            //         required: 10,
            //         isChange: true
            //     };
            //     Connect(`${Endpoint.putProgressSurvey}`, 'PUT', body).then(response => {
            //         console.log(response);
            //     });
            // }
        });
    }

    const answer = (type, value, number, page, step) => {
        return {
            textAnswer: type === 'text' ? value : null,
            numericAnswer: type === 'numeric' ? value : null,
            questionOptions: type === 'select' ? value : [],
            user: {
                id: JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).id
            },
            question: {
                id: JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[page].steps[step].questions[number].id,
                questionText: JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[page].steps[step].questions[number].questionText,
                questionTextKey: JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[page].steps[step].questions[number].questionTextKey
            }
        }
    }

    const adminAnswer = (type, value, number, page, step) => {
        return {
            textAnswer: type === 'text' ? value : null,
            numericAnswer: type === 'numeric' ? value : null,
            questionOptions: type === 'select' ? value : [],
            question: {
                id: JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[page].steps[step].questions[number].id,
                questionText: JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[page].steps[step].questions[number].questionText,
                questionTextKey: JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[page].steps[step].questions[number].questionTextKey
            }
        }
    }

    const save = () => {
        let body = {}
        if (props.currentStep === 1) {
            body = [
                adminAnswer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).name, 0, 0, 0),
                adminAnswer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).surname, 1, 0, 0),
                adminAnswer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).phone, 3, 0, 0),
                adminAnswer('select', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).nationality, 4, 0, 0)
            ]
            props.save(body, JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[0].steps[0].id, 5, 0);
        } else if (props.currentStep === 2) {
            body = [
                adminAnswer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).dateStart, 0, 1, 0),
                adminAnswer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyName, 1, 1, 0),
                adminAnswer('select', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).occupation, 2, 1, 0),
                adminAnswer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyActivities, 3, 1, 0),
                adminAnswer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyActivitiesDescription, 4, 1, 0),
                adminAnswer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).postalCode, 5, 1, 0),
                adminAnswer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).city, 6, 1, 0),
            ]
            props.save(body, JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[1].steps[0].id, 7, 1);
        } else if (props.currentStep === 3) {
            let required = 1;
            body = [
                adminAnswer('numeric', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenue.replaceAll('.', ''), 0, 2, 0),
                adminAnswer('select', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectNextYear, 1, 2, 0),
            ]
            body.push(adminAnswer('numeric', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenueNextYear.replaceAll('.', ''), 2, 2, 0));
            if (sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenueNextYear && JSON.parse(sessionStorage.getItem('companyType')).revenueNextYear.length > 0) {
                body.push(adminAnswer('select', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear, 3, 2, 0));
                required++;
            }
            if (sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear.length > 0) {
                required++;
            }
            body.push(adminAnswer('numeric', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenueThirdYear.replaceAll('.', ''), 4, 2, 0));
            props.save(body, JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[2].steps[0].id, required, 2);
        }
    }

    const changeStep = (value, leave) => {
        let body = {}
        if (value === '-') {
            if (props.currentStep === 6 && (props.type === 'establishment') && sessionStorage.getItem('legalForm') !== 'LIMITED_COMPANY') {
                props.handleUpdateStep(props.currentStep - 2, leave);
            } else if (props.currentStep === 4 && (props.type === 'establishment') && sessionStorage.getItem('legalForm') !== 'LIMITED_COMPANY') {
                props.handleUpdateStep(props.currentStep - 3, leave);
            } else {
                props.handleUpdateStep(props.currentStep - 1, leave);
            }
        } else {
            if ((props.currentStep === 1) && (props.type === 'company')) {
                let validate = {
                    name: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).name && JSON.parse(sessionStorage.getItem('companyType')).name.length > 0 ? 'true' : 'false',
                    surname: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).surname && JSON.parse(sessionStorage.getItem('companyType')).surname.length > 0 ? 'true' : 'false',
                    nationality: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).nationality && JSON.parse(sessionStorage.getItem('companyType')).nationality.length > 0 ? 'true' : 'false'
                }
                props.handleValidate(validate);
                if (validate.name === 'true' && validate.surname === 'true' && validate.nationality === 'true') {
                    props.handleUpdateStep(props.currentStep + 1, leave);
                    body = [
                        answer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).name, 0, 0, 0),
                        answer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).surname, 1, 0, 0),
                        answer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).phone, 3, 0, 0),
                        answer('select', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).nationality, 4, 0, 0)
                    ]
                    props.save(body, JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[0].steps[0].id, 5, 0);
                }
            } else if ((props.currentStep === 2) && (props.type === 'company')) {
                let validate = {
                    dateStart: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).dateStart && JSON.parse(sessionStorage.getItem('companyType')).dateStart.length > 0 ? 'true' : 'false',
                    companyName: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyName && JSON.parse(sessionStorage.getItem('companyType')).companyName.length > 0 ? 'true' : 'false',
                    occupation: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).occupation && JSON.parse(sessionStorage.getItem('companyType')).occupation.length > 0 ? 'true' : 'false',
                    companyActivities: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyActivities && JSON.parse(sessionStorage.getItem('companyType')).companyActivities.length > 0 ? 'true' : 'false',
                    postalCode: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).postalCode && JSON.parse(sessionStorage.getItem('companyType')).postalCode.length > 0 ? 'true' : 'false',
                }
                props.handleValidate(validate);
                if (validate.dateStart === 'true' && validate.companyName === 'true' && validate.occupation === 'true' && validate.companyActivities === 'true' && validate.postalCode === 'true') {
                    props.handleUpdateStep(props.currentStep + 1, leave);
                    body = [
                        answer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).dateStart, 0, 1, 0),
                        answer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyName, 1, 1, 0),
                        answer('select', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).occupation, 2, 1, 0),
                        answer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyActivities, 3, 1, 0),
                        answer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).companyActivitiesDescription, 4, 1, 0),
                        answer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).postalCode, 5, 1, 0),
                        answer('text', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).city, 6, 1, 0),
                    ]
                    props.save(body, JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[1].steps[0].id, 7, 1);
                }
            } else if ((props.currentStep === 2) && (props.type === 'establishment')) {
                props.saveFetchShareholder(props.shareholderData.id, props.shareholderData.fullName, props.shareholderData.sharesAmount, props.shareholderData.street, props.shareholderData.houseNumber, props.shareholderData.postalCode, props.shareholderData.city);
                setTimeout(() => {
                    Connect(`${Endpoint.getShareholders}`, 'GET').then(response => {
                        let validate = false;
                        response.shareholders.map((data) => {
                            if (data.street === null || data.houseNumber === null || data.postalCode === null || data.city === null || data.street === '' || data.houseNumber === '' || data.postalCode === '' || data.city === '') {
                                validate = true;
                            }
                        })
                        props.handleValidate(validate);
                        if (validate === false) {
                            props.handleUpdateStep(props.currentStep + 1, leave);
                        }
                    })
                }, 400);
            } else if ((props.currentStep === 3) && (props.type === 'establishment')) {
                props.handleUpdateStep(props.currentStep + 1, leave);
            } else if ((props.currentStep === 1) && (props.type === 'establishment')) {
                handleSaveCompanyDetails();
                if (sessionStorage.getItem('legalForm') === 'LIMITED_COMPANY') {
                    props.handleUpdateStep(props.currentStep + 1, leave);
                } else {
                    props.handleUpdateStep(props.currentStep + 3, leave);
                }
            } else if (props.currentStep === 3 && (props.type === 'company')) {
                let validate = {
                    revenue: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenue && JSON.parse(sessionStorage.getItem('companyType')).revenue.length > 0 ? 'true' : 'false',
                    revenueNextYear: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectNextYear && JSON.parse(sessionStorage.getItem('companyType')).expectNextYear[0] && JSON.parse(sessionStorage.getItem('companyType')).expectNextYear[0].text === 'Yes' && sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenueNextYear && JSON.parse(sessionStorage.getItem('companyType')).revenueNextYear.length > 0 ? 'true' : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectNextYear && JSON.parse(sessionStorage.getItem('companyType')).expectNextYear[0] && JSON.parse(sessionStorage.getItem('companyType')).expectNextYear[0].text === 'Yes' ? 'false' : 'true',
                    revenueThirdYear: sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear[0] && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear[0].text === 'Yes' && sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenueThirdYear && JSON.parse(sessionStorage.getItem('companyType')).revenueThirdYear.length > 0 ? 'true' : sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear[0] && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear[0].text === 'Yes' ? 'false' : 'true',
                }
                props.handleValidate(validate);
                if (validate.revenue === 'true' && validate.revenueNextYear === 'true' && validate.revenueThirdYear === 'true') {
                    props.handleUpdateStep(props.currentStep + 1, leave);
                    let required = 1;
                    body = [
                        answer('numeric', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenue.replaceAll('.', ''), 0, 2, 0),
                        answer('select', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectNextYear, 1, 2, 0),
                    ]
                    body.push(answer('numeric', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenueNextYear.replaceAll('.', ''), 2, 2, 0));
                    if (sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenueNextYear && JSON.parse(sessionStorage.getItem('companyType')).revenueNextYear.length > 0) {
                        body.push(answer('select', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear, 3, 2, 0));
                        required++;
                    }
                    if (sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear.length > 0) {
                        required++;
                    }
                    body.push(answer('numeric', sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).revenueThirdYear.replaceAll('.', ''), 4, 2, 0));
                    props.save(body, JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[2].steps[0].id, required, 2);
                }
            } else if (props.currentStep === 4 && (props.type === 'company')) {
                let {officeSpace, officeSpaceRent} = props.data;
                let {ownOffice, rentOffice} = props.validateForm.checkboxState;
        
                if(ownOffice && (!officeSpace || officeSpace === '')){
                       return toast.error(localStorage.getItem("i18nextLng") === "en" ? "Complete the required fields." : "Vul de verplichte velden in.")
                }
                if(rentOffice && (!officeSpaceRent || officeSpaceRent === '')){
                      return  toast.error(localStorage.getItem("i18nextLng") === "en" ? "Complete the required fields." : "Vul de verplichte velden in.")
                }
                props.handleUpdateStep(props.currentStep + 1, leave);
            } else if (props.currentStep === 4 && (props.type === 'establishment')) {
                if (sessionStorage.getItem('legalForm') === 'LIMITED_COMPANY') {
                    props.handleUpdateStep(props.currentStep + 1, leave)
                } else {
                    props.handleUpdateStep(props.currentStep + 2, leave)
                }
            } else if (props.currentStep === 4 && (props.type !== 'company')) {
                props.handleUpdateStep(props.currentStep + 1, leave)
            } else if ((props.currentStep === 5) && (props.type === 'establishment')) {
                let validate = [];
                let arr = false;
                if (sessionStorage.getItem('isPropertyCardOn') && sessionStorage.getItem('isPropertyCardOn') === 'true') {
                    if (sessionStorage.getItem('cadastralIncome') && (sessionStorage.getItem('cadastralIncome') === 'null' || sessionStorage.getItem('cadastralIncome') < 1)) {
                        arr = true;
                    }
                    if (sessionStorage.getItem('propertyOfficePercent') && sessionStorage.getItem('propertyOfficePercent').length < 1) {
                        arr = true;
                    }
                }
                let arr2 = false;
                if (sessionStorage.getItem('isCommutesCardOn') && sessionStorage.getItem('isCommutesCardOn') === 'true') {
                    if (sessionStorage.getItem('professionalCommutes') && sessionStorage.getItem('professionalCommutes') < 1) {
                        arr2 = true;
                    }
                }
                let arr3 = false;
                if (sessionStorage.getItem('isCarCardOn') && sessionStorage.getItem('isCarCardOn') === 'true') {
                    if (sessionStorage.getItem('brand') && sessionStorage.getItem('brand').length < 1) {
                        arr3 = true;
                    }
                    if (sessionStorage.getItem('model') && sessionStorage.getItem('model').length < 1) {
                        arr3 = true;
                    }
                    if (sessionStorage.getItem('fuel') && sessionStorage.getItem('fuel').length < 1) {
                        arr3 = true;
                    }
                    if (sessionStorage.getItem('trimline') && sessionStorage.getItem('trimline').length < 1) {
                        arr3 = true;
                    }
                    if (sessionStorage.getItem('carInitialRegDate') && sessionStorage.getItem('carInitialRegDate').length < 1) {
                        arr3 = true;
                    }
                }
                let arr4 = false;
                if (sessionStorage.getItem('isMealVouchersCardOn') && sessionStorage.getItem('isMealVouchersCardOn') === 'true') {
                    if (sessionStorage.getItem('mealVouchers') && sessionStorage.getItem('mealVouchers') < 1) {
                        arr4 = true;
                    }
                }
                let arr5 = true;
                if (sessionStorage.getItem('startDate')) {
                    arr5 = false;
                }
                validate.push(arr);
                validate.push(arr2);
                validate.push(arr3);
                validate.push(arr4);
                validate.push(arr5);
                console.log(validate)
                props.handleValidate(validate);
                if (validate[0] === false && validate[1] === false && validate[2] === false && validate[3] === false && validate[4] === false) {
                    props.handleUpdateStep(props.currentStep + 1, leave);
                }
            } else if ((props.currentStep === 6) && (props.type === 'establishment')) {
                props.handleUpdateStep(props.currentStep + 1, leave);
            } else if ((props.currentStep === 7) && (props.type === 'establishment')) {
                props.handleUpdateStep(props.currentStep + 1, leave);
            } else if ((props.currentStep === 8) && (props.type === 'establishment')) {
                props.handleUpdateStep(props.currentStep + 1, leave);
            } else if ((props.currentStep === 9) && (props.type === 'establishment')) {
                props.handleUpdateStep(props.currentStep + 1, leave);
            } else if ((props.currentStep === 1) && (props.type === 'companyAdmin')) {
                props.handleUpdateStep(props.currentStep + 1, leave);
            } else if ((props.currentStep === 2) && (props.type === 'companyAdmin')) {
                props.handleUpdateStep(props.currentStep + 1, leave);
            } else if ((props.currentStep === 3) && (props.type === 'companyAdmin')) {
                props.handleUpdateStep(props.currentStep + 1, leave);
            } else if ((props.currentStep === 4) && (props.type === 'companyAdmin')) {
                props.handleUpdateStep(props.currentStep + 1, leave);
            }
        }
    }

    return (
        <div className="footer">
            {admin === true ?
                <>
                    {props.currentStep !== 1 ?
                        <button className="btn btn--secondary" onClick={() => changeStep('-', false)}><Trans>survey.back</Trans></button>
                        : <div></div>}
                    <div className="row">
                        <button className="btn btn--primary next d-none" />
                        <button className="btn btn--primary next" onClick={() => save()}><Trans>admin.save</Trans></button>
                        <Output type={'admin'} outputData={props.outputData} graphSummary={props.graphSummary} data={props.costData} summary={props.summary} form={props.data} loading={props.loading} />
                        <button className="btn btn--primary next" onClick={() => changeStep('+', false)}><Trans>survey.next</Trans></button>
                    </div>
                </>
                :
                <>
                    {props.currentStep !== 1 ?
                        <button className="btn btn--secondary" onClick={() => changeStep('-', false)}><Trans>survey.back</Trans></button>
                        : <div></div>}
                    <button className="btn btn--primary next" onClick={() => changeStep('+', false)}><Trans>survey.next</Trans></button>
                    <NavLink to={'dashboard'} className="btn btn--primary dashboard__button d-none">{localStorage.getItem('i18nextLng') === 'en' ? 'To dashboard' : 'Naar dashboard'}</NavLink>
                </>
            }
        </div>
    );
}

export default Footer;
