import React, {useState} from 'react';
import './editProfile.scss';
import { TextField } from "@material-ui/core";
import {closePopup, passwordEye} from "../login/loginConstants";
import {Trans} from "react-i18next";
import DatePicker from "react-datepicker";
import {calendar} from "../survey/surveyConstant";
import InputMask from 'react-input-mask';

function EditProfile(props) {
    const [startDate, setStartDate] = useState(null);

    const handleDate = (date) => {
        setStartDate(date);
        props.handleActive(true, props.inputName, `${date.getFullYear()}-${date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`, props.index)
    };

    const handlePasswordEye = (event) => {
        let target = event.target.closest('.container__body-input').querySelector('input');
        if (target.getAttribute('type') === 'password') {
            target.setAttribute('type', 'text');
        } else {
            target.setAttribute('type', 'password');
        }
    }

    console.log(props)
    return (
        <div className={props.active === true ? "editProfile__wrapper active" : "editProfile__wrapper"}>
            <div className="editProfile__content">
                <div className="editProfile__content-close" onClick={() => props.handleActive(false, '', '', 0)}>{closePopup}</div>
                <div className="editProfile__content-header">{props.header} {props.inputName}</div>
                {props.inputName === 'password' ?
                    <>
                        <div className="container__body-input">
                            <p className="container__body-text">{localStorage.getItem('i18nextLng') === 'en' ? 'Old password' : 'Oud Wachtwoord'}</p>
                            <div className="passwordEye" onClick={(e) => handlePasswordEye(e)}>
                                {passwordEye}
                            </div>
                            <TextField label={''} variant={'outlined'} size={'small'} placeholder={'...'} type="password" value={props.oldPassword} onChange={(e) => props.handlePassword && props.handlePassword(e.target.value, 'oldPassword')} />
                        </div>
                        <div className="container__body-input">
                            <p className="container__body-text">{localStorage.getItem('i18nextLng') === 'en' ? 'New password' : 'Nieuw paswoord'}</p>
                            <div className="passwordEye" onClick={(e) => handlePasswordEye(e)}>
                                {passwordEye}
                            </div>
                            <TextField label={''} variant={'outlined'} size={'small'} placeholder={'...'} type="password" value={props.password} onChange={(e) => props.handlePassword && props.handlePassword(e.target.value, 'password')} />
                        </div>
                        <div className="container__body-input">
                            <p className="container__body-text">{localStorage.getItem('i18nextLng') === 'en' ? 'Confirm password' : 'Bevestig wachtwoord'}</p>
                            <div className="passwordEye" onClick={(e) => handlePasswordEye(e)}>
                                {passwordEye}
                            </div>
                            <TextField label={''} variant={'outlined'} size={'small'} placeholder={'...'} type="password" value={props.confirmPassword} onChange={(e) => props.handlePassword && props.handlePassword(e.target.value, 'confirmPassword')} />
                        </div>
                        <div className="editProfile__strength">
                            <div className={props.strength >= 1 ? "editProfile__strength-item active" : "editProfile__strength-item"}></div>
                            <div className={props.strength >= 2 ? "editProfile__strength-item active" : "editProfile__strength-item"}></div>
                            <div className={props.strength >= 3 ? "editProfile__strength-item active" : "editProfile__strength-item"}></div>
                            <div className={props.strength >= 4 ? "editProfile__strength-item active" : "editProfile__strength-item"}></div>
                        </div>
                        <div className="text">{localStorage.getItem('i18nextLng') === 'en' ? 'Your password should contain at least 1 letter, 1 number, 1 symbol and be at least 8 characters.' : 'Uw wachtwoord moet minimaal 1 letter, 1 cijfer en 1 symbool bevatten.'}</div>
                        {props.validate !== '' ?
                            <div className="text error">{props.validate}</div>
                        : ''}
                    </>
                :
                    <div className="container__body-input">
                        <p className="container__body-text">{props.inputName}</p>
                        {props.index === 'dateOfBirth' ?
                            <>
                                <DatePicker
                                    id='dateStart'
                                    placeholderText="Select a date"
                                    selected={startDate}
                                    onChange={(date) => handleDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    autoComplete="off"
                                    onKeyDown={e => e.preventDefault()}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                <div className="calendarIcon">
                                    {calendar}
                                </div>
                            </>
                        :
                        <>
                        {
                            props.type === 'phone' ?   
                            <InputMask mask="+(99) 999 999 999 999 999 999" maskChar=" " class="phoneInput" autoComplete={'new-password'} value={props.inputValue} onChange={(e) => props.handleActive(true, props.inputName, e.target.value, props.index, props.type)} ></InputMask> : 
                            <TextField label={''} variant={'outlined'} size={'small'} placeholder={'...' }value={props.inputValue} onChange={(e) => props.handleActive(true, props.inputName, e.target.value, props.index)}/>
                        }
                        </>
                       
                          
                        }
                    </div>
                }

                <div className="row">
                    <button className="btn btn--primary" onClick={() => props.handleSave(false)}>{props.inputName === 'password' ? <Trans>basic.save-2</Trans> : 'OK'}</button>
                </div>
            </div>
        </div>
    );
}

export default EditProfile;
