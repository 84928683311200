import React, { useState } from "react";
import './_settings.scss';
import * as Constants from './settingsConstants'
import * as Endpoint from "../../configFile";
import SaveButton from "../saveButton/saveButton";
import { Trans } from "react-i18next";

const Settings = (props) => {
    const [state, setState] = useState({
        id: props.request.id,
        picture: props.request.picture,
        name: props.request.name,
        surname: props.request.surname,
        email: props.request.email,
        function: props.request.function,
        description: props.request.description,
        phone: props.request.phone
    });

    // const toBase64 = (file) => new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => resolve(reader.result);
    //     reader.onerror = (error) => reject(error);
    // });

    const handleChangeInput = (e) => {
        const inputType = e.target.getAttribute('name');
        setState({ ...state, [inputType]: e.target.value });
    };

    // const handleChangeLogo = async (elem) => {
    //     let img = `[${elem.name}]${await toBase64(elem)}`;
    //     setState((prevState) => ({ ...prevState, picture: img }));
    //     document.querySelector('.modal__logo').setAttribute('style', 'background-image: url("")');
    //     let reader = new FileReader();
    //
    //     reader.onload = function (e) {
    //         document.querySelector('.modal__logo').setAttribute('style', `background-image: url("${e.target.result}")`);
    //     };
    //
    //     reader.readAsDataURL(elem);
    // };

    const onlyRead = props.onlyRead;

    const settingsFormLeft = Constants.settingsForm.map((formInput) => (
        <div className="input-group" key={formInput.attr}>
            <label htmlFor={formInput.attr}>{formInput.name}</label>
            <input
                type="text"
                name={formInput.attr}
                id={formInput.attr}
                value={state[formInput.state]}
                onChange={handleChangeInput}
                required={formInput.required}
                readOnly={onlyRead}
            />
            <label className="label--error d-none"><Trans>basic.required</Trans></label>
        </div>
    ));

    return (
        <>
            {!onlyRead && <h2 className="header__title"><Trans>menu.profile</Trans></h2>}
            <div className={`${!onlyRead && "container__body"} container--settings`}>
                <h2 className="container__title"><Trans>profile.info</Trans></h2>
                <div className="settings--logo">
                    {!onlyRead && <div className={state.picture === '' ? "logo--icon" : "logo--icon icon--delete"} onClick={() => setState({ ...state, picture: null })} />}
                </div>

                <div className="settings--inputs">
                    <div className="settings__block">
                        {settingsFormLeft}
                    </div>
                    <div className="settings__block">
                        <div className="input-group group--textarea">
                            <label htmlFor="description"><Trans>profile.desc</Trans></label>
                            <textarea name="description" id="description" value={state.description} onChange={handleChangeInput} readOnly={onlyRead} />
                        </div>
                    </div>
                </div>

                {!onlyRead && <SaveButton url={Endpoint.sendPersonData} data={JSON.stringify(state)} type={'myProfile'} />}
            </div>
        </>
    );
};

export default Settings;
