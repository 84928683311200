import React, { useEffect } from "react";
import '../establishmentFlow/finish.scss';
import './ready.scss';
import simulation from '../../assets/siimulation.png';
import {Output} from "./output";

function Ready() {
    useEffect(() => {
        document.querySelector('.navbar__menu').classList.add('output');
        document.querySelector('.footer .btn.next').classList.add('d-none');

        return () => {
            document.querySelector('.navbar__menu').classList.remove('output');
            document.querySelector('.footer .btn.next').classList.remove('d-none');
        };
    }, []);

    return (
        <div className="container__body finish ready">
            <div className="finish">
                <img alt="" src={simulation} />
                {sessionStorage.getItem('activeOutput') === '1' ?
                    <>
                        <h2>{localStorage.getItem('i18nextLng') === 'en' ? 'Your simulation is ready!' : 'Je simulatie is klaar!'}</h2>
                        <p>{localStorage.getItem('i18nextLng') === 'en' ? 'Find out what company type best suits your needs.' : 'Ontdek welk bedrijfstype het beste bij uw behoeften past.'}</p>
                    </>
                :
                    <>
                        <h2>{localStorage.getItem('i18nextLng') === 'en' ? 'We’re working on your simulation!' : 'We werken aan uw simulatie!'}</h2>
                        <p>{localStorage.getItem('i18nextLng') === 'en' ? 'We’ll notify you once it’s ready. You can access it here, or from your Dashboard.' : 'We laten het u weten zodra het klaar is. U kunt deze hier of via uw Dashboard openen.'}</p>
                    </>
                }
                <Output type={'userFinish'} />
            </div>
        </div>
    );
}

export default Ready;
