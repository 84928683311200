import React from "react";
import './quickstart.scss';
import { documentIcon, switcherIcon } from "../login/loginConstants";
import { Link } from "react-router-dom";
import { OutputFlow } from "../establishmentFlow/outputFlow";
import {Output} from "../companyType/output";
import {Trans} from "react-i18next";

function Quickstart(props) {
    return (
        <div className={props.status === true ? "quickstart is-active" : "quickstart"}>
            <div className="quickstart__header">
                <div className="quickstart__text">
                    <p className="quickstart__title"><Trans>quickstart.quickstart</Trans></p>
                    <p className="quickstart__description"><Trans>quickstart.started</Trans></p>
                </div>
                <div className="switcher" onClick={() => props.handleQuickstart(false)}>{switcherIcon}</div>
            </div>
            <div className="quickstart__overview">
                <p className="quickstart__title"><Trans>quickstart.overview</Trans></p>
                <div className="quickstart__box">
                    <p className="quickstart__box-title"><Trans>quickstart.company</Trans></p>
                    <p className="quickstart__box-description"><Trans>quickstart.simulation</Trans></p>
                    <Output type={'user'} />
                </div>
                <div className="quickstart__box d-none">
                    <p className="quickstart__box-title"><Trans>quickstart.establishment</Trans></p>
                    <p className="quickstart__box-description"><Trans>quickstart.what</Trans></p>
                    <OutputFlow />
                </div>
                <div className="quickstart__box">
                    <p className="quickstart__box-title"><Trans>quickstart.call</Trans></p>
                    <p className="quickstart__box-description"><Trans>quickstart.after</Trans></p>
                    <Link to={'/support'}>
                        <button className="quickstart__box-btn"><Trans>quickstart.contact</Trans></button>
                    </Link>
                </div>
            </div>
            <div className="quickstart__steps">
                <div className="quickstart__step">
                    {documentIcon}
                    <p><Trans>quickstart.email</Trans></p>
                </div>
                <div className="quickstart__step d-none">
                    {documentIcon}
                    <p><Trans>quickstart.start</Trans></p>
                </div>
                <Link to={'/support'}>
                    <div className="quickstart__step">
                        {documentIcon}
                        <p><Trans>quickstart.discover</Trans></p>
                    </div>
                </Link>
                <div className="quickstart__step d-none">
                    {documentIcon}
                    <p><Trans>quickstart.add</Trans></p>
                </div>
            </div>
        </div>
    );
}

export default Quickstart;
