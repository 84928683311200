import React, { useState, useEffect } from "react";
import './personalSituation.scss';
import {FormControl, FormControlLabel, IconButton, Radio, TextField, Tooltip} from "@material-ui/core";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import RadioGroup from "@material-ui/core/RadioGroup";
import CustomSelect from "../customSelect/customSelect";
import {avatar, pdf, upload} from "../login/loginConstants";
import {InfoIcon} from "../survey/surveyConstant";
import {Trans} from "react-i18next";
import { toast } from "react-toastify";

const PersonalSituation = () => {
    const [married, setMarried] = useState(false);
    const [belgium, setBelgium] = useState(false);
    const [city, setCity] = useState("");
    const [maritalResidence, setMaritalResidence] = useState("");
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const [shareholders, setShareholders] = useState([]);
    const [selectList] = useState([
        { id: 1, name: localStorage.getItem('i18nextLng') === 'en' ? 'Statutory system' : 'Statutair systeem', value: localStorage.getItem('i18nextLng') === 'en' ? 'Statutory system' : 'Statutair systeem' },
        { id: 2, name: localStorage.getItem('i18nextLng') === 'en' ? 'Community of goods system' : 'Gemeenschap van goederen-systeem', value: localStorage.getItem('i18nextLng') === 'en' ? 'Community of goods system' : 'Gemeenschap van goederen-systeem' },
        { id: 3, name: localStorage.getItem('i18nextLng') === 'en' ? 'System of separation of goods' : 'Systeem van scheiding van goederen', value: localStorage.getItem('i18nextLng') === 'en' ? 'System of separation of goods' : 'Systeem van scheiding van goederen' }
    ]);
    const [firstYear] = useState(12);
    const [country, setCountry] = useState('');
    const [cityMarried, setCityMarried] = useState('');
    const [maritalSystem, setMaritalSystem] = useState(localStorage.getItem('i18nextLng') === 'en' ? 'Statutory system' : 'Statutair systeem');
    const [residence, setResidence] = useState('');
    const [addShareholderPhoto1, setAddShareholderPhoto1] = useState(null);
    const [addShareholderPhoto2, setAddShareholderPhoto2] = useState(null);
    const [addShareholderPhoto3, setAddShareholderPhoto3] = useState(null);
    const [addShareholderPhotoMy, setAddShareholderPhotoMy] = useState(null);
    const [addShareholderId1, setAddShareholderId1] = useState(null);
    const [addShareholderId2, setAddShareholderId2] = useState(null);
    const [addShareholderId3, setAddShareholderId3] = useState(null);
    const [addShareholderIdMy, setAddShareholderIdMy] = useState(null);
    const [addShareholderName1, setAddShareholderName1] = useState(null);
    const [addShareholderName2, setAddShareholderName2] = useState(null);
    const [addShareholderName3, setAddShareholderName3] = useState(null);
    const [addShareholderNameMy, setAddShareholderNameMy] = useState(null);
    const [addBookletFile1, setAddBookletFile1] = useState(null);
    const [addContractFile1, setAddContractFile1] = useState(null);
    const [addBookletFile2, setAddBookletFile2] = useState(null);
    const [addContractFile2, setAddContractFile2] = useState(null);
    const [addBookletFile3, setAddBookletFile3] = useState(null);
    const [addContractFile3, setAddContractFile3] = useState(null);
    const [addBookletFileMy, setAddBookletFileMy] = useState(null);
    const [addContractFileMy, setAddContractFileMy] = useState(null);
    const [shareholderIndex, setShareholderIndex] = useState(0);
    const [downloadDocument, setDownloadDocument] = useState(null);
    const [deleteFile1, setDeleteFile1] = useState(0);
    const [deleteFile2, setDeleteFile2] = useState(0);

    useEffect(() => {
        getShareholders();
        let body = {
            checkpointCategory: JSON.parse(sessionStorage.getItem('checkpointCategoryEstablishment'))[2],
            completed: 1,
            required: 1,
            isChange: true
        };
        Connect(`${Endpoint.putProgressSurvey}`, 'PUT', body).then(response => {
            console.log(response);
        });
    }, []);

    const fetchAndSetDocument = (documentUrl, documentIndex) => {
        const token = (typeof window !== 'undefined') ? localStorage.getItem('token') : null;
        const headers = {
            'Authorization': token ? `Bearer ${token}` : '',
        };

        fetch(documentUrl, {
            method: 'GET',
            headers: headers,
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`Network response was not ok: ${res.status}`);
                }
                return res.blob();
            })
            .then(blob => {
                const documentUrl = URL.createObjectURL(blob);
                setDownloadDocument(documentUrl);
                switch (documentIndex) {
                    case 1:
                        // setAddShareholderPhoto1(documentUrl);
                        break;
                    case 2:
                        // setAddShareholderPhoto2(documentUrl);
                        break;
                    default:
                        break;
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleImageChange = async (e, pid) => {
        const file = e.target.files[0];

        if (file) {
            const base64Image = await convertToBase64(file);
            uploadAvatar(pid, base64Image);
        }
    };

    const handleDocumentChange = async (e, pid) => {
        const file = e.target.files[0];

        if (file) {
            const base64Image = await convertToBase64(file);
            if (pid === 1) {
                setFile(`[Marriage_booklet_${shareholderIndex === 0 ? addShareholderNameMy : shareholderIndex === 1 ? addShareholderName1 : shareholderIndex === 2 ? addShareholderName2 : addShareholderName3}_${shareholderIndex === 0 ? addShareholderIdMy : shareholderIndex === 1 ? addShareholderId1 : shareholderIndex === 2 ? addShareholderId2 : addShareholderId3}.pdf]${base64Image}`);
                setDeleteFile1(0);
            } else {
                setFile2(`[Marriage_contract_${shareholderIndex === 0 ? addShareholderNameMy : shareholderIndex === 1 ? addShareholderName1 : shareholderIndex === 2 ? addShareholderName2 : addShareholderName3}_${shareholderIndex === 0 ? addShareholderIdMy : shareholderIndex === 1 ? addShareholderId1 : shareholderIndex === 2 ? addShareholderId2 : addShareholderId3}.pdf]${base64Image}`);
                setDeleteFile2(0);
            }
        }
    };

    const handleInputChange = (inputValue) => {
        const firstWord = inputValue.split(' ')[0];
        return sanitizePolishLetters(firstWord);
    };

    const sanitizePolishLetters = (input) => {
        return input
            .replace(/[ąĄ]/g, 'a')
            .replace(/[ćĆ]/g, 'c')
            .replace(/[ęĘ]/g, 'e')
            .replace(/[łŁ]/g, 'l')
            .replace(/[ńŃ]/g, 'n')
            .replace(/[óÓ]/g, 'o')
            .replace(/[śŚ]/g, 's')
            .replace(/[źŹ]/g, 'z')
            .replace(/[żŻ]/g, 'z');
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const uploadAvatar = (id, file) => {
        let body = {
            id,
            photoFile: `[photo.jpg]${file}`
        }
        Connect(`${Endpoint.avatarShareholder}`, 'PATCH', body).then(response => {
            getShareholders();
        })
    }

    const fetchAndSetImage = (photoUrl, shareholderIndex) => {
        const token = (typeof window !== 'undefined') ? localStorage.getItem('token') : null;
        const headers = {
            'Authorization': token ? `Bearer ${token}` : '',
        };

        fetch(photoUrl, {
            method: 'GET',
            headers: headers,
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`Network response was not ok: ${res.status}`);
                }
                return res.blob();
            })
            .then(blob => {
                const imageUrl = URL.createObjectURL(blob);
                switch (shareholderIndex) {
                    case 1:
                        setAddShareholderPhoto1(imageUrl);
                        break;
                    case 2:
                        setAddShareholderPhoto2(imageUrl);
                        break;
                    case 3:
                        setAddShareholderPhoto3(imageUrl);
                        break;
                    case 'My':
                        setAddShareholderPhotoMy(imageUrl);
                        break;
                    default:
                        break;
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const deleteFile = (index, category) => {
        if (category === 1) {
            setFile(null);
            setDeleteFile1(1);
            if (index === 0) {
                setAddBookletFileMy(null);
            } else if (index === 1) {
                setAddBookletFile1(null);
            } else if (index === 2) {
                setAddBookletFile2(null);
            } else if (index === 3) {
                setAddBookletFile3(null);
            }
        } else {
            setFile2(null);
            setDeleteFile2(1);
            if (index === 0) {
                setAddContractFileMy(null);
            } else if (index === 1) {
                setAddContractFile1(null);
            } else if (index === 2) {
                setAddContractFile2(null);
            } else if (index === 3) {
                setAddContractFile3(null);
            }
        }
    }

    const getShareholders = () => {
        Connect(`${Endpoint.getShareholders}`, 'GET').then((response) => {
            if (response.shareholders.length > 0) {
                if (response.shareholders[0] !== null) {
                    const photoUrl = response.shareholders[0].photoUri;
                    const token = (typeof window !== 'undefined') ? localStorage.getItem('token') : null;
                    const headers = {
                        'Authorization': token ? `Bearer ${token}` : '',
                    };
                    fetchAndSetImage(photoUrl, 'My');
                    setAddBookletFileMy(response.shareholders[0].personalSituation.marriageBookletUri);
                    setAddContractFileMy(response.shareholders[0].personalSituation.marriageContractUri);
                    setAddShareholderIdMy(response.shareholders[0].id);
                    setAddShareholderNameMy(handleInputChange(response.shareholders[0].fullName));
                }
            }
            if (response.shareholders.length > 1) {
                if (response.shareholders[1] !== null) {
                    const photoUrl = response.shareholders[1].photoUri;
                    const token = (typeof window !== 'undefined') ? localStorage.getItem('token') : null;
                    const headers = {
                        'Authorization': token ? `Bearer ${token}` : '',
                    };
                    fetchAndSetImage(photoUrl, 1);
                    setAddBookletFile1(response.shareholders[1].personalSituation.marriageBookletUri);
                    setAddContractFile1(response.shareholders[1].personalSituation.marriageContractUri);
                    setAddShareholderId1(response.shareholders[1].id);
                    setAddShareholderName1(handleInputChange(response.shareholders[1].fullName));
                }
            }
            if (response.shareholders.length > 2) {
                if (response.shareholders[2] !== null) {
                    const photoUrl = response.shareholders[2].photoUri;
                    const token = (typeof window !== 'undefined') ? localStorage.getItem('token') : null;
                    const headers = {
                        'Authorization': token ? `Bearer ${token}` : '',
                    };
                    fetchAndSetImage(photoUrl, 2);
                    setAddBookletFile2(response.shareholders[2].personalSituation.marriageBookletUri);
                    setAddContractFile2(response.shareholders[2].personalSituation.marriageContractUri);
                    setAddShareholderId2(response.shareholders[2].id);
                    setAddShareholderName2(handleInputChange(response.shareholders[2].fullName));
                }
            }
            if (response.shareholders.length > 3) {
                if (response.shareholders[3] !== null) {
                    const photoUrl = response.shareholders[3].photoUri;
                    const token = (typeof window !== 'undefined') ? localStorage.getItem('token') : null;
                    const headers = {
                        'Authorization': token ? `Bearer ${token}` : '',
                    };
                    fetchAndSetImage(photoUrl, 3);
                    setAddBookletFile3(response.shareholders[3].personalSituation.marriageBookletUri);
                    setAddContractFile3(response.shareholders[3].personalSituation.marriageContractUri);
                    setAddShareholderId3(response.shareholders[3].id);
                    setAddShareholderName3(handleInputChange(response.shareholders[3].fullName));
                }
            }
            let summarySharesAmount = 0;
            response.shareholders.map((data) => {
                summarySharesAmount += data.sharesAmount;
            })
            response.shareholders.map((data) => {
                data.total = data.sharesAmount !== null ? ((parseInt(data.sharesAmount)/summarySharesAmount) * 100).toFixed(2) : 0
            })
            let firstShareholder = response.shareholders[document.querySelector('.shareholder.active') !== null ? parseInt(document.querySelector('.shareholder.active').getAttribute('data-index')) : 0];
            setShareholderIndex(document.querySelector('.shareholder.active') !== null ? parseInt(document.querySelector('.shareholder.active').getAttribute('data-index')) : 0)
            setShareholders(response.shareholders);
            setCity(firstShareholder.personalSituation.city);
            setMaritalResidence(firstShareholder.personalSituation.maritalResidence);
            setMaritalSystem(firstShareholder.personalSituation.maritalSystem)
            setMarried(firstShareholder.personalSituation.isMarried);
            setBelgium(firstShareholder.personalSituation.isMarriedBelgium !== null ? firstShareholder.personalSituation.isMarriedBelgium : false);
            setCountry(firstShareholder.personalSituation.country);
            setResidence(firstShareholder.personalSituation.maritalResidence);
            setCityMarried(firstShareholder.personalSituation.city);
        });
    };

    const handleMarried = (value) => {
        setMarried(value);
    };

    const handleBelgium = (value) => {
        setBelgium(value);
    };

    const handleData = (value, type) => {
        // Do something with the data if needed
        setMaritalSystem(type[0].text)
    };

    const handleSave = () => {
        if(married && belgium){
            if(!(file2 !== null || (shareholderIndex === 0 && addContractFileMy !== null) || (shareholderIndex === 1 && addContractFile1 !== null) || (shareholderIndex === 2 && addContractFile2 !== null) || (shareholderIndex === 3 && addContractFile3 !== null)) || !city || !maritalSystem){
                return toast.error(localStorage.getItem("i18nextLng") === "en" ? "Complete the required fields." : "Vul de verplichte velden in.")
            }
        }
        const currentShareholder = document.querySelector('.shareholder.active');
        currentShareholder.querySelector('.btn').classList.remove('pending');
        currentShareholder.querySelector('.btn').classList.add('complete');
        currentShareholder.querySelector('.btn').innerText = 'Complete';
        currentShareholder.classList.remove('active');
        const pid = currentShareholder.getAttribute('data-pid');
        const ele = document.querySelectorAll("input");
        const radio = document.querySelectorAll('.PrivateRadioButtonIcon-root-30');
        for (let i = 0; i < ele.length; i++) {
            ele[i].value = '';
        }
        for (let i = 0; i < radio.length; i++) {
            radio[i].classList.remove('PrivateRadioButtonIcon-checked-32');
        }
        handleMarried(false);
        handleBelgium(false);
        

        if (currentShareholder.nextSibling !== null) {
            currentShareholder.nextSibling.classList.add('active');
        } else {
            document.querySelector('.footer .btn:nth-child(2)').click();
        }

        let body = {
            id: parseInt(pid),
            isMarried: married,
            isMarriedBelgium: belgium,
            city: married === true && belgium === false ? cityMarried : city,
            maritalResidence: married === true && belgium === false ? residence : maritalResidence,
            marriageBookletFile: belgium === true ? file : null,
            marriageContractFile: belgium === true ? file2 : null,
            maritalSystem: belgium === true ? maritalSystem : null,
            country: belgium === false ? country : null
        };
        if ((deleteFile1 === 1) && (deleteFile2 === 0)) {
            body = {
                id: parseInt(pid),
                isMarried: married,
                isMarriedBelgium: belgium,
                city: married === true && belgium === false ? cityMarried : city,
                maritalResidence: married === true && belgium === false ? residence : maritalResidence,
                marriageBookletFile: belgium === true ? file : null,
                marriageContractFile: belgium === true ? file2 : null,
                maritalSystem: belgium === true ? maritalSystem : null,
                isMarriageBookletDelete: true,
                country: belgium === false ? country : null
            };
        } else if ((deleteFile1 === 0) && (deleteFile2 === 1)) {
            body = {
                id: parseInt(pid),
                isMarried: married,
                isMarriedBelgium: belgium,
                city: married === true && belgium === false ? cityMarried : city,
                maritalResidence: married === true && belgium === false ? residence : maritalResidence,
                marriageBookletFile: belgium === true ? file : null,
                marriageContractFile: belgium === true ? file2 : null,
                maritalSystem: belgium === true ? maritalSystem : null,
                isMarriageContractDelete: true,
                country: belgium === false ? country : null
            };
        } else if ((deleteFile1 === 1) && (deleteFile2 === 1)) {
            body = {
                id: parseInt(pid),
                isMarried: married,
                isMarriedBelgium: belgium,
                city: married === true && belgium === false ? cityMarried : city,
                maritalResidence: married === true && belgium === false ? residence : maritalResidence,
                marriageBookletFile: belgium === true ? file : null,
                marriageContractFile: belgium === true ? file2 : null,
                maritalSystem: belgium === true ? maritalSystem : null,
                isMarriageBookletDelete: true,
                isMarriageContractDelete: true,
                country: belgium === false ? country : null
            };
        } else if (belgium === false && married === true) {
            body = {
                id: parseInt(pid),
                isMarried: married,
                isMarriedBelgium: belgium,
                city: cityMarried,
                maritalResidence: residence,
                marriageBookletFile: null,
                marriageContractFile: null,
                maritalSystem: null,
                isMarriageBookletDelete: true,
                isMarriageContractDelete: true,
                country: country
            };
        } else if (married === false) {
            body = {
                id: parseInt(pid),
                isMarried: married,
                isMarriedBelgium: null,
                city: null,
                maritalResidence: null,
                marriageBookletFile: null,
                marriageContractFile: null,
                maritalSystem: null,
                isMarriageBookletDelete: true,
                isMarriageContractDelete: true,
                country: null
            };
        }
        Connect(`${Endpoint.putPersonalSituation}`, 'PUT', body).then((response) => {
            setMarried(false);
            setBelgium(false);
            setCity('');
            setMaritalResidence('');
            setDeleteFile1(0);
            setDeleteFile2(0);
            getShareholders();
        });
    };

    return (
        <>
            <div className="container__body establishmentFlow personalSituation">
                <div className="container__body-header"><Trans>establishmentFlow.shareholders</Trans></div>
                <div className="container__body-text"><Trans>establishmentFlow.necessary</Trans></div>
                <div className="personalSituation__shareholders">
                    {shareholders.map((data, index) => (
                        <div className={index === 0 ? "shareholder active" : "shareholder"} data-pid={data.personalSituation.id} key={data.personalSituation.id} data-index={index}>
                            <label htmlFor={`avatarInput${index + 1}`} className="avatar">
                                {data.photoUri !== null ?
                                    <img src={index === 1 ? addShareholderPhoto1 : index === 2 ? addShareholderPhoto2 : index === 3 ? addShareholderPhoto3 : addShareholderPhotoMy} alt="" />
                                    :
                                    avatar
                                }
                            </label>
                            <input id={`avatarInput${index + 1}`} type="file" className="upload" accept="image/*" onChange={(e) => handleImageChange(e, data.id)} style={{ display: 'none' }} />
                            <div className="box">
                                <div className="shareholder__header">{data.fullName}</div>
                                <div className="row">
                                    <div className="shareholder__text award">{data.sharesAmount !== null ? data.sharesAmount : '0'} <Trans>establishmentFlow.shares</Trans></div>
                                    <div className="shareholder__text">- {data.total}% <Trans>establishmentFlow.total</Trans></div>
                                </div>
                            </div>
                            <div className={data.personalSituation.isSaved === true ? "btn complete" : "btn pending"}>{data.personalSituation.isSaved === true ? <Trans>admin.complete</Trans> : <Trans>admin.pending</Trans>}</div>
                        </div>
                    ))}
                </div>
                <div className="container__body-header"><Trans>establishmentFlow.marital</Trans></div>
                <div className="container__body-box">
                    <div className="container__body-input" style={{ flexDirection: 'row', marginTop: 0 }}>
                        <div className="container__body-input" style={{ marginRight: 0 }}>
                            <p className="container__body-text"><Trans>establishmentFlow.married</Trans></p>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="married"
                                    value={married && married.toString()}
                                >
                                    <div className="structure__checkbox">
                                        <div className="container__body-radio" onClick={() => handleMarried(true)}>
                                            <FormControlLabel value="true" control={<Radio />} label={<Trans>establishmentFlow.yes</Trans>} className={'container__body-text'} />
                                        </div>
                                        <div className="container__body-radio" onClick={() => handleMarried(false)}>
                                            <FormControlLabel value="false" control={<Radio checked={!married} />} label={<Trans>establishmentFlow.no</Trans>} className="container__body-text" />
                                        </div>
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {married === true ?
                            <div className="container__body-input" style={{ marginRight: 0 }}>
                                <p className="container__body-text"><Trans>establishmentFlow.belgium</Trans></p>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="married"
                                        value={belgium && belgium.toString()}
                                    >
                                        <div className="structure__checkbox">
                                            <div className="container__body-radio" onClick={() => handleBelgium(true)}>
                                                <FormControlLabel value="true" control={<Radio />} label={<Trans>establishmentFlow.yes</Trans>} className={'container__body-text'} />
                                            </div>
                                            <div className="container__body-radio" onClick={() => handleBelgium(false)}>
                                                <FormControlLabel value="false" control={<Radio checked={!belgium} />} label={<Trans>establishmentFlow.no</Trans>} className="container__body-text" />
                                            </div>
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            : <></>}
                    </div>
                    {married === true && belgium === true ?
                        <div className="container__body-select">
                            <p className="container__body-text"><Trans>establishmentFlow.maritalSystem</Trans> *</p>
                            <CustomSelect
                                defaultText={maritalSystem}
                                optionsList={selectList}
                                handleData={handleData}
                                type={'registrationRegion'}
                            />
                        </div>
                        : <></>}
                </div>
                {married === true && belgium === true ?
                    <div className="container__body-box">
                        <div className="container__body-input">
                            <p className="container__body-text"><Trans>establishmentFlow.whatCity</Trans> *</p>
                            <TextField label={''} variant={'outlined'} value={city} size={'small'} type={'text'} placeholder={'...'} onChange={(e) => setCity(e.target.value)} />
                        </div>
                        <div className="container__body-input">
                            <p className="container__body-text"><Trans>establishmentFlow.whatCityFirst</Trans></p>
                            <TextField label={''} variant={'outlined'} value={maritalResidence} size={'small'} type={'text'} placeholder={'...'} onChange={(e) => setMaritalResidence(e.target.value)} />
                        </div>
                    </div>
                :
                    <></>
                }
                {married === true && belgium === true ?
                    <div className="container__body-box">
                        <div className="container__body-input">
                            <p className="container__body-text"><Trans>establishmentFlow.upload</Trans>
                                <Tooltip title="While it is useful if we can consult your marriage booklet, it is not an obligatory document to upload. The notary will, however, ask about your marriage contract" placement={'right'}>
                                    <IconButton aria-label="delete">
                                        {InfoIcon}
                                    </IconButton>
                                </Tooltip>
                            </p>
                            <div className={file !== null || (shareholderIndex === 0 && addBookletFileMy !== null) || (shareholderIndex === 1 && addBookletFile1 !== null) || (shareholderIndex === 2 && addBookletFile2 !== null) || (shareholderIndex === 3 && addBookletFile3 !== null) ? "upload loaded" : "upload"}>
                                {file !== null || (shareholderIndex === 0 && addBookletFileMy !== null) || (shareholderIndex === 1 && addBookletFile1 !== null) || (shareholderIndex === 2 && addBookletFile2 !== null) || (shareholderIndex === 3 && addBookletFile3 !== null) ?
                                    <div className="row">
                                        <div className="box">
                                            {pdf}
                                            <div className="upload__text">Marriage_booklet_{shareholderIndex === 0 ? addShareholderNameMy : shareholderIndex === 1 ? addShareholderName1 : shareholderIndex === 2 ? addShareholderName2 : addShareholderName3}_{shareholderIndex === 0 ? addShareholderIdMy : shareholderIndex === 1 ? addShareholderId1 : shareholderIndex === 2 ? addShareholderId2 : addShareholderId3}.pdf</div>
                                        </div>
                                        <div className="upload__extend" onMouseEnter={(e) => e.currentTarget.querySelector('.upload__extend-list').classList.add('is-active')} onMouseLeave={(e) => e.currentTarget.querySelector('.upload__extend-list').classList.remove('is-active')}>...
                                            <div className="upload__extend-list">
                                                <div className="action" onClick={() => deleteFile(shareholderIndex, 1)}><Trans>users.delete</Trans></div>
                                                <label htmlFor={`documentInput`} className="avatar">
                                                    <div className="action"><Trans>users.replace</Trans></div>
                                                </label>
                                                <input id={`documentInput`} type="file" className="upload" accept="application/pdf" onChange={(e) => handleDocumentChange(e, 1)} style={{ display: 'none' }} />
                                                {(shareholderIndex === 0 && addBookletFileMy !== null) || (shareholderIndex === 1 && addBookletFile1 !== null) || (shareholderIndex === 2 && addBookletFile2 !== null) || (shareholderIndex === 3 && addBookletFile3 !== null) ?
                                                    <div className="action" onClick={() => fetchAndSetDocument(shareholders[shareholderIndex].personalSituation.marriageBookletUri, 1)}><Trans>establishmentFlow.download</Trans></div>
                                                : <></>}
                                                <iframe
                                                    title="PDF Viewer"
                                                    width="100%"
                                                    height="500"
                                                    src={downloadDocument}
                                                    frameBorder="0"
                                                    allowFullScreen
                                                    style={{display: 'none'}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <>
                                        <label htmlFor={`documentInput`} className="avatar">
                                            <div className="icon">
                                                {upload}
                                            </div>
                                            <div className="upload__text"><span><Trans>establishmentFlow.choose</Trans> </span><Trans>establishmentFlow.drag</Trans></div>
                                            <div className="upload__text small"><Trans>establishmentFlow.size</Trans> 5 mb</div>
                                            <div className="upload__text small validation"><Trans>establishmentFlow.supported</Trans> pdf</div>
                                        </label>
                                        <input id={`documentInput`} type="file" className="upload" accept="application/pdf" onChange={(e) => handleDocumentChange(e, 1)} style={{ display: 'none' }} />
                                    </>
                                }
                            </div>
                        </div>
                        <div className="container__body-input">
                            <p className="container__body-text"><Trans>establishmentFlow.uploadContract</Trans> *</p>
                            <div className={file2 !== null || (shareholderIndex === 0 && addContractFileMy !== null) || (shareholderIndex === 1 && addContractFile1 !== null) || (shareholderIndex === 2 && addContractFile2 !== null) || (shareholderIndex === 3 && addContractFile3 !== null) ? "upload loaded" : "upload"}>
                                {file2 !== null || (shareholderIndex === 0 && addContractFileMy !== null) || (shareholderIndex === 1 && addContractFile1 !== null) || (shareholderIndex === 2 && addContractFile2 !== null) || (shareholderIndex === 3 && addContractFile3 !== null) ?
                                    <div className="row">
                                        <div className="box">
                                            {pdf}
                                            <div className="upload__text">Marriage_contract_{shareholderIndex === 0 ? addShareholderNameMy : shareholderIndex === 1 ? addShareholderName1 : shareholderIndex === 2 ? addShareholderName2 : addShareholderName3}_{shareholderIndex === 0 ? addShareholderIdMy : shareholderIndex === 1 ? addShareholderId1 : shareholderIndex === 2 ? addShareholderId2 : addShareholderId3}.pdf</div>
                                        </div>
                                        <div className="upload__extend" onMouseEnter={(e) => e.currentTarget.querySelector('.upload__extend-list').classList.add('is-active')} onMouseLeave={(e) => e.currentTarget.querySelector('.upload__extend-list').classList.remove('is-active')}>...
                                            <div className="upload__extend-list">
                                                <div className="action" onClick={() => deleteFile(shareholderIndex, 2)}><Trans>users.delete</Trans></div>
                                                <label htmlFor={`documentInput2`} className="avatar">
                                                    <div className="action"><Trans>users.replace</Trans></div>
                                                </label>
                                                <input id={`documentInput2`} type="file" className="upload" accept="application/pdf" onChange={(e) => handleDocumentChange(e, 2)} style={{ display: 'none' }} />
                                                {(shareholderIndex === 0 && addContractFileMy !== null) || (shareholderIndex === 1 && addContractFile1 !== null) || (shareholderIndex === 2 && addContractFile2 !== null) || (shareholderIndex === 3 && addContractFile3 !== null) ?
                                                    <div className="action" onClick={() => fetchAndSetDocument(shareholders[shareholderIndex].personalSituation.marriageContractUri, 1)}><Trans>establishmentFlow.download</Trans></div>
                                                    : <></>}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <label htmlFor={`documentInput2`} className="avatar">
                                            <div className="icon">
                                                {upload}
                                            </div>
                                            <div className="upload__text"><span><Trans>establishmentFlow.choose</Trans> </span><Trans>establishmentFlow.drag</Trans></div>
                                            <div className="upload__text small"><Trans>establishmentFlow.size</Trans> 5 mb</div>
                                            <div className="upload__text small validation"><Trans>establishmentFlow.supported</Trans> pdf</div>
                                        </label>
                                        <input id={`documentInput2`} type="file" className="upload" accept="application/pdf" onChange={(e) => handleDocumentChange(e, 2)} style={{ display: 'none' }} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                :
                    <></>
                }
                {married === true && belgium === false ?
                    <div className="container__body-box">
                        <div className="container__body-input">
                            <p className="container__body-text"><Trans>establishmentFlow.where</Trans></p>
                            <div className="form__item">
                                <p className="container__body-text"><Trans>establishmentFlow.country</Trans></p>
                                <TextField label={''} variant={'outlined'} value={country} size={'small'} type={'text'} placeholder={'...'} onChange={(e) => setCountry(e.target.value)} />
                            </div>
                            <div className="form__item">
                                <p className="container__body-text"><Trans>establishmentFlow.city</Trans></p>
                                <TextField label={''} variant={'outlined'} value={cityMarried} size={'small'} type={'text'} placeholder={'...'} onChange={(e) => setCityMarried(e.target.value)} />
                            </div>
                            <div className="form__item">
                                <p className="container__body-text"><Trans>establishmentFlow.firstCity</Trans></p>
                                <TextField label={''} variant={'outlined'} value={residence} size={'small'} type={'text'} placeholder={'...'} onChange={(e) => setResidence(e.target.value)} />
                            </div>
                        </div>
                    </div>
                :
                    <></>
                }
                <div className="personalSituation__footer">
                    <button className="btn btn--primary" onClick={handleSave}><Trans>establishmentFlow.save</Trans></button>
                </div>
            </div>
        </>
    );
};

export default PersonalSituation;
