import React, {useEffect, useState} from "react";
import {usePDF, Document, Page, Font, View, Text, StyleSheet, Image, Svg, Path, Circle} from '@react-pdf/renderer';
import Logo from '../../assets/b.png';
import source from '../../assets/fonts/Inter-Bold.ttf';
import whiteLogo from '../../assets/whiteLogo.png';
import Light from '../../assets/light.png';
import Grow from '../../assets/grow.png';
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import {buildStyles, CircularProgressbar, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {Trans} from "react-i18next";
import loadingFile from "../../assets/loading.svg";
import {fullScreen} from "../login/loginConstants";
import {transformDate} from "../admin/adminEstablishment";

// Register font
Font.register({ family: 'Inter', src: source });

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    pageStart: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    pageCenter: {
        alignItems: 'center'
    },
    section: {
        margin: '50px 30px 20px 30px',
        padding: 10,
        flexGrow: 1
    },
    header: {
        backgroundColor: '#4A5BCF',
        height: 40,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        top: 0
    },
    headerText: {
        color: '#FFFFFF',
        fontFamily: 'Inter',
        fontSize: 16,
        fontWeight: 600
    },
    headerLogo: {
        position: 'absolute',
        left: 18,
        top: 9,
        width: 109,
        height: 27
    },
    bold: {
        fontFamily: 'Inter',
        fontWeight: 700
    },
    footer: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    footerBox: {
        backgroundColor: '#4A5BCF',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '92%',
        padding: '0 20px'
    },
    footerText: {
        color: '#FFF',
        fontSize: 10,
        fontWeight: 600,
        fontFamily: 'Inter'
    },
    footerLogo: {
        width: 23,
        height: 29,
        objectFit: 'contain',
        marginRight: 12
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: 'row'
    },
    box: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        color: '#000',
        fontSize: 24,
        fontWeight: 700,
        fontFamily: 'Inter'
    },
    text: {
        color: '#000',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 'normal',
        marginTop: 25
    },
    subTitle: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: 20,
        fontWeight: 700,
        marginTop: 30,
        marginBottom: 10
    },
    subHeader: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: 18,
        fontWeight: 700,
        marginTop: 40,
        marginBottom: 15
    },
    margin0: {
        margin: 0
    },
    sole: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    soleBox: {
        width: 129,
        height: 118
    },
    soleHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '1px solid #D9D9D9',
        height: 25,
        marginBottom: 7
    },
    successText: {
        color: '#53CEA8',
        // fontFamily: 'Inter',
        fontSize: 9,
        fontWeight: 500,
    },
    errorText: {
        color: '#E94F37',
    },
    soleText: {
        color: '#000',
        margin: '5px 0'
    },
    awardText: {
        color: '#2E1754',
        fontSize: 10,
        fontWeight: 500,
        width: 250,
        marginLeft: 15,
        marginBottom: 20,
        fontFamily: 'Inter'
    },
    lightLogo: {
        width: 33,
        height: 33,
        marginRight: 15
    },
    summaryText: {
        color: '#6964A1',
        fontFamily: 'Inter',
        fontSize: 12,
        fontWeight: 700,
    },
    graphText: {
        color: '#000',
        // fontFamily: 'Inter',
        fontSize: 11,
        width: 435,
        textAlign: 'center'
    },
    table: {
        display: 'flex',
        alignItems: 'center',
        width: 520,
        border: '1px solid #CFDBD5',
        borderRadius: 9
    },
    tableText: {
        color: '#000',
        fontSize: 11,
    },
    tableBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        margin: 16,
        width: 220
    },
    tableCompany: {
        display: 'flex',
        flexDirection: 'column',
        width: 310,
        border: '1px solid #CFDBD5',
        borderRadius: 9
    },
    tableCompanyBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        margin: 16,
        width: 270
    },
    tableRevenue: {
        display: 'flex',
        flexDirection: 'column',
        width: 160
    },
    tableRevenueBox: {
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        width: 160,
        border: '1px solid #CFDBD5',
        borderRadius: 9,
        marginBottom: 10,
        position: 'relative'
    },
    revenueText: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 700,
        marginBottom: 5
    },
    revenueTextPrev: {
        color: '#747A80',
        fontSize: 10,
        marginBottom: 10
    },
    revenueTextDate: {
        color: '#000',
        fontSize: 8
    },
    grow: {
        width: 16,
        height: 16,
        position: 'absolute',
        top: 15,
        right: 15
    },
    tableCost: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        width: 520,
        height: 188,
        border: '1px solid #CFDBD5',
        borderRadius: 9,
        position: 'relative'
    },
    costList: {
        display: 'flex',
        flexDirection: 'row',
        width: 281,
        margin: '10px 0'
    },
    costText: {
        color: '#0A004B',
        fontSize: 9
    },
    costRow: {
        width: 111
    },
    costRowAmount: {
        width: 50
    },
    containerExtend: {
        width: 302,
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 25,
        marginBottom: 30
    },
    container: {
        height: 28,
        backgroundColor: '#F20089',
        borderRadius: 5,
        margin: 10,
        display: 'flex',
        flexDirection: 'row',
        position: 'relative'
    },
    bar: {
        height: 28,
        backgroundColor: '#0052FF',
        borderRadius: 5,
    },
    limitedBar: {
        height: 28,
        backgroundColor: '#9800FF',
        borderRadius: 5,
    },
    except: {
        position: 'absolute',
        left: 50,
        top: 25,
        backgroundColor: '#FFF',
        borderRadius: 10,
        width: 95,
        height: 16,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    smallText: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: 8
    }
});

export const svgToBase64 = (file) => {
    let string = new XMLSerializer().serializeToString(file);
    return `data:image/svg+xml;base64,${window.btoa(string)}`;
}

export const Output = (props) => {
    const [graphSummary, setGraphSummary] = useState([]);
    const [data, setData] = useState([]);
    const [summary, setSummary] = useState(0);
    const [progress, setProgress] = useState(255);
    const [limitProgress, setLimitProgress] = useState(155);
    const [limitProgress2, setLimitProgress2] = useState(166);
    const [outputData, setOutputData] = useState([]);
    const [graph, setGraph] = useState(<svg></svg>);
    const [form, setForm] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        nationality: [],
        dateStart: '',
        companyName: '',
        occupation: [],
        companyActivities: '',
        companyActivitiesDescription: '',
        postalCode: '',
        city: '',
        revenue: '',
        expectNextYear: [],
        revenueNextYear: '',
        expectThirdYear: [],
        revenueThirdYear: '',
        transport: [],
        transportCar: [],
        transportPublic: [],
        transportBicycle: [],
        transportMotorcycle: [],
        transportTaxi: '',
        usuallyWork: [],
        officeSpace: '',
        officeSpaceRent: '',
        usedHome: 0,
        applicableSituation: [],
        homeOffice: [],
        restaurants: [],
        pensionSaving: [],
        goodsToSell: [],
        buyingGoods: '',
        otherCategories: []
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.type !== 'admin') {
            Connect(`${Endpoint.userOutputData}`, 'GET').then(response => {
                if (response.soleAvgTaxRate !== undefined && response.ltdAvgTaxRate !== null && response.ltdCorporateTax !== null && response.ltdIncomeTax !== null && response.ltdMonthNetIncome !== null && response.ltdSocialContribution !== null && response.ltdTotalTax !== null && response.soleAvgTaxRate !== null && response.soleIncomeTax !== null && response.soleMonthNetIncome !== null && response.soleSocialContribution !== null && response.soleTotalTax !== null) {
                    sessionStorage.setItem('activeOutput', '1');
                    progressSurvey();
                } else {
                    sessionStorage.setItem('activeOutput', '0');
                }
                setOutputData(response);
            });
        }
        Connect(`${props.type === 'user' || props.type === 'userFinish' || props.type === 'userDashboard' || props.type === 'userEstablishment' ? Endpoint.getUserSurvey : Endpoint.getAdminSurvey}/company-type${props.type === 'admin' ? `/${sessionStorage.getItem('userID')}` : ''}`, 'GET').then(response => {
            const newForm = {
                name: response.surveyPages[0].steps[0].questions[0].answers[0]
                    && response.surveyPages[0].steps[0].questions[0].answers[0].textAnswer,
                surname: response.surveyPages[0].steps[0].questions[1].answers[0]
                    && response.surveyPages[0].steps[0].questions[1].answers[0].textAnswer,
                email: JSON.parse(localStorage.getItem('user')).email,
                phone: response.surveyPages[0].steps[0].questions[3].answers[0]
                    && response.surveyPages[0].steps[0].questions[3].answers[0].textAnswer,
                nationality: createArray(response.surveyPages[0].steps[0].questions[4].answers[0] && response.surveyPages[0].steps[0].questions[4].answers[0].questionOptions),
                dateStart: response.surveyPages[1].steps[0].questions[0].answers[0]
                    && response.surveyPages[1].steps[0].questions[0].answers[0].textAnswer,
                companyName: response.surveyPages[1].steps[0].questions[1].answers[0]
                    && response.surveyPages[1].steps[0].questions[1].answers[0].textAnswer,
                occupation: createArray(response.surveyPages[1].steps[0].questions[2].answers[0] && response.surveyPages[1].steps[0].questions[2].answers[0].questionOptions),
                companyActivities: response.surveyPages[1].steps[0].questions[3].answers[0]
                    && response.surveyPages[1].steps[0].questions[3].answers[0].textAnswer,
                companyActivitiesDescription: response.surveyPages[1].steps[0].questions[4].answers[0]
                    && response.surveyPages[1].steps[0].questions[4].answers[0].textAnswer,
                postalCode: response.surveyPages[1].steps[0].questions[5].answers[0]
                    && response.surveyPages[1].steps[0].questions[5].answers[0].textAnswer,
                city: response.surveyPages[1].steps[0].questions[6].answers[0]
                    && response.surveyPages[1].steps[0].questions[6].answers[0].textAnswer,
                revenue: response.surveyPages[2].steps[0].questions[0].answers[0] && response.surveyPages[2].steps[0].questions[0].answers[0].numericAnswer !== null
                    && addCommas(removeNonNumeric(response.surveyPages[2].steps[0].questions[0].answers[0].numericAnswer)),
                expectNextYear: createArray(response.surveyPages[2].steps[0].questions[1].answers[0] && response.surveyPages[2].steps[0].questions[1].answers[0].questionOptions),
                revenueNextYear: response.surveyPages[2].steps[0].questions[2].answers[0] && response.surveyPages[2].steps[0].questions[2].answers[0].numericAnswer !== null
                    && addCommas(removeNonNumeric(response.surveyPages[2].steps[0].questions[2].answers[0].numericAnswer)),
                expectThirdYear: createArray(response.surveyPages[2].steps[0].questions[3].answers[0] && response.surveyPages[2].steps[0].questions[3].answers[0].questionOptions),
                revenueThirdYear: response.surveyPages[2].steps[0].questions[4].answers[0] && response.surveyPages[2].steps[0].questions[4].answers[0].numericAnswer !== null ? addCommas(removeNonNumeric(response.surveyPages[2].steps[0].questions[4].answers[0].numericAnswer)) : '',
                transport: createArray(response.surveyPages[3].steps[0].questions[0].answers[0] && response.surveyPages[3].steps[0].questions[0].answers[0].questionOptions),
                transportCar: createArray(response.surveyPages[3].steps[0].questions[1].answers[0] && response.surveyPages[3].steps[0].questions[1].answers[0].questionOptions),
                transportMotorcycle: createArray(response.surveyPages[3].steps[0].questions[2].answers[0] && response.surveyPages[3].steps[0].questions[2].answers[0].questionOptions),
                transportPublic: createArray(response.surveyPages[3].steps[0].questions[3].answers[0] && response.surveyPages[3].steps[0].questions[3].answers[0].questionOptions),
                transportBicycle: createArray(response.surveyPages[3].steps[0].questions[4].answers[0] && response.surveyPages[3].steps[0].questions[4].answers[0].questionOptions),
                transportTaxi: response.surveyPages[3].steps[0].questions[5].answers[0] && response.surveyPages[3].steps[0].questions[5].answers[0].numericAnswer !== null
                    && addCommas(removeNonNumeric(response.surveyPages[3].steps[0].questions[5].answers[0].numericAnswer)),
                usuallyWork: createArray(response.surveyPages[3].steps[1].questions[0].answers[0] && response.surveyPages[3].steps[1].questions[0].answers[0].questionOptions),
                officeSpace: response.surveyPages[3].steps[1].questions[1].answers[0] && response.surveyPages[3].steps[1].questions[1].answers[0].numericAnswer !== null
                    ? addCommas(removeNonNumeric(response.surveyPages[3].steps[1].questions[1].answers[0].numericAnswer)) : 1,
                officeSpaceRent: response.surveyPages[3].steps[1].questions[2].answers[0] && response.surveyPages[3].steps[1].questions[2].answers[0].numericAnswer !== null
                    ? addCommas(removeNonNumeric(response.surveyPages[3].steps[1].questions[2].answers[0].numericAnswer)) : 1,
                usedHome: response.surveyPages[3].steps[1].questions[3].answers[0] && response.surveyPages[3].steps[1].questions[3].answers[0].numericAnswer !== null ? response.surveyPages[3].steps[1].questions[3].answers[0].numericAnswer : 0,
                applicableSituation: createArray(response.surveyPages[3].steps[1].questions[4].answers[0] && response.surveyPages[3].steps[1].questions[4].answers[0].questionOptions),
                homeOffice: createArray(response.surveyPages[3].steps[1].questions[5].answers[0] && response.surveyPages[3].steps[1].questions[5].answers[0].questionOptions),
                restaurants: createArray(response.surveyPages[3].steps[2].questions[0].answers[0] && response.surveyPages[3].steps[2].questions[0].answers[0].questionOptions),
                pensionSaving: createArray(response.surveyPages[3].steps[2].questions[1].answers[0] && response.surveyPages[3].steps[2].questions[1].answers[0].questionOptions),
                goodsToSell: createArray(response.surveyPages[3].steps[2].questions[2].answers[0] && response.surveyPages[3].steps[2].questions[2].answers[0].questionOptions),
                buyingGoods: response.surveyPages[3].steps[2].questions[3].answers[0] && response.surveyPages[3].steps[2].questions[3].answers[0].numericAnswer,
                otherCategories: createArray(response.surveyPages[3].steps[3].questions[0].answers[0] && response.surveyPages[3].steps[3].questions[0].answers[0].questionOptions),
            }
            setForm(newForm);
        });

        Connect(`${props.type === 'user' || props.type === 'userFinish' || props.type === 'userDashboard' || props.type === 'userEstablishment' ? Endpoint.getCostsUser : Endpoint.getAdminCostsUser}/${props.type === 'admin' ? sessionStorage.getItem('userID') : ''}`, 'GET').then(response => {
            let costData = [
                ['Establishment costs', '', 'One-off', 0, [], 0, 0],
                ['Professional services', 'purple', 'Per year', 0, [], 0, 0],
                ['Transportation costs', 'pink', 'Per year', 0, [], 0, 0],
                ['Office expenses', 'yellow', 'Per year', 0, [], 0, 0],
                ['General & Admin', 'green', 'Per year', 0, [], 0, 0],
                ['Other', 'red', 'Per year', 0, [], 0, 0]
            ];
            let summary = 0;
            let establishment = 0;
            let professional = 0;
            let transportation = 0;
            let office = 0;
            let general = 0;
            let other = 0;
            response.map((data) => {
                summary += data.isPeriodic === true ? data.amount * 12 : data.amount;
                if (data.costType === 'ESTABLISHMENT') {
                    establishment += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[0][6] += data.amount;
                    } else {
                        costData[0][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[0][4].push(array);
                }
                if (data.costType === 'PRO_SERVICES') {
                    professional += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[1][6] += data.amount;
                    } else {
                        costData[1][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[1][4].push(array);
                }
                if (data.costType === 'TRANSPORTATION') {
                    transportation += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[2][6] += data.amount;
                    } else {
                        costData[2][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[2][4].push(array);
                }
                if (data.costType === 'OFFICE') {
                    office += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[3][6] += data.amount;
                    } else {
                        costData[3][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[3][4].push(array);
                }
                if (data.costType === 'GENERAL') {
                    general += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[4][6] += data.amount;
                    } else {
                        costData[4][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[4][4].push(array);
                }
                if (data.costType === 'OTHER') {
                    other += data.isPeriodic === true ? data.amount * 12 : data.amount;
                    // other += data.costPeriod === 'PER_MONTH' ? data.amount * 12 : data.amount;
                    if ((data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true)) {
                        costData[5][6] += data.amount;
                    } else {
                        costData[5][5] += data.amount;
                    }
                    let array = [data.description, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? data.amount * 12 : data.amount, (data.costPeriod === 'PER_MONTH') || (data.isPeriodic === true) ? 'PER_MONTH' : '', data.id, data.costType, data.answerId, data.amount];
                    costData[5][4].push(array);
                }
            })
            costData[0][3] = establishment;
            costData[1][3] = professional;
            costData[2][3] = transportation;
            costData[3][3] = office;
            costData[4][3] = general;
            costData[5][3] = other;
            let array = [establishment, professional, transportation, office, general, other];
            array.sort((a, b) => a - b);

            const colorMapping = {
                [establishment]: '#0052FF',
                [professional]: '#0052FF',
                [transportation]: '#0052FF',
                [office]: '#0052FF',
                [general]: '#0052FF',
                [other]: '#0052FF',
                [costData[1][3]]: '#9800FF',
                [costData[2][3]]: '#F20089',
                [costData[3][3]]: '#FFBD00',
                [costData[4][3]]: '#23E33E',
                [costData[5][3]]: '#E94F37',
            };

            let graphSummary = array.map((data, index) => [colorMapping[data], data]);
            graphSummary[0][1] = graphSummary[0][1]/summary * 100;
            graphSummary[1][1] = graphSummary[1][1]/summary * 100;
            graphSummary[2][1] = graphSummary[2][1]/summary * 100;
            graphSummary[3][1] = graphSummary[3][1]/summary * 100;
            graphSummary[4][1] = graphSummary[4][1]/summary * 100;
            graphSummary[5][1] = graphSummary[5][1]/summary * 100;
            for (let i = 0; i <= 5; i++) {
                costData[i][3] = addCommas(removeNonNumeric(costData[i][3].toFixed(0)));
                costData[i][4].map((data) => {
                    data[1] = addCommas(removeNonNumeric(data[1].toFixed(0)));
                    data[6] = addCommas(removeNonNumeric(data[6].toFixed(0)));
                })
                costData[i][7] = addCommas(removeNonNumeric((costData[i][6]*12).toFixed(0)));
                costData[i][5] = addCommas(removeNonNumeric(costData[i][5].toFixed(0)));
                costData[i][6] = addCommas(removeNonNumeric(costData[i][6].toFixed(0)));
            }
            sessionStorage.setItem('costData', JSON.stringify(costData));
            summary = addCommas(removeNonNumeric(summary.toFixed(0)));
            setSummary(summary);
            setData(costData);
            setGraphSummary(graphSummary);
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        })
    }, []);

    const createArray = (element) => {
        let array = [];
        if (element) {
            element.forEach((data) => {
                array.push({
                    id: data.id,
                    text: data.text
                });
            });
        }
        return array;
    }

    const addCommas = (num) => {
        if (num.length > 1) {
            if (num[0] === '0') {
                num = num.slice(1);
            }
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    const alpha = (value) => {
        let res = value.replace(/[^A-Za-z -]/g, "");
        return res;
    }

    const progressSurvey = () => {
        let body = {
            checkpointCategory: 'MY_COMPANY_OUTPUT',
            completed: 1,
            required: 1,
            isChange: true
        };
        Connect(`${Endpoint.putProgressSurvey}`, 'PUT', body).then(response => {
            // console.log(response);
        });
    }

    const createDocument = () => {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <Image style={styles.headerLogo} source={whiteLogo} />
                        <Text style={styles.headerText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Company type simulation' : 'Ondernemingsvorm simulatie'}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={[styles.title]}>Hey {props.type !== 'admin' ? form && form.name : props.form && props.form.name}, {localStorage.getItem('i18nextLng') === 'en' ? 'thanks for completing the \ncompany type survey!' : 'bedankt voor het invullen van de ondernemingsvorm survey!'}</Text>
                        <Text style={styles.text}>{localStorage.getItem('i18nextLng') === 'en' ? 'We’ve generated your company type simulation, based on your input.' : 'We hebben op basis van jouw input een simulatie gemaakt die je ideale ondernemingsvorm aantoont.'}</Text>
                        <Text style={styles.text}>{localStorage.getItem('i18nextLng') === 'en' ? 'Before we get into the results, we recommend you to check the comparison table below. It shows the key differences between starting as a sole trader and starting with a limited company.' : 'Voordat we ingaan op de resultaten, raden we je aan de onderstaande vergelijkingstabel te bekijken. Deze vergelijkt een opstart als éénmanszaak met de opstart van een vennootschap.'}</Text>
                        <Text style={styles.text}>{localStorage.getItem('i18nextLng') === 'en' ? 'On the next page, you will find the results of your simulation. Finally, you will find \na summary of your input on the final page.' : 'Op de volgende pagina vind je de resultaten van je simulatie. Tot slot vind je op de laatste pagina een samenvatting van je input.'}</Text>
                        <Text style={styles.subTitle}>{localStorage.getItem('i18nextLng') === 'en' ? 'Sole proprietorship' : 'Éénmanszaak'}</Text>
                        <View style={styles.row}>
                            <View style={styles.box}>
                                <View style={styles.sole}>
                                    <View style={styles.soleBox}>
                                        <View style={styles.soleHeader}>
                                            <Text style={styles.successText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Advantages' : 'Voordelen'}</Text>
                                        </View>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Low establishment costs' : 'Lage oprichtingskosten'}</Text>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Simplified accounting' : 'Enkelvoudige boekhouding'}</Text>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'One founder' : 'Één oprichter'}</Text>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'No financial plan needed' : 'Geen financieel plan nodig'}</Text>
                                    </View>
                                    <View style={styles.soleBox}>
                                        <View style={styles.soleHeader}>
                                            <Text style={[styles.successText, styles.errorText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Disadvantages' : 'Nadelen'}</Text>
                                        </View>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Personally liable' : 'Persoonlijk aansprakelijk'}</Text>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Higher tax rate' : 'Hoger belastingtarief'}</Text>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'No possibility for' : 'Geen mogelijkheid voor externe investeerders'}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.box}>
                                <Text style={styles.awardText}>{localStorage.getItem('i18nextLng') === 'en' ? 'If you’re just getting started as self-employed and don’t have a lot of projected turnover yet, the sole proprietorship might be a good fit for you. You can start a sole proprietorship quickly, without many start-up or shut-down costs.' : 'Als je je eerste stappen als zelfstandige neemt en nog niet veel omzet hebt, is een éénmanszaak misschien iets voor jou. Je kunt een éénmanszaak snel starten, zonder veel opstart- of afsluitkosten.'}</Text>
                                <Text style={styles.awardText}>{localStorage.getItem('i18nextLng') === 'en' ? 'The downside is that you will be taxed entirely via the personal income tax, which has a higher tax rate than the corporate tax. Also, you are personally \nliable if things don’t turn out as expected.' : 'Het nadeel is dat je volledig wordt belast via de personenbelasting, die een hoger belastingtarief heeft dan de vennootschapsbelasting. Bovendien ben je persoonlijk aansprakelijk als de zaken niet lopen zoals verwacht.'}</Text>
                            </View>
                        </View>
                        <Text style={styles.subTitle}>{localStorage.getItem('i18nextLng') === 'en' ? 'Limited company' : 'Besloten vennootschap'}</Text>
                        <View style={styles.row}>
                            <View style={styles.box}>
                                <View style={styles.sole}>
                                    <View style={styles.soleBox}>
                                        <View style={styles.soleHeader}>
                                            <Text style={styles.successText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Advantages' : 'Voordelen'}</Text>
                                        </View>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Private wealth protected' : 'Privévermogen beschermd'}</Text>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Lots of fiscal advantages' : 'Veel fiscale voordelen'}</Text>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Establish with a partner' : 'Opzetten met een partner'}</Text>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Financial risks are limited' : 'Financiële risico\'s zijn beperkt'}</Text>
                                    </View>
                                    <View style={styles.soleBox}>
                                        <View style={styles.soleHeader}>
                                            <Text style={[styles.successText, styles.errorText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Disadvantages' : 'Nadelen'}</Text>
                                        </View>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'More complex establishment' : 'Complexere vestiging'}</Text>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Double accounting system' : 'Dubbel boekhoudsysteem'}</Text>
                                        <Text style={[styles.successText, styles.soleText]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Administrative requirements' : 'Administratieve vereisten'}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={[styles.box]}>
                                <Text style={styles.awardText}>{localStorage.getItem('i18nextLng') === 'en' ? 'The private limited company (BV) is the most \nestablished company type in Belgium, and for good reason. It’s fiscal advantages and flexibility in terms of the establishment terms make it the most \nsuitable company type for most starters.' : 'De besloten vennootschap met beperkte aansprakelijkheid (BV) is het meest opgerichte vennootschapsvorm in België, en daar is een goede reden voor. De fiscale voordelen en flexibiliteit wat betreft de oprichtingsvoorwaarden maken het de meest geschikte vennootschapsvorm voor de meeste starters.'}</Text>
                                <Text style={styles.awardText}>{localStorage.getItem('i18nextLng') === 'en' ? 'While you don’t need a legally stipulated minimum capital anymore to establish a BV, it’s important to have a strong financial plan that frames why your starting capital is sufficient for the activities you have in mind.' : 'Hoewel je geen wettelijk vastgesteld minimumkapitaal meer nodig hebt om een BV op te richten, is het belangrijk om een sterk financieel plan te hebben dat duidelijk maakt waarom je startkapitaal voldoende is voor de activiteiten die je voor ogen hebt.'}</Text>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.box}>
                                <Image style={styles.lightLogo} source={Light} />
                            </View>
                            <Text style={styles.summaryText}>{localStorage.getItem('i18nextLng') === 'en' ? '97,4% of all companies established in 2022 in Belgium are private limited companies.' : '97,4% van alle in 2022 opgerichte bedrijven in België zijn besloten vennootschappen met beperkte aansprakelijkheid.'}</Text>
                        </View>
                    </View>
                    <View style={styles.footer}>
                        <Image style={styles.footerLogo} source={Logo} />
                        <View style={styles.footerBox}>
                            <Text style={styles.footerText}>BE0686.793.751</Text>
                            <Text style={styles.footerText}>-</Text>
                            <Text style={styles.footerText}>Terlinden 178, 1785 Merchtem</Text>
                            <Text style={styles.footerText}>-</Text>
                            <Text style={styles.footerText}>support@bizantium.be</Text>
                        </View>
                    </View>
                </Page>
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <Image style={styles.headerLogo} source={whiteLogo} />
                        <Text style={styles.headerText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Company type simulation' : 'Ondernemingsvorm simulatie'}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.title}>{localStorage.getItem('i18nextLng') === 'en' ? 'Our recommendation' : 'Onze aanbeveling'}</Text>
                        <View style={[styles.row, {marginTop: 25}]}>
                            <Image style={styles.lightLogo} source={Light} />
                            {props.type !== 'admin' ?
                                outputData && outputData.soleAvgTaxRate && outputData.ltdAvgTaxRate && (outputData.soleAvgTaxRate < outputData.ltdAvgTaxRate) ?
                                    <Text
                                        style={[styles.subTitle, styles.margin0]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Starting as a sole trader is the way to go!' : 'Starten als eenmanszaak is dé oplossing!'}</Text>
                                :
                                    <Text
                                        style={[styles.subTitle, styles.margin0]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Establishing a limited company is the way to go!' : 'Een besloten vennootschap is je beste optie!'}</Text>
                            :
                                props.outputData && props.outputData.soleAvgTaxRate && props.outputData.ltdAvgTaxRate && (props.outputData.soleAvgTaxRate < props.outputData.ltdAvgTaxRate) ?
                                    <Text
                                        style={[styles.subTitle, styles.margin0]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Starting as a sole trader is the way to go!' : 'Starten als eenmanszaak is dé oplossing!'}</Text>
                                    :
                                    <Text
                                        style={[styles.subTitle, styles.margin0]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Establishing a limited company is the way to go!' : 'Een besloten vennootschap is je beste optie!'}</Text>
                            }
                        </View>
                        {props.type !== 'admin' ?
                            outputData && outputData.soleAvgTaxRate && outputData.ltdAvgTaxRate && (outputData.soleAvgTaxRate < outputData.ltdAvgTaxRate) ?
                                <>
                                    <Text style={styles.text}>{localStorage.getItem('i18nextLng') === 'en' ? 'We recommend that you start as a sole trader, and later re-evaluate whether or not an upgrade to a limited company is in order.' : 'We raden je aan om als éénmanszaak te starten en de simulatie later opnieuw te proberen om te zien of je gebaat zou zijn bij een vennootschapsstructuur.'}</Text>
                                    <Text style={styles.text}>{localStorage.getItem('i18nextLng') === 'en' ? 'This is due to the fact that with your expected revenue and costs, you wouldn’t benefit from a limited company structure.' : 'Je ziet hieronder waarom we dat aanbevelen. Met je huidige omzet en kostenstructuur zou je minder belastingen betalen als éénmanszaak.'}</Text>
                                </>
                            :
                                <>
                                    <Text style={styles.text}>{localStorage.getItem('i18nextLng') === 'en' ? 'With a private limited company, you immediately get the space you need to start and grow your business.': 'Met een besloten vennootschap krijg je meteen de nodige ruimte om je onderneming op te starten en te laten groeien.'}</Text>
                                    <Text style={styles.text}>{localStorage.getItem('i18nextLng') === 'en' ? 'You can see below why we recommend this. With your current turnover and cost structure, a limited company will generate a better fiscal result.' : 'Je ziet hieronder waarom we dat aanbevelen. Met je huidige omzet en kostenstructuur hou je met een vennootschap meer over aan het einde van de rit.'}</Text>
                                </>
                        :
                            props.outputData && props.outputData.soleAvgTaxRate && props.outputData.ltdAvgTaxRate && (props.outputData.soleAvgTaxRate < props.outputData.ltdAvgTaxRate) ?
                                <>
                                    <Text style={styles.text}>{localStorage.getItem('i18nextLng') === 'en' ? 'We recommend that you start as a sole trader, and later re-evaluate whether or not an upgrade to a limited company is in order.' : 'We raden je aan om als éénmanszaak te starten en de simulatie later opnieuw te proberen om te zien of je gebaat zou zijn bij een vennootschapsstructuur.'}</Text>
                                    <Text style={styles.text}>{localStorage.getItem('i18nextLng') === 'en' ? 'This is due to the fact that with your expected revenue and costs, you wouldn’t benefit from a limited company structure.' : 'Je ziet hieronder waarom we dat aanbevelen. Met je huidige omzet en kostenstructuur zou je minder belastingen betalen als éénmanszaak.'}</Text>
                                </>
                                :
                                <>
                                    <Text style={styles.text}>{localStorage.getItem('i18nextLng') === 'en' ? 'With a private limited company, you immediately get the space you need to start and grow your business.': 'Met een besloten vennootschap krijg je meteen de nodige ruimte om je onderneming op te starten en te laten groeien.'}</Text>
                                    <Text style={styles.text}>{localStorage.getItem('i18nextLng') === 'en' ? 'You can see below why we recommend this. With your current turnover and cost structure, a limited company will generate a better fiscal result.' : 'Je ziet hieronder waarom we dat aanbevelen. Met je huidige omzet en kostenstructuur hou je met een vennootschap meer over aan het einde van de rit.'}</Text>
                                </>
                        }
                        <View style={styles.row}>
                            <Text style={styles.subHeader}>{localStorage.getItem('i18nextLng') === 'en' ? 'Sole trader' : 'Éénmanszaak'}</Text>
                        </View>
                        <View style={styles.container}>
                            <View style={[styles.bar, { width: progress }]}></View>
                            <View style={[styles.except, { left: 75 }]}>
                                <Text style={styles.smallText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Personal income tax' : 'Personenbelasting'}</Text>
                            </View>
                            <Text style={{fontFamily: 'Inter', fontSize: 11, position: 'absolute', left: 105, top: 7, color: '#FFF'}}>{`€${props.type !== 'admin' ? (outputData && outputData.soleIncomeTax ? outputData.soleIncomeTax : 0) : (props.outputData && props.outputData.soleIncomeTax ? props.outputData.soleIncomeTax : 0)}`}</Text>
                            <View style={[styles.except, { left: 325 }]}>
                                <Text style={styles.smallText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Social contributions' : 'Sociale bijdragen'}</Text>
                            </View>
                            <Text style={{fontFamily: 'Inter', fontSize: 11, position: 'absolute', left: 355, top: 7, color: '#FFF'}}>{`€${props.type !== 'admin' ? (outputData && outputData.soleSocialContribution ? outputData.soleSocialContribution : 0) : (props.outputData && props.outputData.soleSocialContribution ? props.outputData.soleSocialContribution : 0)}`}</Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: 500}}>
                            <View style={styles.containerExtend}>
                                <View style={[styles.box, {width: 100, alignItems: 'center'}]}>
                                    {props.type !== 'admin' ?
                                        outputData && outputData.soleAvgTaxRate && outputData.ltdAvgTaxRate && (outputData.soleAvgTaxRate < outputData.ltdAvgTaxRate) ?
                                            <Text style={{color: '#04B497', fontFamily: 'Inter', fontSize: 11, marginBottom: 5}}>{`${props.type !== 'admin' ? (outputData && outputData.soleAvgTaxRate ? outputData.soleAvgTaxRate : 0) : (props.outputData && props.outputData.soleAvgTaxRate ? props.outputData.soleAvgTaxRate : 0)}%`}</Text>
                                        :
                                            <Text style={{color: '#E94F37', fontFamily: 'Inter', fontSize: 11, marginBottom: 5}}>{`${props.type !== 'admin' ? (outputData && outputData.soleAvgTaxRate ? outputData.soleAvgTaxRate : 0) : (props.outputData && props.outputData.soleAvgTaxRate ? props.outputData.soleAvgTaxRate : 0)}%`}</Text>
                                    :
                                        props.outputData && props.outputData.soleAvgTaxRate && props.outputData.ltdAvgTaxRate && (props.outputData.soleAvgTaxRate < props.outputData.ltdAvgTaxRate) ?
                                            <Text style={{color: '#04B497', fontFamily: 'Inter', fontSize: 11, marginBottom: 5}}>{`${props.type !== 'admin' ? (outputData && outputData.soleAvgTaxRate ? outputData.soleAvgTaxRate : 0) : (props.outputData && props.outputData.soleAvgTaxRate ? props.outputData.soleAvgTaxRate : 0)}%`}</Text>
                                            :
                                            <Text style={{color: '#E94F37', fontFamily: 'Inter', fontSize: 11, marginBottom: 5}}>{`${props.type !== 'admin' ? (outputData && outputData.soleAvgTaxRate ? outputData.soleAvgTaxRate : 0) : (props.outputData && props.outputData.soleAvgTaxRate ? props.outputData.soleAvgTaxRate : 0)}%`}</Text>
                                    }
                                    <Text style={{color: '#8181A5', fontSize: 8}}>{localStorage.getItem('i18nextLng') === 'en' ? 'Average tax rate' : 'Gemiddeld belastingtarief'}</Text>
                                </View>
                                <View style={[styles.box, {width: 100, alignItems: 'center'}]}>
                                    <Text style={{color: '#000', fontFamily: 'Inter', fontSize: 11, marginBottom: 5}}>{`€${props.type !== 'admin' ? (outputData && outputData.soleTotalTax ? outputData.soleTotalTax : 0) : (props.outputData && props.outputData.soleTotalTax ? props.outputData.soleTotalTax : 0)}`}</Text>
                                    <Text style={{color: '#8181A5', fontSize: 8}}>{localStorage.getItem('i18nextLng') === 'en' ? 'Total taxes' : 'Totale belastingen'}</Text>
                                </View>
                                <View style={[styles.box, {width: 100, alignItems: 'center'}]}>
                                    <Text style={{color: '#000', fontFamily: 'Inter', fontSize: 11, marginBottom: 5}}>{`€${props.type !== 'admin' ? (outputData && outputData.soleMonthNetIncome ? outputData.soleMonthNetIncome : 0) : (props.outputData && props.outputData.soleMonthNetIncome ? props.outputData.soleMonthNetIncome : 0)}`}</Text>
                                    <Text style={{color: '#8181A5', fontSize: 8}}>{localStorage.getItem('i18nextLng') === 'en' ? 'Monthly net income' : 'Maandelijks netto-inkomen'}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.subHeader}>{localStorage.getItem('i18nextLng') === 'en' ? 'Limited liability company' : 'Besloten vennootschap'}</Text>
                        </View>
                        <View style={styles.container}>
                            <View style={[styles.bar, { width: limitProgress }]}></View>
                            <View style={[styles.limitedBar, { width: limitProgress2 }]}></View>
                            <View style={[styles.except, { left: 30 }]}>
                                <Text style={styles.smallText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Personal income tax' : 'Personenbelasting'}</Text>
                            </View>
                            <Text style={{fontFamily: 'Inter', fontSize: 11, position: 'absolute', left: 60, top: 7, color: '#FFF'}}>{`€${props.type !== 'admin' ? (outputData && outputData.ltdIncomeTax ? outputData.ltdIncomeTax : 0) : (props.outputData && props.outputData.ltdIncomeTax ? props.outputData.ltdIncomeTax : 0)}`}</Text>
                            <View style={[styles.except, { left: 180 }]}>
                                <Text style={styles.smallText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Social contributions' : 'Sociale bijdragen'}</Text>
                            </View>
                            <Text style={{fontFamily: 'Inter', fontSize: 11, position: 'absolute', left: 210, top: 7, color: '#FFF'}}>{`€${props.type !== 'admin' ? (outputData && outputData.ltdSocialContribution ? outputData.ltdSocialContribution : 0) : (props.outputData && props.outputData.ltdSocialContribution ? props.outputData.ltdSocialContribution : 0)}`}</Text>
                            <View style={[styles.except, { left: 360 }]}>
                                <Text style={styles.smallText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Corporate tax' : 'Vennootschapsbelasting'}</Text>
                            </View>
                            <Text style={{fontFamily: 'Inter', fontSize: 11, position: 'absolute', left: 390, top: 7, color: '#FFF'}}>{`€${props.type !== 'admin' ? (outputData && outputData.ltdCorporateTax ? outputData.ltdCorporateTax : 0) : (props.outputData && props.outputData.ltdCorporateTax ? props.outputData.ltdCorporateTax : 0)}`}</Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: 500}}>
                            <View style={styles.containerExtend}>
                                <View style={[styles.box, {width: 100, alignItems: 'center'}]}>
                                    {props.type !== 'admin' ?
                                        outputData && outputData.soleAvgTaxRate && outputData.ltdAvgTaxRate && (outputData.soleAvgTaxRate < outputData.ltdAvgTaxRate) ?
                                            <Text style={{
                                                color: '#E94F37',
                                                fontFamily: 'Inter',
                                                fontSize: 11,
                                                marginBottom: 5
                                            }}>{`${props.type !== 'admin' ? (outputData && outputData.ltdAvgTaxRate ? outputData.ltdAvgTaxRate : 0) : (props.outputData && props.outputData.ltdAvgTaxRate ? props.outputData.ltdAvgTaxRate : 0)}%`}</Text>
                                            :
                                            <Text style={{
                                                color: '#04B497',
                                                fontFamily: 'Inter',
                                                fontSize: 11,
                                                marginBottom: 5
                                            }}>{`${props.type !== 'admin' ? (outputData && outputData.ltdAvgTaxRate ? outputData.ltdAvgTaxRate : 0) : (props.outputData && props.outputData.ltdAvgTaxRate ? props.outputData.ltdAvgTaxRate : 0)}%`}</Text>
                                    :
                                        props.outputData && props.outputData.soleAvgTaxRate && props.outputData.ltdAvgTaxRate && (props.outputData.soleAvgTaxRate < props.outputData.ltdAvgTaxRate) ?
                                            <Text style={{
                                                color: '#E94F37',
                                                fontFamily: 'Inter',
                                                fontSize: 11,
                                                marginBottom: 5
                                            }}>{`${props.type !== 'admin' ? (outputData && outputData.ltdAvgTaxRate ? outputData.ltdAvgTaxRate : 0) : (props.outputData && props.outputData.ltdAvgTaxRate ? props.outputData.ltdAvgTaxRate : 0)}%`}</Text>
                                            :
                                            <Text style={{
                                                color: '#04B497',
                                                fontFamily: 'Inter',
                                                fontSize: 11,
                                                marginBottom: 5
                                            }}>{`${props.type !== 'admin' ? (outputData && outputData.ltdAvgTaxRate ? outputData.ltdAvgTaxRate : 0) : (props.outputData && props.outputData.ltdAvgTaxRate ? props.outputData.ltdAvgTaxRate : 0)}%`}</Text>
                                    }
                                    <Text style={{color: '#8181A5', fontSize: 8}}>{localStorage.getItem('i18nextLng') === 'en' ? 'Average tax rate' : 'Gemiddeld belastingtarief'}</Text>
                                </View>
                                <View style={[styles.box, {width: 100, alignItems: 'center'}]}>
                                    <Text style={{color: '#000', fontFamily: 'Inter', fontSize: 11, marginBottom: 5}}>{`€${props.type !== 'admin' ? (outputData && outputData.ltdTotalTax ? outputData.ltdTotalTax : 0) : (props.outputData && props.outputData.ltdTotalTax ? props.outputData.ltdTotalTax : 0)}`}</Text>
                                    <Text style={{color: '#8181A5', fontSize: 8}}>{localStorage.getItem('i18nextLng') === 'en' ? 'Total taxes' : 'Totale belastingen'}</Text>
                                </View>
                                <View style={[styles.box, {width: 100, alignItems: 'center'}]}>
                                    <Text style={{color: '#000', fontFamily: 'Inter', fontSize: 11, marginBottom: 5}}>{`€${props.type !== 'admin' ? (outputData && outputData.ltdMonthNetIncome ? outputData.ltdMonthNetIncome : 0) : (props.outputData && props.outputData.ltdMonthNetIncome ? props.outputData.ltdMonthNetIncome : 0)}`}</Text>
                                    <Text style={{color: '#8181A5', fontSize: 8}}>{localStorage.getItem('i18nextLng') === 'en' ? 'Monthly net income' : 'Maandelijks netto-inkomen'}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[styles.box, {alignItems: 'center'}]}>
                            <Text style={styles.graphText}>{localStorage.getItem('i18nextLng') === 'en' ? 'The information contained in this simulation has a purely informational value. The simulation is not advice per se and cannot serve as a substitute for tax, legal or accounting advice. Bizantium is in no way liable for any damages of any kind resulting from this simulation.' : 'De informatie in deze simulatie heeft een zuiver informatieve waarde. De simulatie is geen advies op zich en kan niet dienen ter vervanging van fiscaal, juridisch of boekhoudkundig advies. Bizantium is op geen enkele wijze aansprakelijk voor enige schade als gevolg van deze simulatie.'}</Text>
                        </View>
                    </View>
                    <View style={styles.footer}>
                        <Image style={styles.footerLogo} source={Logo} />
                        <View style={styles.footerBox}>
                            <Text style={styles.footerText}>BE0686.793.751</Text>
                            <Text style={styles.footerText}>-</Text>
                            <Text style={styles.footerText}>Terlinden 178, 1785 Merchtem</Text>
                            <Text style={styles.footerText}>-</Text>
                            <Text style={styles.footerText}>support@bizantium.be</Text>
                        </View>
                    </View>
                </Page>
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <Image style={styles.headerLogo} source={whiteLogo} />
                        <Text style={styles.headerText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Company type simulation' : 'Ondernemingsvorm simulatie'}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={styles.title}>{localStorage.getItem('i18nextLng') === 'en' ? 'Summary of your input' : 'Samenvatting van jouw input'}</Text>
                        <Text style={[styles.subTitle, {marginTop: 10}]}>{localStorage.getItem('i18nextLng') === 'en' ? 'This simulation was made for' : 'Deze simulatie is gemaakt voor'}</Text>
                        <View style={styles.table}>
                            <View style={styles.row}>
                                <View style={styles.tableBox}>
                                    <Text style={styles.tableText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Full name' : 'Naam'}</Text>
                                    <Text style={[styles.tableText, styles.bold]}>{props.type !== 'admin' ? form && form.name : props.form && props.form.name} {props.type !== 'admin' ? form && form.surname : props.form && props.form.surname}</Text>
                                </View>
                                <View style={styles.tableBox}>
                                    <Text style={styles.tableText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Phone' : 'Telefoon'}</Text>
                                    <Text style={[styles.tableText, styles.bold]}>{props.type !== 'admin' ? form && form.phone : props.form && props.form.phone}</Text>
                                </View>
                            </View>
                            <View style={styles.row}>
                                <View style={styles.tableBox}>
                                    <Text style={styles.tableText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Email' : 'Email'}</Text>
                                    <Text style={[styles.tableText, styles.bold]}>{props.type !== 'admin' ? form && form.email : props.form && props.form.email}</Text>
                                </View>
                                <View style={styles.tableBox}>
                                    <Text style={styles.tableText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Nationality' : 'Nationaliteit'}</Text>
                                    <Text style={[styles.tableText, styles.bold]}>{props.type !== 'admin' ? (form && form.nationality && form.nationality[0] && form.nationality[0].text) : (props.form && props.form.nationality && props.form.nationality[0] && props.form.nationality[0].text)}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={[styles.row, {width: 520, alignItems: 'flex-start'}]}>
                            <View style={styles.box}>
                                <Text style={[styles.subTitle, {marginTop: 10}]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Company info' : 'Bedrijfsinformatie'}</Text>
                                <View style={styles.tableCompany}>
                                    <View style={styles.tableCompanyBox}>
                                        <Text style={styles.tableText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Starting date' : 'Opstartdatum'}</Text>
                                        <Text style={[styles.tableText, styles.bold]}>{props.type !== 'admin' ? form && form.dateStart && transformDate(form.dateStart.substring(8,10), form.dateStart.substring(5,7), form.dateStart.substring(0,4)) : props.form && props.form.dateStart && transformDate(props.form.dateStart.substring(8,10), props.form.dateStart.substring(5,7), props.form.dateStart.substring(0,4)) }</Text>
                                    </View>
                                    <View style={styles.tableCompanyBox}>
                                        <Text style={styles.tableText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Company name' : 'Bedrijfsnaam'}</Text>
                                        <Text style={[styles.tableText, styles.bold]}>{props.type !== 'admin' ? form && form.companyName : props.form && props.companyName}</Text>
                                    </View>
                                    <View style={styles.tableCompanyBox}>
                                        <Text style={styles.tableText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Activity' : 'Activiteit'}</Text>
                                        <Text style={[styles.tableText, styles.bold]}>{props.type !== 'admin' ? form && form.companyActivities : props.form && props.form.companyActivities}</Text>
                                    </View>
                                    <View style={styles.tableCompanyBox}>
                                        <Text style={styles.tableText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Occupation' : 'Hoofd- of bijberoep'}</Text>
                                        <Text style={[styles.tableText, styles.bold]}>{props.type !== 'admin' ? form && form.occupation && form.occupation[0] && form.occupation[0].text : props.form && props.form.occupation && props.form.occupation[0] && props.form.occupation[0].text}</Text>
                                    </View>
                                    <View style={styles.tableCompanyBox}>
                                        <Text style={styles.tableText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Postal code' : 'Postcode'}</Text>
                                        <Text style={[styles.tableText, styles.bold]}>{props.type !== 'admin' ? form && form.postalCode : props.form && props.form.postalCode}</Text>
                                    </View>
                                    <View style={styles.tableCompanyBox}>
                                        <Text style={styles.tableText}>{localStorage.getItem('i18nextLng') === 'en' ? 'Region' : 'Regio'}</Text>
                                        <Text style={[styles.tableText, styles.bold]}>{props.type !== 'admin' ? form && form.city : props.form && props.form.city}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.box}>
                                <Text style={[styles.subTitle, {marginTop: 10}]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Revenue' : 'Omzet'}</Text>
                                <View style={styles.tableRevenue}>
                                    <View style={styles.tableRevenueBox}>
                                        <Text style={styles.revenueTextPrev}>{localStorage.getItem('i18nextLng') === 'en' ? 'Revenue' : 'Omzet'} - Y1</Text>
                                        <Text style={styles.revenueText}>€{props.type !== 'admin' ? (form && form.revenue && form && form.revenue.replaceAll('.', '') * (form && form.dateStart && 12 - (form && form.dateStart && parseInt(form.dateStart.substring(8,10)) >= 15 ? parseInt(form.dateStart.substring(5,7)) : parseInt(form.dateStart.substring(5,7)) - 1))) : (props.form && props.form.revenue && props.form && props.form.revenue.replaceAll('.', '') * (props.form && props.form.dateStart && 12 - (parseInt(props.form.dateStart.substring(8,10)) >= 15 ? parseInt(props.form.dateStart.substring(5,7)) : parseInt(props.form.dateStart.substring(5,7)) - 1)))}</Text>
                                        <Text style={styles.revenueTextDate}>{props.type !== 'admin' ? form && form.dateStart && transformDate(form.dateStart.substring(8,10), form.dateStart.substring(5,7), form.dateStart.substring(0,4)) : props.form && props.form.dateStart && transformDate(props.form.dateStart.substring(8,10), props.form.dateStart.substring(5,7), props.form.dateStart.substring(0,4))} - 31/12/{props.type !== 'admin' ? form && form.dateStart && form.dateStart.substring(0,4) : props.form && props.form.dateStart && props.form.dateStart.substring(0,4)}</Text>
                                        <Image style={styles.grow} source={Grow} />
                                    </View>
                                    <View style={styles.tableRevenueBox}>
                                        <Text style={styles.revenueTextPrev}>{localStorage.getItem('i18nextLng') === 'en' ? 'Revenue' : 'Omzet'} - Y2</Text>
                                        <Text style={styles.revenueText}>€{props.type !== 'admin' ? (form && form.revenueNextYear && form && form.revenueNextYear.replaceAll('.', '') * 12) : (props.form && props.form.revenueNextYear && props.form && props.form.revenueNextYear.replaceAll('.', '') * 12)}</Text>
                                        <Text style={styles.revenueTextDate}>01/01/{props.type !== 'admin' ? form && form.dateStart && parseInt(form.dateStart.substring(0,4)) + 1 : props.form && props.form.dateStart && parseInt(props.form.dateStart.substring(0,4)) + 1} - 31/12/{props.type !== 'admin' ? form && form.dateStart && parseInt(form.dateStart.substring(0,4)) + 1 : props.form && props.form.dateStart && parseInt(props.form.dateStart.substring(0,4)) + 1}</Text>
                                        <Image style={styles.grow} source={Grow} />
                                    </View>
                                    <View style={styles.tableRevenueBox}>
                                        <Text style={styles.revenueTextPrev}>{localStorage.getItem('i18nextLng') === 'en' ? 'Revenue' : 'Omzet'} - Y3</Text>
                                        <Text style={styles.revenueText}>€{props.type !== 'admin' ? (form && form.revenueThirdYear && form && form.revenueThirdYear.replaceAll('.', '') * 12) : (props.form && props.form.revenueThirdYear && props.form && props.form.revenueThirdYear.replaceAll('.', '') * 12)}</Text>
                                        <Text style={styles.revenueTextDate}>01/01/{props.type !== 'admin' ? form && form.dateStart && parseInt(form.dateStart.substring(0,4)) + 1 : props.form && props.form.dateStart && parseInt(props.form.dateStart.substring(0,4)) + 2} - 31/12/{props.type !== 'admin' ? form && form.dateStart && parseInt(form.dateStart.substring(0,4)) + 1 : props.form && props.form.dateStart && parseInt(props.form.dateStart.substring(0,4)) + 2}</Text>
                                        <Image style={styles.grow} source={Grow} />
                                    </View>
                                </View>
                            </View>
                        </View>
                        <Text style={[styles.subTitle, {marginTop: 10}]}>{localStorage.getItem('i18nextLng') === 'en' ? 'Cost structure' : 'Kostenstructuur'}</Text>
                        <View style={styles.tableCost}>
                            {props.type !== 'admin' ?
                                graphSummary && graphSummary[0] &&
                                    <Svg width="200" height="200" viewBox="-25 -25 250 250">
                                        <Circle
                                            cx="100"
                                            cy="100"
                                            r="90"
                                            fill="none"
                                            stroke={graphSummary && graphSummary[0] ? graphSummary[0][0] : '#000000'}
                                            strokeWidth={14}
                                            strokeDasharray={"565"}
                                        />
                                        <Circle
                                            cx="100"
                                            cy="100"
                                            r="90"
                                            fill="none"
                                            stroke={graphSummary && graphSummary[1] ? graphSummary[1][0] : '#000000'}
                                            strokeWidth={14}
                                            strokeDasharray={graphSummary && graphSummary[0] && isNaN(graphSummary[0][1]) === false ? `${((100 - graphSummary[0][1])/100) * 564}` : "0"}
                                        />
                                        <Circle
                                            cx="100"
                                            cy="100"
                                            r="90"
                                            fill="none"
                                            stroke={graphSummary && graphSummary[2] ? graphSummary[2][0] : '#000000'}
                                            strokeWidth={14}
                                            strokeDasharray={graphSummary && graphSummary[1] && isNaN(graphSummary[0][1]) === false ? `${((100 - (graphSummary[1][1] + graphSummary[0][1]))/100) * 564}` : "0"}
                                        />
                                        <Circle
                                            cx="100"
                                            cy="100"
                                            r="90"
                                            fill="none"
                                            stroke={graphSummary && graphSummary[3] ? graphSummary[3][0] : '#000000'}
                                            strokeWidth={14}
                                            strokeDasharray={graphSummary && graphSummary[1] && isNaN(graphSummary[0][1]) === false ? `${((100 - (graphSummary[2][1] + graphSummary[1][1] + graphSummary[0][1]))/100) * 564}` : "0"}
                                        />
                                        <Circle
                                            cx="100"
                                            cy="100"
                                            r="90"
                                            fill="none"
                                            stroke={graphSummary && graphSummary[4] ? graphSummary[4][0] : '#000000'}
                                            strokeWidth={14}
                                            strokeDasharray={graphSummary && graphSummary[1] && isNaN(graphSummary[0][1]) === false ? `${((100 - (graphSummary[3][1] + graphSummary[2][1] + graphSummary[1][1] + graphSummary[0][1]))/100) * 564}` : "0"}
                                        />
                                        <Circle
                                            cx="100"
                                            cy="100"
                                            r="90"
                                            fill="none"
                                            stroke={graphSummary && graphSummary[5] ? graphSummary[5][0] : '#000000'}
                                            strokeWidth={14}
                                            strokeDasharray={graphSummary && graphSummary[1] && isNaN(graphSummary[0][1]) === false ? `${(((100 - (graphSummary[4][1] + graphSummary[3][1] + graphSummary[2][1] + graphSummary[1][1] + graphSummary[0][1]))/100) * 564) < 250 ? 250 : ((100 - (graphSummary[4][1] + graphSummary[3][1] + graphSummary[2][1] + graphSummary[1][1] + graphSummary[0][1]))/100) * 564}` : "0"}
                                        />
                                    </Svg>
                            :
                                props.graphSummary !== undefined &&
                                    <Svg width="200" height="200" viewBox="-25 -25 250 250">
                                        <Circle
                                            cx="100"
                                            cy="100"
                                            r="90"
                                            fill="none"
                                            stroke={props.graphSummary && props.graphSummary[0] ? props.graphSummary[0][0] : '#000000'}
                                            strokeWidth={14}
                                            strokeDasharray={"565"}
                                        />
                                        <Circle
                                            cx="100"
                                            cy="100"
                                            r="90"
                                            fill="none"
                                            stroke={props.graphSummary && props.graphSummary[1] ? props.graphSummary[1][0] : '#000000'}
                                            strokeWidth={14}
                                            strokeDasharray={props.graphSummary && props.graphSummary[0] ? `${((100 - props.graphSummary[0][1])/100) * 564}` : "0"}
                                        />
                                        <Circle
                                            cx="100"
                                            cy="100"
                                            r="90"
                                            fill="none"
                                            stroke={props.graphSummary && props.graphSummary[2] ? props.graphSummary[2][0] : '#000000'}
                                            strokeWidth={14}
                                            strokeDasharray={props.graphSummary && props.graphSummary[1] ? `${((100 - (props.graphSummary[1][1] + props.graphSummary[0][1]))/100) * 564}` : "0"}
                                        />
                                        <Circle
                                            cx="100"
                                            cy="100"
                                            r="90"
                                            fill="none"
                                            stroke={props.graphSummary && props.graphSummary[3] ? props.graphSummary[3][0] : '#000000'}
                                            strokeWidth={14}
                                            strokeDasharray={props.graphSummary && props.graphSummary[1] ? `${((100 - (props.graphSummary[2][1] + props.graphSummary[1][1] + props.graphSummary[0][1]))/100) * 564}` : "0"}
                                        />
                                        <Circle
                                            cx="100"
                                            cy="100"
                                            r="90"
                                            fill="none"
                                            stroke={props.graphSummary && props.graphSummary[4] ? props.graphSummary[4][0] : '#000000'}
                                            strokeWidth={14}
                                            strokeDasharray={props.graphSummary && props.graphSummary[1] ? `${((100 - (props.graphSummary[3][1] + props.graphSummary[2][1] + props.graphSummary[1][1] + props.graphSummary[0][1]))/100) * 564}` : "0"}
                                        />
                                        <Circle
                                            cx="100"
                                            cy="100"
                                            r="90"
                                            fill="none"
                                            stroke={props.graphSummary && props.graphSummary[5] ? props.graphSummary[5][0] : '#000000'}
                                            strokeWidth={14}
                                            strokeDasharray={props.graphSummary && props.graphSummary[1] ? `${(((100 - (props.graphSummary[4][1] + props.graphSummary[3][1] + props.graphSummary[2][1] + props.graphSummary[1][1] + props.graphSummary[0][1]))/100) * 564) < 280 ? 280 : ((100 - (props.graphSummary[4][1] + props.graphSummary[3][1] + props.graphSummary[2][1] + props.graphSummary[1][1] + props.graphSummary[0][1]))/100) * 564}` : "0"}
                                        />
                                    </Svg>
                            }
                            <View style={{position: 'absolute', left: 50, top: 70}}>
                                <Text style={{fontFamily: 'Inter', fontWeight: 'bold', fontSize: 20, color: '#2E1754', width: 100, marginBottom: 3, textAlign: 'center'}}>€{props.type !== 'admin' ? summary : props.summary}</Text>
                                <Text style={{fontSize: 10, color: '#747A80', width: 100, textAlign: 'center'}}>Projected yearly costs</Text>
                            </View>
                            <View style={{width: 300, marginLeft: 10}}>
                                <View style={{display: 'row', flexDirection: 'row'}}>
                                    <View style={{width: 6, marginRight: 15}}></View>
                                    <Text style={{width: 130, color: '#0A004B', fontSize: 10, fontFamily: 'Inter',}}></Text>
                                    <Text style={{width: 60, color: '#747A80', fontSize: 9}}>One-off</Text>
                                    <Text style={{width: 70, color: '#747A80', fontSize: 9}}>Per month</Text>
                                    <Text style={{width: 50, color: '#747A80', fontSize: 9}}>Per year</Text>
                                </View>
                                {props.type !== 'admin' ?
                                    data.map((data) => (
                                        <View style={{display: 'row', flexDirection: 'row', marginTop: 12}}>
                                            <View style={{width: 6, marginRight: 10, borderRadius: 25, height: 6, backgroundColor: data[1] === 'red' ? '#E94F37' : data[1] === 'green' ? '#23E33E' : data[1] === 'yellow' ? '#F4C952' : data[1] === 'pink' ? '#F20089' : data[1] === 'purple' ? '#9800FF' : '#0052FF', marginTop: 3}}></View>
                                            <Text style={{width: 130, color: '#0A004B', fontSize: 10, fontWeight: 'bold', fontFamily: 'Inter'}}>{data[0]}</Text>
                                            <Text style={{width: 60, color: '#0A004B', fontSize: 10, fontWeight: 'bold', fontFamily: 'Inter'}}>{data[5] !== '0' ? `€${data[5]}` : ''}</Text>
                                            <Text style={{width: 70, color: '#0A004B', fontSize: 10, fontWeight: 'bold', fontFamily: 'Inter'}}>{data[6] !== '0' ? `€${data[6]}` : ''}</Text>
                                            <Text style={{width: 50, color: '#0A004B', fontSize: 10, fontWeight: 'bold', fontFamily: 'Inter'}}>{data[7] !== '0' ? `€${data[7]}` : ''}</Text>
                                        </View>
                                    ))
                                :
                                    props.data.map((data) => (
                                        <View style={{display: 'row', flexDirection: 'row', marginTop: 12}}>
                                            <View style={{width: 6, marginRight: 10, borderRadius: 25, height: 6, backgroundColor: data[1] === 'red' ? '#E94F37' : data[1] === 'green' ? '#23E33E' : data[1] === 'yellow' ? '#F4C952' : data[1] === 'pink' ? '#F20089' : data[1] === 'purple' ? '#9800FF' : '#0052FF', marginTop: 3}}></View>
                                            <Text style={{width: 130, color: '#0A004B', fontSize: 10, fontWeight: 'bold', fontFamily: 'Inter'}}>{data[0]}</Text>
                                            <Text style={{width: 60, color: '#0A004B', fontSize: 10, fontWeight: 'bold', fontFamily: 'Inter'}}>{data[5] !== '0' ? `€${data[5]}` : ''}</Text>
                                            <Text style={{width: 70, color: '#0A004B', fontSize: 10, fontWeight: 'bold', fontFamily: 'Inter'}}>{data[6] !== '0' ? `€${data[6]}` : ''}</Text>
                                            <Text style={{width: 50, color: '#0A004B', fontSize: 10, fontWeight: 'bold', fontFamily: 'Inter'}}>{data[7] !== '0' ? `€${data[7]}` : ''}</Text>
                                        </View>
                                    ))
                                }
                            </View>
                        </View>
                    </View>
                    <View style={styles.footer}>
                        <Image style={styles.footerLogo} source={Logo} />
                        <View style={styles.footerBox}>
                            <Text style={styles.footerText}>BE0686.793.751</Text>
                            <Text style={styles.footerText}>-</Text>
                            <Text style={styles.footerText}>Terlinden 178, 1785 Merchtem</Text>
                            <Text style={styles.footerText}>-</Text>
                            <Text style={styles.footerText}>support@bizantium.be</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        )
    }

    const documentPDF = createDocument();
    const [instance, updateInstance] = usePDF({ document: documentPDF });


    if(instance.url !== null) {
        updateInstance(documentPDF)
    }

    useEffect(() => {
        // Update pdf
        setTimeout(() => {
            updateInstance(documentPDF);
        }, 1000);
    });

    return (
        <>
            {props.type === 'userEstablishment' ?
                outputData && outputData.soleAvgTaxRate !== undefined && outputData.ltdAvgTaxRate !== null && outputData.ltdCorporateTax !== null && outputData.ltdIncomeTax !== null && outputData.ltdMonthNetIncome !== null && outputData.ltdSocialContribution !== null && outputData.ltdTotalTax !== null && outputData.soleAvgTaxRate !== null && outputData.soleIncomeTax !== null && outputData.soleMonthNetIncome !== null && outputData.soleSocialContribution !== null && outputData.soleTotalTax !== null ?
                    <>
                        <div className="text">Based on your company type simulation, we advise you to establish a</div>
                        <div className="text bold">{outputData && outputData.soleAvgTaxRate && outputData.ltdAvgTaxRate && (outputData.soleAvgTaxRate < outputData.ltdAvgTaxRate) ? 'sole trader' : 'Limited Company'}</div>
                        <div className="btn green" onClick={() => props.handleSave('LIMITED_COMPANY')}>Establish LTD.</div>
                        <div className="btn red" onClick={() => props.handleSave('SOLE_TRADER')}>Establish as sole trader</div>
                    </>
                :
                    <>
                        <div className="text">{localStorage.getItem('i18nextLng') === 'en' ? "We're working on your simulation! Please wait for our recomendation." : "We werken aan uw simulatie! Wacht alstublieft op ons advies."}</div>
                        <div className="btn green blocked">Establish LTD.</div>
                        <div className="btn red blocked">Establish as sole trader</div>
                        <div className="btn blue blocked">Check my simulation</div>
                    </>
            : <></>}
            <a href={instance.url} rel="noopener noreferrer" target='_blank' className={loading === true ? "blocked" : ""}>
                {props.type === 'user' ?
                    outputData && outputData.soleAvgTaxRate !== undefined && outputData.ltdAvgTaxRate !== null && outputData.ltdCorporateTax !== null && outputData.ltdIncomeTax !== null && outputData.ltdMonthNetIncome !== null && outputData.ltdSocialContribution !== null && outputData.ltdTotalTax !== null && outputData.soleAvgTaxRate !== null && outputData.soleIncomeTax !== null && outputData.soleMonthNetIncome !== null && outputData.soleSocialContribution !== null && outputData.soleTotalTax !== null ?
                        <button className="quickstart__box-btn" onClick={() => progressSurvey()}>{loading === true ?
                            <img className="loading" src={loadingFile} alt={'loading'}/>
                        :
                            localStorage.getItem('i18nextLng') === 'en' ? 'Check my simulation' : 'Bekijk mijn simulatie'
                        }</button>
                        : <></>
                    :
                    props.type === 'userFinish' ?
                        outputData && outputData.soleAvgTaxRate !== undefined && outputData.ltdAvgTaxRate !== null && outputData.ltdCorporateTax !== null && outputData.ltdIncomeTax !== null && outputData.ltdMonthNetIncome !== null && outputData.ltdSocialContribution !== null && outputData.ltdTotalTax !== null && outputData.soleAvgTaxRate !== null && outputData.soleIncomeTax !== null && outputData.soleMonthNetIncome !== null && outputData.soleSocialContribution !== null && outputData.soleTotalTax !== null ?
                            <button className="btn btn--primary next" onClick={() => progressSurvey()}>{loading === true ?
                                <img className="loading" src={loadingFile} alt={'loading'}/>
                            :
                                localStorage.getItem('i18nextLng') === 'en' ? 'Check simulation' : 'Controleer simulatie'
                            }</button>
                        : <></>
                    :
                        props.type === 'userDashboard' ?
                            outputData && outputData.soleAvgTaxRate !== undefined && outputData.ltdAvgTaxRate !== null && outputData.ltdCorporateTax !== null && outputData.ltdIncomeTax !== null && outputData.ltdMonthNetIncome !== null && outputData.ltdSocialContribution !== null && outputData.ltdTotalTax !== null && outputData.soleAvgTaxRate !== null && outputData.soleIncomeTax !== null && outputData.soleMonthNetIncome !== null && outputData.soleSocialContribution !== null && outputData.soleTotalTax !== null ?
                                <div className="icon" onClick={() => progressSurvey()}>
                                    {fullScreen}
                                </div>
                            :
                                <></>
                        :
                            props.type === 'userEstablishment' ?
                                outputData && outputData.soleAvgTaxRate !== undefined && outputData.ltdAvgTaxRate !== null && outputData.ltdCorporateTax !== null && outputData.ltdIncomeTax !== null && outputData.ltdMonthNetIncome !== null && outputData.ltdSocialContribution !== null && outputData.ltdTotalTax !== null && outputData.soleAvgTaxRate !== null && outputData.soleIncomeTax !== null && outputData.soleMonthNetIncome !== null && outputData.soleSocialContribution !== null && outputData.soleTotalTax !== null ?
                                    <div className="btn blue" onClick={() => progressSurvey()}>Check my simulation</div>
                                :
                                    <></>
                            :
                                <button className="btn btn--primary next">
                                    {props.loading === true ?
                                        <img className="loading" src={loadingFile} alt={'loading'}/>
                                    :
                                        <Trans>admin.generate</Trans>
                                    }
                                </button>
                }
            </a>
        </>
    );
}