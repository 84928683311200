import React, { useState } from 'react';
import './adminFiles.scss';
import {
    download, downloadFile,
    editAdminIcon,
    fullScreen,
    infoIcon,
    ListIcon, pdfFile,
    print, removeFile,
    secondListIcon,
    sendIcon
} from "../login/loginConstants";
import { LinearProgress, TextField, InputAdornment } from "@material-ui/core";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import {Trans} from "react-i18next";
import loadingFile from '../../assets/loading.svg';
import Confirm from "../confirm/confirm";
import DocumentPdf from "../document/document";
import DatePicker from "react-datepicker";
import {calendar} from "../survey/surveyConstant";

export const base64ToBlob = (base64String, contentType = '') => {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type: contentType });
}

export const saveBlobAsTextFile = (blob, fileName) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    window.open(link.href, '_blank');
}

export const openDocument = async (url, setPdfUrl, type) => {
    const response = await fetch(url, {
        headers: {
            'authorization': (typeof window !== 'undefined') ? localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token') : '',
            'Content-Type': 'application/pdf'
        },
    });
    if (!response.ok) {
        throw new Error("HTTP error " + response.status);
    }
    const buffer = await response.arrayBuffer();
    const byteArray = new Uint8Array(buffer);
    const charArray = Array.from(byteArray, byte => String.fromCharCode(byte));
    const binaryString = charArray.join("");
    const theImage = btoa(binaryString);
    if (type === 'inside') {
        setPdfUrl(`data:application/pdf;base64,${theImage}#toolbar=0&navpanes=0&scrollbar=0`);
    } else {
        const blob = base64ToBlob(theImage, 'application/pdf');
        saveBlobAsTextFile(blob, 'example.pdf');
    }
}

export const fetchData = async (url, setPdfUrl, fileName) => {
    try {
        const response = await fetch(url, {
            headers: {
                'authorization': (typeof window !== 'undefined') ? localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token') : '',
                'Content-Type': 'application/pdf'
            },
        });

        const blob = await response.blob();
        const pdfUrl = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = pdfUrl;

        a.download = fileName || 'defaultFileName.pdf';

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // setPdfUrl(responseFetch);
    } catch (error) {
        console.error('Błąd pobierania danych:', error);
    }
};

export const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};

const AdminFiles = (props) => {
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [message, setMessage] = useState('');
    const [only, setOnly] = useState(false);
    const [startDate, setStartDate] = useState(null);

    const deleteFile = documentId => {
        Connect(`${Endpoint.deleteFile}/${documentId}`, 'PUT').then(response => {
            props.handleData();
            props.handlePdfUrl('');
            props.handleDocumentUrl(null);
            props.handleSignStatus(false);
            props.handleIsSignVisible(false);
            props.handleEditable(false);
            // props.handleDeleteDocument(0);
        })
    }

    const open = (id, url, isSignVisible, signStatus, type = 'inside') => {
        props.handleDeleteDocument(id);
        props.handleDocumentUrl(url);
        props.handleIsSignVisible(isSignVisible);
        props.handleSignStatus(signStatus);
        openDocument(url, props.handlePdfUrl, type).then(r => console.log(r));
    }

    const downloadDocument = (id, url, isSignVisible, signStatus) => {
        props.handleDeleteDocument(id);
        props.handleDocumentUrl(url);
        props.handleIsSignVisible(isSignVisible);
        props.handleSignStatus(signStatus);
        fetchData(url, props.handlePdfUrl, url.split('/')[url.split('/').length - 1]).then((res) => console.log(res));
    }

    const save = () => {
        let body = {
            id: props.necessaryId,
            assignmentStartDate: props.assigmentDate,
            incomeTaxStartDate: props.incomeTax,
            bookkeepingFee: typeof props.bookkeepingFee === 'number' ? props.bookkeepingFee : props.bookkeepingFee.replaceAll('.', '')
        }
        Connect(`${Endpoint.necessaryValues}`, 'PUT', body).then(response => {
            props.handleEditable(false);
            props.handleData();
        });
    }

    const generate = type => {
        setLoading(true);
        Connect(`${Endpoint.generateDocument}/${sessionStorage.getItem('userID')}?documentType=${type}`, 'POST').then(response => {
            props.handleData();
            setConfirm(true);
            setOnly(true);
            if (response.status === 404 || response.status === 500 || response === 'Błąd serwera') {
                setMessage('Failed to generate document');
            } else {
                setMessage('Document generated successfully');
                props.handleDeleteDocument(response.id)
                props.handleIsSignVisible(response.isSignVisible);
                props.handleSignStatus(response.signStatus);
                props.handleDocumentUrl(response.fileUri);
                setTimeout(() => {
                    setLoading(false);
                }, 800);
            }
        });
    }

    const upload = (file, companyId, documentType) => {
        setLoading(true);
        let body = {
            companyId,
            documentType,
            file: `[file.pdf]${file}`
        }
        Connect(`${Endpoint.uploadAdminFiles}`, 'POST', body).then(response => {
            props.handleData();
            setTimeout(() => {
                setLoading(false);
            }, 800);

        })
    }

    const uploadFile = async (e, companyId, documentType) => {
        const file = e.target.files[0];

        if (file) {
            const base64 = await convertToBase64(file);
            upload(base64, companyId, documentType);
        }
    }

    const sign = pid => {
        setLoading(true);
        Connect(`${Endpoint.signFile}/${pid}`, 'GET').then(response => {
            props.handleData();
            setConfirm(true);
            setOnly(true);
            if (response.status === 404 || response.status === 500 || response === 'Błąd serwera') {
                setMessage('Error occured during sign status update');
            } else {
                setMessage('Sign status of the document updated');
                openFile(props.deleteDocument, props.documentUrl, response.isSignVisible, response.signStatus, props.editable);
            }
            setLoading(false);
        });
    }

    const confirmSign = pid => {
        setConfirm(true);
        setOnly(false);
        setMessage('Are you sure you want to send the document for signature?');
        sessionStorage.setItem("indexDelete", pid);
    }

    const sendSign = pid => {
        setConfirm(false);
        setLoading(true);
        Connect(`${Endpoint.sendSign}/${pid}`, 'POST').then(response => {
            props.handleData();
            setConfirm(true);
            setOnly(true);
            if (response.status === 404 || response.status === 500 || response === 'Błąd serwera') {
                setMessage('Error occured during sending document for signature');
            } else {
                setMessage('Document was sent successfully for signature');
                openFile(props.deleteDocument, props.documentUrl, response.isSignVisible, response.signStatus, props.editable);
            }
            setLoading(false);
        });
    }

    const eventFile = (type, name, url, id, isSignVisible, signStatus) => {
        props.handleDeleteDocument(id);
        props.handleEditable(false);
        if (name === 'GENERATE') {
            generate(type)
        }
        if (name === 'VIEW') {
            // openFile(id, url, isSignVisible, signStatus, false);
            open(id, url, isSignVisible, signStatus);
        }
    }

    const openFile = (id, url, isSignVisible, signStatus, editable) => {
        props.handleDeleteDocument(id);
        props.handleDocumentUrl(url);
        props.handleIsSignVisible(isSignVisible);
        props.handleSignStatus(signStatus);
        props.handleEditable(editable);
        if (editable === false) {
            open(id, url, isSignVisible, signStatus)
        }
    }

    const addCommas = (num) => {
        if (num.length > 1) {
            if (num[0] === "0") {
                num = num.slice(1);
            }
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    return (
        <div className="row start">
            <div className="admin__content">
                <div className="admin__header"><Trans>menu.users</Trans></div>
                {props.data && props.data.map((item, index) => (
                    <div className={item.id === props.deleteDocument ? "adminFiles active" : "adminFiles"} key={index}>
                        <div className="adminFiles__item">{ListIcon}</div>
                        <div className="adminFiles__item hover" onClick={() => openFile(item.id, item.fileUri, item.isSignVisible, item.signStatus, item.name === 'Assignment letter')}>
                            <div className="text">{item.name}</div>
                        </div>
                        {item.documentType === 'OVERVIEW_DOCUMENT' ?
                            <div className="adminFiles__item"></div>
                        :
                            <div
                                className={item.signStatus === 'NOT_READY' ? "adminFiles__item pending" : item.signStatus === 'TO_SIGN' ? "adminFiles__item almost" : "adminFiles__item success"}>
                                <p>{item.signStatus === 'NOT_READY' ? 'Not ready!' : item.signStatus === 'TO_SIGN' ? 'To sign!' : 'Signed!'}</p>
                            </div>
                        }
                        <div className="adminFiles__item">
                            {item.actionType !== 'UPLOAD' && item.documentType !== 'FINANCIAL_PLAN' ?
                                <LinearProgress
                                    variant={'determinate'}
                                    value={item.completedValuesNumber > 0 ? (item.completedValuesNumber)/item.requiredValuesNumber*100 : 0}
                                />
                            : ''}
                        </div>
                        <div className={item.actionType !== 'UPLOAD' && item.documentType !== 'FINANCIAL_PLAN' ? "adminFiles__item" : "adminFiles__item blocked"}>
                            {item.actionType !== 'UPLOAD' && item.documentType !== 'FINANCIAL_PLAN' ?
                                <>
                                    {secondListIcon} {item.completedValuesNumber}/{item.requiredValuesNumber}
                                </>
                            : ''}
                        </div>
                        <div className="adminFiles__item">
                            {item.actionType === 'UPLOAD' && item.fileUri === null ?
                                <label id={`uploadUnput${item.id}`}>
                                    <div
                                        className="btn btn--primary"
                                        onClick={() => eventFile(item.documentType, item.fileUri === null ? item.actionType : 'VIEW', item.fileUri, item.id, item.isSignVisible, item.signStatus)}>
                                        {(loading === true) && (item.id === props.deleteDocument) ?
                                            <img className="loading" src={loadingFile} alt={'loading'}/>
                                            :
                                            item.fileUri === null ? item.actionType : 'VIEW'
                                        }
                                    </div>
                                    <input id={`uploadInput${item.id}`} type="file" className="upload" accept="application/pdf"
                                           onChange={(e) => uploadFile(e, item.company.id, item.documentType)} style={{display: 'none'}}/>
                                </label>
                            :
                                item.documentType === 'FINANCIAL_PLAN' && item.fileUri === null ?
                                    <></>
                                :
                                    item.documentType === 'FINANCIAL_PLAN' && item.fileUri !== null ?
                                        <div
                                            className="btn btn--primary"
                                            onClick={() => eventFile(item.documentType, item.fileUri === null ? item.actionType : 'VIEW', item.fileUri, item.id, item.isSignVisible, item.signStatus)}>
                                            {(loading === true) && (item.id === props.deleteDocument) ?
                                                <img className="loading" src={loadingFile} alt={'loading'}/>
                                                :
                                                item.fileUri === null ? item.actionType : 'VIEW'
                                            }
                                        </div>
                                    :
                                        <div
                                            className={(item.completedValuesNumber !== item.requiredValuesNumber) && (item.completedValuesNumber !== '') && (item.actionType === 'GENERATE') ? "btn btn--primary disabled" : "btn btn--primary"}
                                            onClick={() => eventFile(item.documentType, item.fileUri === null ? item.actionType : 'VIEW', item.fileUri, item.id, item.isSignVisible, item.signStatus)}>
                                            {(loading === true) && (item.id === props.deleteDocument) ?
                                                <img className="loading" src={loadingFile} alt={'loading'}/>
                                            :
                                                item.fileUri === null ? item.actionType : 'VIEW'
                                            }
                                        </div>
                            }
                        </div>
                    </div>
                ))}
                <div className="admin__header uploaded"><Trans>admin.uploaded</Trans></div>
                {props.shareholdersData.map((item, index) => (
                    <div className="adminFiles uploaded" key={index}>
                        <div className="adminFiles__item">{pdfFile}</div>
                        <div className="adminFiles__item">
                            <div className="text">{item.split('/')[item.split('/').length - 1]}</div>
                        </div>
                        <div className="adminFiles__item" onClick={() => downloadDocument(0, item, props.isSignVisible, props.signStatus)}>{downloadFile}</div>
                    </div>
                ))}
                {props.companyData && props.companyData.bankData && props.companyData.bankData.certificateUri !== null ?
                    <div className="adminFiles uploaded">
                        <div className="adminFiles__item">{pdfFile}</div>
                        <div className="adminFiles__item">
                            <div className="text">{props.companyData.bankData.certificateUri.split('/')[props.companyData.bankData.certificateUri.split('/').length - 1]}</div>
                        </div>
                        <div className="adminFiles__item"
                             onClick={() => downloadDocument(0, props.companyData.bankData.certificateUri, props.isSignVisible, props.signStatus)}>{downloadFile}</div>
                    </div>
                :
                    ''
                }
            </div>
            <div className="admin__content">
                <div className="admin__header"><Trans>admin.necessary</Trans></div>
                <div className={props.deleteDocument !== 0 ? "adminFiles__preview is-active" : "adminFiles__preview"}>
                    <div className="adminFiles__preview-menu">
                        <div className={props.isSignVisible === true ? "icon" : "icon blocked"} onClick={() => confirmSign(props.deleteDocument)}>{sendIcon}</div>
                        <div className={props.signStatus === 'TO_SIGN' ? "icon" : "icon blocked"} onClick={() => sign(props.deleteDocument)}>{editAdminIcon}</div>
                        <div className={props.documentUrl !== null ? "icon" : "icon blocked"} onClick={() => open(props.deleteDocument, props.documentUrl, props.isSignVisible, props.signStatus, 'tab')}>{fullScreen}</div>
                        <div className={props.documentUrl !== null ? "icon" : "icon blocked"} onClick={() => downloadDocument(props.deleteDocument, props.documentUrl, props.isSignVisible, props.signStatus)}>{download}</div>
                        <div className={props.documentUrl !== null ? "icon" : "icon blocked"} onClick={() => open(props.deleteDocument, props.documentUrl, props.isSignVisible, props.signStatus, 'tab')}>{print}</div>
                        <div className={props.deleteDocument !== 0 ? "icon trash" : "icon blocked"} onClick={() => deleteFile(props.deleteDocument)}>{removeFile}</div>
                    </div>
                    {props.editable === false ? (
                        props.deleteDocument !== 0 ?
                            props.documentUrl !== null ?
                                props.pdfUrl ? (
                                        <DocumentPdf pdf={props.pdfUrl} />
                                    ) : (
                                        <p className="d-none">Loading file...</p>
                                    )
                                // <>
                                //     <p className="text">The file has been selected,<br /> but there was a problem opening the file</p>
                                //     <div className="btn btn--primary" onClick={() => downloadDocument(props.deleteDocument, props.documentUrl, props.isSignVisible, props.signStatus)}>Download file</div>
                                // </>
                            :
                                <p className="text">The file is not ready</p>
                        :
                            <p className="text"><Trans>admin.selectDocument</Trans></p>
                    ) : (
                        <>
                            <div className="container__body-input">
                                <p className="container__body-text"><Trans>admin.assignmentDate</Trans></p>
                                <TextField label={''} variant={'outlined'} size={'small'} type={'date'} defaultValue={''} value={props.assigmentDate} onChange={(e) => props.handleAssigmentDate(e.target.value)} />
                            </div>
                            <div className="container__body-input">
                                <p className="container__body-text"><Trans>admin.starting</Trans></p>
                                <TextField label={''} variant={'outlined'} size={'small'} type={'date'} defaultValue={''} value={props.incomeTax} onChange={(e) => props.handleIncomeTax(e.target.value)} />
                                {/*<DatePicker*/}
                                {/*    id='dateStart'*/}
                                {/*    placeholderText="Select a date"*/}
                                {/*    selected={startDate}*/}
                                {/*    onChange={(date) => props.handleIncomeTax(date)}*/}
                                {/*    dateFormat="dd/MM/yyyy"*/}
                                {/*    autoComplete="off"*/}
                                {/*    onKeyDown={e => e.preventDefault()}*/}
                                {/*/>*/}
                                {/*<div className="calendarIcon">*/}
                                {/*    {calendar}*/}
                                {/*</div>*/}
                            </div>
                            <div className="container__body-input">
                                <p className="container__body-text"><Trans>admin.fee</Trans></p>
                                <TextField InputProps={{
            startAdornment: props.handleBookkeepingFee  ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }} label={''} variant={'outlined'} size={'small'} placeholder={'€...'} value={addCommas(removeNonNumeric(props.bookkeepingFee))} onChange={(e) => props.handleBookkeepingFee(e.target.value)} />
                            </div>
                            <div className="btn btn--primary" onClick={() => save()}>Save</div>
                        </>
                    )}
                </div>
            </div>
            <div className={props.fullScreen === false ? "files__fullscreen" : "files__fullscreen active"}>
                <div className="files__sidebar">
                    <div className="files__sidebar-document">
                    </div>
                    <div className="text">1</div>
                </div>
                <div className="files__fullscreen-header">
                    <div className="icon" onMouseEnter={() => document.querySelector('.files__fullscreen-popup').classList.add('active')} onMouseLeave={() => document.querySelector('.files__fullscreen-popup').classList.remove('active')}>{infoIcon}</div>
                    <div className="icon">{editAdminIcon}</div>
                    <div className="icon" onClick={() => downloadDocument(props.deleteDocument, props.documentUrl, props.isSignVisible, props.signStatus)}>{download}</div>
                    <div className="icon" onClick={() => downloadDocument(props.deleteDocument, props.documentUrl, props.isSignVisible, props.signStatus)}>{print}</div>
                    <div className="icon" onClick={() => props.handleFullScreen(false)}>{fullScreen}</div>
                </div>
                <div className="files__fullscreen-popup">
                    <div className="text header">Composition assignment</div>
                    <div className="text">This document contains information about the founders of the company, and the conditions under which they will manage the company to be established.</div>
                    <div className="row">
                        <div className="box">
                            <div className="text small"><b>Type:</b> PDF</div>
                            <div className="text small"><b>Size:</b> 1.3 MB</div>
                        </div>
                        <div className="box">
                            <div className="text small"><b>Created date:</b> 18/04/2023</div>
                            <div className="text small"><b>Signed date:</b> 20/04/2023</div>
                        </div>
                    </div>
                </div>
                <div className="files__fullscreen-content">
                </div>
            </div>
            <Confirm active={confirm} handleConfirm={setConfirm} handleRemove={sendSign} message={message} only={only} />
        </div>
    );
};

export default AdminFiles;
