import React, { useState, useEffect } from "react";
import './factors.scss';
import {IconButton, TextField, Tooltip,InputAdornment} from "@material-ui/core";
import CustomSwitch from "../switch/switch";
import { Range } from "react-range";
import DatePicker from "react-datepicker";
import {calendar, InfoIcon} from "../survey/surveyConstant";
import CustomSelect from "../customSelect/customSelect";
import {vehicleDetails} from "./remuneration";
import {closePopup} from "../login/loginConstants";

const Factors = (props) => {
    const [brandList, setBrandList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [fuelList, setFuelList] = useState([]);
    const [trimLineList, setTrimLineList] = useState([]);
    const [usedOffice, setUsedOffice] = useState([0]);

    useEffect(() => {
        setTimeout(() => {
            const inputObject = JSON.parse(sessionStorage.getItem('vehicle-list'));
            const resultArray = [];

            for (const make in inputObject) {
                const modelsObject = inputObject[make];
                const makeModelsArray = [];

                for (const model in modelsObject) {
                    makeModelsArray.push({
                        model: model,
                        count: modelsObject[model],
                    });
                }

                resultArray.push({
                    make: make,
                    models: makeModelsArray,
                });
            }
            sessionStorage.setItem('vehicle-list-array', JSON.stringify(resultArray));
            setBrandList(resultArray.map(data => {
                return { id: 1, name: data.make, value: data.make }
            }));
        }, 500);
        props.handleDefaultBrand(localStorage.getItem('i18nextLng') === 'en' ? 'Select a brand' : 'Selecteer een merk...');
        props.handleDefaultModel(localStorage.getItem('i18nextLng') === 'en' ? 'Select a model' : 'Selecteer een model...');
        props.handleDefaultFuel(localStorage.getItem('i18nextLng') === 'en' ? 'Select a fuel type' : 'Selecteer een brandstof...');
        props.handleDefaultTrimline(localStorage.getItem('i18nextLng') === 'en' ? 'Select a trimline' : 'Selecteer een trimlijn...');
        setTimeout(() => {
            if (sessionStorage.getItem('brand')) {
                props.handleDefaultBrand(sessionStorage.getItem('brand'));
                sessionStorage.getItem('vehicle-list-array') && JSON.parse(sessionStorage.getItem('vehicle-list-array')).map(data => {
                    if (data.make === sessionStorage.getItem('brand')) {
                        setModelList(data.models.map(data => {
                            return { id: 1, name: data.model, value: data.model }
                        }))
                        setTimeout(() => {
                            props.handleDefaultModel(sessionStorage.getItem('model'));
                            vehicleDetails(25000, 5, `${sessionStorage.getItem('brand')}`,sessionStorage.getItem('model')).then(r => sessionStorage.setItem('r', r));
                            setTimeout(() => {
                                let fuelArray = []
                                sessionStorage.getItem('vehicle-details') && JSON.parse(sessionStorage.getItem('vehicle-details')).map(data => {
                                    if (!fuelArray.includes(data.fuel_type)) {
                                        fuelArray.push(data.fuel_type)
                                    }
                                    setFuelList(fuelArray.map(data => {
                                        return { id: 1, name: data, value: data }
                                    }))
                                    setTimeout(() => {
                                        props.handleDefaultFuel(sessionStorage.getItem('fuel'))
                                        let trimlineArray = [];
                                        JSON.parse(sessionStorage.getItem('vehicle-details')).map(data => {
                                            if (data.fuel_type === sessionStorage.getItem('fuel')) {
                                                trimlineArray.push(data.trimline);
                                            }
                                        })
                                        setTrimLineList(trimlineArray.map(data => {
                                            return { id: 1, name: data, value: data }
                                        }))
                                        setTimeout(() => {
                                            props.handleDefaultTrimline(sessionStorage.getItem('trimline'));
                                            JSON.parse(sessionStorage.getItem('vehicle-details')).map(data => {
                                                if (data.trimline === sessionStorage.getItem('trimline')) {
                                                    sessionStorage.setItem('companyCar', data.monthly_lease_price_vat_excl)
                                                }
                                            })
                                        }, 100);
                                    }, 100);
                                })
                            }, 1000);
                        }, 100);
                    }
                })
            }
            props.handleRentingProperty(sessionStorage.getItem('cadastralIncome') ? addCommas(removeNonNumeric(parseInt(sessionStorage.getItem('cadastralIncome').replaceAll('.', '')))) : 1);
            props.handleWorkingDays(sessionStorage.getItem('mealVouchers') ? parseInt(sessionStorage.getItem('mealVouchers').replaceAll('.', '')) : 1);
            props.handleCommutes(sessionStorage.getItem('professionalCommutes') ? (parseFloat(sessionStorage.getItem('professionalCommutes'))).toFixed(0) : 1);
            props.handleUsedOffice(sessionStorage.getItem('propertyOfficePercent') ? [parseInt(sessionStorage.getItem('propertyOfficePercent'))] : [0]);
            setUsedOffice(sessionStorage.getItem('propertyOfficePercent') ? [parseInt(sessionStorage.getItem('propertyOfficePercent'))] : [0]);
            props.handleIsOverFourtyCommutes(sessionStorage.getItem('isOverFourtyCommutes') === 'true');
            if (sessionStorage.getItem('carInitialRegDate') !== null && sessionStorage.getItem('carInitialRegDate') !== 'null') {
                const initialStartDate = sessionStorage.getItem('carInitialRegDate') || "";
                if (initialStartDate) {
                    props.handleInitialStartDate(new Date(initialStartDate));
                }
            }
        }, 2000);
    }, []);

    const handleDate = (date) => {
        props.handleInitialStartDate(date);
        sessionStorage.setItem('carInitialRegDate', `${date.getFullYear()}-${date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`)
    }

    const handleBrand = (type, value) => {
        sessionStorage.setItem('brand', `${value[0].text}`);
        sessionStorage.getItem('vehicle-list-array') && JSON.parse(sessionStorage.getItem('vehicle-list-array')).map(data => {
            if (data.make === value[0].text) {
                setModelList(data.models.map(data => {
                    return { id: 1, name: data.model, value: data.model }
                }))
            }
        })
    }

    const handleModel = (type, value) => {
        sessionStorage.setItem('model', `${value[0].text}`);
        vehicleDetails(25000, 5, `${sessionStorage.getItem('brand')}`,`${value[0].text}`).then(r => sessionStorage.setItem('r', r));
        setTimeout(() => {
            let fuelArray = []
            JSON.parse(sessionStorage.getItem('vehicle-details')).map(data => {
                if (!fuelArray.includes(data.fuel_type)) {
                    fuelArray.push(data.fuel_type)
                }
                setFuelList(fuelArray.map(data => {
                    return { id: 1, name: data, value: data }
                }))
            })
        }, 1000);
    }

    const handleFuel = (type, value) => {
        sessionStorage.setItem('fuel', `${value[0].text}`);
        let trimlineArray = [];
        JSON.parse(sessionStorage.getItem('vehicle-details')).map(data => {
            if (data.fuel_type === value[0].text) {
                trimlineArray.push(data.trimline);
            }
        })
        setTrimLineList(trimlineArray.map(data => {
            return { id: 1, name: data, value: data }
        }))
    }

    const handleTrimLine = (type, value) => {
        sessionStorage.setItem('trimline', `${value[0].text}`);
        JSON.parse(sessionStorage.getItem('vehicle-details')).map(data => {
            if (data.trimline === value[0].text) {
                sessionStorage.setItem('companyCar', data.monthly_lease_price_vat_excl)
            }
        })
    }

    const handleCommutes = (value) => {
        props.handleCommutes(parseFloat(value))
        sessionStorage.setItem('professionalCommutes', (1 * (!isNaN(parseFloat(value)) ? parseFloat(value) : 0)).toFixed(2));
        sessionStorage.setItem('commutesPerMonth', value);
    }

    const handleMealVouchers = (value) => {
        props.handleWorkingDays(parseFloat(value))
        sessionStorage.setItem('mealVouchers', `${1 * (!isNaN(parseFloat(value)) ? parseFloat(value) : 0)}`);
        sessionStorage.setItem('mealWorkDays', value);
    }

    const handleRentingProperty = (value) => {
        props.handleRentingProperty(addCommas(removeNonNumeric(parseFloat(value))));
        sessionStorage.setItem('rentingProperty', `${((props.usedOffice/100) * parseFloat(value.replaceAll('.', ''))).toFixed(0)}`);
        sessionStorage.setItem('cadastralIncome', value);
    }

    const handleUsedOffice = (value) => {
        props.handleUsedOffice(value);
        setUsedOffice(value);
        sessionStorage.setItem('rentingProperty', `${((value/100) * sessionStorage.getItem('cadastralIncome')).toFixed(0)}`);
        sessionStorage.setItem('propertyOfficePercent', value);
    }

    const handleOverFourtyCommutes = (value) => {
        props.handleIsOverFourtyCommutes(value);
        sessionStorage.setItem('isOverFourtyCommutes', value);
    }

    const checkValidation = (i) => {
        let value = 0;
        if (i === 1) {
            if (sessionStorage.getItem('cadastralIncome') && sessionStorage.getItem('cadastralIncome') > 0) {
                value++;
            }
            if (sessionStorage.getItem('propertyOfficePercent') && sessionStorage.getItem('propertyOfficePercent').length > 0) {
                value++;
            }
        }
        if (i === 2) {
            if (sessionStorage.getItem('professionalCommutes') && sessionStorage.getItem('professionalCommutes') > 0) {
                value++;
            }
        }
        if (i === 3) {
            if (sessionStorage.getItem('brand') && sessionStorage.getItem('brand').length > 0) {
                value++
            }
            if (sessionStorage.getItem('model') && sessionStorage.getItem('model').length > 0) {
                value++
            }
            if (sessionStorage.getItem('fuel') && sessionStorage.getItem('fuel').length > 0) {
                value++
            }
            if (sessionStorage.getItem('trimline') && sessionStorage.getItem('trimline').length > 0) {
                value++
            }
            if (sessionStorage.getItem('carInitialRegDate') && sessionStorage.getItem('carInitialRegDate').length > 0) {
                value++
            }
        }
        if (i === 4) {
            if (sessionStorage.getItem('mealVouchers') && sessionStorage.getItem('mealVouchers') > 0) {
                value++;
            }
        }
        return value;
    }

    const save = () => {
        let value = checkValidation(props.factor);
        props.handleSave(props.factor, value);
    }

    const addCommas = (num) => {
        if (num.length > 1) {
            if (num[0] === "0") {
                num = num.slice(1);
            }
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const addCommasWithRest = (num) => {
        let rest = num.toFixed(2).slice(-2);
        if(rest === '00') rest = null;
        if (num.length > 1) {
            if (num[0] === "0") {
                num = num.slice(1);
            }
        }
        return !rest ? +num.toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : +num.toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+","+rest;
    };

    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    return (
        <div className={props.active === true ? "factors active" : "factors"}>
            {console.log(props.commutes)}
            <div className="factors__content">
                <div className="factors__content-header">{props.header}</div>
                <div className={props.factor === 5 ? "factors__content-description calculation" : "factors__content-description"}>{props.text}</div>
                {props.factor === 1 ?
                    <>
                        <div className="row space">
                            <div className="text">
                                <Tooltip title={localStorage.getItem('i18nextLng') === 'en' ? 'This info can be found on your personal income tax declaration, on MyMinFin, or on the purchasing deed of your home' : 'Deze info vindt u op uw aangifte personenbelasting, op MyMinFin of op de koopakte van uw woning'} placement={'right'}>
                                    <IconButton aria-label="delete">
                                        {InfoIcon}
                                    </IconButton>
                                </Tooltip>
                                {localStorage.getItem('i18nextLng') === 'en' ? 'Cadastral income' : 'Kadastraal inkomen'}
                            </div>
                            <TextField InputProps={{
            startAdornment: props.rentingProperty  ? <InputAdornment variant="outlined" disableTypography position="start">€</InputAdornment> : <></>,
          }} label={''} variant={'outlined'} size={'small'} type={'number'} value={props.rentingProperty} onChange={(e) => handleRentingProperty(e.target.value)}
                                       placeholder={'€...'} />
                        </div>
                        <div className="row space">
                            <div className="text">% {localStorage.getItem('i18nextLng') === 'en' ? 'of property used as office' : 'van onroerend goed dat als kantoor wordt gebruikt'}</div>
                            <div className="structure__range">
                                <Range
                                    step={1}
                                    min={0}
                                    max={100}
                                    values={props.usedOffice}
                                    onChange={(usedOffice) => handleUsedOffice(usedOffice)}
                                    renderTrack={({ props, children }) => (
                                        <div
                                            {...props}
                                            style={{
                                                ...props.style,
                                                height: '6px',
                                                width: '100%',
                                                backgroundColor: '#C4D0E5'
                                            }}
                                        >
                                            {children}
                                        </div>
                                    )}
                                    renderThumb={({ props }) => (
                                        <div
                                            {...props}
                                            style={{
                                                ...props.style,
                                                height: '18px',
                                                width: '18px',
                                                backgroundColor: '#4A5BCF',
                                                borderRadius: 18,
                                            }}
                                            className="structure__range-pointer"
                                        >
                                            <div className="structure__range-percentage">
                                                <p>{usedOffice}%</p>
                                            </div>
                                        </div>
                                    )}
                                />
                                <div className="structure__range-legend">
                                    <p>0%</p>
                                    <p>25%</p>
                                    <p>50%</p>
                                    <p>75%</p>
                                    <p>100%</p>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <></>
                }
                {props.factor === 2 ?
                    <>
                        <div className={props.commutes > 16 ? "row space is-error" : "row space"}>
                            <div className="text">
                                <Tooltip title="Only add the days on which you were away for more than 6 hours" placement={'right'}>
                                    <IconButton aria-label="delete">
                                        {InfoIcon}
                                    </IconButton>
                                </Tooltip>
                                {localStorage.getItem('i18nextLng') === 'en' ? 'Commutes per month' : 'Woon-werkverkeer per maand'}
                            </div>
                            <TextField label={''} variant={'outlined'} size={'small'} type={'number'}
                                       placeholder={'...'} min={0} max={16} value={props.commutes} onChange={(e) => handleCommutes(e.target.value)} />
                            <div className="error">{localStorage.getItem('i18nextLng') === 'en' ? 'There is a maximum of 16 commutes per month!' : 'Er geldt een maximum van 16 woon-werkverkeer per maand!'}</div>
                        </div>
                        <div className="row space">
                            <div className="text tooltip">
                                <Tooltip title="When you travel to the same client more than 40 days per year, you're not entitled to the allowance." placement={'right'}>
                                    <IconButton aria-label="delete">
                                        {InfoIcon}
                                    </IconButton>
                                </Tooltip>
                                {localStorage.getItem('i18nextLng') === 'en' ? 'More than 40 days per year for the same client?' : 'Meer dan 40 dagen per jaar voor dezelfde klant?'}
                            </div>
                            <CustomSwitch checked={props.isOverFourtyCommutes} onChange={(e) => handleOverFourtyCommutes(e.target.checked)} />
                        </div>
                        <div className="row end">
                            <div className="text"><span>{!isNaN(props.commutes) ? props.commutes : 0}</span> {localStorage.getItem('i18nextLng') === 'en' ? 'commutes per month' : 'woon-werkverkeer per maand'} x <span>€19,99 = €{(19.99 * (!isNaN(props.commutes) ? props.commutes : 0)).toFixed(2)}</span></div>
                        </div>
                    </>
                    :
                    <></>
                }
                {props.factor === 3 ?
                    <>
                        <div className="row space">
                            <div className="text">{localStorage.getItem('i18nextLng') === 'en' ? 'Initial registration date' : 'Eerste inschrijvingsdatum'}</div>
                            <DatePicker
                                id='dateStart'
                                autoComplete="off"
                                selected={props.initialStartDate}
                                onChange={(date) => handleDate(date)}
                                // minDate={new Date(today)}
                                placeholderText={localStorage.getItem('i18nextLng') === 'en' ? 'Select a date' : '\n' +
                                    'Selecteer een datum'}
                                dateFormat="dd/MM/yyyy"
                                onKeyDown={e => e.preventDefault()}
                            />
                            <div className="calendarIcon">
                                {calendar}
                            </div>
                        </div>
                        <div className="row space">
                            <div className="text">{localStorage.getItem('i18nextLng') === 'en' ? 'Brand' : 'Merk'}</div>
                            <CustomSelect
                                defaultText={props.defaultBrand}
                                optionsList={brandList}
                                handleData={handleBrand}
                                type={'revenue'}
                            />
                        </div>
                        {modelList.length > 0 &&
                            <div className="row space">
                                <div className="text">Model</div>
                                <CustomSelect
                                    defaultText={props.defaultModel}
                                    optionsList={modelList}
                                    handleData={handleModel}
                                    type={'revenue'}
                                />
                            </div>
                        }
                        {fuelList.length > 0 &&
                            <div className="row space">
                                <div className="text">{localStorage.getItem('i18nextLng') === 'en' ? 'Fuel type' : 'Brandstoftype'}</div>
                                <CustomSelect
                                    defaultText={props.defaultFuel}
                                    optionsList={fuelList}
                                    handleData={handleFuel}
                                    type={'revenue'}
                                />
                            </div>
                        }
                        {trimLineList.length > 0 &&
                            <div className="row space">
                                <div className="text">{localStorage.getItem('i18nextLng') === 'en' ? 'Trimline' : 'Trimlijn'}</div>
                                <CustomSelect
                                    defaultText={props.defaultTrimline}
                                    optionsList={trimLineList}
                                    handleData={handleTrimLine}
                                    type={'revenue'}
                                />
                            </div>
                        }
                    </>
                    :
                    <></>
                }
                {props.factor === 4 ?
                    <>
                        <div className={props.workingDays > 20 ? "row space is-error" : "row space"}>
                            <div className="text">
                                <Tooltip title={localStorage.getItem('i18nextLng') === 'en' ? 'You may receive a meal voucher for every working day during the month. It\'s possible to fill in a number higher than 20, but be aware that the chances of getting a tax audit increase.' : 'U kunt voor elke werkdag van de maand een maaltijdcheque ontvangen. Het is mogelijk om een getal hoger dan 20 in te vullen, maar houd er rekening mee dat de kans op een belastingcontrole groter is.'} placement={'right'}>
                                    <IconButton aria-label="delete">
                                        {InfoIcon}
                                    </IconButton>
                                </Tooltip>
                                {localStorage.getItem('i18nextLng') === 'en' ? 'Working days per month' : 'Werkdagen per maand'}
                            </div>
                            <TextField label={''} variant={'outlined'} size={'small'} type={'number'} value={props.workingDays} onChange={(e) => handleMealVouchers(parseFloat(e.target.value))}
                                       placeholder={'...'} />
                            <div className="error">{localStorage.getItem('i18nextLng') === 'en' ? 'There is a maximum of 20 meal vouchers per month!' : 'Er geldt een maximum van 20 maaltijdcheques per maand!'}</div>
                        </div>
                        <div className="row end">
                            <div className="text"><span>{!isNaN(props.workingDays) ? props.workingDays : 0}</span> {localStorage.getItem('i18nextLng') === 'en' ? 'working days per month' : 'werkdagen per maand'} x <span>€8 = €{8 * (!isNaN(props.workingDays) ? props.workingDays : 0)}</span></div>
                        </div>
                    </>
                    :
                    <></>
                }
                {props.factor === 5 ?
                    <>
                        {sessionStorage.getItem('netAmount') &&
                            <>
                                <div className="row space">
                                    <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Desired net monthly wage' : 'Gewenst netto maandloon'}</div>
                                    <div className="text small summary">€{sessionStorage.getItem('netAmount') }</div>
                                </div>
                                <div className="row space detailed">
                                    <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Social contributions (estimation)' : 'Sociale bijdragen (schatting)'}</div>
                                    <div className="text small summary">€{sessionStorage.getItem('special_contribution_social_security')}</div>
                                </div>
                                <div className="row space detailed">
                                    <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Withholding tax' : 'Bronbelasting'}</div>
                                    <div className="text small summary">€{sessionStorage.getItem('withholding_tax')}</div>
                                </div>
                            </>
                        }
                        {(sessionStorage.getItem('rentingProperty') || sessionStorage.getItem('companyCar') || sessionStorage.getItem('professionalCommutes') || sessionStorage.getItem('mealVouchers')) &&
                            <div className="row space detailed">
                                <div className="text small bold">{localStorage.getItem('i18nextLng') === 'en' ? 'Additional remuneration factors' : 'Aanvullende beloningsfactoren'}</div>
                            </div>
                        }
                        {sessionStorage.getItem('rentingProperty') && parseInt(sessionStorage.getItem('rentingProperty')) > 0 &&
                            <div className="row space detailed">
                                <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Renting a property' : 'Een pand huren'}</div>
                                <div className="text small summary">€{addCommasWithRest(+((parseInt(sessionStorage.getItem('propertyOfficePercent'))/100) * parseInt(sessionStorage.getItem('rentingProperty'))))}</div>
                            </div>
                        }
                        {sessionStorage.getItem('companyCar') && parseInt(sessionStorage.getItem('companyCar')) > 0 &&
                            <div className="row space detailed">
                                <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Company car' : 'Bedrijfswagen'}</div>
                                <div className="text small summary">€{addCommasWithRest(parseInt(sessionStorage.getItem('companyCar')))  }</div>
                            </div>
                        }
                        {sessionStorage.getItem('professionalCommutes') && parseInt(sessionStorage.getItem('professionalCommutes')) > 0 &&
                            <div className="row space detailed">
                                <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Professional commutes' : 'Professioneel woon-werkverkeer'}</div>
                                <div
                                    className="text small summary">€{addCommasWithRest(parseInt(sessionStorage.getItem('professionalCommutes'))*19.99)}</div>
                            </div>
                        }
                        {sessionStorage.getItem('mealVouchers') && parseInt(sessionStorage.getItem('mealVouchers')) > 0 &&
                            <div className="row space detailed">
                                <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Meal vouchers' : 'Maaltijdcheques'}</div>
                                <div className="text small summary">€{addCommasWithRest(parseInt(sessionStorage.getItem('mealVouchers'))*8)}</div>
                            </div>
                        }
                        {(sessionStorage.getItem('smartphone') && parseInt(sessionStorage.getItem('smartphone')) > 0) || (sessionStorage.getItem('garageCost') && parseInt(sessionStorage.getItem('garageCost')) > 0) || (sessionStorage.getItem('parkingCost') && parseInt(sessionStorage.getItem('parkingCost')) > 0) || (sessionStorage.getItem('carWashCost') && parseInt(sessionStorage.getItem('carWashCost')) > 0) || (sessionStorage.getItem('tablet') && parseInt(sessionStorage.getItem('tablet')) > 0) || (sessionStorage.getItem('smartphonePlan') && parseInt(sessionStorage.getItem('smartphonePlan')) > 0) || (sessionStorage.getItem('internetSubscription') && parseInt(sessionStorage.getItem('internetSubscription')) > 0) || (sessionStorage.getItem('computer') && parseInt(sessionStorage.getItem('computer')) > 0) ?
                            <div className="row space detailed">
                                <div className="text small bold">{localStorage.getItem('i18nextLng') === 'en' ? 'Benefits in kind & Net reimbursements' : 'Voordelen in natura & Nettovergoedingen'}</div>
                            </div>
                        :
                            <></>
                        }
                        {sessionStorage.getItem('garageCost') && parseInt(sessionStorage.getItem('garageCost')) > 0 &&
                            <div className="row space detailed">
                                <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Car costs: garage' : 'Autokosten: garage'}</div>
                                <div className="text small summary">€50</div>
                            </div>
                        }
                        {sessionStorage.getItem('parkingCost') && parseInt(sessionStorage.getItem('parkingCost')) > 0 &&
                            <div className="row space detailed">
                                <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Car costs: parking' : 'Autokosten: parkeren'}</div>
                                <div className="text small summary">€15</div>
                            </div>
                        }
                        {sessionStorage.getItem('carWashCost') && parseInt(sessionStorage.getItem('carWashCost')) > 0 &&
                            <div className="row space detailed">
                                <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Car costs: carwash' : 'Autokosten: carwash'}</div>
                                <div className="text small summary">€15</div>
                            </div>
                        }
                        {sessionStorage.getItem('smartphone') && parseInt(sessionStorage.getItem('smartphone')) > 0 &&
                            <div className="row space detailed">
                                <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Smartphone' : 'Smartphone'}</div>
                                <div className="text small summary">€3</div>
                            </div>
                        }
                        {sessionStorage.getItem('tablet') && parseInt(sessionStorage.getItem('tablet')) > 0 &&
                            <div className="row space detailed">
                                <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Tablet' : 'Tablet'}</div>
                                <div className="text small summary">€3</div>
                            </div>
                        }
                        {sessionStorage.getItem('smartphonePlan') && parseInt(sessionStorage.getItem('smartphonePlan')) > 0 &&
                            <div className="row space detailed">
                                <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Smartphone plan' : 'Smartphone-abonnement'}</div>
                                <div className="text small summary">€4</div>
                            </div>
                        }
                        {sessionStorage.getItem('internetSubscription') && parseInt(sessionStorage.getItem('internetSubscription')) > 0 &&
                            <div className="row space detailed">
                                <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Internet subscription' : 'Internet abonnement'}</div>
                                <div className="text small summary">€5</div>
                            </div>
                        }
                        {sessionStorage.getItem('computer') && parseInt(sessionStorage.getItem('computer')) > 0 &&
                            <div className="row space detailed">
                                <div className="text small">{localStorage.getItem('i18nextLng') === 'en' ? 'Computer' : 'Computer'}</div>
                                <div className="text small summary">€6</div>
                            </div>
                        }
                        {sessionStorage.getItem('netAmount') &&
                            <div className="row space detailed">
                                <div className="text small end">{localStorage.getItem('i18nextLng') === 'en' ? 'Minimum gross wage' : 'Minimum brutoloon'}</div>
                                <div className="text small summary">€{sessionStorage.getItem('grossAmount')}</div>
                            </div>
                        }
                    </>
                    :
                    <></>
                }
                {props.header !== 'Detailed calculation' ?
                    <div className="row">
                        <button className="btn btn--primary" onClick={() => save()}>{localStorage.getItem('i18nextLng') === 'en' ? 'Save' : 'Opslaan'}</button>
                    </div>
                :
                    <></>
                }
                <div className="close" onClick={() => save()}>{closePopup}</div>
            </div>
        </div>
    );
}

export default Factors;
