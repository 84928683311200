import React, {useEffect, useState} from 'react';
import './popup.scss';
import {TextareaAutosize, TextField} from "@material-ui/core";
import CustomSelect from "../customSelect/customSelect";

function Popup(props) {
    const [periodOneFrom, setPeriodOneFrom] = useState('');
    const [periodOneTo, setPeriodOneTo] = useState('');
    const [periodTwoFrom, setPeriodTwoFrom] = useState('');
    const [periodTwoTo, setPeriodTwoTo] = useState('');
    const [periodThreeFrom, setPeriodThreeFrom] = useState('');
    const [periodThreeTo, setPeriodThreeTo] = useState('');
    const [activityType, setActivityType] = useState('');
    const [businessDescription, setBusinessDescription] = useState('');
    const [mainActivities, setMainActivities] = useState('');
    const [sector, setSector] = useState('');
    const [genAssemblyFirst, setGenAssemblyFirst] = useState('');
    const [genAssemblyTime, setGenAssemblyTime] = useState('');
    const [genAssemblyWhen, setGenAssemblyWhen] = useState('');

    useEffect(() => {
        setPeriodOneFrom(props.financialData.periodOneFrom);
        setPeriodOneTo(props.financialData.periodOneTo);
        setPeriodTwoFrom(props.financialData.periodTwoFrom);
        setPeriodTwoTo(props.financialData.periodTwoTo);
        setPeriodThreeFrom(props.financialData.periodThreeFrom);
        setPeriodThreeTo(props.financialData.periodThreeTo);
        setActivityType(props.data.activityType);
        setBusinessDescription(props.data.businessDescription);
        setMainActivities(props.data.mainActivities);
        setSector(props.data.sector);
        setGenAssemblyFirst(props.financialData.genAssemblyFirst);
        setGenAssemblyTime(props.financialData.genAssemblyTime);
        setGenAssemblyWhen(props.financialData.genAssemblyWhen);
    }, [props]);

    const save = () => {
        props.handleSave(false, props.type)
    }

    const handleData = (type, value) => {
        let data = props.data;
        let financialData = props.financialData;
        if (type === 'activityType') {
            data.activityType = value;
            setActivityType(value);
        }
        if (type === 'businessDescription') {
            data.businessDescription = value;
            setBusinessDescription(value);
        }
        if (type === 'mainActivities') {
            data.mainActivities = value;
            setMainActivities(value);
        }
        if (type === 'sector') {
            data.sector = value;
            setSector(value);
        }
        if (type === 'periodOneFrom') {
            financialData.periodOneFrom = value;
            setPeriodOneFrom(value);
        }
        if (type === 'periodOneTo') {
            financialData.periodOneTo = value;
            setPeriodOneTo(value);
        }
        if (type === 'periodTwoFrom') {
            financialData.periodTwoFrom = value;
            setPeriodTwoFrom(value);
        }
        if (type === 'periodTwoTo') {
            financialData.periodTwoTo = value;
            setPeriodTwoTo(value);
        }
        if (type === 'periodThreeFrom') {
            financialData.periodThreeFrom = value;
            setPeriodThreeFrom(value);
        }
        if (type === 'periodThreeTo') {
            financialData.periodThreeTo = value;
            setPeriodThreeTo(value);
        }
        if (type === 'genAssemblyFirst') {
            financialData.genAssemblyFirst = value;
            setGenAssemblyFirst(value);
        }
        if (type === 'genAssemblyTime') {
            financialData.genAssemblyTime = value;
            setGenAssemblyTime(value);
        }
        if (type === 'genAssemblyWhen') {
            financialData.genAssemblyWhen = value;
            setGenAssemblyWhen(value);
        }
        if (type === 'activity') {
            if (value[0].text === 'IT consultant') {
                data.mainActivities = props.nData[0].mainActivities;
                setMainActivities(props.nData[0].mainActivities);
            } else if (value[0].text === 'Lawyer') {
                data.mainActivities = props.nData[1].mainActivities;
                setMainActivities(props.nData[1].mainActivities);
            } else if (value[0].text === 'Trainer / Coach') {
                data.mainActivities = props.nData[2].mainActivities;
                setMainActivities(props.nData[2].mainActivities);
            } else {
                data.mainActivities = props.nData[3].mainActivities;
                setMainActivities(props.nData[3].mainActivities);
            }
        }
        props.handleData(data);
        props.handleFinancialData(financialData);
    }

    return (
        <div className={props.active === true ? "popup__wrapper active" : "popup__wrapper"}>
            <div className="popup__content">
                <div className="popup__content-header">Financial years and general assembly</div>
                {props.type === 'financial' ?
                    <>
                        <div className="popup__box">
                            <div className="popup__box-header">Financial years</div>
                            <div className="popup__box-content">
                                <div className="row">
                                    <div className="text"></div>
                                    <div className="text">From</div>
                                    <div className="text">To</div>
                                </div>
                                <div className="row">
                                    <div className="text">Period 1</div>
                                    <TextField label={''} variant={'outlined'} size={'small'} type={'date'} defaultValue={''} value={periodOneFrom} onChange={(e) => handleData('periodOneFrom', e.target.value)} />
                                    <TextField label={''} variant={'outlined'} size={'small'} type={'date'} defaultValue={''} value={periodOneTo} onChange={(e) => handleData('periodOneTo', e.target.value)} />
                                </div>
                                <div className="row">
                                    <div className="text">Period 2</div>
                                    <TextField label={''} variant={'outlined'} size={'small'} type={'date'} defaultValue={''} value={periodTwoFrom} onChange={(e) => handleData('periodTwoFrom', e.target.value)} />
                                    <TextField label={''} variant={'outlined'} size={'small'} type={'date'} defaultValue={''} value={periodTwoTo} onChange={(e) => handleData('periodTwoTo', e.target.value)} />
                                </div>
                                <div className="row">
                                    <div className="text">Period 3</div>
                                    <TextField label={''} variant={'outlined'} size={'small'} type={'date'} defaultValue={''} value={periodThreeFrom} onChange={(e) => handleData('periodThreeFrom', e.target.value)} />
                                    <TextField label={''} variant={'outlined'} size={'small'} type={'date'} defaultValue={''} value={periodThreeTo} onChange={(e) => handleData('periodThreeTo', e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="popup__box">
                            <div className="popup__box-header">General assembly</div>
                            <div className="popup__box-content">
                                <div className="row long">
                                    <div className="text">When:</div>
                                    <TextField label={''} variant={'outlined'} size={'small'} placeholder={'Text here'} value={genAssemblyWhen} onChange={(e) => handleData('genAssemblyWhen', e.target.value)} />
                                </div>
                                <div className="row long">
                                    <div className="text">Time:</div>
                                    <TextField label={''} variant={'outlined'} size={'small'} placeholder={'Text here'} value={genAssemblyTime} onChange={(e) => handleData('genAssemblyTime', e.target.value)} />
                                </div>
                                <div className="row long">
                                    <div className="text">First:</div>
                                    <TextField label={''} variant={'outlined'} size={'small'} placeholder={'Text here'} value={genAssemblyFirst} onChange={(e) => handleData('genAssemblyFirst', e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </>
                : <></>}
                {props.type === 'activities' ?
                    <>
                        <div className="popup__box">
                            <div className="popup__box-header">Template for main activities (NACEBEL)</div>
                            <CustomSelect
                                defaultText={'IT consultant'}
                                optionsList={props.selectData}
                                handleData={handleData}
                                type={'activity'}
                            />
                        </div>
                        <div className="popup__box">
                            <div className="popup__box-header">Main activities (NACEBEL)</div>
                            <TextareaAutosize minRows={5} maxRows={5} value={mainActivities} onChange={(e) => handleData('mainActivities', e.target.value)} />
                        </div>
                        <div className="popup__box">
                            <div className="popup__box-header">Business description</div>
                            <TextareaAutosize minRows={5} maxRows={5} value={businessDescription} onChange={(e) => handleData('businessDescription', e.target.value)} />
                        </div>
                        <div className="popup__box">
                            <div className="popup__box-header">Activities</div>
                            <div className="popup__box-content">
                                <div className="row long">
                                    <div className="text">Sector</div>
                                    <TextField label={''} variant={'outlined'} size={'small'} placeholder={'Text here'} value={sector} onChange={(e) => handleData('sector', e.target.value)} />
                                </div>
                                <div className="row long">
                                    <div className="text">Type of activity:</div>
                                    <TextField label={''} variant={'outlined'} size={'small'} placeholder={'Text here'} value={activityType} onChange={(e) => handleData('activityType', e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </>
                : <></>}
                <div className="btn btn--primary" onClick={() => save()}>Save</div>
            </div>
        </div>
    );
}

export default Popup;
