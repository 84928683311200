import React, { useState, useEffect } from 'react';
import { editAdminIcon, selectArrow } from "../login/loginConstants";
import Edit from "./edit";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import {Trans} from "react-i18next";

function CompanyTax(props) {
    const [edit, setEdit] = useState(false);
    const [header] = useState('Edit ');
    const [inputName, setInputName] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [index, setIndex] = useState(0);
    const [type, setType] = useState('companyTax');
    const [companyTax, setCompanyTax] = useState([]);
    const [conditions, setConditions] = useState([]);

    useEffect(() => {
        let companyTax = [];
        let conditions = [];
        props.data.map((data) => {
            if (data.type === 'COMPANY_TAX') {
                companyTax.push([data.name, `${data.isCurrency === true ? '€ ' : ''}${data.numberValue}${data.isPercent === true ? '%' : ''}`, false, data.id])
            }
            if (data.type === 'KMO') {
                conditions.push([data.name, `${data.isCurrency === true ? '€ ' : ''}${data.numberValue}${data.isPercent === true ? '%' : ''}`, false, data.id])
            }
        })
        setCompanyTax(companyTax);
        setConditions(conditions)
    }, [props]);

    const handleEdit = (open, name, value, index, type) => {
        setEdit(open);
        setInputName(name);
        setInputValue(value);
        setIndex(index);
        setType(type);
    }

    const save = () => {
        Connect(`${Endpoint.getParameters}/${index}`, 'GET').then(response => {
            let body = response;
            const {id, costType, maxValue, ...newObject} = body;
            newObject.numberValue = inputValue.replaceAll('.','').replace(',', '.');
            props.handleSave(index, newObject);
            handleEdit(false, '', '', 0, 'companyTax');
        })
    }

    const addCommas = (num) => {
        if (num.length > 1) {
            if (num[0] === "0") {
                num = num.slice(1);
            }
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const formatValue = (num) => {
        let value = addCommas(num.toString().replace('.',','))
        let rest = value.split(',')[1];
        if(rest && rest.length === 1){
            value = value + "0"
        }
        return value;
    }

    return (
        <div className="box outline">
            <div className="parameters__box">
                <div className="row">
                    <div className="parameters__header"><Trans>admin.mainOcc</Trans></div>
                    <div className="box">
                        <div className="row">
                            <div className="parameters__text select">
                                <Trans>admin.valid</Trans> <span>01/01/2023 - 31/12/2023</span>
                                {selectArrow}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row spaceBetween">
                    <div className="parameters__text light"><Trans>admin.yearly</Trans></div>
                    <div className="parameters__text light"><Trans>admin.contributions</Trans></div>
                </div>
                <div className="box main">
                    {companyTax.map((data, index) => (
                        <div className="row" key={index}>
                            <div className="parameters__text">{data[0]}</div>
                            {data[2] === true ? <div className="edit" onClick={() => handleEdit(true, data[0], data[1], data[3], 'companyTax')}>{editAdminIcon}</div> : ''}
                            <div className="parameters__text bold">{formatValue(data[1])}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="parameters__box">
                <div className="row">
                    <div className="parameters__header"><Trans>admin.secOcc</Trans></div>
                    <div className="box">
                        <div className="row">
                            <div className="parameters__text select">
                                <Trans>admin.valid</Trans> <span>01/01/2023 - 31/12/2023</span>
                                {selectArrow}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row spaceBetween">
                    <div className="parameters__text light"><Trans>admin.yearly</Trans></div>
                    <div className="parameters__text light"><Trans>admin.contributions</Trans></div>
                </div>
                <div className="box main">
                    {conditions.map((data, index) => (
                        <div className="row" key={index}>
                            <div className="parameters__text">{data[0]}</div>
                            {data[2] === true ? <div className="edit" onClick={() => handleEdit(true, data[0], data[1], data[3], 'conditions')}>{editAdminIcon}</div> : ''}
                            <div className="parameters__text bold">{formatValue(data[1])}</div>
                        </div>
                    ))}
                </div>
            </div>
            <Edit active={edit} handleActive={handleEdit} handleSave={save} header={header} inputName={inputName} inputValue={inputValue} index={index} type={type} />
        </div>
    );
}

export default CompanyTax;
