import React, {useEffect, useState} from 'react';
import Navbar from "../navbar/navbar";
import Menu from "../menu/menu";
import Quickstart from "../quickstart/quickstart";
import './admin.scss';
import {Trans, withTranslation} from "react-i18next";
import {withCookies} from "react-cookie";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import {TextField} from "@material-ui/core";

const AdminAllClients = (props) => {
    const [step, setStep] = useState(1);
    const [quickstart, setQuickstart] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);

    useEffect(() => {
        Connect(`${Endpoint.userList}?page=0&size=10&sort=id%2Cdesc`, 'GET').then(response => {
            setData(response.content)
        });
    }, []);

    const handleQuickstart = (value) => {
        setQuickstart(value);
    }

    const updateStep = (value) => {
        setStep(value);
    }

    return (
        <div className="container">
            <Navbar currentStep={step} handleQuickstart={handleQuickstart} handleStep={updateStep} name={'All clients'} survey={false} desc={''} {...props} />
            <Menu role={'admin'} permissions={null} isMobile={false} component={'all'}/>
            <Quickstart status={quickstart} handleQuickstart={handleQuickstart} />
            <div className="admin clients">
                <div className="container__body-input">
                    <p id="assetsValue" className="container__body-text">
                        Select a client file below.
                    </p>
                    <TextField
                        label={""}
                        variant={"outlined"}
                        size={"small"}
                        placeholder={"Search..."}
                        value={''}
                        name={"assetsValue"}
                    />
                </div>
                <div>
                    {data.map((data) => (
                        <div className="admin__user">
                            <div className="text">{data.name}</div>
                            <div className="text date">01/01/2024</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(withCookies(AdminAllClients));