import React, { useState, useEffect } from 'react';
import Navbar from "../navbar/navbar";
import Menu from "../menu/menu";
import Quickstart from "../quickstart/quickstart";
import './parameters.scss';
import SocialContributions from "./socialContributions";
import DailyAllowances from "./dailyAllowances";
import CompanyTax from "./companyTax";
import DefaultCosts from "./defaultCosts";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import {withTranslation} from "react-i18next";
import {withCookies} from "react-cookie";
import PersonalIncomeTax from "./personalIncomeTax";
import {initLoader, removeLoader} from "../loader/loader";
import { toast } from 'react-toastify';

const Parameters = (props) => {
    const [data, setData] = useState([]);
    const [step, setStep] = useState(1);
    const [quickstart, setQuickstart] = useState(false);

    const getRes = () => {
        Connect(`${Endpoint.getParameters}`, 'GET').then(response => {
            setData(response);
            removeLoader(document.querySelector('.parameters'));
        });
    }

    useEffect(() => {
        initLoader(document.querySelector('.parameters'));
        getRes();
        sessionStorage.removeItem('companyType');
        sessionStorage.removeItem('companyTypeData');
        sessionStorage.removeItem('userEmail');
        sessionStorage.removeItem('userID');
    }, []);

    const save = (data) => {
        Connect(`${Endpoint.getParameters}`, 'PUT', data).then(response => {
           
            getRes()
            if(response.status === 400){
                toast.error(localStorage.getItem("i18nextLng") === "en" ? "An invalid value was entered." : "Er is een ongeldige waarde ingevoerd.")
            }
        }).catch((error)=>console.log(error))
    }

    const handleQuickstart = (value) => {
        setQuickstart(value);
    }

    const updateStep = (value) => {
        setStep(value);
    }

    return (
        <div className="container">
            <Navbar currentStep={step} handleQuickstart={handleQuickstart} handleStep={updateStep} name={'Parameters'} survey={true} desc={'Calculations are made based on the parameters shown here.'} {...props} />
            <Menu role={'admin'} permissions={null} isMobile={false} component={'support'} />
            <Quickstart status={quickstart} handleQuickstart={handleQuickstart} />
            <div className="parameters">
                {data.length > 0 && (
                    <>
                        {step === 1 ?
                            <SocialContributions data={data} handleSave={save}/>
                            :
                            <></>
                        }
                        {step === 2 ?
                            <PersonalIncomeTax data={data} handleSave={save}/>
                            :
                            <></>
                        }
                        {step === 3 ?
                            <DailyAllowances data={data} handleSave={save}/>
                            :
                            <></>
                        }
                        {step === 4 ?
                            <CompanyTax data={data} handleSave={save}/>
                            :
                            <></>
                        }
                        {step === 6 ?
                            <DefaultCosts data={data} handleSave={save}/>
                            :
                            <></>
                        }
                    </>
                )}
            </div>
        </div>
    );
}

export default withTranslation()(withCookies(Parameters));
