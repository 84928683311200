// validate inputs, add messages 'required field'
// valid mail

const validate = () => {
    let reason = 1;

    document.querySelectorAll('[required]').forEach( required => {
        let selfParent = required.closest('.input-group');

        if(selfParent === null){
            selfParent = required.closest('.input-wrapper');
        }

        if(selfParent.closest('.MuiDialog-root') !== null && selfParent.closest('.MuiDialog-root').style.visibility === 'hidden') return true;

        let option3 = true;
        if(required.classList.contains('select')){
            if(required.classList.contains('option--3')){
                selfParent.querySelectorAll("input[name='select']").forEach(elem => {
                    if(elem.getAttribute('value') === '') option3 = false}
                )
            }
            required = selfParent.querySelector("input[name='select']");
        }

        let radioRequired = true;
        if(required.classList.contains('customRadio-group') && selfParent.querySelector('input:checked') === null){
            radioRequired = false;
        }

        if( option3
                && (required.getAttribute('value') !== ''
                && required.getAttribute('value') !== ' '
                && required.value !== ''
                && radioRequired)) {
            selfParent.getElementsByClassName('label--error')[0].classList.add('d-none');
            selfParent.classList.remove('error');
        } else {
            selfParent.classList.add('error');
            selfParent.getElementsByClassName('label--error')[0].classList.remove('d-none');
            reason = 0;
        }

        // validate email\
        if(required.getAttribute('type') === 'email' && required.value !== '') {
            const checkEmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            const validEmail = checkEmailPattern.test(String(required.value).toLowerCase()),
                emailBlock = selfParent.getElementsByClassName('badEmail')[0].classList;
            if(validEmail){
                emailBlock.add('d-none');
                selfParent.classList.remove('error')
            } else {
                emailBlock.remove('d-none');
                selfParent.classList.add('error')
            }
        }

    })

    return reason;
}

export default validate;