import {toast} from "react-toastify";

export const Connect = (url, method, body, type = 'json', isText = false, raw= false) => {
    return (
        fetch(url,{
            method,
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'public, max-age=3600, s-max-age=3600',
                'Authorization': (typeof window !== 'undefined') ? localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token') : '',
                'Accept-Language': (typeof window !== 'undefined') ? localStorage.getItem('i18nextLng') == null ? 'en' : localStorage.getItem('i18nextLng') : 'en'
            },
            body: body ? (type !== 'json' ? body : JSON.stringify(body)) : null
        })
            .then(response => {
                if(!response.ok){
                    // throw response
                    return Promise.reject(response);
                } else {
                    return response;
                }
            })
            .then(res => {
                if(raw){
                    return res;
                } else if (isText) {
                    return res.text()
                } else {
                    res.method = method;
                    if(method === 'DELETE' || raw){
                        return res;
                    } else {
                        return res.json();
                    }
                }
            })
            .catch((error) => {
                if(error.status === 500){
                    return "Błąd serwera";
                } else if (error.status === 401) {
                    console.log('logout')
                    window.location.href = '/';
                    return error
                }
                else {
                    return error
                }
            })
            .then((error) => {
                if(error !== 'Ok' && typeof error !== 'number') {
                    // toast.error(error, {
                    //     position: toast.POSITION.BOTTOM_RIGHT,
                    // });
                }
                return error;
            })
    )
}
