import React, { useState, useEffect } from "react";
import {Checkbox, FormControlLabel, IconButton, TextField, Tooltip ,InputAdornment} from "@material-ui/core";
import './remuneration.scss';
import { homeIcon, routeIcon, appleIcon, carIcon } from "../login/loginConstants";
import Factors from "./factors";
import DatePicker from "react-datepicker";
import {calendar, InfoIcon, redInfoIcon} from "../survey/surveyConstant";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import CustomSmallSwitch from "../switch/smallSwitch";
import {Trans} from "react-i18next";

export async function makeHttpRequest(url, data, storageKey) {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        const text = await response.text();
        sessionStorage.setItem(storageKey, text);
    } catch (error) {
        console.error('Error in makeApiRequest:', error);
    }
}

export async function vehicleList(yearly_km, duration) {
    const data = { yearly_km, duration };
    await makeHttpRequest('https://demo-fe.bizantium.be/proxy/vehicle-list', data, 'vehicle-list');
    // await makeHttpRequest('http://localhost:90/vehicle-list', data, 'vehicle-list');
}

export async function vehicleDetails(yearly_km, duration, make, model) {
    const data = { yearly_km, duration, make, model };
    await makeHttpRequest('https://demo-fe.bizantium.be/proxy/vehicle-details', data, 'vehicle-details');
    // await makeHttpRequest('http://localhost:90/vehicle-details', data, 'vehicle-details');
}

export async function fiscalBenefitInKindCar(list_price, fuel, co2_emissions, first_registration, is_fake_hybrid, co2_emissions_fake_hybrid_alternative) {
    const data = { list_price, fuel, co2_emissions, first_registration, is_fake_hybrid, co2_emissions_fake_hybrid_alternative };
    await makeHttpRequest('https://demo-fe.bizantium.be/proxy/fiscal-benefit-in-kind-car', data, 'fiscal-benefit-in-kind-car');
    // await makeHttpRequest('http://localhost:90/fiscal-benefit-in-kind-car', data, 'fiscal-benefit-in-kind-car');
}

export async function fiscalBenefitInKindItem(profession) {
    const data = { profession };
    await makeHttpRequest('https://demo-fe.bizantium.be/proxy/fiscal-benefit-in-kind-item', data, 'fiscal-benefit-in-kind-item');
    // await makeHttpRequest('http://localhost:90/fiscal-benefit-in-kind-item', data, 'fiscal-benefit-in-kind-item');
}

export async function salaryNetToGross(net_salary, fiscal_benefit_in_kind = 0, profession = 'EMPLOYEE', is_single = true, only_pension_income = false, is_single_parent = false, has_handicap = false, dependent_children = 0, dependants_above_65 = 0, dependants_above_65_extra_care = 0, dependants_other = 0, spouse_has_no_or_very_low_income = false, spouse_has_handicap = false, spouse_has_low_pro_income = false, spouse_has_low_income_only_pension = false, insurance_payments = 0, has_government_contract = false, work_bonus_received = 0, frequency = 'monthly') {
    const data = { net_salary, fiscal_benefit_in_kind, profession, is_single, only_pension_income, is_single_parent, has_handicap, dependent_children, dependants_above_65, dependants_above_65_extra_care, dependants_other, spouse_has_no_or_very_low_income, spouse_has_handicap, spouse_has_low_pro_income, spouse_has_low_income_only_pension, insurance_payments, has_government_contract, work_bonus_received, frequency };
    await makeHttpRequest('https://demo-fe.bizantium.be/proxy/salary-net-to-gross', data, 'salary-net-to-gross');
    // await makeHttpRequest('http://localhost:90/salary-net-to-gross', data, 'salary-net-to-gross');
}


const Remuneration = (props) => {
    const [factors, setFactors] = useState(false);
    const [factorHeader, setFactorHeader] = useState('');
    const [factorText, setFactorText] = useState('');
    const [factor, setFactor] = useState(0);
    const [factorData, setFactorData] = useState([
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
    ]);
    const [netAmountMonth, setNetAmountMonth] = useState(null);
    const [grossAmountMonth, setGrossAmountMonth] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [today, setToday] = useState('');
    const [smartphone, setSmartphone] = useState(false);
    const [tablet, setTablet] = useState(false);
    const [smartphonePlan, setSmartphonePlan] = useState(false);
    const [internet, setInternet] = useState(false);
    const [computer, setComputer] = useState(false);
    const [garage, setGarage] = useState(false);
    const [parking, setParking] = useState(false);
    const [carWash, setCarWash] = useState(false);
    const [isCarCardOn, setIsCarCardOn] = useState(false);
    const [isCommutesCardOn, setIsCommutesCardOn] = useState(false);
    const [isMealVouchersCardOn, setIsMealVouchersCardOn] = useState(false);
    const [isPropertyCardOn, setIsPropertyCardOn] = useState(false);
    const [placeholderName, setPlaceholderName] = useState('');
    const [placeholderDescription, setPlaceholderDescription] = useState('');
    const [placeholderPrice, setPlaceholderPrice] = useState(0);
    const [placeholderType, setPlaceholderType] = useState('benefits');
    const [placeholderActive, setPlaceholderActive] = useState(false);
    const [usedOffice, setUsedOffice] = useState([0]);
    const [defaultBrand, setDefaultBrand] = useState(localStorage.getItem('i18nextLng') === 'en' ? 'Select a brand' : 'Selecteer een merk...');
    const [defaultModel, setDefaultModel] = useState(localStorage.getItem('i18nextLng') === 'en' ? 'Select a model' : 'Selecteer een model...');
    const [defaultFuel, setDefaultFuel] = useState(localStorage.getItem('i18nextLng') === 'en' ? 'Select a fuel type' : 'Selecteer een brandstof...');
    const [defaultTrimline, setDefaultTrimline] = useState(localStorage.getItem('i18nextLng') === 'en' ? 'Select a trimline' : 'Selecteer een trimlijn...');
    const [initialStartDate, setInitialStartDate] = useState(null);
    const [isOverFourtyCommutes, setIsOverFourtyCommutes] = useState(false);
    const [commutes, setCommutes] = useState(sessionStorage.getItem('professionalCommutes') ? parseFloat(sessionStorage.getItem('professionalCommutes').replaceAll('.', '')).toFixed(0) : null);
    const [workingDays, setWorkingDays] = useState(sessionStorage.getItem('mealVouchers') ? parseInt(sessionStorage.getItem('mealVouchers').replaceAll('.', '')) : null);
    const [rentingProperty, setRentingProperty] = useState(sessionStorage.getItem('rentingProperty') ? parseInt(sessionStorage.getItem('rentingProperty').replaceAll('.', '')) : null);

    useEffect(() => {
        const date = new Date();
        const today = `${date.getFullYear()}-${date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
        setToday(today);
        Connect(`${Endpoint.remuneration}`, 'GET').then(response => {
            setIsCarCardOn(response.isCarCardOn);
            setIsCommutesCardOn(response.isCommutesCardOn);
            setIsMealVouchersCardOn(response.isMealVouchersCardOn);
            setIsPropertyCardOn(response.isPropertyCardOn);
            handleDevice('smartphone', response.isSmartphoneBenefit, 3);
            handleDevice('tablet', response.isTabletBenefit, 3);
            handleDevice('smartphonePlan', response.isSmartphonePlanBenefit, 4);
            handleDevice('internetSubscription', response.isInternetBenefit, 5);
            handleDevice('computer', response.isComputerBenefit, 6);
            handleDevice('garageCost', response.isGarageReimbursement, 50);
            handleDevice('parkingCost', response.isParkingReimbursement, 15);
            handleDevice('carWashCost', response.isCarWashReimbursement, 15);
            if (response.cadastralIncome !== null) {
                sessionStorage.setItem('cadastralIncome', response.cadastralIncome);
            }
            if (response.isOverFourtyCommutes !== null) {
                sessionStorage.setItem('isOverFourtyCommutes', response.isOverFourtyCommutes);
            }
            if (response.carInitialRegDate !== null) {
                sessionStorage.setItem('carInitialRegDate', response.carInitialRegDate);
            }
            if (response.commutesPerMonth !== null) {
                sessionStorage.setItem('professionalCommutes', response.commutesPerMonth);
            }
            if (response.cadastralIncome !== null) {
                sessionStorage.setItem('rentingProperty', response.cadastralIncome);
            }
            if (response.propertyOfficePercent !== null) {
                sessionStorage.setItem('rentingPropertyPercent', response.propertyOfficePercent);
            }
            if (response.mealWorkDays !== null) {
                sessionStorage.setItem('mealVouchers', response.mealWorkDays);
            }
            if (response.carBrand !== null) {
                sessionStorage.setItem('brand', response.carBrand);
            }
            if (response.carModel !== null) {
                sessionStorage.setItem('model', response.carModel);
            }
            if (response.carFuelType !== null) {
                sessionStorage.setItem('fuel', response.carFuelType);
            }
            if (response.carTrimline !== null) {
                sessionStorage.setItem('trimline', response.carTrimline);
            }
            if (response.netRemuneration !== null) {
                setNetAmountMonth(response.netRemuneration);
                handleAmountMonth(`${response.netRemuneration}`);
                sessionStorage.setItem('netAmount', response.netRemuneration);
            }
            if (response.propertyOfficePercent !== null) {
                sessionStorage.setItem('propertyOfficePercent', response.propertyOfficePercent);
            }
            if (response.startDate !== null) {
                sessionStorage.setItem('startDate', response.startDate);
            }
            const initialStartDate = response.startDate || "";
            if (initialStartDate) {
                setStartDate(new Date(initialStartDate));
            }
            setTimeout(() => {
                let indexCar = 0;
                let indexCommutes = 0;
                let indexMeal = 0;
                let indexProperty = 0;
                if (response.isCarCardOn === true) {
                    if (sessionStorage.getItem('brand') && sessionStorage.getItem('brand').length > 0) {
                        indexCar++
                    }
                    if (sessionStorage.getItem('model') && sessionStorage.getItem('model').length > 0) {
                        indexCar++
                    }
                    if (sessionStorage.getItem('fuel') && sessionStorage.getItem('fuel').length > 0) {
                        indexCar++
                    }
                    if (sessionStorage.getItem('trimline') && sessionStorage.getItem('trimline').length > 0) {
                        indexCar++
                    }
                    if (sessionStorage.getItem('carInitialRegDate') && sessionStorage.getItem('carInitialRegDate').length > 0) {
                        indexCar++
                    }
                    save(3, indexCar);
                }
                if (response.isCommutesCardOn === true) {
                    if (sessionStorage.getItem('professionalCommutes') && sessionStorage.getItem('professionalCommutes') > 0) {
                        indexCommutes++
                    }
                    save(2, indexCommutes);
                }
                if (response.isMealVouchersCardOn === true) {
                    if (sessionStorage.getItem('mealVouchers') && sessionStorage.getItem('mealVouchers') > 0) {
                        indexMeal++
                    }
                    save(4, indexMeal);
                }
                if (response.isPropertyCardOn === true) {
                    if (sessionStorage.getItem('cadastralIncome') && sessionStorage.getItem('cadastralIncome') > 0) {
                        indexProperty++
                    }
                    if (sessionStorage.getItem('propertyOfficePercent') && sessionStorage.getItem('propertyOfficePercent').length > 0) {
                        indexProperty++
                    }
                    save(1, indexProperty);
                }
            }, 500);
        })
        Connect(`${Endpoint.taxMachine}`, 'GET').then(response => {
            if (response.companyCar !== null) {
                sessionStorage.setItem('companyCar', response.companyCar);
            }
        });
        vehicleList(25000, 5).then(r => sessionStorage.setItem('r', r));
        fiscalBenefitInKindCar(1, 'GASOLINE', 1000, '2023-01-01', false, 1000).then(r => sessionStorage.setItem('r', r));
        // fiscalBenefitInKindItem('EMPLOYEE').then(r => sessionStorage.setItem('r', r));
        let body = {
            checkpointCategory: JSON.parse(sessionStorage.getItem('checkpointCategoryEstablishment'))[4],
            completed: 1,
            required: 1,
            isChange: true
        };
        Connect(`${Endpoint.putProgressSurvey}`, 'PUT', body).then(response => {
            // console.log(response);
        });

        return () => {
            if (sessionStorage.getItem('salary-net-to-gross') !== null) {
                let body = {
                    payStartDate: sessionStorage.getItem('startDate') ? sessionStorage.getItem('startDate') : null,
                    monthlyWage: sessionStorage.getItem('netAmount') ? parseInt(sessionStorage.getItem('netAmount').replaceAll('.', '')) : null,
                    socialContributionsEstimation: sessionStorage.getItem('special_contribution_social_security') ? parseInt(sessionStorage.getItem('special_contribution_social_security').replaceAll('.', '')) : null,
                    withholdingTax: sessionStorage.getItem('withholding_tax') ? parseInt(sessionStorage.getItem('withholding_tax').replaceAll('.', '')) : null,
                    propertyRent: sessionStorage.getItem('rentingProperty') ? parseInt(sessionStorage.getItem('rentingProperty').replaceAll('.', '')) : null,
                    companyCar: sessionStorage.getItem('companyCar') ? parseInt(sessionStorage.getItem('companyCar').replaceAll('.', '')) : null,
                    professionalCommutes: sessionStorage.getItem('professionalCommutes') ? parseFloat(sessionStorage.getItem('professionalCommutes')) : null,
                    mealVouchers: sessionStorage.getItem('mealVouchers') ? parseInt(sessionStorage.getItem('mealVouchers').replaceAll('.', '')) : null,
                    garageCost: sessionStorage.getItem('garageCost') ? parseInt(sessionStorage.getItem('garageCost').replaceAll('.', '')) : null,
                    parkingCost: sessionStorage.getItem('parkingCost') ? parseInt(sessionStorage.getItem('parkingCost').replaceAll('.', '')) : null,
                    carwashCost: sessionStorage.getItem('carWashCost') ? parseInt(sessionStorage.getItem('carWashCost').replaceAll('.', '')) : null,
                    smartphone: sessionStorage.getItem('smartphone') ? parseInt(sessionStorage.getItem('smartphone').replaceAll('.', '')) : null,
                    smartphonePlan: sessionStorage.getItem('smartphonePlan') ? parseInt(sessionStorage.getItem('smartphonePlan').replaceAll('.', '')) : null,
                    computer: sessionStorage.getItem('computer') ? parseInt(sessionStorage.getItem('computer').replaceAll('.', '')) : null,
                    tablet: sessionStorage.getItem('tablet') ? parseInt(sessionStorage.getItem('tablet').replaceAll('.', '')) : null,
                    internetSubscription: sessionStorage.getItem('internetSubscription') ? parseInt(sessionStorage.getItem('internetSubscription').replaceAll('.', '')) : null,
                    carBrand: sessionStorage.getItem('brand') ? sessionStorage.getItem('brand') : null,
                    carModel: sessionStorage.getItem('model') ? sessionStorage.getItem('model') : null,
                    carFuel: sessionStorage.getItem('fuel') ? sessionStorage.getItem('fuel') : null,
                    carTrimline: sessionStorage.getItem('trimline') ? sessionStorage.getItem('trimline') : null,
                }

                let remunerationBody = {
                    cadastralIncome: sessionStorage.getItem('cadastralIncome') ? sessionStorage.getItem('cadastralIncome') : null,
                    carBrand: sessionStorage.getItem('brand') ? sessionStorage.getItem('brand') : null,
                    carModel: sessionStorage.getItem('model') ? sessionStorage.getItem('model') : null,
                    carInitialRegDate: sessionStorage.getItem('carInitialRegDate') ? sessionStorage.getItem('carInitialRegDate') : null,
                    carFuelType: sessionStorage.getItem('fuel') ? sessionStorage.getItem('fuel') : null,
                    carTrimline: sessionStorage.getItem('trimline') ? sessionStorage.getItem('trimline') : null,
                    commutesPerMonth: sessionStorage.getItem('professionalCommutes') ? parseInt(sessionStorage.getItem('professionalCommutes')) : null,
                    isCarCardOn: sessionStorage.getItem('isCarCardOn') === 'true',
                    isCarWashReimbursement: !!sessionStorage.getItem('carWashCost'),
                    isCommutesCardOn: sessionStorage.getItem('isCommutesCardOn') === 'true',
                    isComputerBenefit: !!sessionStorage.getItem('computer'),
                    isGarageReimbursement: !!sessionStorage.getItem('garageCost'),
                    isInternetBenefit: !!sessionStorage.getItem('internetSubscription'),
                    isMealVouchersCardOn: sessionStorage.getItem('isMealVouchersCardOn') === 'true',
                    isOverFourtyCommutes: !!sessionStorage.getItem('isOverFourtyCommutes'),
                    isParkingReimbursement: !!sessionStorage.getItem('parkingCost'),
                    isPropertyCardOn: sessionStorage.getItem('isPropertyCardOn') === 'true',
                    isSmartphoneBenefit: !!sessionStorage.getItem('smartphone'),
                    isSmartphonePlanBenefit: !!sessionStorage.getItem('smartphonePlan'),
                    isTabletBenefit: !!sessionStorage.getItem('tablet'),
                    mealWorkDays: sessionStorage.getItem('mealVouchers') ? parseInt(sessionStorage.getItem('mealVouchers').replaceAll('.', '')) : null,
                    netRemuneration: sessionStorage.getItem('netAmount') ? parseInt(sessionStorage.getItem('netAmount').replaceAll('.', '')) : null,
                    propertyOfficePercent: sessionStorage.getItem('propertyOfficePercent') ? parseInt(sessionStorage.getItem('propertyOfficePercent').replaceAll('.', '')) : null,
                    startDate: sessionStorage.getItem('startDate') ? sessionStorage.getItem('startDate') : null
                }

                Connect(`${Endpoint.remuneration}`, 'PUT', remunerationBody).then(response => {
                });

                Connect(`${Endpoint.taxMachine}`, 'POST', body).then(response => {
                    setTimeout(() => {
                        sessionStorage.removeItem('grossAmount');
                        sessionStorage.removeItem('withholding_tax');
                        sessionStorage.removeItem('special_contribution_social_security');
                        sessionStorage.removeItem('professionalCommutes');
                        sessionStorage.removeItem('commutesPerMonth');
                        sessionStorage.removeItem('mealVouchers');
                        sessionStorage.removeItem('rentingProperty');
                        sessionStorage.removeItem('companyCar');
                        sessionStorage.removeItem('trimline');
                        sessionStorage.removeItem('vehicle-details');
                        sessionStorage.removeItem('model');
                        sessionStorage.removeItem('brand');
                        sessionStorage.removeItem('salary-net-to-gross');
                        sessionStorage.removeItem('netAmount');
                        sessionStorage.removeItem('fiscal-benefit-in-kind-car');
                        sessionStorage.removeItem('fiscal-benefit-in-kind-item');
                        sessionStorage.removeItem('fuel');
                        sessionStorage.removeItem('vehicle-list-array');
                        sessionStorage.removeItem('r');
                        sessionStorage.removeItem('startDate');
                        sessionStorage.removeItem('isMealVouchersCardOn');
                        sessionStorage.removeItem('isCommutesCardOn');
                        sessionStorage.removeItem('computer');
                        sessionStorage.removeItem('isPropertyCardOn');
                        sessionStorage.removeItem('internetSubscription');
                        sessionStorage.removeItem('isCarCardOn');
                        sessionStorage.removeItem('smartphone');
                        sessionStorage.removeItem('garageCost');
                        sessionStorage.removeItem('isOverFourtyCommutes');
                        sessionStorage.removeItem('carInitialRegDate');
                        sessionStorage.removeItem('cadastralIncome');
                        sessionStorage.removeItem('rentingPropertyPercent');
                        sessionStorage.removeItem('propertyOfficePercent');
                        sessionStorage.removeItem('mealWorkDays');
                    }, 300);
                });
            }
        }
    }, []);

    const handleAmountMonth = (value) => {
        setNetAmountMonth(addCommas(removeNonNumeric(value)));
        sessionStorage.setItem('netAmount', addCommas(removeNonNumeric(value)));

        let benefits = 0;
        if (sessionStorage.getItem('smartphone') && parseInt(sessionStorage.getItem('smartphone')) > 0) {
            benefits += 3;
        }
        if (sessionStorage.getItem('tablet') && parseInt(sessionStorage.getItem('tablet')) > 0) {
            benefits += 3;
        }
        if (sessionStorage.getItem('smartphonePlan') && parseInt(sessionStorage.getItem('smartphonePlan')) > 0) {
            benefits += 4;
        }
        if (sessionStorage.getItem('internetSubscription') && parseInt(sessionStorage.getItem('internetSubscription')) > 0) {
            benefits += 5;
        }
        if (sessionStorage.getItem('computer') && parseInt(sessionStorage.getItem('computer')) > 0) {
            benefits += 6;
        }
        if (sessionStorage.getItem('rentingProperty') && parseInt(sessionStorage.getItem('rentingProperty')) > 0) {
            benefits += parseInt(sessionStorage.getItem('rentingProperty'))/2;
        }
        if (sessionStorage.getItem('companyCar') && parseInt(sessionStorage.getItem('companyCar')) > 0) {
            benefits += parseInt(sessionStorage.getItem('companyCar'))/2;
        }
        if (sessionStorage.getItem('professionalCommutes') && parseInt(sessionStorage.getItem('professionalCommutes')) > 0) {
            benefits += parseInt(sessionStorage.getItem('professionalCommutes'))/2;
        }
        if (sessionStorage.getItem('mealVouchers') && parseInt(sessionStorage.getItem('mealVouchers')) > 0) {
            benefits += parseInt(sessionStorage.getItem('mealVouchers'))/2;
        }

        salaryNetToGross(parseInt(value.replaceAll('.', '')), benefits).then(r => sessionStorage.setItem('r', r));

        setTimeout(() => {
            if (sessionStorage.getItem('salary-net-to-gross') !== null) {
                setGrossAmountMonth(addCommas(removeNonNumeric(JSON.parse(sessionStorage.getItem('salary-net-to-gross')).gross_salary.toFixed(0))));
                sessionStorage.setItem('grossAmount', addCommas(removeNonNumeric(JSON.parse(sessionStorage.getItem('salary-net-to-gross')).gross_salary.toFixed(0))));
                sessionStorage.setItem('withholding_tax', addCommas(removeNonNumeric(JSON.parse(sessionStorage.getItem('salary-net-to-gross')).withholding_tax.toFixed(0))));
                sessionStorage.setItem('special_contribution_social_security', addCommas(removeNonNumeric(JSON.parse(sessionStorage.getItem('salary-net-to-gross')).special_contribution_social_security.toFixed(0))));
            }
        }, 1000);
    };

    const handleDate = (date) => {
        setStartDate(date);
        sessionStorage.setItem('startDate', `${date.getFullYear()}-${date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`);
    };

    const save = (index, answers = 0) => {
        let data = factorData;
        if (index !== 5) {
            data[index - 1] = [answers, 1];
        }
        if (index === 1) {
            setIsPropertyCardOn(true);
            sessionStorage.setItem('isPropertyCardOn', 'true');
        }
        if (index === 2) {
            setIsCommutesCardOn(true);
            sessionStorage.setItem('isCommutesCardOn', 'true');
        }
        if (index === 3) {
            setIsCarCardOn(true);
            sessionStorage.setItem('isCarCardOn', 'true');
        }
        if (index === 4) {
            setIsMealVouchersCardOn(true);
            sessionStorage.setItem('isMealVouchersCardOn', 'true');
        }
        setFactors(false);
        setFactorData(data);
        setTimeout(() => {
            sessionStorage.getItem('netAmount') &&
            handleAmountMonth(sessionStorage.getItem('netAmount'));
        }, 100);

    };

    const handleFactors = (value, header, text, factor, load = true) => {
        // setDefaultBrand(localStorage.getItem('i18nextLng') === 'en' ? 'Select a brand' : 'Selecteer een merk...');
        // setDefaultModel(localStorage.getItem('i18nextLng') === 'en' ? 'Select a model' : 'Selecteer een model...');
        // setDefaultFuel(localStorage.getItem('i18nextLng') === 'en' ? 'Select a fuel type' : 'Selecteer een brandstof...');
        // setDefaultTrimline(localStorage.getItem('i18nextLng') === 'en' ? 'Select a trimline' : 'Selecteer een trimlijn...');
        if (load === true) {
            setFactors(value);
            setFactorHeader(header);
            setFactorText(text);
            setFactor(factor);
        }
    };

    const addCommas = (num) => {
        if (num.length > 1) {
            if (num[0] === '0') {
                num = num.slice(1);
            }
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    const handleDevice = (key, checked, value) => {
        switch (key) {
            case 'smartphone':
                setSmartphone(checked);
                break;
            case 'smartphonePlan':
                setSmartphonePlan(checked);
                break;
            case 'computer':
                setComputer(checked);
                break;
            case 'tablet':
                setTablet(checked);
                break;
            case 'internetSubscription':
                setInternet(checked);
                break;
            case 'garageCost':
                setGarage(checked);
                break;
            case 'parkingCost':
                setParking(checked);
                break;
            case 'carWashCost':
                setCarWash(checked);
                break;
            default:
                break;
        }

        if (checked === true) {
            sessionStorage.setItem(key, value);
        } else {
            sessionStorage.removeItem(key);
        }
        sessionStorage.getItem('netAmount') &&
            handleAmountMonth(sessionStorage.getItem('netAmount'));
    }

    const handleCarCardOn = (value) => {
        setIsCarCardOn(value);
        sessionStorage.setItem('isCarCardOn', value);
        if (value === false) {
            sessionStorage.removeItem('brand');
            sessionStorage.removeItem('model');
            sessionStorage.removeItem('fuel');
            sessionStorage.removeItem('trimline');
            sessionStorage.removeItem('carInitialRegDate');
            sessionStorage.removeItem('companyCar');
            setDefaultBrand(localStorage.getItem('i18nextLng') === 'en' ? 'Select a brand' : 'Selecteer een merk...');
            setDefaultModel(localStorage.getItem('i18nextLng') === 'en' ? 'Select a model' : 'Selecteer een model...');
            setDefaultFuel(localStorage.getItem('i18nextLng') === 'en' ? 'Select a fuel type' : 'Selecteer een brandstof...');
            setDefaultTrimline(localStorage.getItem('i18nextLng') === 'en' ? 'Select a trimline' : 'Selecteer een trimlijn...');
            setInitialStartDate(null);
            handleFactors('', '', '', '', true);
            setTimeout(() => {
                handleFactors('', '', '', '', false);
                handleQuestionFilled();
            }, 100);
        }
        setTimeout(() => {
            sessionStorage.getItem('netAmount') &&
            handleAmountMonth(sessionStorage.getItem('netAmount'));
        }, 200);
    }

    const handleCommutesCardOn = (value) => {
        setIsCommutesCardOn(value);
        sessionStorage.setItem('isCommutesCardOn', value);
        if (value === false) {
            sessionStorage.removeItem('commutesPerMonth');
            sessionStorage.removeItem('professionalCommutes');
            sessionStorage.removeItem('isOverFourtyCommutes');
            setCommutes(null);
            setIsOverFourtyCommutes(null);
            handleFactors('', '', '', '', true);
            setTimeout(() => {
                handleFactors('', '', '', '', false);
                handleQuestionFilled();
            }, 100);
        }
        setTimeout(() => {
            sessionStorage.getItem('netAmount') &&
            handleAmountMonth(sessionStorage.getItem('netAmount'));
        }, 200);
    }

    const handleMealVouchersCardOn = (value) => {
        setIsMealVouchersCardOn(value);
        sessionStorage.setItem('isMealVouchersCardOn', value);
        if (value === false) {
            sessionStorage.removeItem('mealVouchers');
            sessionStorage.removeItem('mealWorkDays');
            setWorkingDays(null);
            handleFactors('', '', '', '', true);
            setTimeout(() => {
                handleFactors('', '', '', '', false);
                handleQuestionFilled();
            }, 100);
        }
        setTimeout(() => {
            sessionStorage.getItem('netAmount') &&
            handleAmountMonth(sessionStorage.getItem('netAmount'));
        }, 200);
    }

    const handlePropertyCardOn = (value) => {
        setIsPropertyCardOn(value);
        sessionStorage.setItem('isPropertyCardOn', value);
        if (value === false) {
            sessionStorage.removeItem('rentingProperty');
            sessionStorage.removeItem('cadastralIncome');
            sessionStorage.removeItem('propertyOfficePercent');
            setRentingProperty(null);
            setUsedOffice([0]);
            handleFactors('', '', '', '', true);
            setTimeout(() => {
                handleFactors('', '', '', '', false);
                handleQuestionFilled();
            }, 100);
        }
        setTimeout(() => {
            sessionStorage.getItem('netAmount') &&
            handleAmountMonth(sessionStorage.getItem('netAmount'));
        }, 200);
    }

    const handleQuestionFilled = () => {
        setTimeout(() => {
            let indexCar = 0;
            let indexCommutes = 0;
            let indexMeal = 0;
            let indexProperty = 0;
            let data = factorData;
            if (isCarCardOn === true) {
                if (sessionStorage.getItem('brand') && sessionStorage.getItem('brand').length > 0) {
                    indexCar++
                }
                if (sessionStorage.getItem('model') && sessionStorage.getItem('model').length > 0) {
                    indexCar++
                }
                if (sessionStorage.getItem('fuel') && sessionStorage.getItem('fuel').length > 0) {
                    indexCar++
                }
                if (sessionStorage.getItem('trimline') && sessionStorage.getItem('trimline').length > 0) {
                    indexCar++
                }
                if (sessionStorage.getItem('carInitialRegDate') && sessionStorage.getItem('carInitialRegDate').length > 0) {
                    indexCar++
                }
                data[2] = [indexCar, 1];
            }
            if (isCommutesCardOn === true) {
                if (sessionStorage.getItem('professionalCommutes') && sessionStorage.getItem('professionalCommutes') > 0) {
                    indexCommutes++
                }
                data[1] = [indexCommutes, 1];
            }
            if (isMealVouchersCardOn === true) {
                if (sessionStorage.getItem('mealVouchers') && sessionStorage.getItem('mealVouchers') > 0) {
                    indexMeal++
                }
                data[3] = [indexMeal, 1];
            }
            if (isPropertyCardOn === true) {
                if (sessionStorage.getItem('cadastralIncome') && sessionStorage.getItem('cadastralIncome') > 0) {
                    indexProperty++
                }
                if (sessionStorage.getItem('propertyOfficePercent') && sessionStorage.getItem('propertyOfficePercent').length > 0) {
                    indexProperty++
                }
                data[0] = [indexProperty, 1];
            }
            setFactorData(data);
        }, 500);
    }

    const handlePlaceholder = (name, description, price, type, active) => {
        setPlaceholderName(name);
        setPlaceholderDescription(description);
        setPlaceholderPrice(price);
        setPlaceholderType(type);
        setPlaceholderActive(active)
    }

    return (
        <>
            <div className="remuneration">
                <div className="row">
                    <div className="container__body establishmentFlow">
                        <div className="container__body-header"><Trans>establishmentFlow.payday</Trans></div>
                        <div className="container__body-box">
                            <div className="container__body-input">
                                <p className="container__body-text"><Trans>establishmentFlow.net</Trans></p>
                                <TextField InputProps={{
            startAdornment: netAmountMonth  ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }}  label={''} variant={'outlined'} size={'small'} placeholder={'€...'} value={netAmountMonth} onChange={(e) => handleAmountMonth(e.target.value)} />
                            </div>
                            <div className={props.validate[4] === true ? "container__body-input is-error" : "container__body-input"}>
                                <p className="container__body-text"><Trans>establishmentFlow.when</Trans></p>
                                <DatePicker
                                    id='dateStart'
                                    placeholderText={localStorage.getItem('i18nextLng') === 'en' ? 'Select a date' : 'Selecteer een datum'}
                                    selected={startDate}
                                    onChange={(date) => handleDate(date)}
                                    minDate={new Date(today)}
                                    dateFormat="dd/MM/yyyy"
                                    autoComplete="off"
                                    onKeyDown={e => e.preventDefault()}
                                />
                                <div className="calendarIcon">
                                    {calendar}
                                </div>
                                {props.validate[4] === true ?
                                    <div className="error-text"><Trans>establishmentFlow.valid</Trans></div>
                                :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="container__sidebar">
                        <div className="container__sidebar-header"><Trans>establishmentFlow.rundown</Trans>
                            <Tooltip title={<Trans>establishmentFlow.tooltip4</Trans>} placement={'right'}>
                                <IconButton aria-label="delete">
                                    {InfoIcon}
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="container__sidebar-text"><Trans>establishmentFlow.wage</Trans><b> €{netAmountMonth}</b>, <Trans>establishmentFlow.gross</Trans> <b>€{grossAmountMonth}</b>.</div>
                        <button className="btn btn--primary" onClick={() => handleFactors(true, localStorage.getItem('i18nextLng') === 'en' ? 'Detailed calculation' : 'Gedetailleerde berekening', `In order to have a net monthly wage of €${sessionStorage.getItem('netAmount') ? sessionStorage.getItem('netAmount') : ' ---'}, you need to pay yourself a gross wage of at least €${sessionStorage.getItem('grossAmount') ? sessionStorage.getItem('grossAmount') : ' ---'}.`, 5)}><Trans>establishmentFlow.calculation</Trans></button>
                    </div>
                </div>
                <div className="row">
                    <div className="container__body establishmentFlow">
                        <div className="container__body-header"><Trans>establishmentFlow.factors</Trans></div>
                        <div className="container__body-text"><Trans>establishmentFlow.enable</Trans></div>
                        <div className="row">
                            <div className={props.validate[0] === true && isPropertyCardOn === true ? "remuneration__box is-active is-error" : isPropertyCardOn === true ? "remuneration__box is-active" : "remuneration__box"}>
                                <div className="remuneration__switch">
                                    <CustomSmallSwitch checked={isPropertyCardOn} onChange={(e) => handlePropertyCardOn(e.target.checked)} />
                                </div>
                                <div className="remuneration__icon">
                                    {homeIcon}
                                </div>
                                <div className="remuneration__box-header"><Trans>establishmentFlow.renting</Trans></div>
                                <div className="remuneration__box-text"><Trans>establishmentFlow.rent</Trans></div>
                                <button className={isPropertyCardOn === true ? "btn btn--primary" : "btn btn--primary hide"} onClick={() => handleFactors(true, <Trans>establishmentFlow.boxTitle</Trans>, <Trans>establishmentFlow.boxDescription</Trans>, 1, isPropertyCardOn)}><Trans>establishmentFlow.open</Trans></button>
                                <div className={isPropertyCardOn === true ? "remuneration__footer" : "remuneration__footer hide"}>
                                    <div className="box">
                                        <div className="remuneration__box-header">{factorData[0][0]}/2</div>
                                        <div className="remuneration__box-text"><Trans>establishmentFlow.filled</Trans></div>
                                    </div>
                                    <div className={factorData[0][0] === 2 ? "btn btn--secondary complete" : "btn btn--secondary pending"}>{factorData[0][0] === 2 ? <Trans>admin.complete</Trans> : <Trans>admin.pending</Trans>}</div>
                                </div>
                                {props.validate[0] === true && isPropertyCardOn === true  ?
                                    <div className="error-text"><Trans>establishmentFlow.validFactor</Trans></div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className={props.validate[1] === true && isCommutesCardOn === true ? "remuneration__box is-active is-error" : isCommutesCardOn === true ? "remuneration__box is-active" : "remuneration__box"}>
                                {isCommutesCardOn === true && isMealVouchersCardOn === true ?
                                    <div className="tooltip">
                                        <Tooltip
                                            title={<Trans>establishmentFlow.tooltip5</Trans>}
                                            placement={'right'}>
                                            <IconButton aria-label="delete">
                                                {redInfoIcon}
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                :
                                    <></>
                                }
                                <div className="remuneration__switch">
                                    <CustomSmallSwitch checked={isCommutesCardOn} onChange={(e) => handleCommutesCardOn(e.target.checked)} />
                                </div>
                                <div className="remuneration__icon">
                                    {routeIcon}
                                </div>
                                <div className="remuneration__box-header"><Trans>establishmentFlow.commutes</Trans></div>
                                <div className="remuneration__box-text"><Trans>establishmentFlow.free</Trans></div>
                                <button className={isCommutesCardOn === true ? "btn btn--primary" : "btn btn--primary hide"} onClick={() => handleFactors(true, <Trans>establishmentFlow.boxTitle2</Trans>, <Trans>establishmentFlow.boxDescription2</Trans>, 2, isCommutesCardOn)}><Trans>establishmentFlow.open</Trans></button>
                                <div className={isCommutesCardOn === true ? "remuneration__footer" : "remuneration__footer hide"}>
                                    <div className="box">
                                        <div className="remuneration__box-header">{factorData[1][0]}/1</div>
                                        <div className="remuneration__box-text"><Trans>establishmentFlow.filled</Trans></div>
                                    </div>
                                    <div className={factorData[1][0] === 1 ? "btn btn--secondary complete" : "btn btn--secondary pending"}>{factorData[1][0] === 1 ? <Trans>admin.complete</Trans> : <Trans>admin.pending</Trans>}</div>
                                </div>
                                {props.validate[1] === true && isCommutesCardOn === true ?
                                    <div className="error-text"><Trans>establishmentFlow.validFactor</Trans></div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className={props.validate[2] === true && isCarCardOn === true ? "remuneration__box is-active is-error" : isCarCardOn === true ? "remuneration__box is-active" : "remuneration__box"}>
                                <div className="remuneration__switch">
                                    <CustomSmallSwitch checked={isCarCardOn} onChange={(e) => handleCarCardOn(e.target.checked)} />
                                </div>
                                <div className="remuneration__icon">
                                    {carIcon}
                                </div>
                                <div className="remuneration__box-header"><Trans>establishmentFlow.companyCar</Trans></div>
                                <div className="remuneration__box-text"><Trans>establishmentFlow.taxed</Trans></div>
                                <button className={isCarCardOn === true ? "btn btn--primary" : "btn btn--primary hide"} onClick={() => handleFactors(true, <Trans>establishmentFlow.boxTitle3</Trans>, <Trans>establishmentFlow.boxDescription3</Trans>, 3, isCarCardOn)}><Trans>establishmentFlow.open</Trans></button>
                                <div className={isCarCardOn === true ? "remuneration__footer" : "remuneration__footer hide"}>
                                    <div className="box">
                                        <div className="remuneration__box-header">{factorData[2][0]}/5</div>
                                        <div className="remuneration__box-text"><Trans>establishmentFlow.filled</Trans></div>
                                    </div>
                                    <div className={factorData[2][0] === 5 ? "btn btn--secondary complete" : "btn btn--secondary pending"}>{factorData[2][0] === 5 ? <Trans>admin.complete</Trans> : <Trans>admin.pending</Trans>}</div>
                                </div>
                                {props.validate[2] === true && isCarCardOn === true ?
                                    <div className="error-text"><Trans>establishmentFlow.validFactor</Trans></div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className={props.validate[3] === true && isMealVouchersCardOn === true ? "remuneration__box is-active is-error" : isMealVouchersCardOn === true ? "remuneration__box is-active" : "remuneration__box"}>
                                {isCommutesCardOn === true && isMealVouchersCardOn === true ?
                                    <div className="tooltip">
                                        <Tooltip
                                            title={<Trans>establishmentFlow.tooltip5</Trans>}
                                            placement={'right'}>
                                            <IconButton aria-label="delete">
                                                {redInfoIcon}
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                :
                                    <></>
                                }
                                <div className="remuneration__switch">
                                    <CustomSmallSwitch checked={isMealVouchersCardOn} onChange={(e) => handleMealVouchersCardOn(e.target.checked)} />
                                </div>
                                <div className="remuneration__icon">
                                    {appleIcon}
                                </div>
                                <div className="remuneration__box-header"><Trans>establishmentFlow.meal</Trans></div>
                                <div className="remuneration__box-text"><Trans>establishmentFlow.oneMeal</Trans></div>
                                <button className={isMealVouchersCardOn === true ? "btn btn--primary" : "btn btn--primary hide"} onClick={() => handleFactors(true, <Trans>establishmentFlow.boxTitle4</Trans>, <Trans>establishmentFlow.boxDescription4</Trans>, 4, isMealVouchersCardOn)}><Trans>establishmentFlow.open</Trans></button>
                                <div className={isMealVouchersCardOn === true ? "remuneration__footer" : "remuneration__footer hide"}>
                                    <div className="box">
                                        <div className="remuneration__box-header">{factorData[3][0]}/1</div>
                                        <div className="remuneration__box-text"><Trans>establishmentFlow.filled</Trans></div>
                                    </div>
                                    <div className={factorData[3][0] === 1 ? "btn btn--secondary complete" : "btn btn--secondary pending"}>{factorData[3][0] === 1 ? <Trans>admin.complete</Trans> : <Trans>admin.pending</Trans>}</div>
                                </div>
                                {props.validate[3] === true && isMealVouchersCardOn === true ?
                                    <div className="error-text"><Trans>establishmentFlow.validFactor</Trans></div>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="box">
                                <div className="container__sidebar-header"><Trans>establishmentFlow.benefits</Trans>
                                    <Tooltip title={<Trans>establishmentFlow.tooltip6</Trans>} placement={'right'}>
                                        <IconButton aria-label="delete">
                                            {InfoIcon}
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="container__sidebar-text"><Trans>establishmentFlow.companyPay</Trans> <br /><Trans>establishmentFlow.applicable</Trans>.</div>
                                <div className="row">
                                    <div className="checkbox">
                                        <FormControlLabel label="" control={
                                            <Checkbox color={'#4A5BCF'} checked={smartphone} onChange={(e) => handleDevice('smartphone', e.target.checked, 3)} />
                                        } />
                                        <div className="checkbox__label" onMouseEnter={() => handlePlaceholder('Smartphone', '', 3, 'benefit', true)} onMouseLeave={() => handlePlaceholder('Smartphone', '', 3, 'benefit', false)}>Smartphone</div>
                                    </div>
                                    <div className="checkbox">
                                        <FormControlLabel label="" control={
                                            <Checkbox color={'#4A5BCF'} checked={tablet} onChange={(e) => handleDevice('tablet', e.target.checked, 3)} />
                                        } />
                                        <div className="checkbox__label" onMouseEnter={() => handlePlaceholder('Tablet', '', 3, 'benefit', true)} onMouseLeave={() => handlePlaceholder('Tablet', '', 3, 'benefit', false)}>Tablet</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="checkbox">
                                        <FormControlLabel label="" control={
                                            <Checkbox color={'#4A5BCF'} checked={smartphonePlan} onChange={(e) => handleDevice('smartphonePlan', e.target.checked, 50)} />
                                        } />
                                        <div className="checkbox__label" onMouseEnter={() => handlePlaceholder('Smartphone plan', '', 4, 'benefit', true)} onMouseLeave={() => handlePlaceholder(localStorage.getItem('i18nextLng') === 'en' ? 'Smartphone plan' : 'Smartphone-abonnement', '', 4, 'benefit', false)}>{localStorage.getItem('i18nextLng') === 'en' ? 'Smartphone plan' : 'Smartphone-abonnement'}</div>
                                    </div>
                                    <div className="checkbox">
                                        <FormControlLabel label="" control={
                                            <Checkbox color={'#4A5BCF'} checked={internet} onChange={(e) => handleDevice('internetSubscription', e.target.checked, 50)} />
                                        } />
                                        <div className="checkbox__label" onMouseEnter={() => handlePlaceholder(localStorage.getItem('i18nextLng') === 'en' ? 'Internet subscription' : 'Internet abonnement', '', 5, 'benefit', true)} onMouseLeave={() => handlePlaceholder(localStorage.getItem('i18nextLng') === 'en' ? 'Internet subscription' : 'Internet abonnement', '', 5, 'benefit', false)}>{localStorage.getItem('i18nextLng') === 'en' ? 'Internet subscription' : 'Internet abonnement'}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="checkbox">
                                        <FormControlLabel label="" control={
                                            <Checkbox color={'#4A5BCF'} checked={computer} onChange={(e) => handleDevice('computer', e.target.checked, 6)} />
                                        } />
                                        <div className="checkbox__label" onMouseEnter={() => handlePlaceholder('Computer', '', 6, 'benefit', true)} onMouseLeave={() => handlePlaceholder('Computer', '', 6, 'benefit', false)}>Computer</div>
                                    </div>
                                </div>
                            </div>
                            <div className="box">
                                <div className="container__sidebar-header"><Trans>establishmentFlow.reimbursements</Trans>
                                    <Tooltip title="These net reimbursements will be added to your remuneration package." placement={'right'}>
                                        <IconButton aria-label="delete">
                                            {InfoIcon}
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="container__sidebar-text"><Trans>establishmentFlow.following</Trans> <br /><Trans>establishmentFlow.applicable</Trans>.</div>
                                <div className="checkbox">
                                    <FormControlLabel label="" control={
                                        <Checkbox color={'#4A5BCF'} checked={garage} onChange={(e) => handleDevice('garageCost', e.target.checked, 50)} />
                                    } />
                                    <div className="checkbox__label" onMouseEnter={() => handlePlaceholder(localStorage.getItem('i18nextLng') === 'en' ? 'Car costs: garage' : 'Autokosten: garage', 'May be granted only to the extent that the obligation to safely park the vehicle is imposed on all employees in the same condition. It does not matter whether the employee owns the garage or not.', 50, 'net', true)} onMouseLeave={() => handlePlaceholder(localStorage.getItem('i18nextLng') === 'en' ? 'Car costs: garage' : 'Autokosten: garage', '', 50, 'net', false)}>{localStorage.getItem('i18nextLng') === 'en' ? 'Car costs: garage' : 'Autokosten: garage'}</div>
                                </div>
                                <div className="checkbox">
                                    <FormControlLabel label="" control={
                                        <Checkbox color={'#4A5BCF'} checked={parking} onChange={(e) => handleDevice('parkingCost', e.target.checked, 15)} />
                                    } />
                                    <div className="checkbox__label" onMouseEnter={() => handlePlaceholder(localStorage.getItem('i18nextLng') === 'en' ? 'Car costs: parking' : 'Autokosten: parkeren', 'May be granted only to the extent that the obligation to safely park the vehicle is imposed on all employees in the same condition. It does not matter whether the employee owns the garage or not.', 15, 'net', true)} onMouseLeave={() => handlePlaceholder(localStorage.getItem('i18nextLng') === 'en' ? 'Car costs: parking' : 'Autokosten: parkeren', '', 15, 'net', false)}>{localStorage.getItem('i18nextLng') === 'en' ? 'Car costs: parking' : 'Autokosten: parkeren'}</div>
                                </div>
                                <div className="checkbox">
                                    <FormControlLabel label="" control={
                                        <Checkbox color={'#4A5BCF'} checked={carWash} onChange={(e) => handleDevice('carWashCost', e.target.checked, 15)} />
                                    } />
                                    <div className="checkbox__label" onMouseEnter={() => handlePlaceholder(localStorage.getItem('i18nextLng') === 'en' ? 'Car costs: carwash' : 'Autokosten: carwash', 'May be granted only to the extent that the obligation to safely park the vehicle is imposed on all employees in the same condition. It does not matter whether the employee owns the garage or not.', 15, 'net', true)} onMouseLeave={() => handlePlaceholder(localStorage.getItem('i18nextLng') === 'en' ? 'Car costs: carwash' : 'Autokosten: carwash', '', 15, 'net', false)}>{localStorage.getItem('i18nextLng') === 'en' ? 'Car costs: carwash' : 'Autokosten: carwash'}</div>
                                </div>
                            </div>
                            {placeholderActive === true ?
                                <div className={placeholderType === 'benefit' ? "placeholder right" : "placeholder"}>
                                    <div className="box">
                                        <div className="placeholder__name">{placeholderName}</div>
                                        <div className="placeholder__description">{placeholderDescription}</div>
                                    </div>
                                    <div
                                        className="placeholder__price">{`€${placeholderPrice} / month ${placeholderType === 'benefit' ? 'benefit in kind' : 'net reimbursement'}`}</div>
                                </div>
                            :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Factors
                active={factors}
                handleFactors={handleFactors}
                header={factorHeader}
                text={factorText}
                factor={factor}
                handleSave={save}
                usedOffice={usedOffice}
                handleUsedOffice={setUsedOffice}
                defaultBrand={defaultBrand}
                handleDefaultBrand={setDefaultBrand}
                defaultModel={defaultModel}
                handleDefaultModel={setDefaultModel}
                defaultFuel={defaultFuel}
                handleDefaultFuel={setDefaultFuel}
                defaultTrimline={defaultTrimline}
                handleDefaultTrimline={setDefaultTrimline}
                initialStartDate={initialStartDate}
                handleInitialStartDate={setInitialStartDate}
                isOverFourtyCommutes={isOverFourtyCommutes}
                handleIsOverFourtyCommutes={setIsOverFourtyCommutes}
                commutes={commutes}
                handleCommutes={setCommutes}
                workingDays={workingDays}
                handleWorkingDays={setWorkingDays}
                rentingProperty={rentingProperty}
                handleRentingProperty={setRentingProperty}
            />
        </>
    );
};

export default Remuneration;