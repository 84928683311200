import React, { useState, useEffect } from "react";
import { editIcon, removeIcon } from "../login/loginConstants";
import {FormControl, FormControlLabel, Radio, TextareaAutosize, TextField, InputAdornment} from "@material-ui/core";
import Confirm from "../confirm/confirm";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import DatePicker from "react-datepicker";
import {calendar} from "../survey/surveyConstant";
import RadioGroup from "@material-ui/core/RadioGroup";
import './loans.scss';
import {Trans} from "react-i18next";
import {transformDate} from "../admin/adminEstablishment";

const Loans = () => {
    const [data, setData] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [form, setForm] = useState({
        loanAmount: '',
        startingDate: '',
        description: '',
        capital: '',
        annual: '',
    });
    const [startDate, setStartDate] = useState(null);
    const [today, setToday] = useState('');
    const [editId, setEditId] = useState(0);
    const [uponEstablishment, setUponEstablishment] = useState(true);

    useEffect(() => {
        let date = new Date();
        const today = `${date.getFullYear()}-${date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
        setToday(today);
        getRes();
        let body = {
            checkpointCategory: JSON.parse(sessionStorage.getItem('checkpointCategoryEstablishment'))[sessionStorage.getItem('legalForm') === 'LIMITED_COMPANY' ? 7 : 4],
            completed: 1,
            required: 1,
            isChange: true
        };
        Connect(`${Endpoint.putProgressSurvey}`, 'PUT', body).then(response => {
            console.log(response);
        });
    }, []);

    const getRes = () => {
        Connect(`${Endpoint.getUserLoans}`, 'GET').then((response) => {
            setData(response);
            setForm({
                loanAmount: '',
                startingDate: '',
                description: '',
                capital: '',
                annual: ''
            })
            document.querySelector('#dateStart').value = null;
        });
    };

    const handleDate = (date) => {
        setStartDate(date);
        sessionStorage.setItem('startDate', date);
    };

    const handleData = (param, value) => {
        setForm({
            ...form,
            [param]: (param === 'loanAmount' || param === 'capital') ? addCommas(removeNonNumeric(value)) : value,
        });
    };

    const handleConfirm = (value) => {
        setConfirm(value);
    };

    const confirmRemove = (index, pid) => {
        handleConfirm(true);
        sessionStorage.setItem('indexDelete', pid);
    };

    const remove = (index) => {
        let currentData = data;
        handleConfirm(false);
        Connect(`${Endpoint.getUserLoans}/${sessionStorage.getItem('indexDelete')}`, 'DELETE').then((response) => {
            getRes();
        });
        currentData.splice(index, 1);
        setData(currentData);
        localStorage.setItem('loans', JSON.stringify(currentData));
    };

    const edit = (id, amount, startDate, description, capital, annual) => {
        setEditId(id);
        setTimeout(() => {
            setForm({
                loanAmount: addCommas(removeNonNumeric(amount)),
                startingDate: startDate,
                description: description,
                capital: addCommas(removeNonNumeric(capital)),
                annual: annual
            })
            document.querySelector('#dateStart').value = startDate;
        }, 100);
    };

    const save = () => {
        let currentData = data;
        let loansValue = document.querySelector('input[name="loansValue"]').value;
        let loansDescription = document.querySelector('textarea[name="loansDescription"]').value;
        let loansDate = startDate;
        let loansAnnual = document.querySelector('input[name="loansAnnual"]').value;
        let body = {
            amount: parseInt(loansValue.replaceAll('.', '')),
            startDate: loansDate,
            description: loansDescription,
            capital: parseInt(loansValue.replaceAll('.', '')),
            annualChargePercentage: loansAnnual,
        };
        if (editId !== 0) {
            body = {
                id: parseInt(editId),
                amount: parseInt(loansValue.replaceAll('.', '')),
                startDate: loansDate,
                description: loansDescription,
                capital: parseInt(loansValue.replaceAll('.', '')),
                annualChargePercentage: loansAnnual,
            };
            Connect(`${Endpoint.getUserLoans}`, 'PUT', body).then((response) => {
                getRes();
                setEditId(0);
            });
        } else {
            Connect(`${Endpoint.getUserLoans}`, 'POST', body).then((response) => {
                getRes();
            });
        }
    };

    const addCommas = (num) => {
        if (num.length > 1) {
            if (num[0] === '0') {
                num = num.slice(1);
            }
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    };

    const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '');

    return (
        <>
            <div className="container__body establishmentFlow revenue loans">
                <div className="container__body-header"><Trans>establishmentFlowMenu.loans</Trans></div>
                <div className="container__body-box d-none">
                    <div className="container__body-input">
                        <p className="container__body-text"><Trans>establishmentFlow.loans</Trans></p>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="otherShareholders"
                                defaultValue={'No'}
                            >
                                <div className="container__body-radio" onClick={() => setUponEstablishment(true)}>
                                    <FormControlLabel value="Yes" control={<Radio />} label={<Trans>establishmentFlow.yes</Trans>} className="container__body-text" checked={uponEstablishment} />
                                </div>
                                <div className={"container__body-radio"} onClick={() => setUponEstablishment(false)}>
                                    <FormControlLabel value="No" control={<Radio />} label={<Trans>establishmentFlow.no</Trans>} className="container__body-text" checked={!uponEstablishment} />
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                {data.map((data, index) => (
                    <div className="establishmentFlow__box" key={data.id}>
                        <div className="row space-between">
                            <div className="box actions">
                                <div className="edit" onClick={() => edit(data.id, data.amount, data.startDate && transformDate(data.startDate.substring(8,10), data.startDate.substring(5,7), data.startDate.substring(0,4)), data.description, data.capital, data.annualChargePercentage)}>
                                    {editIcon}
                                </div>
                                <div className="remove" onClick={() => confirmRemove(index, data.id)}>
                                    {removeIcon}
                                </div>
                            </div>
                        </div>
                        <div className="row space-between subheader">
                            <p><Trans>establishmentFlow.startDate</Trans></p>
                            <p><Trans>establishmentFlow.cap</Trans></p>
                            <p><Trans>establishmentFlow.description</Trans></p>
                            <p><Trans>establishmentFlow.annual</Trans></p>
                        </div>
                        <div className="row space-between">
                            <p className="award">{data.startDate && transformDate(data.startDate.substring(8,10), data.startDate.substring(5,7), data.startDate.substring(0,4))}</p>
                            <p className="award">€{addCommas(removeNonNumeric(data.capital))}</p>
                            <p className="award">{data.description}</p>
                            <p className="price">{data.annualChargePercentage} %</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="container__body container__sidebar revenue" data-id={editId}>
                <div className="container__body-input">
                    <p className="container__body-text"><Trans>establishmentFlow.loanAmount</Trans></p>
                    <TextField InputProps={{
            startAdornment: form.loanAmount ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }} label={''} variant={'outlined'} size={'small'} placeholder={'€...'} value={form.loanAmount} onChange={(e) => handleData('loanAmount', e.target.value)} />
                </div>
                <div className="container__body-input">
                    <p id="loansDate" className="container__body-text">
                        <Trans>establishmentFlow.startDate</Trans>
                    </p>
                    <DatePicker
                        id='dateStart'
                        placeholderText="Select a date"
                        selected={startDate}
                        onChange={(date) => handleDate(date)}
                        minDate={new Date(today)}
                        dateFormat="dd/MM/yyyy"
                        autoComplete="off"
                        onKeyDown={e => e.preventDefault()}
                    />
                    <div className="calendarIcon">
                        {calendar}
                    </div>
                </div>
                <div className="container__body-textarea">
                    <p id="loansDescription" className="container__body-text">
                        <Trans>establishmentFlow.describe</Trans>
                    </p>
                    <TextareaAutosize minRows={5} maxRows={5} placeholder={'...'} name={'loansDescription'} value={form.description} onChange={(e) => handleData('description', e.target.value)} />
                </div>
                <div className="container__body-input">
                    <p id="loansValue" className="container__body-text">
                        <Trans>establishmentFlow.capIn</Trans>
                    </p>
                    <TextField InputProps={{
            startAdornment: form.capital  ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }} label={''} variant={'outlined'} size={'small'} placeholder={'€...'} name={'loansValue'} value={form.capital} onChange={(e) => handleData('capital', e.target.value)} />
                </div>
                <div className="container__body-input">
                    <p id="loansAnnual" className="container__body-text">
                        <Trans>establishmentFlow.percent</Trans>
                    </p>
                    <TextField type='number'  label={''} variant={'outlined'} size={'small'} placeholder={'%...'} name={'loansAnnual'} value={form.annual} onChange={(e) => handleData('annual', e.target.value)} />
                </div>
                <div className="row">
                    <button className="btn btn--primary" onClick={save}>
                        {editId !== 0 ? <Trans>establishmentFlow.update</Trans> : <Trans>establishmentFlow.add</Trans>}
                    </button>
                </div>
            </div>
            <Confirm active={confirm} handleConfirm={handleConfirm} handleRemove={remove} message={localStorage.getItem('i18nextLng') === 'en' ? 'Are you sure you want to delete loan?' : 'Weet u zeker dat u de lening wilt verwijderen?'} only={false} />
        </>
    );
};

export default Loans;
