import React  from "react";

const DocumentPdf = (props) => {
    const pdfViewerStyle = {
        width: "100%",
        height: "100%",
        border: "none",
        overflow: "hidden"
    };

    return (
        <iframe
            src={props.pdf}
            style={pdfViewerStyle}
        ></iframe>
    );
}

export default DocumentPdf;