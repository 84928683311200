import React, { useState, useEffect } from "react";
import { TextField, IconButton, Tooltip, InputAdornment } from "@material-ui/core";
import CustomSwitch from "../switch/switch";
import {InfoIcon} from "../survey/surveyConstant";
import step3 from '../../assets/step3.png';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    LineController,
    BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import {Trans} from "react-i18next";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    LineController,
    BarController
);

const labels = ['Y1', 'Y2', 'Y3'];

function Step3(props) {
    const [year, setYear] = useState(1);
    const [dataChart, setDataChart] = useState({
        labels,
        datasets: [
            {
                type: 'bar',
                label: 'Revenue',
                backgroundColor: '#4A5BCF',
                borderColor: 'EBF2FF',
                borderRadius: 25,
                data: [0, 0, 0]
            },
        ],
    });
    const [options] = useState({
        plugins: {
            legend: {
                display: false
            },
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                beginAtZero: false,
                ticks: {
                    color: '#9791BA'
                }
            },
            y: {
                grid: {
                    display: true,
                },
                beginAtZero: true,
                ticks: {
                    color: '#ADAFDC'
                }
            }
        },
    });

    useEffect(() => {
        let value = 1
        if (sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectNextYear && JSON.parse(sessionStorage.getItem('companyType')).expectNextYear[0] && JSON.parse(sessionStorage.getItem('companyType')).expectNextYear[0].text === 'Yes') {
            value = 2
        }
        if (sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear[0] && JSON.parse(sessionStorage.getItem('companyType')).expectThirdYear[0].text === 'Yes') {
            value = 3
        }
        setYear(value);

        let valueRevenue = props.data.revenue && props.data.revenue.replaceAll('.', '');
        let valueRevenueNextYear = props.data.revenueNextYear && props.data.revenueNextYear.replaceAll('.', '');
        let valueRevenueThirdYear = props.data.revenueThirdYear && props.data.revenueThirdYear.replaceAll('.', '');
        handleValue(valueRevenue, valueRevenueNextYear, valueRevenueThirdYear);
    }, [props.data.revenue, props.data.revenueNextYear, props.data.revenueThirdYear]);

    const handleValue = (value, value2, value3) => {
        setDataChart({
            labels,
            datasets: [
                {
                    type: 'bar',
                    label: 'Dataset',
                    backgroundColor: '#4A5BCF',
                    borderColor: 'EBF2FF',
                    borderRadius: 25,
                    data: [value * (sessionStorage.getItem('companyType') && sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).dateStart && 12 - parseInt(sessionStorage.getItem('companyType') && (JSON.parse(sessionStorage.getItem('companyType')).dateStart.substring(8,10) >= 15 ? JSON.parse(sessionStorage.getItem('companyType')).dateStart.substring(5,7) : (parseInt(JSON.parse(sessionStorage.getItem('companyType')).dateStart.substring(5,7)) - 1)))), value2 * 12, value3 * 12]
                },
            ],
        });
    }

    const handleRevenue = (option, value) => {
        let regExp = /[a-zA-Z]/g;
        if(regExp.test(value)) return;
        props.handleData(option, value);
        if (props.data.expectNextYear.length === 0 || (props.data.expectNextYear && props.data.expectNextYear.length > 0 && props.data.expectNextYear[0].text !== 'Yes')) {
            props.handleData('revenueNextYear', value);
        }
        if (props.data.expectThirdYear.length === 0 || (props.data.expectThirdYear && props.data.expectThirdYear.length > 0 && props.data.expectThirdYear[0].text !== 'Yes')) {
            props.handleData('revenueThirdYear', value);
        }
        let valueRevenue = option === 'revenue' ? value.replaceAll('.', '') : props.data.revenue.replaceAll('.', '');
        let valueRevenueNextYear = option === 'revenueNextYear' || props.data.revenueNextYear === '' ? value !== undefined && value.replaceAll('.', '') : props.data.revenueNextYear !== undefined && props.data.revenueNextYear.replaceAll('.', '');
        let valueRevenueThirdYear = option === 'revenueThirdYear' || props.data.revenueThirdYear === '' ? value !== undefined && value.replaceAll('.', '') : props.data.revenueThirdYear !== undefined && props.data.revenueThirdYear.replaceAll('.', '');
        handleValue(valueRevenue, valueRevenueNextYear, valueRevenueThirdYear);
    }

    const handleYear = (value, checked, pid, type) => {
        let target = 'No';
        let key = 'question-option-no';
        if (checked === true) {
            setYear(value);
            sessionStorage.setItem('year', value);
            target = 'Yes';
            key = 'question-option-yes';
        } else {
            setYear(value - 1);
            sessionStorage.setItem('year', value - 1);
            if (value === 3) {
                props.handleData('revenueThirdYear', props.data.revenueNextYear);
            } else {
                props.handleData('revenueNextYear', props.data.revenue);
                props.handleData('revenueThirdYear', props.data.revenue);
            }
        }
        props.handleData(type, [{id: pid, text: target, textKey: key }]);
        let valueRevenue = props.data.revenue.replaceAll('.', '');
        let valueRevenueNextYear = props.data.revenueNextYear && props.data.revenueNextYear.replaceAll('.', '');
        let valueRevenueThirdYear = props.data.revenueThirdYear && props.data.revenueThirdYear.replaceAll('.', '');
        handleValue(valueRevenue, valueRevenueNextYear, valueRevenueThirdYear);
    }

    return (
        <div className="container__body">
            <div className="container__body-header"><Trans>companyType.estimate</Trans>
                <Tooltip title={<Trans>companyType.tooltip5</Trans>} placement={'right'}>
                    <IconButton aria-label="delete">
                        {InfoIcon}
                    </IconButton>
                </Tooltip>
            </div>
            <div className="container__body-box">
                <div className={props.validateForm.revenue !== undefined && props.validateForm.revenue === 'false' ? "container__body-input is-error" : "container__body-input"}>
                    <p className="container__body-text">{sessionStorage.getItem('companyTypeData') && JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[2].steps[0].questions[0].questionText}</p>
                    <TextField InputProps={{
            startAdornment: props.data.revenue  ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }} label={''} variant={'outlined'} size={'small'} type={'text'} placeholder={'€...'} value={props.data.revenue} onChange={(e) => handleRevenue('revenue', e.target.value)} />
                </div>
                <div className="container__body-input">
                    <p className="container__body-text">{sessionStorage.getItem('companyTypeData') && JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[2].steps[0].questions[1].questionText}</p>
                    <CustomSwitch onClick={(e) => handleYear(2, e.target.checked, e.target.checked === true ? 535 : 534, 'expectNextYear')} checked={year >= 2 ? true : false} />
                </div>
            </div>
            {year > 1 ?
                <div className="container__body-box">
                    <div className={props.validateForm.revenueNextYear !== undefined && props.validateForm.revenueNextYear === 'false' ? "container__body-input is-error" : "container__body-input"}>
                        <p className="container__body-text">{sessionStorage.getItem('companyTypeData') && JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[2].steps[0].questions[2].questionText}</p>
                        <TextField InputProps={{
            startAdornment: props.data.revenueNextYear  ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }} label={''} variant={'outlined'} size={'small'} type={'text'} placeholder={'€...'} value={props.data.revenueNextYear} onChange={(e) => handleRevenue('revenueNextYear', e.target.value)} />
                    </div>
                    <div className="container__body-input">
                        <p className="container__body-text">{sessionStorage.getItem('companyTypeData') && JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[2].steps[0].questions[3].questionText}</p>
                        <CustomSwitch onClick={(e) => handleYear(3, e.target.checked, e.target.checked === true ? 541 : 540, 'expectThirdYear')} checked={year >= 3 ? true : false} />
                    </div>
                </div>
                :
                <></>
            }
            {year > 2 ?
                <div className="container__body-box">
                    <div className={props.validateForm.revenueThirdYear !== undefined && props.validateForm.revenueThirdYear === 'false' ? "container__body-input is-error" : "container__body-input"}>
                        <p className="container__body-text">{sessionStorage.getItem('companyTypeData') && JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[2].steps[0].questions[4].questionText}</p>
                        <TextField InputProps={{
            startAdornment: props.data.revenueThirdYear  ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }} label={''} variant={'outlined'} size={'small'} type={'text'} placeholder={'€...'} value={props.data.revenueThirdYear} onChange={(e) => handleRevenue('revenueThirdYear', e.target.value)} />
                    </div>
                </div>
                :
                <></>
            }
            {(props.validateForm.revenue !== undefined && props.validateForm.revenue === 'false') || (props.validateForm.revenueNextYear !== undefined && props.validateForm.revenueNextYear === 'false') || (props.validateForm.revenueThirdYear !== undefined && props.validateForm.revenueThirdYear === 'false') ?
                <p className="errorMessage"><Trans>companyType.valid</Trans></p>
                :
                <></>}
            <img className="step3Image" alt={'step'} src={step3} />
            <div className="chart">
                <Chart type={'bar'} data={dataChart} options={options} />
            </div>
        </div>
    );
}

export default Step3;
