// Add number status with base behavior

import {Trans} from "react-i18next";
import React from "react";

const checkMsg = (status) => {

    switch(status){
        case 1: return {'severity': 'info', 'msg': <Trans>messages.validations</Trans>};
        case 3: return {'severity': 'error', 'msg': <Trans>messages.wrong</Trans>};
        case 4: return {'severity': 'info', 'msg': <Trans>messages.unread</Trans>};
        case 5: return {'severity': 'info', 'msg': <Trans>messages.email</Trans>};
        case 6: return {'severity': 'success', 'msg': <Trans>messages.check-mail</Trans>};
        default: return {'severity': 'success', 'msg': <Trans>messages.success</Trans>};
    }

}

export default checkMsg;