import React, { useState, useEffect, Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { InfoIcon } from "../survey/surveyConstant";
import { TextField } from "@material-ui/core";
import CustomSwitch from "../switch/switch";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import CustomSelect from "../customSelect/customSelect";
import {Trans} from "react-i18next";
import LimitedCompany from "./limitedCompany";

class CompanyDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            operatingAddress: false,
            limitedCompany: false,
            data: {
                operationCity: sessionStorage.getItem('companyDetails') !== null ? JSON.parse(sessionStorage.getItem('companyDetails')).operationCity : '',
                operationHouseNumber: sessionStorage.getItem('companyDetails') !== null ? JSON.parse(sessionStorage.getItem('companyDetails')).operationHouseNumber : '',
                operationPostalCode: sessionStorage.getItem('companyDetails') !== null ? JSON.parse(sessionStorage.getItem('companyDetails')).operationPostalCode : '',
                operationStreet: sessionStorage.getItem('companyDetails') !== null ? JSON.parse(sessionStorage.getItem('companyDetails')).operationStreet : '',
                operationRegion: sessionStorage.getItem('companyDetails') !== null ? JSON.parse(sessionStorage.getItem('companyDetails')).operationRegion : '',
                registrationCity: sessionStorage.getItem('companyDetails') !== null ? JSON.parse(sessionStorage.getItem('companyDetails')).registrationCity : '',
                registrationHouseNumber: sessionStorage.getItem('companyDetails') !== null ? JSON.parse(sessionStorage.getItem('companyDetails')).registrationHouseNumber : '',
                registrationPostalCode: sessionStorage.getItem('companyDetails') !== null ? JSON.parse(sessionStorage.getItem('companyDetails')).registrationPostalCode : '',
                registrationStreet: sessionStorage.getItem('companyDetails') !== null ? JSON.parse(sessionStorage.getItem('companyDetails')).registrationStreet : '',
                registrationRegion: sessionStorage.getItem('companyDetails') !== null ? JSON.parse(sessionStorage.getItem('companyDetails')).registrationRegion : ''
            },
            selectList: [
                { id: 1, name: localStorage.getItem('i18nextLng') === 'en' ? 'Flanders' : 'Vlaanderen', value: localStorage.getItem('i18nextLng') === 'en' ? 'Flanders' : 'Vlaanderen' },
                { id: 2, name: localStorage.getItem('i18nextLng') === 'en' ? 'Brussels' : 'Brussel', value: localStorage.getItem('i18nextLng') === 'en' ? 'Brussels' : 'Brussel' },
                { id: 3, name: localStorage.getItem('i18nextLng') === 'en' ? 'Wallonia' : 'Wallonië', value: localStorage.getItem('i18nextLng') === 'en' ? 'Wallonia' : 'Wallonië' }
            ]
        }
    }

    componentDidMount = () => {
        Connect(`${Endpoint.companyDetails}`, 'GET').then(response => {
            const updatedData = {
                operationCity: response.operationCity,
                operationHouseNumber: response.operationHouseNumber,
                operationPostalCode: response.operationPostalCode,
                operationStreet: response.operationStreet,
                operationRegion: response.operationRegion,
                registrationCity: response.registrationCity,
                registrationHouseNumber: response.registrationHouseNumber,
                registrationPostalCode: response.registrationPostalCode,
                registrationStreet: response.registrationStreet,
                registrationRegion: response.registrationRegion
            };
            this.setState({
                data: updatedData
            })
            sessionStorage.setItem('financialCalendar', JSON.stringify(response.financialCalendar));
            sessionStorage.setItem('companyDetails', JSON.stringify(updatedData));
            if (response.legalForm === null) {
                this.props.setLimitedCompany(true);
            }
        });
        let body = {
            checkpointCategory: JSON.parse(sessionStorage.getItem('checkpointCategoryEstablishment'))[0],
            completed: 1,
            required: 1,
            isChange: true
        };
        Connect(`${Endpoint.putProgressSurvey}`, 'PUT', body).then(response => {
            console.log(response);
        });
    }

    alpha = (value) => {
        let res = value.replace(/[^A-Za-z -]/g, "");
        return res;
    };

    alphaAndNumbers = (value) => {
        let res = value.replace(/[^A-Za-z0-9]/g, "");
        return res;
    };

    handleData = (type, value) => {
        let currentData = JSON.parse(sessionStorage.getItem('companyDetails'));
        if (type === 'operationCity') {
            currentData.operationCity = this.alpha(value);
        }
        if (type === 'operationHouseNumber') {
            currentData.operationHouseNumber = this.alphaAndNumbers(value);
        }
        if (type === 'operationPostalCode') {
            currentData.operationPostalCode = value;
        }
        if (type === 'operationStreet') {
            currentData.operationStreet = value;
        }
        if (type === 'operationRegion') {
            currentData.operationRegion = value[0].text;
        }
        if (type === 'registrationCity') {
            currentData.registrationCity = this.alpha(value);
        }
        if (type === 'registrationHouseNumber') {
            currentData.registrationHouseNumber = this.alphaAndNumbers(value);
        }
        if (type === 'registrationPostalCode') {
            currentData.registrationPostalCode = value;
        }
        if (type === 'registrationStreet') {
            currentData.registrationStreet = value;
        }
        if (type === 'registrationRegion') {
            currentData.registrationRegion = value[0].text;
        }
        this.setState({
            data: currentData
        })
        sessionStorage.setItem('companyDetails', JSON.stringify(currentData));
    };

    handleOperatingAddress = (value) => {
        if (value === true) {
            const updatedData = {
                registrationCity: this.state.data.registrationCity,
                registrationHouseNumber: this.state.data.registrationHouseNumber,
                registrationPostalCode: this.state.data.registrationPostalCode,
                registrationStreet: this.state.data.registrationStreet,
                registrationRegion: this.state.data.registrationRegion,
                operationCity: this.state.data.registrationCity,
                operationHouseNumber: this.state.data.registrationHouseNumber,
                operationPostalCode: this.state.data.registrationPostalCode,
                operationStreet: this.state.data.registrationStreet,
                operationRegion: this.state.data.registrationRegion
            };
            this.setState({
                data: updatedData
            })
            setTimeout(() => {
                sessionStorage.setItem('companyDetails', JSON.stringify(updatedData));
            }, 10);
        } else {
            setTimeout(() => {
                sessionStorage.setItem('companyDetails', JSON.stringify(this.data));
            }, 10);
        }
        this.setState({
            operatingAddress: value
        })
    };

    handleLimitedCompany = (value) => {
        let body = {
            legalForm: value
        }
        Connect(`${Endpoint.companyDetails}`, 'PATCH', body).then(response => {
            if (body.legalForm == response.legalForm) {
                sessionStorage.setItem('legalForm', body.legalForm);
                this.props.setLimitedCompany(false);
            }
        })
    }
    render() {
        return (
            <>
                <div className="container__body establishmentFlow companyDetails">
                    <div className="container__body-header"><Trans>establishmentFlow.office</Trans>
                        <Tooltip title={<Trans>establishmentFlow.tooltip</Trans>} placement={'right'}>
                            <IconButton aria-label="delete">
                                {InfoIcon}
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="container__body-box">
                        <div className="container__body-input">
                            <p className="container__body-text"><Trans>establishmentFlow.street</Trans></p>
                            <TextField label={''} variant={'outlined'} size={'small'} placeholder={'Your street name'} value={this.state.data.registrationStreet} onChange={(e) => this.handleData('registrationStreet', e.target.value)} />
                        </div>
                        <div className="container__body-input">
                            <p className="container__body-text"><Trans>establishmentFlow.house</Trans></p>
                            <TextField label={''} variant={'outlined'} size={'small'} placeholder={'Your address cont.'} value={this.state.data.registrationHouseNumber} onChange={(e) => this.handleData('registrationHouseNumber', e.target.value)} />
                        </div>
                        <div className="container__body-input container__body-switcher" onClick={(e) => this.handleOperatingAddress(e.target.checked === true)}>
                            <p className="container__body-text"><Trans>establishmentFlow.setAddress</Trans></p>
                            <CustomSwitch checked={this.state.operatingAddress === true ? true : false} />
                        </div>
                    </div>
                    <div className="container__body-box">
                        <div className="container__body-input">
                            <p className="container__body-text"><Trans>establishmentFlow.postalCode</Trans></p>
                            <TextField label={''} variant={'outlined'} size={'small'} placeholder={'Your postal code'} value={this.state.data.registrationPostalCode} onChange={(e) => this.handleData('registrationPostalCode', e.target.value)} />
                        </div>
                        <div className="container__body-input">
                            <p className="container__body-text"><Trans>establishmentFlow.city</Trans></p>
                            <TextField label={''} variant={'outlined'} size={'small'} placeholder={'Your city'} value={this.state.data.registrationCity} onChange={(e) => this.handleData('registrationCity', e.target.value)} />
                        </div>
                    </div>
                    <div className="container__body-box">
                        <div className="container__body-select">
                            <p className="container__body-text"><Trans>establishmentFlow.region</Trans></p>
                            <CustomSelect
                                defaultText={this.state.data.registrationRegion !== null ? this.state.data.registrationRegion : '...'}
                                optionsList={this.state.selectList}
                                handleData={this.handleData}
                                type={'registrationRegion'}
                            />
                        </div>
                    </div>
                    <div id="operatingAddress">
                        <div className="container__body-header"><Trans>establishmentFlow.address</Trans>
                            <Tooltip title={<Trans>establishmentFlow.tooltip2</Trans>} placement={'right'}>
                                <IconButton aria-label="delete">
                                    {InfoIcon}
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="container__body-box">
                            <div className="container__body-input">
                                <p className="container__body-text"><Trans>establishmentFlow.street</Trans></p>
                                <TextField label={''} variant={'outlined'} disabled={this.state.operatingAddress === true ? true : false} size={'small'} placeholder={'Your address'} value={this.state.data.operationStreet} onChange={(e) => this.handleData('operationStreet', e.target.value)} />
                            </div>
                            <div className="container__body-input">
                                <p className="container__body-text"><Trans>establishmentFlow.house</Trans></p>
                                <TextField label={''} variant={'outlined'} disabled={this.state.operatingAddress === true ? true : false} size={'small'} placeholder={'Your house number'} value={this.state.data.operationHouseNumber} onChange={(e) => this.handleData('operationHouseNumber', e.target.value)} />
                            </div>
                        </div>
                        <div className="container__body-box">
                            <div className="container__body-input">
                                <p className="container__body-text"><Trans>establishmentFlow.postalCode</Trans></p>
                                <TextField label={''} variant={'outlined'} disabled={this.state.operatingAddress === true ? true : false} size={'small'} placeholder={'Your postal code'} value={this.state.data.operationPostalCode} onChange={(e) => this.handleData('operationPostalCode', e.target.value)} />
                            </div>
                            <div className="container__body-input">
                                <p className="container__body-text"><Trans>establishmentFlow.city</Trans></p>
                                <TextField label={''} variant={'outlined'} disabled={this.state.operatingAddress === true ? true : false} size={'small'} placeholder={'Your city'} value={this.state.data.operationCity} onChange={(e) => this.handleData('operationCity', e.target.value)} />
                            </div>
                        </div>
                        <div className="container__body-box">
                            <div className="container__body-select">
                                <p className="container__body-text"><Trans>establishmentFlow.region</Trans></p>
                                <CustomSelect
                                    defaultText={this.state.data.operationRegion !== null ? this.state.data.operationRegion : '...'}
                                    optionsList={this.state.selectList}
                                    handleData={this.handleData}
                                    type={'operationRegion'}
                                    disabled={this.state.operatingAddress === true ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <LimitedCompany active={this.props.limitedCompany} handleSave={this.props.handleLimitedCompany} handleLimitedCompany={this.props.setLimitedCompany} />
            </>
        );
    }
}

export default CompanyDetails;
