// Add user; Edit user

import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import DialogTitle from "@material-ui/core/DialogTitle";
import * as Endpoint from "../../configFile";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {SelectCustom} from "../select/select";
import {iconEdit} from "../support/supportConstant";
import validate from "../validate/validate";
import Messages from "../messages/messages";
import Cookies from "universal-cookie";
import {Trans} from "react-i18next";

export const useStylesModal = makeStyles(() => ({
    root: {
        padding: 0,
        marginBottom: '25px',
        color: '#37517E',
        '&>h2':{
            fontSize: '16px',
            fontWeight: '600',
            fontFamily: 'Inter, sans-serif',
        }
    },
    content:{
        fontSize: '16px',
        color: '#444445',
    },
    block: {
        padding: '0',
    },
    paper:{
        padding: '15px 30px',
    },
    paperMobile:{
        padding: '10px',
        margin: '10px'
    },
    action:{
        justifyContent: 'space-between',
        marginTop: '40px'
    },
    actionMobile:{
        justifyContent: 'center',
        marginTop: '10px'
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddUser = (props) => {
    const [open, setOpen] = useState(false);
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [ position, setPosition] = useState('');
    const [name, setName] = useState('');
    const [picture, setPicture] = useState('');
    const [idUser, setIdUser] = useState('');
    const [roles, setRoles] = useState('');
    const classes = useStylesModal();
    let file = null;
    const [states, setStates] = React.useState(0);
    let userId;

    const handleClickOpen = (e) => {
        setOpen(true);
        getRoles();
        props.isEdit && getData(e);
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const assignLogo = async (picture) => {
        // file = picture;
        file = `[${picture.name}]${await toBase64(picture)}`;
        console.log(file)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeInput = (e) => {
        const attributeName = e.target.getAttribute('name');
        const text = e.target.value;

        switch(attributeName){
            case 'name': setName(text); break;
            case 'surname': setSurname(text); break;
            case 'email': setEmail(text); break;
            case 'phone': setPhone(text); break;
            case 'position': setPosition(text); break;
            default: console.log('Nie znaleziono pola');
        }
    };

    const getRoles = () =>{
        fetch( Endpoint.getAllRolesNames, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token'),
            }
        })
            .then(res => res.json())
            .then( res => {
                    setRoles(res);
                },
            ).catch((error) => {
            console.log('Error:', error);
        })
    };

    const handleChangeSelect = (e) => {
        setPosition(e.value)
    }

    const getData = (e) => {
        userId = e.target.closest('.table--row').getAttribute('idcompany');
        setIdUser(userId)

        fetch( `${Endpoint.editUserData}/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token'),
            }
        })
            .then(res => res.json())
            .then( res => {
                    setName(res.name)
                    setSurname(res.surname)
                    setPhone(res.phone)
                    setEmail(res.email)
                    setPosition(res.position)
                    setPicture(res.avatar)
                },
            ).catch((error) => {
            console.log('Error:', error);
        })
    }

    const handleSave = () => {
        if(validate() !== 1) {
            setStates(1)
            setTimeout( () => setStates(0),3000);
            return true;
        }
        setOpen(false);

        const data = {id:idUser, avatar: file, name: name, surname: surname, email: email, phone: phone, position: position};
        const cookies = new Cookies();
        fetch( props.isEdit ? `${Endpoint.editUserData}` : Endpoint.addNewUser , {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN')
            },
            body: JSON.stringify(data),
        })
            // .then(response => response.json())
            .then(resp => {if (!resp.ok){
                throw resp
            }
                return resp.json()
            })
            .then(data => {
                console.log('Success:', data);
                // if editable user is current user
                if(idUser === document.querySelector('.user-info__name').getAttribute('id')){
                    console.log('current user')
                    document.querySelector('.user-info__name').innerHTML = name + ' ' + surname;
                    const avatar = document.querySelector('.user-info__avatar'),
                        avatarImg = document.querySelector('.user-info__avatar div');

                    if(avatarImg === null){
                        avatar.classList.remove('avatar--image')
                        avatar.innerHTML = `<div class="picture" style="background-image: url(${data.avatar})"/>`
                    } else {
                        avatarImg.setAttribute('style', `background-image: url(${data.avatar})`)
                    }
                }

                props.update(data,props.isEdit)
                setPosition('')
                setSurname('')
                setEmail('')
                setPhone('')
                setPosition('')
                setName('')
                file = null

                setStates(!props.isEdit ? 6 : 2)
                setTimeout( () => setStates(0),3000);
            })
            .catch((error) => {
                setStates(3)
                setTimeout( () => setStates(0),3000);
                console.log('Error:', error);
            });
    }


    return (
        <>

            {states !== 0 && <Messages status={states} />}

            {props.isEdit && <button className='icon--edit' onClick={handleClickOpen}>{iconEdit}</button>}

            {!props.isEdit && (props.isMobile ?
                <button className="btn--mobileAdd" onClick={handleClickOpen} />
                : <button className="btn--primary" onClick={handleClickOpen}><Trans>users.add-user</Trans></button>)
            }

            <Dialog
                fullWidth={false}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                classes={{paper: props.isMobile? classes.paperMobile : classes.paper}}
                >

                <DialogTitle id="userAdd" classes={{ root: classes.root }}><Trans>users.new-user</Trans></DialogTitle>

                <DialogContent classes={{root: classes.block}}>

                    <div className="input-group">
                        <label htmlFor="name"><Trans>profile.name</Trans></label>
                        <input type="text" name="name" id="name" value={name} onChange={handleChangeInput} required/>
                        <label className="label--error d-none"><Trans>basic.required</Trans></label>
                    </div>
                    <div className="input-group">
                        <label htmlFor="surname"><Trans>profile.surname</Trans></label>
                        <input type="text" name="surname" id="surname" value={surname} onChange={handleChangeInput} required />
                        <label className="label--error d-none"><Trans>basic.required</Trans></label>
                    </div>
                    <div className="input-group">
                        <label htmlFor="position"><Trans>profile.position</Trans></label>
                        <div className="select" required>
                            <SelectCustom options={roles}
                                          onChange={handleChangeSelect}
                                          selectedValue={{ value: position, label: position }}
                                          isEdit={props.isEdit} />
                        </div>
                        <label className="label--error d-none"><Trans>basic.required</Trans></label>
                    </div>
                    <div className="input-group">
                        <label htmlFor="phone"><Trans>profile.phone</Trans></label>
                        <input type="text" name="phone" id="phone" value={phone} onChange={handleChangeInput} required/>
                        <label className="label--error d-none"><Trans>basic.required</Trans></label>
                    </div>
                    <div className="input-group">
                        <label htmlFor="email"><Trans>profile.email</Trans></label>
                        <input type="email" name="email" id="email" value={email} onChange={handleChangeInput} required/>
                        <label className="label--error d-none"><Trans>basic.required</Trans></label>
                        <label className="label--error d-none badEmail">Incorrect mail</label>
                    </div>

                </DialogContent>

                <DialogActions classes={{root: props.isMobile ? classes.actionMobile : classes.action}}>
                    <button onClick={handleClose} className="btn--outline"><Trans>basic.cancel</Trans></button>
                    <button onClick={handleSave} className="btn--primary"><Trans>basic.save-2</Trans></button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AddUser;