import React, { useState } from 'react';
import { withRouter } from 'react-router';
import Logo from '../../assets/icon/logo.svg';
import { slider } from './login';
import { checkIcon, lockIcon, passwordEye } from './loginConstants';
import Cookies from 'universal-cookie';
import { Trans } from 'react-i18next';
import Welcome from '../../assets/welcome.png';
import * as Endpoint from '../../configFile';

function ResetPassword(props) {
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [confirm, setConfirm] = useState(false);

    const sendPassword = (e) => {
        e.preventDefault();
        const sendButton = document.querySelector('.btn--primary');

        checkValidate(newPassword, repeatPassword, sendButton);

        if (confirm === true) {
            const key = window.location.href.split('?key=')[1];
            const cookies = new Cookies();
            fetch(Endpoint.forgotPasswordFinish, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN'),
                },
                body: JSON.stringify({key: key, password: newPassword}),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw response;
                    }
                    return response;
                })
                .then((res) => {
                    window.location.href = '/login';
                })
                .catch((error) => {
                    console.log(error);
                    if (error.status === 400) {
                        document.querySelector('#label--error').classList.remove('d-none');
                    } else {
                        document.querySelector('#label--error-server').classList.remove('d-none');
                    }
                });
        }
    };

    const changeValue = (e) => {
        const name = e.target.getAttribute('name');
        const value = e.target.value;
        setNewPassword(name === 'newPassword' ? value : newPassword);
        setRepeatPassword(name === 'repeatPassword' ? value : repeatPassword);

        const sendButton = document.querySelector('.btn--primary');
        document.querySelector('.require').style.display = 'none';
        document.querySelector('.inconsistent').style.display = 'none';
        if ((name === 'newPassword' ? value : newPassword) !== (name === 'repeatPassword' ? value : repeatPassword)) {
            document.querySelector('.inconsistent').style.display = 'block';
            sendButton.classList.add('disabled');
        } else {
            sendButton.classList.remove('disabled');
        }

        checkValidate(name === 'newPassword' ? value : newPassword, name === 'repeatPassword' ? value : repeatPassword, sendButton);
    };

    const checkValidate = (newPassword, repeatPassword, sendButton) => {
        console.log(newPassword)
        console.log(repeatPassword)
        if (newPassword.length < 8 || newPassword.match(/[1-9]/g) === null || newPassword.match(/[a-z]/g) === null || repeatPassword !== newPassword) {
            document.querySelector('.require').style.display = 'block';
            sendButton.classList.add('disabled');
            setConfirm(false);
        } else {
            sendButton.classList.remove('disabled');
            setConfirm(true);
        }
    };

    const handlePasswordEye = (event) => {
        const target = event.target.closest('.input--value').querySelector('input');
        if (target.getAttribute('type') === 'password') {
            target.setAttribute('type', 'text');
        } else {
            target.setAttribute('type', 'password');
        }
    };

    return (
        <main className="login">
            <section className="login__left">
                <div className="login__box">
                    <img src={Logo} alt="logo Bizantium" className="logo" />
                </div>

                <form onSubmit={sendPassword} className="login--form">
                    <h2 className="login__right--title">
                        <Trans>password.change</Trans>
                    </h2>
                    <div className="login__right--container">
                        <div className="inputs-wrapper">
                            <div className="input-wrapper item item--white">
                                <div className="input--icon">{lockIcon}</div>
                                <div className="input--value">
                                    <input
                                        type="password"
                                        autoComplete={'on'}
                                        name="newPassword"
                                        id="newPassword"
                                        placeholder="Password"
                                        value={newPassword}
                                        onChange={changeValue}
                                    />
                                    <div className="passwordEye" onClick={(e) => handlePasswordEye(e)}>
                                        {passwordEye}
                                    </div>
                                    <label className="label--error d-none">
                                        <Trans>basic.required</Trans>
                                    </label>
                                </div>
                                <div className="input--check d-none">{checkIcon}</div>
                            </div>
                            <div className="input-wrapper item item--color forgot">
                                <div className="input--icon">{lockIcon}</div>
                                <div className="input--value">
                                    <input
                                        type="password"
                                        autoComplete={'on'}
                                        name="repeatPassword"
                                        id="repeatPassword"
                                        placeholder="Repeat password"
                                        value={repeatPassword}
                                        onChange={changeValue}
                                    />
                                    <div className="passwordEye" onClick={(e) => handlePasswordEye(e)}>
                                        {passwordEye}
                                    </div>
                                    <label className="label--error d-none">
                                        <Trans>basic.required</Trans>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="login__box resetPassword">
                            <span className={'label--error global-validation require'} style={{ display: 'none' }}>
                                <Trans>password.require</Trans>
                            </span>
                            <span className={'label--error global-validation inconsistent'} style={{ display: 'none' }}>
                                <Trans>password.inconsistent</Trans>
                            </span>
                            <label id="label--error" className="label--error d-none">
                                The link to change your password has expired!
                            </label>
                            <label id="label--error-server" className="label--error d-none">
                                The server is unavailable. Try later.
                            </label>

                            <button type="submit" className="btn btn--primary">
                                <Trans>basic.send</Trans>
                            </button>
                        </div>
                    </div>
                </form>
            </section>

            <section className="login__right">
                <h1 className="login__left--title">
                    <Trans>homepage.welcome</Trans>
                </h1>
                <span className="login__left--subtitle award">
                    <Trans>homepage.ready</Trans>
                </span>

                <img src={Welcome} alt="Welcome" className="login__right--picture" />

                <div className="login__welcome">
                    <span className="login__left--subtitle bold">
                        <Trans>homepage.bizantium</Trans>
                    </span>
                </div>

                {slider()}
            </section>
        </main>
    );
}

export default withRouter(ResetPassword);
