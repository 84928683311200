// export const backend ='http://localhost:8080';
export const backend ='https://demo-be.bizantium.be';
// export const apiTaxMachine = 'https://api.taxmachine.be/financial-state';
export const apiTaxMachine = 'https://development.api.taxmachine.be';
export const homepage = backend + '/homepage'

// logowanie, wysyłam login i hasło
// const data = {email: 'email@wp.pl',password: 'test'}
export const sendLogin = backend + '/login';

// zapomniałam hasła, wysyłam POSTem mail
// const data = {email: 'email@wp.pl'}
export const forgotPassword =  backend + '/api/user/reset-password';

export const forgotPasswordFinish =  backend + '/api/user/reset-password/finish';

// rejestracja, POST, wysyłam json
// const data = { company: '', enterprise: '', legal: '', calendar: null, name: '', surname: '', function: '', email: ''}
export const signUp = backend + '/register';

// aktywacja po rejestraci i przesłanie key
// post
export const activateUser = backend + '/activate'

// rejestracja, POST, wysyłam json
// const data = { oldPassword: '', newPassword: '', repeatPassword: '',}
export const changePassword2 = backend + '/api/user/password';

// GET cost type
export const getCostType = backend + '/api/cost-type';

// GET user costs
export const getCostsUser = backend + '/api/costs/user';

// GET user costs by admin
export const getAdminCostsUser = backend + '/api/costs/admin/all';

// POST user cost
export const postCostUser = backend + '/api/cost/user';

// POST user cost by admin
export const postAdminPostUser = backend + '/api/cost/admin';

// PUT user cost
export const putCostUser = backend + '/api/cost/user';

// DELETE user cost
export const deleteCostUser = backend + '/api/cost/user';

// GET survey
export const getSurvey = backend + '/api/survey';

// GET page survey
export const getPageSurvey = backend + '/api/survey/page/user';

// GET user survey
export const getUserSurvey = backend + '/api/survey/user';

// GET admin survey
export const getAdminSurvey = backend + '/api/survey/admin';

// POST user answer
export const postUserAnswer = backend + '/api/answer/user';

// POST admin answer
export const postAdminAnswer = backend + '/api/answer/admin'

// GET FAQ
export const getFAQs = backend + '/api/faqs';

// GET Output data
export const outputData = backend + '/api/company/admin/output-data';

// GET User output data
export const userOutputData = backend + '/api/company/user/output-data';

// DELETE FAQ
export const deleteFAQ = backend + '/api/faqs/admin';

// POST new FAQ
export const postFAQ = backend + '/api/faqs/admin';

// POST support message
export const postSupportMessage = backend + '/api/support/user/message';

// GET User profile
export const userProfile = backend + '/api/user/profile';

// GET Company details
export const companyDetails = backend + '/api/company/user';

// GET Notifications consents
export const getNotificationsConsents = backend + '/api/user/notification-consents';

// GET Notifications
export const getNotifications = backend + '/api/notifications/user/all';

// GET Checklist
export const getChecklist = backend + '/api/dashboard/user/checklist';

// PATCH Checklist
export const patchChecklist = backend + '/api/dashboard/admin/checklist';

// GET Financial calendar
export const getFinancialCalendar = backend + '/api/company/admin/financial-calendar';

// GET Company activities
export const getCompanyActivities = backend + '/api/company/admin/activity-details';

// GET Admin files
export const getAdminFiles = backend + '/api/documents/admin';

// GET User files
export const userFiles = backend + '/api/documents/user';

// UPLOAD Admin files
export const uploadAdminFiles = backend + '/api/documents/upload/admin';

// UPDATE IBAN and Certificate
export const bankData = backend + '/api/company/user/bank-data';

// GET Bank
export const bank = backend + '/api/company/user/bank';

// Block bank
export const blockBank = backend + '/api/company/user/bank-block';

// Dashboard administration
export const dashboardAdministration = backend + '/api/file/user/resource/pdf/Handleiding_CSAM_mandaten_Bizantium.pdf';

// PUT Password
export const changePassword = backend + '/api/user/change-password';

// GET Parameters
export const getParameters = backend + '/api/parameter/admin';

// GET Dashboard company type
export const getDashboardCompanyType = backend + '/api/dashboard/user/company-type';

// GET Dashboard Establishment flow
export const getDashboardEstablishmentFlow = backend + '/api/dashboard/user/establishment-flow';

// PUT Progress Survey
export const putProgressSurvey = backend + '/api/dashboard/user';

// GET Progress Survey
export const progressSurvey = backend + '/api/dashboard/user';

// GET Shareholders
export const getShareholders = backend + '/api/shareholders/user';

// PUT Personal Situation
export const putPersonalSituation = backend + '/api/shareholders/personal/user';

// GET User transport
export const getUserTransport = backend + '/api/transport/user';

// GET User revenues
export const getUserRevenue = backend + '/api/revenues/user';

// GET User assets
export const getUserAssets = backend + '/api/assets/user';

// GET User loans
export const getUserLoans = backend + '/api/loans/user';

// POST List of available makes, models and trimlines
export const vehicleList = apiTaxMachine + '/financial-state/v1/vehicle-list';

// POST list of available vehicles with their details
export const vehicleDetails = apiTaxMachine + '/financial-state/v1/vehicle-details';

// POST calculate the fiscal benefit in kind for an item
export const fiscalBenefitInKind = apiTaxMachine + '/personal-income-tax/v1/2023/fiscal-benefit-in-kind';

// POST Calculate the gross salary from a net salary
export const netToGross = apiTaxMachine + '/personal-income-tax/v1/2023/salary/net-to-gross';

// GET Tax machine data
export const taxMachine = backend + '/api/finance-calculation/user';

// GET Necessary Values
export const necessaryValues = backend + '/api/documents/values/admin';

// Generate document
export const generateDocument = backend + '/api/documents/admin/generate';

// GET User List
export const userList = backend + '/api/user-list/admin';

// GET Main Activities
export const mainActivities = backend + '/api/company/admin/main-activities';

// DELETE File
export const deleteFile = backend + '/api/documents/admin/remove-file';

// SIGN File
export const signFile = backend + '/api/sign/admin';

// GET Call Schedule
export const callSchedule = backend + '/api/dashboard/user/call-schedule';

// Update sign
export const sendSign = backend + '/api/sign/admin/send';

// GET Files admin shareholders
export const shareholdersAdminFiles = backend + '/api/shareholders/admin/files';

// UPLOAD avatar shareholder
export const avatarShareholder = backend + '/api/shareholders/user/photo';

// Remuneration data
export const remuneration = backend + '/api/remuneration/user';

export const getClientsData = backend + "/api/company/admin";
// wysyłam dane z podstrony My Company na poniższy link
// POST
export const sendCompanyData = backend + '/api/company';
// -----------------do obiektu potrzebne jest jeszcze id


export const getCompanyData = backend + "/api/company";

export const getFAQData = backend + "/api/support";

// oznaczanie wiadomości jako przeczytanych
// post
// body: id
export const unreadMessages = backend + '/api/support/check'

// -------------------
// pobranie wszystkich pytań w zakładce support/help
//GET
export const getAdminMessagesData = backend + "/api/support/message/admin";

// -------------------
// pobranie pytań użytkownika w zakładce support/help
//GET
export const getUserMessagesData = backend + "/api/support/message";

// dodanie pytania przez usera w zakładce support/help
// POST
// w rezultacie chce dostać obiekt np: {id: '4', question: question, answer: answer, user: '2', type: 'HELP'}
// czyli tak jak wyżej tylko jeden element żebym mogła zrobić update stanu i zmiany pokazały się na żywo
export const sendHelpQuestion = backend + '/api/support/message';

// wysłanie edycji pytania - edycja przez admina
// do linku poniżej dodaję jeszcze /idPytania czyli np. /test/1
// POST
export const changeFAQAnswer = backend + '/api/support/faq/update/admin';

// usunięcie pytania przez admina
// do linku poniżej dodaję jeszcze /idPytania czyli np. /test/1
// method DELETE
export const deleteFAQItem = backend + '/api/support/faq/admin'; // + /id/type (type = FAQ || HELP)

//add new item in support FAQ - admin
// response example -> {id: '4', question: question, answer: answer, type:'FAQ', user: '1'}
// POST
export const addNewQuestionSupport = backend + '/api/support/faq/admin';

//add new answer in support FAQ - admin
// response example -> {id: '4', question: 'text', answer: 'text', type:'HELP', user: '1'}
// POST
// request with answer in body
export const addNewAnswerSupport = backend + '/api/support/message/admin'; // +/id

// pobieranie danych do zakładki moje konto
// GET
// przykład
// {
//     id: 23
//     picture: 'https://www.w3schools.com/w3images/avatar6.png',
//     name: 'Kasia',
//     surname: 'Kasia',
//     email: 'kasia@wp.pl',
//     function: 'Developer',
//     description: 'Frontend',
//     plan: 'standard'
// }
// -------------------- wczytuje dane zalogowanego
export const getPersonData = backend + "/api/user";


// wysyłanie zmian w moim profilu
// w obiekcie musi być id
// POST
export const sendPersonData = backend + "/api/user";

//pobieranie danych o userach
// GET
// const test = [
//     {id: '1', name:'Colin Alvarado', avatar:'https://www.w3schools.com/w3images/avatar6.png', phone: '452-455-119', email: 'c.alvarado@gmail.com', position: 'CEO', role: 'admin'},
//     {id: '2', name:'Brandy Strickland', avatar: '', phone: '442-555-419', email: 'b.strickland@gmail.com', position: 'Sales Manager', role: 'admin'},
//     {id: '3', name:'Donnie Simpson', avatar:'https://www.w3schools.com/w3images/avatar6.png', phone: '352-555-919', email: 'd.simpson@gmail.com', position: 'Technology Manager', role: 'user'},
// ]
export const getUsersData = backend + "/api/user/admin";

// pobranie danych o edytowanych uzytkowniku
// /example/idUSer
// GET
// odpowiedz: obiekt z userem
// {id: '1', name:'Colin', surname:'Arvado', avatar:'https://www.w3schools.com/w3images/avatar6.png', phone: '452-455-119', email: 'c.alvarado@gmail.com', position: 'CEO', role: 'user'},
export const editUserData = backend + '/api/user/admin'; // + /id

// dodanie nowego usera
// POST
// odpowiedz: obiekt z userem
// id: '4', name:'test', avatar: 'https://www.w3schools.com/w3images/avatar6.png', phone: '442-555-419', email: 'b.strickland@gmail.com', position: 'Sales Manager', role: 'user'}
export const addNewUser = backend + '/api/user/create/admin'

// /deleteUser/idUSer
// method: delete
export const deleteUser = backend + '/api/user/admin'; // + /id

// /deleteClients/idClients
// method: delete
export const deleteClients = backend + '/api/company/admin'; // +/id

// pliki w zakładce klienci
// GET
// przykład
// [
//     {id: 1, nameTemplate: 'Survay 1', postDate: '01.03.2020', downloadLink: '/download/1', sendBy: 'clients'},
//     {id: 2, nameTemplate: 'Survay 2', postDate: '23.03.2020', downloadLink: '/download/1', sendBy: 'clients'},
//     {id: 3, nameTemplate: 'Survay 3', postDate: '01.03.2020', downloadLink: '/download/1', sendBy: 'bizantium'},
//     {id: 4, nameTemplate: 'Survay 4', postDate: '01.03.2020', downloadLink: '/download/1', sendBy: 'bizantium'},
//     {id: 5, nameTemplate: 'Financial Plan', postDate: '18.02.2020', downloadLink: '/download/1', sendBy: 'bizantium'},
// ]
// wyśle dodatkowo id clienta -> /test/1
export const getClientFiles = backend + '/api/file/admin' // + /id


// admin dodaje plik
// method POST
// w odpowiedzi chcę obiekt tego pliku, np:
// {id: 6, nameFile: newFile.name, postDate: '02.07.2020', downloadLink: '/download/6', sendBy: 'bizantium'}
// tutaj
export const sendClientFile = backend + '/api/file/admin' // + /user_id




// wyślij mi json z danymi o ankiecie,
// to jest w widoku klienta zakładka survey, dostaniesz jeszcze id clienta
// tutaj jeszcze można zmienić ankietę którą admin chce zobaczyć i wtedy na ten sam link wyślę po id clienta jezcze value nowej ankiety np ./endpoint/idCLinet/survey2 (?)
// const test = {
//     data: [{
//         currentNameTemplate: {value: '1', label: '1'},
//         allNameTemplate: [{value: '1', label: '1' },{value: '2', label: '2'}],
//         pages: [
//             {
//                 page: 1,
//                 questions: [
//                  {
//                     question: 'question 1',
//                     answer: 'answer 1',
//                  },
//                  {
//                     question: 'question 2',
//                     answer: 'answer 2',
//                  },
//                 ]
//             },
//             {
//                 page: 2,
//                 questions: [
//                  {
//                     question: 'question 1',
//                     answer: 'answer 1',
//                  },
//                  {
//                     question: 'question 2',
//                     answer: 'answer 2',
//                  },
//                 ]
//             },
//         ]
//     }]
// }
export const GetSurveyDataClient = backend + '/api/survey/answers/admin'; // + /survey_id/user_id

//PUT
export const changeDefaultSurvey = backend + '/api/survey/admin' //+ id

export const getAllSurveys = backend + '/api/survey/admin';

// pobranie danych przez admina lub przez usera
// GET
// w przypadku admina wyślij mi json z danymi do tabeli ( poniżej przykład )
// const test = [
//     {id: 1, nameTemplate: 'Survey 1', creationDate: '01.03.2020'},
//     {id: 2, nameTemplate: 'Survey 2', creationDate: '23.03.2020'},
//     {id: 3, nameTemplate: 'Survey 3', creationDate: '18.02.2020'},
// ]
// ----------
export const GetAllSurveyData = backend + "/api/survey";

// w przypadku usera wyślij mi json z danymi o ankiecie ( poniżej przykład, jest to taki sam json jak w przypadku np. edycji ankiety czy jej tworzenia przez admina )
// const test = {
//     data: [{
//         nameTemplate: '',
//         pages: [
//             {
//                 page: 1,
//                 name: 'name 1',
//                 explanationSwitch: true,
//                 explanationText: 'explanation 1',
//                 questions: [{
//                     id: 1,
//                     question: 'question 1',
//                     type: {label: "MPC", value: "MPC"},
//                     obligatory: true,
//                     remarkSwitch: true,
//                     remarkText: 'remark 1',
//                     option: ["1", "2"],
//                     logic: [{label: "1", value: "1"}, {label: "1", value: "1"}]
//                 }]
//             },
//         ]
//     }]
// }
export const GetSurveyData = '/survey';

// usuwanie ankiety
// DELETE
export const DeleteSurvey = backend + '/api/survey/admin'; // + /survey_id

// wysyłanie ankiety
// POST
export const sendNewSurvey = backend + '/api/survey/admin';

// edycja ankiety
//POST
export const updateSurvey = backend + '/api/survey/update/admin';

// pobieranie danych do ankiety JSON - edycja ankiety
// GET
export const GetSurveyDataEdit = backend + '/api/survey/admin'; // + /id

// wysyłanie danych które wprowadził user w ankiecie
// POST
export const SendSurveyData = backend + '/api/survey/submit'

// survey zakładka file
// GET
// const test = [
//     {id: 1, nameFile: 'Survay 1', postDate: '01.03.2020', downloadLink: '/download/1', sendBy: 'clients'},
//     {id: 2, nameFile: 'Survay 2', postDate: '23.03.2020', downloadLink: '/download/2', sendBy: 'clients'},
//     {id: 3, nameFile: 'Survay 3', postDate: '01.03.2020', downloadLink: '/download/3', sendBy: 'bizantium'},
//     {id: 4, nameFile: 'Survay 4', postDate: '01.03.2020', downloadLink: '/download/4', sendBy: 'bizantium'},
//     {id: 5, nameFile: 'Financial Plan', postDate: '18.02.2020', downloadLink: '/download/5', sendBy: 'bizantium'},
// ]
export const GetDataFiles = backend + '/api/file'

// send file as client ,POST
export const uploadFile = backend + "/api/file"

// GET
// pobieranie danych o tabeli i wykresie do revenue
// const test = {
//     chartData: [
//         {name: 'Y1', 'Product 1': 130000, 'Product 2': 96000},
//         {name: 'Y2', 'Product 1': 136500, 'Product 2': 98500},
//         {name: 'Y3', 'Product 1': 150150, 'Product 2': 15150},
//     ],
//     tableData: [
//         {id: 1, name: 'Product 1', value: [130000, 136500, 150150]},
//         {id: 2, name: 'Product 2', value: [96000, 98500, 15150]},
//         {id: 3, name: 'Total', value: [96000, 98500, 15150]},
//     ]
// }
export const getFinanceData = backend + '/api/finance/revenue';

// GET
// pobieram dane dp listy rozwijalnej w tabeli
export const getFinanceDataRevenueTable = backend + '/api/finance/revenue'; // +/id

// POST
// nowy produkt do reveniu w finansach
export const addNewProductInFinance = backend + '/api/finance/revenue';

// -------------------
// pobieranie danych do cost
// na razie jest to lista Kosztów
//GET
export const getFinanceCostData = backend + '/api/finance/cost';

// -------------------
// GET
// pobieram dane dp listy rozwijalnej w tabeli
export const getFinanceDataCostTable = backend + '/api/finance/cost'; // +/id

// -------------------
// POST
// nowy koszt do finansów
export const addNewCostInFinance = backend + '/api/finance/cost';

// -------------------
// pobieranie danych do financing
// na razie jest to lista Financing
//GET
export const getFinanceFinancingData = backend + '/api/finance/financing';


// zmienia nazwę kategorii w support: POST
// w body obiekt: {oldCategory, newCategory}
export const changeCategoryName = backend + '/api/support/admin/category';


//usuwa kategorię: DELETE
export const deleteCategorySupport = backend + '/api/support/admin/category' //+ id najbliższego pytania



//pobiera wszystkie role: GET
export const getAllRoles = backend + '/api/role/admin';

export const getAllRolesNames = backend + '/api/role/admin/name';

//aktualizuje rolę: POST
export const updateRole = backend + '/api/role/admin';

// tworzy nową rolę: POST
export const createRole = backend + '/api/role/new/admin';

//usuwa daną rolę: DELETE
export const deleteRole = backend + '/api/role/admin'; // + /position

//i dodałem nowe GET endpointy:
// zmiana wersji produktów w finansach
export const changeVersionRevenue = backend + '/api/finance/revenue/version/' //+ version
export const changeVersionCost = backend + '/api/finance/cost/version/' //+ version
export const changeVersionFinancing = backend + '/api/finance/financing/version/' //+ version

//login google
export const loginGoogle = backend + '/oauth2/authorize/google'

// login google - pozostałe dane przy logowaniu przez google
export const loginGoogleALlData = backend + '/api/user'

//Financing delete
export const deleteFinancingRevenueProduct = backend + '/api/finance/product/' // + /id
export const deleteFinancingRevenueVersion = backend + '/api/finance/products/' // + /id

export const deleteFinancingCostProduct = backend + '/api/finance/cost/' // + /id
export const deleteFinancingCostVersion = backend + '/api/finance/costs/' // + /id

export const deleteFinancingFinancingProduct = backend + '/api/finance/financing/' // + /id
export const deleteFinancingFinancingVersion = backend + '/api/finance/financings/' // + /id

// change language
export const changeLanguage = backend + '/api/user/language'