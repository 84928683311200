// base save button with send data

import React from "react";
import validate from "../validate/validate";
import Messages from "../messages/messages";
import Cookies from 'universal-cookie';
import {Trans} from 'react-i18next';
import { toast } from "react-toastify";

const SaveButton = (props) => {
    const [states, setStates] = React.useState(0);

    const additionalClass = props.newClass ? ' '+ props.newClass : '';

    const sendData = () => {
        if(props.url === 'https://demo-be.bizantium.be/register'){
            let data = JSON.parse(props.data)
            const checkEmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zAZ0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            const validEmail = checkEmailPattern.test(String(data.mail).toLowerCase());
            if(!validEmail) return toast.error(localStorage.getItem("i18nextLng") === "en" ? "Please enter a valid email address." : "Gelieve een geldig e-mailadres in te geven.")
            if(data.password !== data.confirmPassword) return toast.error(localStorage.getItem("i18nextLng") === "en" ? "Passwords vary." : "Wachtwoorden variëren.")
        }

        const reasonValidate = validate();
        if(reasonValidate === 1) {
            document.querySelectorAll('.progress__item').forEach( progress => {
                progress.classList.add('complete');
                progress.querySelector('.progress__item--circle').innerHTML = ' '
            })
        } else {
            setStates(1)
            setTimeout( () => setStates(0),3000);
            return true
        }

        const cookies = new Cookies();

        fetch( props.url , {
            method: 'POST',
            body: props.data,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token') == null ? '' : 'Bearer ' + localStorage.getItem('token'),
                'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN')
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw response
                }
                return response
                })
            .then(res => {
                return res.url.includes('/api/user/password') ? res : res.json()})
            .then(data => {
                setStates(2);
                setTimeout( () => setStates(0),3000);
                if(props.type === "myProfile"){
                    document.querySelector('.user-info__name').innerHTML = `${data.name} ${data.surname}`;
                    const avatar = document.querySelector('.user-info__avatar'),
                        avatarImg = document.querySelector('.user-info__avatar .picture');
                    if(data.picture !== null) {
                        if (avatarImg === null) {
                            avatar.classList.remove('avatar--image')
                            avatar.innerHTML = `<div class="picture" style="background-image: url(${data.picture})"/>`
                        } else {
                            avatarImg.setAttribute('style', `background-image: url(${data.picture})`)
                        }
                    }
                }

                if(props.btnName === 'Sign up' || props.btnName === 'Account maken') {
                    document.getElementById("login--form").style.display = "none"
                    document.getElementById("finish-msg-register").classList.add('show')
                }
            })
            .catch((error) => {
                setStates((error.status === 400 && props.btnName === 'Sign up') ? 5 : 3);
                if (error.status === 400 && document.querySelector('#label--error-already') !== undefined && (props.btnName === 'Sign up' || props.btnName === 'Account maken')) {
                    document.querySelector('#label--error-already').classList.remove('d-none')
                }
                setTimeout( () => setStates(0),3000);
                console.log('Error:', error)
            });
    }

    return(<>
        <button className={'btn btn--primary sendData btn--sm--block' + additionalClass} onClick={sendData}>
            {props.btnName !== undefined ? props.btnName : <Trans>basic.save</Trans>}
        </button>
            {states !== 0 && <Messages status={states} />}
        </>
    )
}

export default SaveButton;