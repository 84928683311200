import React, { useState } from 'react';
import './_login.scss';
import Logo from '../../assets/icon/logo.svg';
import { Link } from 'react-router-dom';
import * as Endpoint from '../../configFile';
import { messagesIcon, lockIcon, checkIcon, smile, passwordEye } from './loginConstants';
import SaveButton from '../saveButton/saveButton';
import { slider } from './login';
import GoogleIcon from '../../assets/google.png';
import MicrosoftIcon from '../../assets/icon/microsoft.png';
import { withCookies } from 'react-cookie';
import { SelectCustom } from '../select/select';
import { languageOptions } from '../mainContainer/mainContainer';
import {Trans, useTranslation, withTranslation} from 'react-i18next';
import Welcome from '../../assets/welcome.png';
import { Checkbox } from '@material-ui/core';

function SignUp(props) {
    const {t} = useTranslation();
    const [clientName, setClientName] = useState('');
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [language, setLanguage] = useState({ label: (typeof window !== 'undefined') ? localStorage.getItem('i18nextLng') == null ? 'en' : localStorage.getItem('i18nextLng') : 'en', value: (typeof window !== 'undefined') ? localStorage.getItem('i18nextLng') == null ? 'en' : localStorage.getItem('i18nextLng') : 'en' });
    const [acceptRules, setAcceptRules] = useState(true);
    const [body, setBody] = useState({
        clientName: '',
        mail: '',
        password: '',
        confirmPassword: '',
        language: '',
        acceptRules: '',
    });

    const isMobile = window.innerWidth <= 768;

    const changeInput = (state, value) => {
        if (state === 'password') {
            document.querySelector('.password--error').style.display = 'none';
            const sendButton = document.querySelector('.sendData');

            if (value.length < 8) {
                document.querySelector('.password--error').style.display = 'block';
                sendButton.classList.add('disabled');
            } else {
                sendButton.classList.remove('disabled');
            }
            if (value.match(/[1-9]/g) === null || value.match(/[a-z]/g) === null) {
                document.querySelector('.password--error').style.display = 'block';
                sendButton.classList.add('disabled');
            } else {
                sendButton.classList.remove('disabled');
            }
        }

        if (state === 'mail') {
            const checkEmailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zAZ0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            const validEmail = checkEmailPattern.test(String(value).toLowerCase());
            const mailCorrectElement = document.querySelector('.email--error').style;
            validEmail ? (mailCorrectElement.display = 'none') : (mailCorrectElement.display = 'block');
        }

        if (state === 'confirmPassword') {
            if (value !== document.querySelector('#password').value) {
                document.querySelector('.confirm-password--error').style.display = 'block';
            } else {
                document.querySelector('.confirm-password--error').style.display = 'none';
            }
        }

        let body = {
            clientName: state === 'clientName' ? value : clientName,
            mail: state === 'mail' ? value : mail,
            password: state === 'password' ? value : password,
            confirmPassword: state === 'confirmPassword' ? value : confirmPassword,
            language: state === 'language' ? value : language,
            acceptRules: state === 'acceptRules' ? value : acceptRules
        }

        setBody(body)

        switch (state) {
            case 'clientName':
                setClientName(value);
                break;
            case 'mail':
                setMail(value);
                break;
            case 'password':
                setPassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };

    const changeLanguage = (event) => {
        let newLang = event.value;
        props.i18n.changeLanguage(newLang);
        setLanguage(event);
    };

    const handlePasswordEye = (event) => {
        let target = event.target.closest('.input--value').querySelector('input');
        if (target.getAttribute('type') === 'password') {
            target.setAttribute('type', 'text');
        } else {
            target.setAttribute('type', 'password');
        }
    };

    return (
        <main className="login">
            <section className="login__left">
                <div className="login__box">
                    <img src={Logo} alt="logo Bizantium" className="logo" />
                    <div className="change-language">
                        <SelectCustom
                            selectedValue={language}
                            isEdit={true}
                            options={languageOptions}
                            onChange={(event) => changeLanguage(event)}
                        />
                    </div>
                </div>
                <div className="login--form" id="login--form">
                    {!isMobile && (
                        <h2 className="login__right--title">
                            <Trans>homepage.signup-title</Trans>
                        </h2>
                    )}
                    {isMobile && (
                        <h2 className="login__right--title">
                            <Trans>homepage.login</Trans>
                            <br />
                            <br />
                            <Link to="/login" className="btn btn--primary">
                                <Trans>homepage.login</Trans>
                            </Link>
                            <br />
                            <br />
                            <Trans>homepage.or-account</Trans>
                        </h2>
                    )}
                    <span className="login__left--subtitle">
                        <Trans>homepage.signup-description</Trans>
                    </span>

                    <div className="login__right--container">
                        <div className="login__box d-none">
                            <a href={`${Endpoint.loginGoogle}?redirect_uri=/loginGoogle`} target="__blank" className="login__right--google">
                                <img src={GoogleIcon} alt="google icon" />
                                <p>Sign in with google</p>
                            </a>
                            <a href={`${Endpoint.loginGoogle}?redirect_uri=/loginGoogle`} target="__blank" className="login__right--google">
                                <img src={MicrosoftIcon} alt="google icon" />
                                <p>Sign in with Microsoft</p>
                            </a>
                        </div>
                        <div className="login__box d-none">
                            <div className="login__line" />
                            <p className="login__alternative">OR</p>
                            <div className="login__line" />
                        </div>

                        <div className="inputs-wrapper">
                            <div className={`input-wrapper item item--color`}>
                                <div className="input--icon">{messagesIcon}</div>
                                <div className="input--value">
                                    <input
                                        type="email"
                                        id="login"
                                        placeholder={t("homepage.email")}
                                        autoComplete="none"
                                        required
                                        value={mail}
                                        onChange={(e) => changeInput('mail', e.target.value)}
                                    />
                                    <label className="label--error d-none">
                                        <Trans>basic.required</Trans>
                                    </label>
                                    <label className="label--error d-none badEmail">{localStorage.getItem('i18nextLng') === 'en' ? 'Please enter a valid email address.' : 'Gelieve een geldig e-mailadres in te geven.'}</label>
                                </div>
                                <div className="input--check d-none">{checkIcon}</div>
                            </div>
                            <div className="input-wrapper item item--white">
                                <div className="input--icon">{smile}</div>
                                <div className="input--value">
                                    <input
                                        type="text"
                                        id="name"
                                        placeholder={t("homepage.first")}
                                        required
                                        value={clientName}
                                        onChange={(e) => changeInput('clientName', e.target.value)}
                                    />
                                    <label className="label--error d-none">
                                        <Trans>basic.required</Trans>
                                    </label>
                                </div>
                            </div>
                            <div className="input-wrapper item item--white">
                                <div className="input--icon">{lockIcon}</div>
                                <div className="input--value">
                                    <input
                                        type="password"
                                        id="password"
                                        placeholder={t("homepage.passwordCreate")}
                                        required
                                        value={password}
                                        autoComplete="new-password"
                                        onChange={(e) => changeInput('password', e.target.value)}
                                    />
                                    <div className="passwordEye" onClick={(e) => handlePasswordEye(e)}>
                                        {passwordEye}
                                    </div>
                                    <label className="label--error d-none">
                                        <Trans>basic.required</Trans>
                                    </label>
                                </div>
                            </div>
                            <div className="input-wrapper item item--white">
                                <div className="input--icon">{lockIcon}</div>
                                <div className="input--value">
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        placeholder={t("homepage.passwordConfirm")}
                                        required
                                        value={confirmPassword}
                                        autoComplete="new-password"
                                        onChange={(e) => changeInput('confirmPassword', e.target.value)}
                                    />
                                    <div className="passwordEye" onClick={(e) => handlePasswordEye(e)}>
                                        {passwordEye}
                                    </div>
                                    <label className="label--error d-none">
                                        <Trans>basic.required</Trans>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <span className={'label--error global-validation email--error'} style={{ display: 'none' }}></span>
                        <span className={'label--error global-validation password--error'} style={{ display: 'none' }}>
                            <Trans>password.require</Trans>
                        </span>
                        <span className={'label--error global-validation confirm-password--error'} style={{ display: 'none' }}>
                            {localStorage.getItem('i18nextLng') === 'en' ? 'The passwords are different!' : 'De wachtwoorden zijn verschillend!'}
                        </span>

                        <div className="input-wrapper item item--white login__checkbox">
                            <Checkbox
                                className="checkbox"
                                color={'#301164'}
                                name={'agree'}
                                onChange={(e) =>
                                    e.target.checked === true
                                        ? (document.querySelector('.hidInput').value = 'test')
                                        : (document.querySelector('.hidInput').value = '')
                                }
                            />
                            <label for={'agree'}>
                                {localStorage.getItem('i18nextLng') === 'en' ? 'I agree to the' : 'Ik ga akkoord met de'} <a href="https://bizantium.be/privacy-policy-2/" target="_blank">
                                {localStorage.getItem('i18nextLng') === 'en' ? 'Terms & Conditions' : 'Algemene voorwaarden'}
                            </a>
                            </label>
                            <input className="hidInput d-none" type="text" required />
                            <label className="label--error d-none">
                                <Trans>basic.required</Trans>
                            </label>
                        </div>

                        <div className="login__box">
                            <SaveButton url={Endpoint.signUp} data={JSON.stringify(body)} btnName={localStorage.getItem('i18nextLng') === 'en' ? 'Sign up' : 'Account maken'} />
                            <Link to="/login" type="button" className="btn btn--secondary">
                                <Trans>homepage.login</Trans>
                            </Link>
                            <label id="label--error-already" className="label--error d-none">{localStorage.getItem('i18nextLng') === 'en' ? 'Provided email address already in use!' : '\n' +
                                'Opgegeven e-mailadres is al in gebruik!'}</label>
                        </div>
                    </div>
                </div>

                <div className="login--finish" id="finish-msg-register">
                    <svg
                        width="181"
                        height="126"
                        viewBox="0 0 181 126"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0_5033_38247)">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M174.613 124.389C185.347 97.5733 182.1 67.6308 168.256 44.6342L23.3348 64.6844L162.215 35.7636C103.683 -37.1694 -10.2415 11.2061 0.740232 103.289L174.613 124.389Z"
                                fill="#EBF2FF"
                            />
                            <circle cx="89.5" cy="88.889" r="36.5" fill="#4A5BCF" />
                            <path
                                d="M86.5 94.3109L81.5781 89.389L79.9922 91.0296L86.5 97.5374L100.5 83.5374L98.8594 81.8968L86.5 94.3109Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_5033_38247">
                                <rect width="181" height="125" fill="white" transform="translate(0 0.388977)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div className="login__finish--title">Thanks for signing up!</div>
                    <div className="login__finish--subtitle">
                        We sent an email to {mail}
                        <br />
                        Click the confirmation link in the email to verify your account and get started.
                    </div>
                    <div className="login__box finish">
                        <Link to="/login" type="button" className="btn btn--primary">
                            Sign In
                        </Link>
                    </div>
                </div>
            </section>

            <section className="login__right">
                <h1 className="login__left--title">
                    <Trans>homepage.welcome</Trans>
                </h1>
                <span className="login__left--subtitle award">
                    <Trans>homepage.ready</Trans>
                </span>

                <img src={Welcome} alt="Welcome" className="login__right--picture" />

                <div className="login__welcome">
                    <span className="login__left--subtitle bold">
                        <Trans>homepage.bizantium</Trans>
                    </span>
                </div>
                {slider()}
            </section>
        </main>
    );
}

export default withTranslation()(withCookies(SignUp));
