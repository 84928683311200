import React, { useState, useEffect } from 'react';
import './_support.scss';
import './faq.scss';
import Navbar from "../navbar/navbar";
import Menu from "../menu/menu";
import Quickstart from "../quickstart/quickstart";
import { arrowDropdown } from "../login/loginConstants";
import { FormControl, MenuItem, OutlinedInput, Select, TextareaAutosize } from "@material-ui/core";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import Confirm from "../confirm/confirm";
import {Trans, withTranslation} from "react-i18next";
import {withCookies} from "react-cookie";

const Support = (props) => {
    const [data, setData] = useState([]);

    const [confirm, setConfirm] = useState(false);
    const [quickstart, setQuickstart] = useState(false);

    const handleConfirm = (value) => {
        setConfirm(value);
    }

    const handleQuickstart = (value) => {
        setQuickstart(value);
    }

    const handleFaq = (e) => {
        e.currentTarget.closest('.faq__item').classList.toggle('active');
    }

    const send = () => {
        let body = {
            'topic': document.querySelector('.MuiSelect-select').innerText,
            'question': document.querySelector('textarea[name="question"]').value
        };
        Connect(`${Endpoint.postSupportMessage}`, 'POST', body).then(response => {
            if (response.type === 'HELP') {
                handleConfirm(true);
            }
        });
    }

    const fetchData = async () => {
        try {
            const response = await Connect(`${Endpoint.getFAQs}`, 'GET');
            console.log(response);
            setData(response);
        } catch (error) {
            console.log('Error:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="container">
            <Navbar currentStep={props.step} progress={props.progress} handleQuickstart={handleQuickstart} name={'Support'} handleSupport={fetchData} {...props} />
            <Menu role={'user'} permissions={null} isMobile={false} component={'support'} />
            <Quickstart status={quickstart} handleQuickstart={handleQuickstart} />
            <div className="support">
                <div className="faq">
                    <div className="support__header"><Trans>admin.faq</Trans></div>
                    {data.map((item, index) => (
                        <div className="faq__item" key={index} onClick={(e) => handleFaq(e)}>
                            <div className="faq__header">
                                <div className="text">{item.question}</div>
                                <div className="svg">{arrowDropdown}</div>
                            </div>
                            <div className="faq__extend">
                                <div className="text">{item.answer}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="support__form">
                    <div className="support__header">{localStorage.getItem('i18nextLng') === 'en' ? 'Ask your question' : 'Stel je vraag'}</div>
                    <div className="container__body-select">
                        <p className="container__body-text">{localStorage.getItem('i18nextLng') === 'en' ? 'Pick a topic' : 'Kies een onderwerp'}</p>
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <Select input={<OutlinedInput label="Name" defaultValue={'Establishment cost'} />}>
                                <MenuItem value={'Establishment cost'}>{localStorage.getItem('i18nextLng') === 'en' ? 'Establishment cost' : 'Oprichtingskosten'}</MenuItem>
                                <MenuItem value={'Company type'}><Trans>menu.clients</Trans></MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="container__body-textarea">
                        <p id="supportMessage" className="container__body-text">{localStorage.getItem('i18nextLng') === 'en' ? 'Your question' : 'Jouw vraag'}</p>
                        <TextareaAutosize minRows={6} maxRows={6} placeholder={'...'} name={'question'} />
                    </div>
                    <div className="row">
                        <button className="btn btn--primary" onClick={() => send()}>{localStorage.getItem('i18nextLng') === 'en' ? 'Send' : 'Versturen'}</button>
                    </div>
                </div>
            </div>
            <Confirm active={confirm} handleConfirm={handleConfirm} message={localStorage.getItem('i18nextLng') === 'en' ? 'Your message has been sent. We will contact you' : 'Je bericht is verzonden. We nemen contact met je op'} button={'Close'} only={true} />
        </div>
    );
}

export default withTranslation()(withCookies(Support));
