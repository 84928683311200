import React, { useEffect } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { InfoIcon } from "../survey/surveyConstant";
import { likeIcon, question } from "../login/loginConstants";
import './finish.scss';
import frame from '../../assets/frame.svg';
import { NavLink } from "react-router-dom";

const Finish = () => {
    useEffect(() => {
        document.querySelector('.navbar__menu').classList.add('output');
        document.querySelector('.footer .btn.next').classList.add('d-none');
        document.querySelector('.footer .btn.dashboard__button').classList.remove('d-none');

        return () => {
            document.querySelector('.navbar__menu').classList.remove('output');
            document.querySelector('.footer .btn.next').classList.remove('d-none');
            document.querySelector('.footer .btn.dashboard__button').classList.add('d-none');
        };
    }, []);

    return (
        <>
            <div className="container__body finish" style={{ backgroundImage: `url(${frame})` }}>
                <div className="container__body-header d-none">Add your projected revenue streams to the table.
                    <Tooltip title="We’ve pre-filled some info you already provided during sign-up" placement={'right'}>
                        <IconButton aria-label="delete">
                            {InfoIcon}
                        </IconButton>
                    </Tooltip>
                </div>
                <div className="finish">
                    {likeIcon}
                    <h2>{localStorage.getItem('i18nextLng') === 'en' ? 'Awesome!' : 'Geweldig!'}</h2>
                    <h3>{localStorage.getItem('i18nextLng') === 'en' ? 'The output of the establishment flow will soon be available in your Dashboard. We’ll keep you posted about the next steps!' : 'De output van de oprichtingsflow zal binnenkort beschikbaar zijn in je Dashboard. We houden je op de hoogte van de volgende stappen!'}</h3>
                    <div className="finish__box">
                        {question}
                        <h4>{localStorage.getItem('i18nextLng') === 'en' ? 'In the meantime...' : 'Ondertussen...'}</h4>
                        <NavLink to={'support'} className="btn btn--primary">{localStorage.getItem('i18nextLng') === 'en' ? 'Schedule a call' : 'Plan een call'}</NavLink>
                        <NavLink to={'support'} className="btn btn--primary">{localStorage.getItem('i18nextLng') === 'en' ? 'Send us a message' : 'Stuur ons een bericht'}</NavLink>
                        <NavLink to={'support'} className="btn btn--primary">{localStorage.getItem('i18nextLng') === 'en' ? 'Provide feedback' : 'Feedback geven'}</NavLink>
                    </div>
                    <p>{localStorage.getItem('i18nextLng') === 'en' ? 'Something not clear? An urgent question on your mind? Feel free to schedule a call or send us a message!' : 'Iets niet duidelijk? Zit je met een dringende vraag? Plan gerust een gesprek in of stuur ons een bericht!'}</p>
                </div>
            </div>
        </>
    );
}

export default Finish;
