import React, { useState } from 'react';
import './adminChecklist.scss';
import { Checkbox, FormControlLabel } from "@material-ui/core";
import avatar from '../../assets/avatar.svg';
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";

function AdminChecklist(props) {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [descriptionInternal, setDescriptionInternal] = useState('');
    const [descriptionClient, setDescriptionClient] = useState('');
    const [assignee, setAssignee] = useState('');

    const handleOpen = (value, name, descriptionInternal, descriptionClient, assignee) => {
        setOpen(value);
        setName(name);
        setDescriptionInternal(descriptionInternal);
        setDescriptionClient(descriptionClient);
        setAssignee(assignee);
    }

    const check = (value, e) => {
        let body = {
            value
        }
        Connect(`${Endpoint.patchChecklist}/${sessionStorage.getItem('userID')}`, 'PATCH', value).then(response => {
            props.handleData();
        });
    }

    return (
        <div className="adminChecklist">
            <div className="adminChecklist__box">
                {props.data && props.data.map((data, index) => (
                    <div className="adminChecklist__box-item" onClick={() => handleOpen(true, data.name, data.descriptionAdmin, data.descriptionUser, data.assignee)} key={index}>
                        <div className="box">
                            <FormControlLabel label={''} control={<Checkbox color={'#4A5BCF'} onChange={(e) => check(data.generalCheckpointValue, e)} checked={data.checked} />} />
                        </div>
                        <div className="box">{data.name}</div>
                        <div className="box">
                            <div className="adminChecklist__category">{data.checkpointCategory}</div>
                        </div>
                        <div className="box">
                            <img alt="" src={avatar} className="adminChecklist__logo" />
                        </div>
                    </div>
                ))}
            </div>
            <div className={open === true ? "adminChecklist__extend active" : "adminChecklist__extend"}>
                <div className="title">{name}</div>
                <div className="text award">Assigned to</div>
                <div className="adminChecklist__extend-assigned">
                    <img alt="" src={avatar} />
                    <div className="text avatar">{assignee}</div>
                </div>
                <div className="adminChecklist__extend-box">
                    <div className="subtitle">Description internal</div>
                    <div className="text">{descriptionInternal}</div>
                </div>
                <div className="adminChecklist__extend-box">
                    <div className="subtitle">Description client</div>
                    <div className="text">{descriptionClient}</div>
                    <div className="text">You can either immediately sign the plan if everything seems correct, or schedule a call with our team to discuss what needs to be changed.</div>
                </div>
            </div>
        </div>
    );
}

export default AdminChecklist;
