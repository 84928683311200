import React from "react";
import {Trans} from "react-i18next";

export const tableHeaderSurvey = [
    { id: 'nameTemplate', label: <Trans>clients.template</Trans>, mobile: true},
    { id: 'creationDate', label: <Trans>survey.creation</Trans>, mobile: true},
    { id: 'option', label: '', typeOption: ['defaultBtn','edit','delete'], mobile: false}
]

export const selectTypeQuestion = [
    { value: 'SLT', label: 'SLT'},
    { value: 'MLT', label: 'MLT'},
    { value: 'AIN', label: 'AIN'},
    { value: 'MPC', label: 'MPC'},
    { value: 'CAL', label: 'CAL'},
    { value: 'FUL', label: 'FUL'},
    { value: 'SLS', label: 'SLS'},
    { value: 'DDM', label: 'DDM'},
];

export const AddIcon = <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.78947 10.5167H10.4211V5.84261H11.5789V10.5167H16.2105V11.6852H11.5789V16.3593H10.4211V11.6852H5.78947V10.5167ZM11 0C13.9174 0 16.7153 1.16956 18.7782 3.25139C20.8411 5.33323 22 8.1568 22 11.101C22 14.0451 20.8411 16.8687 18.7782 18.9505C16.7153 21.0323 13.9174 22.2019 11 22.2019C8.08262 22.2019 5.28473 21.0323 3.22183 18.9505C1.15893 16.8687 0 14.0451 0 11.101C0 8.1568 1.15893 5.33323 3.22183 3.25139C5.28473 1.16956 8.08262 0 11 0V0ZM11 1.16852C8.38971 1.16852 5.88633 2.21497 4.04058 4.07766C2.19483 5.94036 1.15789 8.46671 1.15789 11.101C1.15789 13.7352 2.19483 16.2616 4.04058 18.1242C5.88633 19.9869 8.38971 21.0334 11 21.0334C13.6103 21.0334 16.1137 19.9869 17.9594 18.1242C19.8052 16.2616 20.8421 13.7352 20.8421 11.101C20.8421 8.46671 19.8052 5.94036 17.9594 4.07766C16.1137 2.21497 13.6103 1.16852 11 1.16852Z" fill="#37517E" fillOpacity="0.4"/>
</svg>;

export const DeleteIcon = (
    <svg width="17" height="20" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.13542 1.62H4.95833C5.05573 1.62 5.13542 1.539 5.13542 1.44V1.62H11.8646V1.44C11.8646 1.539 11.9443 1.62 12.0417 1.62H11.8646V3.24H13.4583V1.44C13.4583 0.64575 12.823 0 12.0417 0H4.95833C4.17695 0 3.54167 0.64575 3.54167 1.44V3.24H5.13542V1.62ZM16.2917 3.24H0.708333C0.316536 3.24 0 3.56175 0 3.96V4.68C0 4.779 0.0796875 4.86 0.177083 4.86H1.51406L2.06081 16.6275C2.09622 17.3948 2.72044 18 3.47526 18H13.5247C14.2818 18 14.9038 17.397 14.9392 16.6275L15.4859 4.86H16.8229C16.9203 4.86 17 4.779 17 4.68V3.96C17 3.56175 16.6835 3.24 16.2917 3.24ZM13.3543 16.38H3.6457L3.11003 4.86H13.89L13.3543 16.38Z" fill="#37517E" fillOpacity="0.3"/>
    </svg>
)

export const InfoIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="10" fill="#EBF2FF"/>
    <rect x="11" y="10" width="2" height="7" rx="1" fill="#4A5BCF"/>
    <rect x="11" y="7" width="2" height="2" rx="1" fill="#4A5BCF"/>
</svg>

export const redInfoIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <circle opacity="0.3" cx="12" cy="12" r="10" fill="#E94F37"/>
    <rect x="11" y="7" width="2" height="8" rx="1" fill="#E94F37"/>
    <rect x="11" y="16" width="2" height="2" rx="1" fill="#E94F37"/>
</svg>

export const calendar = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 6.5C4.34315 6.5 3 7.84315 3 9.5V18.5C3 20.1569 4.34315 21.5 6 21.5H18C19.6569 21.5 21 20.1569 21 18.5V9.5C21 7.84315 19.6569 6.5 18 6.5H6ZM7 11.5C5.89543 11.5 5 12.3954 5 13.5V17.5C5 18.6046 5.89543 19.5 7 19.5H17C18.1046 19.5 19 18.6046 19 17.5V13.5C19 12.3954 18.1046 11.5 17 11.5H7Z" fill="#4A5BCF"/>
    <path d="M5 4C5 3.44772 5.44772 3 6 3C6.55228 3 7 3.44772 7 4V5.5H5V4Z" fill="#666AED"/>
    <path d="M17 4C17 3.44772 17.4477 3 18 3C18.5523 3 19 3.44772 19 4V5.5H17L17 4Z" fill="#666AED"/>
</svg>

export const removeIcon = <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 5.33331V13.1666C4 13.9951 4.67157 14.6666 5.5 14.6666H10.5C11.3284 14.6666 12 13.9951 12 13.1666V5.33331H4Z" fill="#4A5BCF"/>
<path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M9.3335 3C9.3335 2.44772 8.88578 2 8.3335 2H7.66683C7.11455 2 6.66683 2.44772 6.66683 3H3.8335C3.55735 3 3.3335 3.22386 3.3335 3.5C3.3335 3.77614 3.55735 4 3.8335 4H12.1668C12.443 4 12.6668 3.77614 12.6668 3.5C12.6668 3.22386 12.443 3 12.1668 3H9.3335Z" fill="#ADAFDC"/>
</svg>