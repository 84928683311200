import React from 'react';
import './checkbox.scss';

function Checkbox(props) {
    const handleInput = () => {
        document.querySelector('#rememberMe').click();
    }

    return (
        <div className="checkbox">
            <input
                id="rememberMe"
                type="checkbox"
                checked={props.isChecked}
                name="rememberMe"
                onChange={props.handleChangeCheckbox}
            />
            <div
                className={`checkbox-box ${props.isChecked && "checkbox-box-active"}`}
                onClick={handleInput}
            >
                <div className="checkbox-tick">
                    {props.isChecked && <p>✓</p>}
                </div>
            </div>
            <div className="checkbox-label" onClick={handleInput}>
                <p>{props.label}</p>
            </div>
        </div>
    );
}

export default Checkbox;
