import React, { useState, useEffect } from 'react';
import Menu from "../menu/menu";
import Quickstart from "../quickstart/quickstart";
import Navbar from "../navbar/navbar";
import './files.scss';
import { download, editAdminIcon, extend, fullScreen, infoIcon, print } from "../login/loginConstants";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import {Trans, withTranslation} from "react-i18next";
import {withCookies} from "react-cookie";
import {fetchData, openDocument} from "../admin/adminFiles";
import DocumentPdf from "../document/document";

const Files = (props) => {
    const [quickstart, setQuickstart] = useState(false);
    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState(0);
    const [data, setData] = useState([]);
    const [documentActive, setDocumentActive] = useState(false);
    const [fullScreenStatus, setFullScreenStatus] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [deleteDocument, setDeleteDocument] = useState(0);
    const [documentUrl, setDocumentUrl] = useState('');
    const [documentStatus,setDocumentStatus] = useState(null);

    const handleQuickstart = (value) => {
        setQuickstart(value);
    }

    const handleDropdown = (e, value, current) => {
        const allPopups = document.querySelectorAll(".item__extend");
        for (let i = 0; i < allPopups.length; i++) {
            allPopups[i].classList.remove('active');
        }
        if (e.target.querySelector('.item__extend') !== null) {
            if (value === 'add' && current === false) {
                // setTimeout(() => {
                    e.target.querySelector('.item__extend').classList.add('active');
                // }, 1);
            } else if (value === 'remove' && current === false) {
                e.target.querySelector('.item__extend').classList.remove('active');
            }
        } else {

            if (e.target.closest('.item__extend') !== null) {
                e.target.closest('.item__extend').classList.remove('active');
            } else {
                e.target.classList.remove('active');
            }
        }
    }

    useEffect(() => {
        getRes();
    }, []);

    const getRes = () => {
        Connect(`${Endpoint.userFiles}`, 'GET').then(response => {
            setData(response);
        })
    }

    const open = (id, url) => {
        fetchData(url, setPdfUrl, url.split('/')[url.split('/').length - 1]).then((res) => console.log(res));
    }

    const info = (id, url, status, type) => {
        setDeleteDocument(id);
        setDocumentUrl(url);
        setDocumentActive(true);
        setDocumentStatus(status);
        openDocument(url, setPdfUrl, type).then(r => console.log(r));
    }

    return (
        <div className="container">
            <Navbar currentStep={step} progress={progress} handleQuickstart={handleQuickstart} name={'Files'} handleUserFiles={getRes} {...props} />
            <Menu role={'user'} permissions={null} isMobile={false} component={'company'} />
            <Quickstart status={quickstart} handleQuickstart={handleQuickstart} />
            <div className="files">
                <p><Trans>files.there</Trans></p>
                <div className="row">
                    <div className="box">
                        <div className="files__header">
                            <div className="item">
                                <p><Trans>profile.name</Trans></p>
                            </div>
                            <div className="item">
                                <p><Trans>files.sign</Trans></p>
                            </div>
                            <div className="item">
                                <p><Trans>files.date</Trans></p>
                            </div>
                        </div>
                        {data.map((data) => (
                            <div className={data.id === deleteDocument ? "files__content active" : "files__content"}>
                                <div className="item" onClick={() => info(data.id, data.fileUri, data.signStatus, 'inside')} style={{cursor: 'pointer'}}>
                                    <p>{data.name}</p>
                                </div>
                                <div className={data.signStatus === 'NOT_READY' ? "item pending" : data.signStatus === 'TO_SIGN' ? "item almost" : "item success"}>
                                    <p>{data.signStatus === 'NOT_READY' ? 'Not ready!' : data.signStatus === 'TO_SIGN' ? 'To sign!' : 'Signed!'}</p>
                                </div>
                                <div className="item date">
                                    <p>{data.signDate !== null ? data.signDate : '-'}</p>
                                </div>
                                <div className="item extend" onMouseEnter={(e) => handleDropdown(e, 'add', false)}>
                                    {extend}
                                    <div className="item__extend" onMouseLeave={(e) => handleDropdown(e, 'remove', true)}>
                                        <div className="text" onClick={() => info(data.id, data.fileUri, data.signStatus, 'inside')}>Info</div>
                                        <div className="text" onClick={() => info(data.id, data.fileUri, data.signStatus, 'tab')}><Trans>files.enlarge</Trans></div>
                                        <div className="text" onClick={() => open(data.id, data.fileUri)}><Trans>establishmentFlow.download</Trans></div>
                                        <div className="text" onClick={() => info(data.id, data.fileUri, data.signStatus, 'tab')}><Trans>files.print</Trans></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="box">
                        <div className={(documentActive === false) || (documentStatus === 'NOT_READY') || (documentStatus === 'TO_SIGN') ? "files__header blocked" : "files__header"}>
                            <div className="icon" onClick={() => open(deleteDocument, documentUrl)}>{download}</div>
                            <div className="icon" onClick={() => info(deleteDocument, documentUrl, null, 'tab')}>{print}</div>
                            <div className="icon" onClick={() => info(deleteDocument, documentUrl, null, 'tab')}>{fullScreen}</div>
                        </div>
                        <div className={(documentActive === true) && (documentStatus === 'NOT_READY' || documentStatus === 'TO_SIGN') ? "files__content active" : "files__content"}>
                            {documentActive === false ?
                                <p><Trans>files.select</Trans></p>
                                :
                                <>
                                    {documentStatus === 'NOT_READY' ?
                                        <div className="files__popup">
                                            <div
                                                className="files__popup-header">{localStorage.getItem('i18nextLng') === 'en' ? 'Document not ready yet' : 'Document nog niet klaar'}</div>
                                            <div
                                                className="files__popup-text">{localStorage.getItem('i18nextLng') === 'en' ? 'We’re finalising the details of this document. You will receive a notification once the document is ready to sign!' : 'We leggen de laatste hand aan de details van dit document. Je ontvangt een bericht zodra het document klaar is om te ondertekenen!'}</div>
                                        </div>
                                    :
                                        documentStatus === 'TO_SIGN' ?
                                            <div className="files__popup">
                                                <div
                                                    className="files__popup-header">{localStorage.getItem('i18nextLng') === 'en' ? 'The document has been sent for signature.' : '\n' +
                                                    'Het document is ter ondertekening verzonden.'}</div>
                                            </div>
                                        :
                                            pdfUrl ? (
                                                <DocumentPdf pdf={pdfUrl} />
                                            ) : (
                                                <p className="d-none">Loading file...</p>
                                            )
                                    }
                                </>

                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={fullScreenStatus === false ? "files__fullscreen" : "files__fullscreen active"}>
                <div className="files__sidebar">
                    <div className="files__sidebar-document">
                        <div className="text">Temporary document PDF</div>
                    </div>
                    <div className="text">1</div>
                </div>
                <div className="files__fullscreen-header">
                    <div className="icon" onMouseEnter={() => document.querySelector('.files__fullscreen-popup').classList.add('active')} onMouseLeave={() => document.querySelector('.files__fullscreen-popup').classList.remove('active')}>{infoIcon}</div>
                    <div className="icon">{editAdminIcon}</div>
                    <div className="icon" onClick={() => open(deleteDocument, documentUrl)}>{download}</div>
                    <div className="icon" onClick={() => open(deleteDocument, documentUrl)}>{print}</div>
                    <div className="icon" onClick={() => setFullScreenStatus(false)}>{fullScreen}</div>
                </div>
                <div className="files__fullscreen-popup">
                    <div className="text header">Composition assignment</div>
                    <div className="text">This document contains information about the founders of the company, and the conditions under which they will manage the company to be established.</div>
                    <div className="row">
                        <div className="box">
                            <div className="text small"><b>Type:</b> PDF</div>
                            <div className="text small"><b>Size:</b> 1.3 MB</div>
                        </div>
                        <div className="box">
                            <div className="text small"><b>Created date:</b> 18/04/2023</div>
                            <div className="text small"><b>Signed date:</b> 20/04/2023</div>
                        </div>
                    </div>
                </div>
                <div className="files__fullscreen-content">
                    <h2>Temporary document PDF</h2>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(withCookies(Files));
