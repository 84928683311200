import React, { useState } from "react";
import { TextField, Tooltip, IconButton } from "@material-ui/core";
import InputMask from 'react-input-mask';
import CustomSelect from "../customSelect/customSelect";
import { InfoIcon } from "../survey/surveyConstant";
import {Trans} from "react-i18next";

function Step1(props) {
    const [selectList] = useState([
        { id: 517, name: localStorage.getItem('i18nextLng') === 'en' ? 'Belgian' : 'Belg', value: localStorage.getItem('i18nextLng') ? 'Belgian' : 'Belg', textKey: 'question-option-belgian' },
        { id: 516, name: localStorage.getItem('i18nextLng') === 'en' ? 'Inside EU' : 'Binnen de EU', value: localStorage.getItem('i18nextLng') === 'en' ? 'Inside EU' : 'Binnen de EU', textKey: 'question-option-inside-eu' },
        { id: 518, name: localStorage.getItem('i18nextLng') === 'en' ? 'Outside EU' : 'Buiten de EU', value: localStorage.getItem('i18nextLng') === 'en' ? 'Outside EU' : 'Buiten de EU', textKey: 'question-option-outside-eu' }
    ]);

    return (
        <div className="container__body">
            <div className="container__body-header"><Trans>companyType.intro</Trans>
                <Tooltip title={<Trans>companyType.tooltip1</Trans>} placement={'right'}>
                    <IconButton aria-label="delete">
                        {InfoIcon}
                    </IconButton>
                </Tooltip>
            </div>
            <div className="container__body-box">
                <div className={props.validateForm.name !== undefined && props.validateForm.name === 'false' ? "container__body-input is-error" : "container__body-input"}>
                    <p className="container__body-text">{sessionStorage.getItem('companyTypeData') && JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[0].steps[0].questions[0].questionText} <span className={props.validateForm.name !== undefined && props.validateForm.name === 'false' ? "require is-error" : "require"}>*</span></p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={props.data.name} onChange={(e) => props.handleData('name', e.target.value)} />
                </div>
                <div className={props.validateForm.surname !== undefined && props.validateForm.surname === 'false' ? "container__body-input is-error" : "container__body-input"}>
                    <p className="container__body-text">{sessionStorage.getItem('companyTypeData') && JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[0].steps[0].questions[1].questionText} <span className={props.validateForm.surname !== undefined && props.validateForm.surname === 'false' ? "require is-error" : "require"}>*</span></p>
                    <TextField label={''} variant={'outlined'} size={'small'} autoComplete={'new-password'} value={props.data.surname} onChange={(e) => props.handleData('surname', e.target.value)} />
                </div>
            </div>
            <div className="container__body-box">
                <div className="container__body-input">
                    <p className="container__body-text">{sessionStorage.getItem('companyTypeData') && JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[0].steps[0].questions[2].questionText}
                        <Tooltip title={<Trans>companyType.tooltip2</Trans>} placement={'right'}>
                            <IconButton aria-label="delete">
                                {InfoIcon}
                            </IconButton>
                        </Tooltip>
                    </p>
                    <TextField label={''} variant={'outlined'} size={'small'} value={props.data.email} disabled />
                </div>
                <div className="container__body-input">
                    <p className="container__body-text">{sessionStorage.getItem('companyTypeData') && JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[0].steps[0].questions[3].questionText}</p>
                    <InputMask mask="+(99) 999 999 999 999 999 999" maskChar=" " class="phoneInput" autoComplete={'new-password'} value={props.data.phone} onChange={(e) => props.handleData('phone', e.target.value)} ></InputMask>
                </div>
            </div>
            <div className="container__body-box">
                <div className={props.validateForm.nationality !== undefined && props.validateForm.nationality === 'false' ? "container__body-select is-error" : "container__body-select"}>
                    <p className="container__body-text">{sessionStorage.getItem('companyTypeData') && JSON.parse(sessionStorage.getItem('companyTypeData')).surveyPages[0].steps[0].questions[4].questionText} <span className={props.validateForm.nationality !== undefined && props.validateForm.nationality === 'false' ? "require is-error" : "require"}>*</span></p>
                    <CustomSelect
                        defaultText={sessionStorage.getItem('companyType') && JSON.parse(sessionStorage.getItem('companyType')).nationality[0] && JSON.parse(sessionStorage.getItem('companyType')).nationality[0].text}
                        optionsList={selectList}
                        handleData={props.handleData}
                        type={'nationality'}
                    />
                </div>
            </div>
            {(props.validateForm.name !== undefined && props.validateForm.name === 'false') || (props.validateForm.surname !== undefined && props.validateForm.surname === 'false') || (props.validateForm.nationality !== undefined && props.validateForm.nationality === 'false') ?
                <p className="errorMessage"><Trans>companyType.valid</Trans></p>
                :
                <></>}
        </div>
    );
}

export default Step1;
