import React, { useState, useEffect } from 'react';
// import CustomSwitch from "../switch/switch";

const Role = (props) => {
    const [state] = useState({ ...props.data });

    // const validateRoles = (name, value) => {
    //     // Implement validateRoles logic here
    // };

    // const handleChange = (e) => {
    //     const name = e.target.getAttribute('name');
    //     console.log(name);
    //     if (props.canEdit) {
    //         setState((prevState) => {
    //             const updatedState = { ...prevState, [name]: !prevState[name] };
    //             validateRoles(name, updatedState[name]);
    //             console.log('test');
    //             if (props.isAdd) {
    //                 props.onChange(updatedState);
    //             }
    //             return updatedState;
    //         });
    //     }
    // };

    // const renderPermission = (P, variable, name) => {
    //     const addClass = props.isAdd ? ' permission__item__add' : '';
    //     return (
    //         <div className={"permission__item" + addClass}>
    //             <p>{P}</p>
    //             <CustomSwitch checked={variable} name={name} onChange={handleChange} className="permission--switch" />
    //         </div>
    //     );
    // };

    useEffect(() => {
        // You can add any side effects here, if needed.
    }, []); // Empty dependency array means this effect runs once, similar to componentDidMount

    const addPermissionClass = props.isAdd ? ' permission__add' : '';
    // const addGroupClass = props.isAdd ? ' permission__group__add' : '';

    return (
        <>
            {!props.isAdd && (
                <div className="container__title container-justifySpace">
                    <h2>{state.position}</h2>
                </div>
            )}

            <div className={"permission" + addPermissionClass}>
                {/* Render permission groups and items here */}
            </div>
        </>
    );
};

export default Role;
