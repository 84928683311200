// request behavior when fetch return error

import React from "react";
import Cookies from "universal-cookie";

const requestBehavior = (request) => {
    console.log(request);
    const status = request.status;
    let messages = '';

    switch(status){
        case 401:
            window.location.href = "/login";
            // remove cookies
            const cookies = new Cookies();
            cookies.remove('loginData', { path: '/' });
        break;
        case 404:
            window.location.href = "/error"
        break;
        case 403:
            messages = 'No permission'
        break;
        case 500:
            messages = 'Error data'
        break;
        default: console.log('unknown error')
    }

    return <span>{messages}</span>
}

export default requestBehavior;