import React, { useState, useEffect } from 'react';
import { editAdminIcon, selectArrow } from "../login/loginConstants";
import Edit from "./edit";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import {Trans} from "react-i18next";

function PersonalIncomeTax(props) {
    const [edit, setEdit] = useState(false);
    const [header] = useState('Edit');
    const [inputName, setInputName] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [index, setIndex] = useState(0);
    const [type, setType] = useState('mainOccupation');

    const [personalIncomeTax, setPersonalIncomeTax] = useState([]);


    useEffect(() => {
        let personalIncomeTax = []
        props.data.map((data) => {
            if (data.type === 'PERSONAL_TAX') {
                personalIncomeTax.push([data.name, `${data.isCurrency === true ? '€ ' : ''}${data.numberValue}${data.isPercent === true ? '%' : ''}`, data.numberValue !== 0, data.id])
            }
        })
        setPersonalIncomeTax(personalIncomeTax);
    }, [props]);

    const handleEdit = (open, name, value, index, type) => {
        setEdit(open);
        setInputName(name);
        setInputValue(value);
        setIndex(index);
        setType(type);
    }

    const save = () => {
        Connect(`${Endpoint.getParameters}/${index}`, 'GET').then(response => {
            let body = response;
            const {costType, maxValue, ...newObject} = body;
            newObject.numberValue = inputValue.replaceAll('.','').replace(',', '.');
            props.handleSave(newObject);
            handleEdit(false, '', '', 0, 'mainOccupation');
        })
    }

    const addCommas = (num) => {
        if (num.length > 1) {
            if (num[0] === "0") {
                num = num.slice(1);
            }
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    const formatValue = (num) => {
        let value = addCommas(num.toString().replace('.',','))
        let rest = value.split(',')[1];
        if(rest && rest.length === 1){
            value = value + "0"
        }
        return value;
    }

    return (
        <>
            <div className="parameters__box">
                <div className="row">
                    <div className="parameters__header"><Trans>parametersMenu.tax</Trans></div>
                    <div className="box">
                        <div className="row">
                            <div className="parameters__text select">
                                <Trans>admin.valid</Trans> <span>01/01/2023 - 31/12/2023</span>
                                {selectArrow}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row spaceBetween">
                    <div className="parameters__text light"><Trans>admin.parameters</Trans></div>
                    <div className="parameters__text light"><Trans>admin.value</Trans></div>
                </div>
                <div className="box main">
                    {personalIncomeTax.map((data, i) => (
                        <div className="row" key={i}>
                            <div className="parameters__text">{data[0]}</div>
                            {data[2] === true ? <div className="edit" onClick={() => handleEdit(true, data[0], data[1], data[3], 'mainOccupation')}>{editAdminIcon}</div> : ''}
                            <div className="parameters__text bold">{formatValue(data[1])}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="parameters__box d-none">
                <div className="row">
                    <div className="parameters__header"><Trans>admin.example</Trans></div>
                    <div className="box">
                        <div className="row">
                            <div className="parameters__text select">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row spaceBetween">
                </div>
                <div className="box main">
                </div>
            </div>
            <Edit active={edit} handleActive={handleEdit} handleSave={save} header={header} inputName={inputName} inputValue={inputValue} index={index} type={type} />
        </>
    );
}

export default PersonalIncomeTax;
