import React, { useState } from "react";
import SaveButton from "../saveButton/saveButton";
import * as Endpoint from "../../configFile";
import '../settings/_settings.scss';
import { Trans } from 'react-i18next';

function Password() {
    const [state, setState] = useState({
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
    });

    const changeValue = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));

        const sendButton = document.querySelector('.sendData');
        let { newPassword, repeatPassword } = state;

        switch (name) {
            case 'newPassword':
                newPassword = value;
                break;
            case 'repeatPassword':
                repeatPassword = value;
                break;
            default:
                console.log('Error value or oldPassword');
        }

        document.querySelector('.require').style.display = "none";
        document.querySelector('.inconsistent').style.display = "none";

        if (newPassword !== repeatPassword) {
            document.querySelector('.inconsistent').style.display = "block";
            sendButton.classList.add('disabled');
        } else {
            sendButton.classList.remove('disabled');
        }

        if (newPassword !== '') {
            if (newPassword.length < 8 || newPassword.match(/[1-9]/g) === null || newPassword.match(/[a-z]/g) === null) {
                document.querySelector('.require').style.display = "block";
                sendButton.classList.add('disabled');
            } else {
                sendButton.classList.remove('disabled');
            }
        }
    };

    return (
        <>
            <div className="container__body container--settings">
                <h2 className="container__title"><Trans>password.change</Trans></h2>
                <form className="settings__block">
                    <div className="input-group">
                        <label htmlFor={'old-password'}><Trans>password.old</Trans></label>
                        <input type="password" name={'oldPassword'} id={'old-password'} value={state.oldPassword} onChange={changeValue} autoComplete="on" required />
                        <label className="label--error d-none"><Trans>basic.required</Trans></label>
                    </div>
                    <div className="input-group">
                        <label htmlFor={'new-password'}><Trans>password.new</Trans></label>
                        <input type="password" name={'newPassword'} id={'new-password'} value={state.newPassword} onChange={changeValue} autoComplete="on" required />
                        <label className="label--error d-none"><Trans>basic.required</Trans></label>
                    </div>
                    <div className="input-group">
                        <label htmlFor={'repeat-password'}><Trans>password.repeat</Trans></label>
                        <input type="password" name={'repeatPassword'} id={'repeat-password'} value={state.repeatPassword} onChange={changeValue} autoComplete="on" required />
                        <label className="label--error d-none"><Trans>basic.required</Trans></label>
                    </div>
                    <span className={"label--error global-validation require"} style={{ display: "none" }}><Trans>password.require</Trans></span>
                    <span className={"label--error global-validation inconsistent"} style={{ display: "none" }}><Trans>password.inconsistent</Trans></span>
                </form>

                <SaveButton url={Endpoint.changePassword} data={JSON.stringify(state)} />
            </div>
        </>
    );
}

export default Password;
