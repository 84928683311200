import React, { useState, useEffect } from 'react';
import CustomSwitch from "../switch/switch";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { InfoIcon } from "../survey/surveyConstant";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";

function ProfileNotifications() {
    const [data, setData] = useState([true, true, false]);

    useEffect(() => {
        getUserNotificationsConsents();
    }, []);

    const getUserNotificationsConsents = () => {
        Connect(`${Endpoint.getNotificationsConsents}`, 'GET').then(response => {
            const tempData = [response[0].isConsent, response[1].isConsent, response[2].isConsent];
            setData(tempData);
        });
    }

    const handleActive = (index, value) => {
        let temp = [...data];
        temp[index] = value;
        setData(temp);

        if (index === 0) {
            handleUpdate(value, 'APP_INSIDE');
        }
        if (index === 1) {
            handleUpdate(value, 'APP_EMAIL');
        }
        if (index === 2) {
            handleUpdate(value, 'PRODUCT_EMAIL');
        }
    }

    const handleUpdate = (value, type) => {
        const body = {
            isConsent: value,
            notificationType: type
        }
        Connect(`${Endpoint.getNotificationsConsents}`, 'PATCH', body).then(response => {
            // Handle the response, if needed
        });
    }

    return (
        <>
            <div className="profile">
                <div className="row margin">
                    <div className="box">
                        <div className="profile__header">Notifications</div>
                        <div className="profile__box notify">
                            <div className="profile__input main">
                                <div className="box">
                                    <div className="profile__header">Receive notifications inside the platform</div>
                                    <div className="profile__option">
                                        <ul>
                                            <li>When a new document is ready to sign</li>
                                            <li>When a task is assigned to you</li>
                                            <li>When we have an update about your establishment</li>
                                            <li>And more...</li>
                                        </ul>
                                    </div>
                                    <div className="container__body-input">
                                        <CustomSwitch checked={data[0]} onClick={(e) => handleActive(0, e.target.checked)} />
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="profile__header">
                                        Receive email notifications
                                        <Tooltip title="You will still have access to all files on the platform when this is turned off." placement={'right'}>
                                            <IconButton aria-label="delete">
                                                {InfoIcon}
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div className="profile__option">
                                        <ul>
                                            <li>When a new document is ready to sign</li>
                                            <li>When a task is assigned to you</li>
                                            <li>When we have an update about your establishment</li>
                                            <li>And more...</li>
                                        </ul>
                                    </div>
                                    <div className="container__body-input">
                                        <CustomSwitch checked={data[1]} onClick={(e) => handleActive(1, e.target.checked)} />
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="profile__header">Receive emails about product updates and new features</div>
                                    <div className="profile__option">
                                        <div className="text">We send out a periodic newsletter with updates about our platform or announcements for new features and integrations.</div>
                                    </div>
                                    <div className="container__body-input">
                                        <CustomSwitch checked={data[2]} onClick={(e) => handleActive(2, e.target.checked)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfileNotifications;
