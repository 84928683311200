import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {InfoIcon} from "../survey/surveyConstant";
import {editIcon, removeIcon} from "../login/loginConstants";
import {TextField, InputAdornment} from "@material-ui/core";
import Confirm from "../confirm/confirm";
import {Connect} from "../connect/connect";
import * as Endpoint from "../../configFile";
import CustomSelect from "../customSelect/customSelect";
import './revenue.scss';
import {Trans} from "react-i18next";

class Revenue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            confirm: false,
            type: 'SIMPLE',
            summary: [],
            revenue: {
                id: null,
                name: '',
                description: '',
                monthlyRevenue: '',
                averageHourlyRate: '',
                averageMonthly: '',
                totalCustomers: '',
                takeRate: '',
                averageTransactionSize: '',
                totalTransactionsMonth: '',
                revenuePerUnit: '',
                unitsMonth: '',
                revenue: '',
                varianceNextYear: 5,
                varianceThirdYear: 10,
                revenueYearOne: null,
                revenueYearTwo: null,
                revenueYearThree: null
            },
            firstYearMonths: JSON.parse(sessionStorage.getItem('financialCalendar')).periodOneFrom !== null ? (12 - parseInt(JSON.parse(sessionStorage.getItem('financialCalendar')).periodOneFrom.substring(5,7))) : 12,
            year: 'Year 1'
        }
    }

    componentDidMount() {
        this.getRes();
        let body = {
            checkpointCategory: JSON.parse(sessionStorage.getItem('checkpointCategoryEstablishment'))[sessionStorage.getItem('legalForm') === 'LIMITED_COMPANY' ? 3 : 1],
            completed: 1,
            required: 1,
            isChange: true
        };
        Connect(`${Endpoint.putProgressSurvey}`, 'PUT', body).then(response => {
            console.log(response);
        });
    }

    getRes = () => {
        Connect(`${Endpoint.getUserRevenue}`, 'GET').then(response => {
            this.setState({
                data: response,
                revenue: {
                    id: null,
                    name: '',
                    description: '',
                    monthlyRevenue: '',
                    averageHourlyRate: '',
                    averageMonthly: '',
                    totalCustomers: '',
                    takeRate: '',
                    averageTransactionSize: '',
                    totalTransactionsMonth: '',
                    revenuePerUnit: '',
                    unitsMonth: '',
                    revenue: '',
                    varianceNextYear: 5,
                    varianceThirdYear: 10
                }
            })
            sessionStorage.removeItem('revenue');
        })
    }

    handleRevenue = (param, value) => {
        this.setState({
            revenue: {
                id: this.state.revenue.id,
                name: param === 'name' ? value : sessionStorage.getItem('revenue') && JSON.parse(sessionStorage.getItem('revenue')).name,
                description: param === 'description' ? value : sessionStorage.getItem('revenue') && JSON.parse(sessionStorage.getItem('revenue')).description,
                monthlyRevenue: param === 'monthlyRevenue' ? this.addCommas(this.removeNonNumeric(value)) : sessionStorage.getItem('revenue') && JSON.parse(sessionStorage.getItem('revenue')).monthlyRevenue,
                averageHourlyRate: param === 'averageHourlyRate' ? this.addCommas(this.removeNonNumeric(value)) : sessionStorage.getItem('revenue') && JSON.parse(sessionStorage.getItem('revenue')).averageHourlyRate,
                averageMonthly: param === 'averageMonthly' ? this.addCommas(this.removeNonNumeric(value)) : sessionStorage.getItem('revenue') && JSON.parse(sessionStorage.getItem('revenue')).averageMonthly,
                totalCustomers: param === 'totalCustomers' ? this.addCommas(this.removeNonNumeric(value)) : sessionStorage.getItem('revenue') && JSON.parse(sessionStorage.getItem('revenue')).totalCustomers,
                takeRate: param === 'takeRate' ? value : sessionStorage.getItem('revenue') && JSON.parse(sessionStorage.getItem('revenue')).takeRate,
                averageTransactionSize: param === 'averageTransactionSize' ? this.addCommas(this.removeNonNumeric(value)) : sessionStorage.getItem('revenue') && JSON.parse(sessionStorage.getItem('revenue')).averageTransactionSize,
                totalTransactionsMonth: param === 'totalTransactionsMonth' ? this.addCommas(this.removeNonNumeric(value)) : sessionStorage.getItem('revenue') && JSON.parse(sessionStorage.getItem('revenue')).totalTransactionsMonth,
                revenuePerUnit: param === 'revenuePerUnit' ? this.addCommas(this.removeNonNumeric(value)) : sessionStorage.getItem('revenue') && JSON.parse(sessionStorage.getItem('revenue')).revenuePerUnit,
                unitsMonth: param === 'unitsMonth' ? this.addCommas(this.removeNonNumeric(value)) : sessionStorage.getItem('revenue') && JSON.parse(sessionStorage.getItem('revenue')).unitsMonth,
                revenue: param === 'revenue' ? this.addCommas(this.removeNonNumeric(value)) : sessionStorage.getItem('revenue') && JSON.parse(sessionStorage.getItem('revenue')).revenue,
            }
        })
        setTimeout(() => {
            if (this.state.type === 'SIMPLE' && this.state.revenue.monthlyRevenue !== null) {
                this.recalculate(this.state.revenue.monthlyRevenue.replaceAll('.', ''), true);
            } else if (this.state.type === 'SERVICE' && this.state.revenue.averageHourlyRate !== null && this.state.revenue.averageMonthly !== null && this.state.revenue.totalCustomers !== null) {
                let averageHourlyRate = this.state.revenue.averageHourlyRate.replaceAll('.', '');
                let averageMonthly = typeof this.state.revenue.averageMonthly === 'string' ? this.state.revenue.averageMonthly.replaceAll('.', '') : this.state.revenue.averageMonthly;
                let totalCustomers = this.state.revenue.totalCustomers.replaceAll('.', '');
                let sum = averageHourlyRate * averageMonthly * totalCustomers;
                this.recalculate(`${sum}`, true);
            } else if (this.state.type === 'TRANSACTIONAL' && this.state.revenue.averageTransactionSize !== null && this.state.revenue.takeRate !== null && this.state.revenue.totalTransactionsMonth !== null) {
                let takeRate = this.state.revenue.takeRate.replaceAll('.', '');
                let averageTransactionSize = this.state.revenue.averageTransactionSize.replaceAll('.', '');
                let totalTransactionsMonth = this.state.revenue.totalTransactionsMonth.replaceAll('.', '');
                let sum = (takeRate/100) * (averageTransactionSize * totalTransactionsMonth);
                this.recalculate(`${sum}`, true);
            } else if (this.state.type === 'SELLING_GOODS' && this.state.revenue.revenuePerUnit !== null && this.state.revenue.unitsMonth !== null) {
                let revenuePerUnit = this.state.revenue.revenuePerUnit.replaceAll('.', '');
                let unitsMonth = this.state.revenue.unitsMonth.replaceAll('.', '');
                let sum = revenuePerUnit * unitsMonth;
                this.recalculate(`${sum}`, true);
            } else if (this.state.type === 'ONE_OFF' && this.state.revenue.revenue !== null) {
                this.recalculate(this.state.revenue.revenue.replaceAll('.', ''), false);
            }
            sessionStorage.setItem('revenue', JSON.stringify(this.state.revenue));
        }, 10);

    }

    recalculate = (value, monthly, year = this.state.year) => {
        if (parseInt(value) > 0) {
            let firstYear = parseInt(value.replaceAll('.', '')) * 12;
            if (monthly === false) {
                firstYear = parseInt(value.replaceAll('.', ''));
            }
            let displayFirstYear = parseInt(value.replaceAll('.', '')) * (this.state.firstYearMonths !== 0 ? this.state.firstYearMonths : 12);
            document.querySelector('#firstYear').setAttribute('data-value', displayFirstYear);
            if (monthly === false) {
                document.querySelector('#firstYear').setAttribute('data-value', firstYear);
            }
            document.querySelector('#firstYear').textContent = `€${monthly === false ? this.addCommas(this.removeNonNumeric(firstYear)) : this.addCommas(this.removeNonNumeric(displayFirstYear))}`;
            let nextYear = firstYear;
            let thirdYear = firstYear;
            if (monthly === true && document.querySelector('#varNextYear') !== null) {
                let varNextYear = document.querySelector('#varNextYear').closest('.container__body-input').querySelector('input').value;
                let varThirdYear = document.querySelector('#varThirdYear').closest('.container__body-input').querySelector('input').value;
                nextYear = parseInt((firstYear * (varNextYear/100)) + firstYear);
                thirdYear = parseInt((firstYear * (varThirdYear/100)) + firstYear);
            }
            document.querySelector('#nextYear').setAttribute('data-value', nextYear);
            document.querySelector('#nextYear').textContent = `€${this.addCommas(this.removeNonNumeric(nextYear))}`;
            document.querySelector('#thirdYear').setAttribute('data-value', thirdYear);
            document.querySelector('#thirdYear').textContent = `€${this.addCommas(this.removeNonNumeric(thirdYear))}`;
            setTimeout(() => {
                if (this.state.type === 'ONE_OFF') {
                    if (year === 'Year 1') {
                        document.querySelector('#nextYear').textContent = `€/`;
                        document.querySelector('#thirdYear').textContent = `€/`;
                    }
                    if (year === 'Year 2') {
                        document.querySelector('#firstYear').textContent = `€/`;
                        document.querySelector('#thirdYear').textContent = `€/`;
                    }
                    if (year === 'Year 3') {
                        document.querySelector('#nextYear').textContent = `€/`;
                        document.querySelector('#firstYear').textContent = `€/`;
                    }
                    this.setState({
                        year
                    })
                }
            }, 10);
        }
    }

    edit = (index) => {
        Connect(`${Endpoint.getUserRevenue}/${index}`, 'GET').then(response => {
            this.props.handleSelectList([
                { id: 1, name: localStorage.getItem('i18nextLng') === 'en' ? 'Simple revenue' : 'Simpele inkomsten', value: 'SIMPLE' },
                { id: 2, name: localStorage.getItem('i18nextLng') === 'en' ? 'Service revenue' : 'Omzet uit diensten', value: 'SERVICE' },
                { id: 3, name: localStorage.getItem('i18nextLng') === 'en' ? 'Transactional revenue' : 'Transactionele inkomsten', value: 'TRANSACTIONAL' },
                { id: 4, name: localStorage.getItem('i18nextLng') === 'en' ? 'Selling goods' : 'Goederen verkopen', value: 'SELLING_GOODS' },
                { id: 5, name: localStorage.getItem('i18nextLng') === 'en' ? 'One-off revenue' : 'Eenmalige omzet', value: 'ONE_OFF' }
            ]);
            this.props.handleDefaultSelect(response.revenueStreamType === 'SIMPLE' ? localStorage.getItem('i18nextLng') === 'en' ? 'Simple revenue' : 'Simpele inkomsten' : response.revenueStreamType === 'SERVICE' ? 'Service revenue' : response.revenueStreamType === 'TRANSACTIONAL' ? localStorage.getItem('i18nextLng') === 'en' ? 'Transactional revenue' : 'Transactionele inkomsten' : response.revenueStreamType === 'SELLING_GOODS' ? localStorage.getItem('i18nextLng') === 'en' ? 'Selling goods' : 'Goederen verkopen' : localStorage.getItem('i18nextLng') === 'en' ? 'One-off revenue' : 'Eenmalige omzet');
            this.setState({
                type: response.revenueStreamType,
                revenue: {
                    id: response.id,
                    name: response.name,
                    description: response.description,
                    monthlyRevenue: response.monthlyRevenue ? this.addCommas(this.removeNonNumeric(response.monthlyRevenue)) : '',
                    averageHourlyRate: response.avgHourlyRate ? this.addCommas(this.removeNonNumeric(response.avgHourlyRate)) : '',
                    yearAverage: response.yearAverage ? this.addCommas(this.removeNonNumeric(response.yearAverage)) : '',
                    totalCustomers: response.totalCustomers,
                    takeRate: response.takeRate,
                    averageMonthly: response.avgHoursPerCustomer,
                    averageTransactionSize: response.averageTransaction ? this.addCommas(this.removeNonNumeric(response.averageTransaction)) : '',
                    totalTransactionsMonth: response.transactionsPerMonth ? this.addCommas(this.removeNonNumeric(response.transactionsPerMonth)) : '',
                    revenuePerUnit: response.revenuePerUnit ? this.addCommas(this.removeNonNumeric(response.revenuePerUnit)) : '',
                    unitsMonth: response.unitsPerMonth ? this.addCommas(this.removeNonNumeric(response.unitsPerMonth)) : '',
                    revenue: response.yearAverage ? this.addCommas(this.removeNonNumeric(response.yearAverage)) : '',
                    varianceNextYear: response.varianceNextYear ? this.addCommas(this.removeNonNumeric(response.varianceNextYear)) : 5,
                    varianceThirdYear: response.varianceThirdYear ? this.addCommas(this.removeNonNumeric(response.varianceThirdYear)) : 10,
                    revenueYearOne: response.revenueYearOne ? this.addCommas(this.removeNonNumeric(response.revenueYearOne)) : '',
                    revenueYearTwo: response.revenueYearTwo ? this.addCommas(this.removeNonNumeric(response.revenueYearTwo)) : '',
                    revenueYearThree: response.revenueYearThree ? this.addCommas(this.removeNonNumeric(response.revenueYearThree)) : '',
                    year: response.revenueYearThree !== null ? localStorage.getItem('i18nextLng') === 'en' ? 'Year 3' : 'Jaar 3' : response.revenueYearTwo !== null ? localStorage.getItem('i18nextLng') === 'en' ? 'Year 2' : 'Jaar 2' : localStorage.getItem('i18nextLng') === 'en' ? 'Year 1' : 'Jaar 1'
                }
            })
            setTimeout(() => {
                if (this.state.type === 'SIMPLE' && this.state.revenue.monthlyRevenue !== null) {
                    this.recalculate(this.state.revenue.monthlyRevenue.replaceAll('.', ''), true);
                } else if (this.state.type === 'SERVICE' && this.state.revenue.averageHourlyRate !== null && this.state.revenue.averageMonthly !== null && this.state.revenue.totalCustomers !== null) {
                    let averageHourlyRate = this.state.revenue.averageHourlyRate.replaceAll('.', '');
                    let averageMonthly = typeof this.state.revenue.averageMonthly === 'string' ? this.state.revenue.averageMonthly.replaceAll('.', '') : this.state.revenue.averageMonthly;
                    let totalCustomers = typeof this.state.revenue.totalCustomers === 'string' ? this.state.revenue.totalCustomers.replaceAll('.', '') : this.state.revenue.totalCustomers;
                    let sum = averageHourlyRate * averageMonthly * totalCustomers;
                    this.recalculate(`${sum}`, true);
                } else if (this.state.type === 'TRANSACTIONAL' && this.state.revenue.averageTransactionSize !== null && this.state.revenue.averageMonthly !== null && this.state.revenue.totalTransactionsMonth !== null) {
                    let takeRate = this.state.revenue.takeRate && this.state.revenue.takeRate.replaceAll('.', '');
                    let averageTransactionSize = this.state.revenue.averageTransactionSize && this.state.revenue.averageTransactionSize.replaceAll('.', '');
                    let totalTransactionsMonth = this.state.revenue.totalTransactionsMonth && this.state.revenue.totalTransactionsMonth.replaceAll('.', '');
                    let sum = (takeRate/100) * (averageTransactionSize * totalTransactionsMonth);
                    this.recalculate(`${sum}`, true);
                } else if (this.state.type === 'SELLING_GOODS' && this.state.revenue.revenuePerUnit !== null && this.state.revenue.unitsMonth !== null) {
                    let revenuePerUnit = this.state.revenue.revenuePerUnit.replaceAll('.', '');
                    let unitsMonth = this.state.revenue.unitsMonth.replaceAll('.', '');
                    let sum = revenuePerUnit * unitsMonth;
                    this.recalculate(`${sum}`, true);
                } else if (this.state.type === 'ONE_OFF' && this.state.revenue.revenue !== null) {
                    this.recalculate(this.state.revenue.revenue.replaceAll('.', ''), false, response.revenueYearThree !== null ? localStorage.getItem('i18nextLng') === 'en' ? 'Year 3' : 'Jaar 3' : response.revenueYearTwo !== null ? localStorage.getItem('i18nextLng') === 'en' ? 'Year 2' : 'Jaar 2' : localStorage.getItem('i18nextLng') === 'en' ? 'Year 1' : 'Jaar 1');
                }
                sessionStorage.setItem('revenue', JSON.stringify(this.state.revenue));
            }, 200);
        })
    }

    clear = () => {
        this.handleRevenue('name', '');
        this.handleRevenue('description', '');
        this.handleRevenue('monthlyRevenue', '');
        this.handleRevenue('averageHourlyRate', '');
        this.handleRevenue('averageMonthly', '');
        this.handleRevenue('totalCustomers', '');
        this.handleRevenue('takeRate', '');
        this.handleRevenue('averageTransactionSize', '');
        this.handleRevenue('totalTransactionsMonth', '');
        this.handleRevenue('revenuePerUnit', '');
        this.handleRevenue('unitsMonth', '');
        this.handleRevenue('revenue', '');
        document.querySelector('#firstYear').setAttribute('data-value', '0');
        document.querySelector('#firstYear').textContent = `€0`;
        document.querySelector('#nextYear').setAttribute('data-value', '0');
        document.querySelector('#nextYear').textContent = `€0`;
        document.querySelector('#thirdYear').setAttribute('data-value', '0');
        document.querySelector('#thirdYear').textContent = `€0`;
    }

    handleConfirm = (value) => {
        this.setState({
            confirm: value
        })
    }

    confirmRemove = (index, pid) => {
        this.handleConfirm(true);
        sessionStorage.setItem('indexDelete', pid);
    }

    addCommas = (num) => {
        if (num.length > 1) {
            if (num[0] === '0') {
                num = num.slice(1);
            }
        }
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }


    removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

    remove = (index) => {
        let currentData = this.state.data;
        this.handleConfirm(false);
        Connect(`${Endpoint.getUserRevenue}/${sessionStorage.getItem('indexDelete')}`, 'DELETE').then(response => {
            this.getRes();
        })
        currentData.splice(index, 1);
        this.setState({
            data: currentData
        })
        localStorage.setItem('revenue', JSON.stringify(currentData));
        setTimeout(() => {
            this.clear();
        }, 100);
    }

    save = () => {
        let currentData = this.state.data;
        let averageHourlyRate = document.querySelector('#averageHourlyRate') && document.querySelector('#averageHourlyRate').closest('.container__body-input').querySelector('input').value;
        let averageMonthly = document.querySelector('#averageMonthly') && document.querySelector('#averageMonthly').closest('.container__body-input').querySelector('input').value;
        let totalCustomers = document.querySelector('#totalCustomers') && document.querySelector('#totalCustomers').closest('.container__body-input').querySelector('input').value;
        let takeRate = document.querySelector('#takeRate') && document.querySelector('#takeRate').closest('.container__body-input').querySelector('input').value;
        let averageTransactionSize = document.querySelector('#averageTransactionSize') && document.querySelector('#averageTransactionSize').closest('.container__body-input').querySelector('input').value;
        let totalTransactionsMonth = document.querySelector('#totalTransactionsMonth') && document.querySelector('#totalTransactionsMonth').closest('.container__body-input').querySelector('input').value;
        let revenuePerUnit = document.querySelector('#revenuePerUnit') && document.querySelector('#revenuePerUnit').closest('.container__body-input').querySelector('input').value;
        let unitsMonth = document.querySelector('#unitsMonth') && document.querySelector('#unitsMonth').closest('.container__body-input').querySelector('input').value;
        let revenue = document.querySelector('#revenue') && document.querySelector('#revenue').closest('.container__body-input').querySelector('input').value;
        let productName = document.querySelector('#productName').closest('.container__body-input').querySelector('input').value;
        let productDescription = document.querySelector('#productDescription').closest('.container__body-input').querySelector('input').value;
        let monthlyRevenue = document.querySelector('#monthlyRevenue') && document.querySelector('#monthlyRevenue').closest('.container__body-input').querySelector('input').value;
        let firstYear = document.querySelector('#firstYear').getAttribute('data-value');
        let nextYear = document.querySelector('#nextYear').getAttribute('data-value');
        let thirdYear = document.querySelector('#thirdYear').getAttribute('data-value');
        let average = ((((parseInt(firstYear)/this.state.firstYearMonths) * 12 ) + parseInt(nextYear) + parseInt(thirdYear))/3).toFixed(0);
        localStorage.setItem('revenue', JSON.stringify(currentData));
        if (this.state.type === 'ONE_OFF') {
            if (document.querySelector('#firstYear').innerText === '€/') {
                firstYear = null;
            }
            if (document.querySelector('#nextYear').innerText === '€/') {
                nextYear = null;
            }
            if (document.querySelector('#thirdYear').innerText === '€/') {
                thirdYear = null;
            }
        }
        let body;
        if (this.state.type === 'SIMPLE') {
            let varNextYear = document.querySelector('#varNextYear').closest('.container__body-input').querySelector('input').value;
            let varThirdYear = document.querySelector('#varThirdYear').closest('.container__body-input').querySelector('input').value;
            body = {
                id: this.state.revenue.id,
                name: productName,
                revenueStreamType: this.state.type,
                description: productDescription,
                averageTransaction: average,
                yearAverage: average,
                monthlyRevenue: parseInt(monthlyRevenue.replaceAll('.', '')),
                varianceNextYear: parseInt(varNextYear),
                varianceThirdYear: parseInt(varThirdYear),
                revenueYearOne: parseInt(firstYear),
                revenueYearTwo: parseInt(nextYear),
                revenueYearThree: parseInt(thirdYear),
            }
        }
        if (this.state.type === 'SERVICE') {
            let varNextYear = document.querySelector('#varNextYear').closest('.container__body-input').querySelector('input').value;
            let varThirdYear = document.querySelector('#varThirdYear').closest('.container__body-input').querySelector('input').value;
            body = {
                id: this.state.revenue.id,
                name: productName,
                revenueStreamType: this.state.type,
                description: productDescription,
                avgHourlyRate: parseInt(averageHourlyRate),
                avgHoursPerCustomer: parseInt(averageMonthly),
                totalCustomers: parseInt(totalCustomers),
                yearAverage: average,
                varianceNextYear: parseInt(varNextYear),
                varianceThirdYear: parseInt(varThirdYear),
                revenueYearOne: parseInt(firstYear),
                revenueYearTwo: parseInt(nextYear),
                revenueYearThree: parseInt(thirdYear),
            }
        }
        if (this.state.type === 'TRANSACTIONAL') {
            let varNextYear = document.querySelector('#varNextYear').closest('.container__body-input').querySelector('input').value;
            let varThirdYear = document.querySelector('#varThirdYear').closest('.container__body-input').querySelector('input').value;
            body = {
                id: this.state.revenue.id,
                name: productName,
                revenueStreamType: this.state.type,
                description: productDescription,
                takeRate,
                yearAverage: average,
                averageTransaction: averageTransactionSize,
                transactionsPerMonth: totalTransactionsMonth,
                varianceNextYear: parseInt(varNextYear),
                varianceThirdYear: parseInt(varThirdYear),
                revenueYearOne: parseInt(firstYear),
                revenueYearTwo: parseInt(nextYear),
                revenueYearThree: parseInt(thirdYear),
            }
        }
        if (this.state.type === 'SELLING_GOODS') {
            let varNextYear = document.querySelector('#varNextYear').closest('.container__body-input').querySelector('input').value;
            let varThirdYear = document.querySelector('#varThirdYear').closest('.container__body-input').querySelector('input').value;
            body = {
                id: this.state.revenue.id,
                name: productName,
                revenueStreamType: this.state.type,
                description: productDescription,
                revenuePerUnit,
                yearAverage: average,
                unitsPerMonth: unitsMonth,
                varianceNextYear: parseInt(varNextYear),
                varianceThirdYear: parseInt(varThirdYear),
                revenueYearOne: parseInt(firstYear),
                revenueYearTwo: parseInt(nextYear),
                revenueYearThree: parseInt(thirdYear),
            }
        }
        if (this.state.type === 'ONE_OFF') {
            body = {
                id: this.state.revenue.id,
                name: productName,
                revenueStreamType: this.state.type,
                description: productDescription,
                yearAverage: parseInt(revenue.replaceAll('.', '')),
                revenueYearOne: parseInt(firstYear),
                revenueYearTwo: parseInt(nextYear),
                revenueYearThree: parseInt(thirdYear),
            }
        }
        setTimeout( () => {
            Connect(`${Endpoint.getUserRevenue}`, this.state.revenue.id !== null ? 'PUT' : 'POST', body).then(response => {
                this.getRes();
                setTimeout(() => {
                    this.clear();
                }, 500);
            })
        } ,1);
    }

    handleData = (type, value) => {
        this.setState({
            type: value[0].text,
            revenue: {
                id: null,
                name: '',
                description: '',
                monthlyRevenue: '',
                averageHourlyRate: '',
                averageMonthly: '',
                totalCustomers: '',
                takeRate: '',
                averageTransactionSize: '',
                totalTransactionsMonth: '',
                revenuePerUnit: '',
                unitsMonth: '',
                revenue: '',
                varianceNextYear: 5,
                varianceThirdYear: 10
            }
        })
        sessionStorage.removeItem('revenue');
        setTimeout(() => {
            if (this.state.type === 'ONE_OFF') {
                if (this.state.year === localStorage.getItem('i18nextLng') === 'en' ? 'Year 1' : 'Jaar 1') {
                    document.querySelector('#nextYear').textContent = `€/`;
                    document.querySelector('#thirdYear').textContent = `€/`;
                }
                if (this.state.year === localStorage.getItem('i18nextLng') === 'en' ? 'Year 2' : 'Jaar 2') {
                    document.querySelector('#firstYear').textContent = `€/`;
                    document.querySelector('#thirdYear').textContent = `€/`;
                }
                if (this.state.year === localStorage.getItem('i18nextLng') === 'en' ? 'Year 3' : 'Jaar 3') {
                    document.querySelector('#nextYear').textContent = `€/`;
                    document.querySelector('#firstYear').textContent = `€/`;
                }
            } else {
                document.querySelector('#firstYear').textContent = `€${document.querySelector('#firstYear').getAttribute('data-value')}`;
                document.querySelector('#nextYear').textContent = `€${document.querySelector('#nextYear').getAttribute('data-value')}`;
                document.querySelector('#thirdYear').textContent = `€${document.querySelector('#thirdYear').getAttribute('data-value')}`;
            }
        }, 10);
        setTimeout(() => {
            this.clear();
        }, 100);
    }

    handleYear = (type, value) => {
        this.setState({
            year: value[0].text
        })
        setTimeout(() => {
            if (this.state.type === 'ONE_OFF') {
                if (value[0].id === 1) {
                    document.querySelector('#firstYear').textContent = `€${document.querySelector('#firstYear').getAttribute('data-value')}`;
                    document.querySelector('#nextYear').textContent = `€/`;
                    document.querySelector('#thirdYear').textContent = `€/`;
                }
                if (value[0].id === 2) {
                    document.querySelector('#nextYear').textContent = `€${document.querySelector('#nextYear').getAttribute('data-value')}`;
                    document.querySelector('#firstYear').textContent = `€/`;
                    document.querySelector('#thirdYear').textContent = `€/`;
                }
                if (value[0].id === 3) {
                    document.querySelector('#thirdYear').textContent = `€${document.querySelector('#thirdYear').getAttribute('data-value')}`;
                    document.querySelector('#nextYear').textContent = `€/`;
                    document.querySelector('#firstYear').textContent = `€/`;
                }
            }
        }, 10);
    }

    render() {
        return (
            <>
           <div className='revenue__wrapper'>
          <div className='container__body establishmentFlow revenue'>
            <div className='container__body-header'>
              <Trans>establishmentFlow.revenue</Trans>
              <Tooltip title={<Trans>establishmentFlow.tooltip3</Trans>} placement={"right"}>
                <IconButton aria-label='delete'>{InfoIcon}</IconButton>
              </Tooltip>
            </div>
            {this.state.data.map((data, index) => (
              <div className='establishmentFlow__box'>
                <div className='row space-between'>
                  <div className='header'>
                    {data.revenueStreamType === "SIMPLE"
                      ? localStorage.getItem('i18nextLng') === 'en' ? 'Simple revenue: ' : 'Simpele inkomsten: '
                      : data.revenueStreamType === "SERVICE"
                      ? localStorage.getItem('i18nextLng') === 'en' ? 'Service revenue: ' : 'Omzet uit diensten: '
                      : data.revenueStreamType === "TRANSACTIONAL"
                      ? localStorage.getItem('i18nextLng') === 'en' ? 'Transactional revenue: ' : 'Transactionele inkomsten: '
                      : data.revenueStreamType === "SELLING_GOODS"
                      ? localStorage.getItem('i18nextLng') === 'en' ? 'Selling goods: ' : 'Goederen verkopen: '
                      : data.revenueStreamType === "ONE_OFF"
                      ? localStorage.getItem('i18nextLng') === 'en' ? 'One-off revenue: ' : 'Eenmalige omzet: '
                      : ""}
                    {data.name}
                  </div>
                  <div className='box'>
                    <div className='edit' onClick={() => this.edit(data.id)}>
                      {editIcon}
                    </div>
                    <div className='remove' onClick={() => this.confirmRemove(index, data.id)}>
                      {removeIcon}
                    </div>
                  </div>
                </div>
                <div className='row space-between'>
                  <p>
                    <Trans>establishmentFlow.description</Trans>
                  </p>
                  <p>
                    <Trans>establishmentFlow.nextYear</Trans>
                  </p>
                  <p>
                    <Trans>establishmentFlow.thirdYear</Trans>
                  </p>
                  <p>
                    <Trans>establishmentFlow.average</Trans>
                  </p>
                </div>
                <div className='row space-between'>
                  <p className='award'>{data.description}</p>
                  <p className='award'>{data.varianceNextYear !== null ? `${data.varianceNextYear}%` : "/"}</p>
                  <p className='award'>{data.varianceThirdYear !== null ? `${data.varianceThirdYear}%` : "/"}</p>
                  <p className='price'>€{data.yearAverage && this.addCommas(this.removeNonNumeric(data.yearAverage.toFixed(0)))}</p>
                </div>
              </div>
            ))}
          </div>
          <div className='revenue__summary'>
            <div className='revenue__summary-item'>
              <div className='award'>Y1</div>
              <div className='text'>
                {JSON.parse(sessionStorage.getItem("financialCalendar")).periodOneFrom !== null
                  ? JSON.parse(sessionStorage.getItem("financialCalendar")).periodOneFrom
                  : "N/A"}
              </div>
              <div className='text'>
                {JSON.parse(sessionStorage.getItem("financialCalendar")).periodOneTo !== null
                  ? JSON.parse(sessionStorage.getItem("financialCalendar")).periodOneTo
                  : "N/A"}
              </div>
            </div>
            <div className='revenue__summary-item'>
              <div className='award'>Y2</div>
              <div className='text'>
                {JSON.parse(sessionStorage.getItem("financialCalendar")).periodTwoFrom !== null
                  ? JSON.parse(sessionStorage.getItem("financialCalendar")).periodTwoFrom
                  : "N/A"}
              </div>
              <div className='text'>
                {JSON.parse(sessionStorage.getItem("financialCalendar")).periodTwoTo !== null
                  ? JSON.parse(sessionStorage.getItem("financialCalendar")).periodTwoTo
                  : "N/A"}
              </div>
            </div>
            <div className='revenue__summary-item'>
              <div className='award'>Y3</div>
              <div className='text'>
                {JSON.parse(sessionStorage.getItem("financialCalendar")).periodThreeFrom !== null
                  ? JSON.parse(sessionStorage.getItem("financialCalendar")).periodThreeFrom
                  : "N/A"}
              </div>
              <div className='text'>
                {JSON.parse(sessionStorage.getItem("financialCalendar")).periodThreeTo !== null
                  ? JSON.parse(sessionStorage.getItem("financialCalendar")).periodThreeTo
                  : "N/A"}
              </div>
            </div>
          </div>
        </div>
                <div className="container__sidebar revenue">
                    <div className="container__body-select">
                        <p className="container__body-text"><Trans>establishmentFlow.addRevenue</Trans></p>
                        <CustomSelect
                            defaultText={this.props.defaultSelect}
                            optionsList={this.props.selectList}
                            handleData={this.handleData}
                            type={'revenue'}
                        />
                    </div>
                    <div className="container__body-input">
                        <p id="productName" className="container__body-text">{this.state.type === 'SERVICE' ? <Trans>establishmentFlow.service</Trans> : <Trans>establishmentFlow.product</Trans>} <Trans>establishmentFlow.revenueName</Trans></p>
                        <TextField label={''} variant={'outlined'} size={'small'} placeholder={'...'} value={this.state.revenue.name} onChange={(e) => this.handleRevenue('name', e.target.value)} />
                    </div>
                    <div className="container__body-input">
                        <p id="productDescription" className="container__body-text">{this.state.type === 'SERVICE' ? <Trans>establishmentFlow.service</Trans> : <Trans>establishmentFlow.product</Trans>} <Trans>establishmentFlow.revenueDescription</Trans></p>
                        <TextField label={''} variant={'outlined'} size={'small'} placeholder={'...'} value={this.state.revenue.description} onChange={(e) => this.handleRevenue('description', e.target.value)} />
                    </div>
                    {this.state.type === 'SIMPLE' ?
                        <div className="container__body-input">
                            <p id="monthlyRevenue" className="container__body-text"><Trans>establishmentFlow.monthlyRevenue</Trans></p>
                            <TextField InputProps={{
            startAdornment: this.state.revenue.monthlyRevenue  ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }} label={''} variant={'outlined'} size={'small'} placeholder={'€...'} value={this.state.revenue.monthlyRevenue} onChange={(e) => this.handleRevenue('monthlyRevenue', e.target.value)} />
                        </div>
                    : <></>}
                    {this.state.type === 'SERVICE' ?
                        <>
                            <div className="container__body-input">
                                <p id="averageHourlyRate" className="container__body-text"><Trans>establishmentFlow.averageRate</Trans></p>
                                <TextField InputProps={{
            startAdornment: this.state.revenue.averageHourlyRate  ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }}  label={''} variant={'outlined'} size={'small'} placeholder={'€...'} value={this.state.revenue.averageHourlyRate} onChange={(e) => this.handleRevenue('averageHourlyRate', e.target.value)} />
                            </div>
                            <div className="container__body-input">
                                <p id="averageMonthly" className="container__body-text"><Trans>establishmentFlow.averageMonthly</Trans></p>
                                <TextField label={''} variant={'outlined'} size={'small'} placeholder={'...'} value={this.state.revenue.averageMonthly} onChange={(e) => this.handleRevenue('averageMonthly', e.target.value)} />
                            </div>
                            <div className="container__body-input">
                                <p id="totalCustomers" className="container__body-text"><Trans>establishmentFlow.totalCustomers</Trans></p>
                                <TextField label={''} variant={'outlined'} size={'small'} placeholder={'...'} value={this.state.revenue.totalCustomers} onChange={(e) => this.handleRevenue('totalCustomers', e.target.value)} />
                            </div>
                        </>
                    : <></>}
                    {this.state.type === 'TRANSACTIONAL' ?
                        <>
                            <div className="container__body-input">
                                <p id="takeRate" className="container__body-text"><Trans>establishmentFlow.takeRate</Trans></p>
                                <TextField label={''} variant={'outlined'} size={'small'} placeholder={'%...'} value={this.state.revenue.takeRate} onChange={(e) => this.handleRevenue('takeRate', e.target.value)} />
                            </div>
                            <div className="container__body-input">
                                <p id="averageTransactionSize" className="container__body-text"><Trans>establishmentFlow.averageSize</Trans></p>
                                <TextField InputProps={{
            startAdornment: this.state.revenue.averageTransactionSize  ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }} label={''} variant={'outlined'} size={'small'} placeholder={'€...'} value={this.state.revenue.averageTransactionSize} onChange={(e) => this.handleRevenue('averageTransactionSize', e.target.value)} />
                            </div>
                            <div className="container__body-input">
                                <p id="totalTransactionsMonth" className="container__body-text"><Trans>establishmentFlow.totalPerMonth</Trans></p>
                                <TextField label={''} variant={'outlined'} size={'small'} placeholder={'...'} value={this.state.revenue.totalTransactionsMonth} onChange={(e) => this.handleRevenue('totalTransactionsMonth', e.target.value)} />
                            </div>
                        </>
                    : <></>}
                    {this.state.type === 'SELLING_GOODS' ?
                        <>
                            <div className="container__body-input">
                                <p id="revenuePerUnit" className="container__body-text"><Trans>establishmentFlow.revenueUnit</Trans></p>
                                <TextField InputProps={{
            startAdornment: this.state.revenue.revenuePerUnit  ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }} label={''} variant={'outlined'} size={'small'} placeholder={'€...'} value={this.state.revenue.revenuePerUnit} onChange={(e) => this.handleRevenue('revenuePerUnit', e.target.value)} />
                            </div>
                            <div className="container__body-input">
                                <p id="unitsMonth" className="container__body-text"><Trans>establishmentFlow.unitMonth</Trans></p>
                                <TextField label={''} variant={'outlined'} size={'small'} placeholder={'...'} value={this.state.revenue.unitsMonth} onChange={(e) => this.handleRevenue('unitsMonth', e.target.value)} />
                            </div>
                        </>
                    : <></>}
                    {this.state.type === 'ONE_OFF' ?
                        <>
                            <div className="container__body-input">
                                <p id="revenue" className="container__body-text"><Trans>establishmentFlowMenu.revenue</Trans></p>
                                <TextField InputProps={{
            startAdornment: this.state.revenue.revenue  ? <InputAdornment variant="standard" disableTypography position="start">€</InputAdornment> : <></>,
          }} label={''} variant={'outlined'} size={'small'} placeholder={'€...'} value={this.state.revenue.revenue} onChange={(e) => this.handleRevenue('revenue', e.target.value)} />
                            </div>
                            <div className="container__body-select">
                                <p className="container__body-text"><Trans>establishmentFlow.fiscal</Trans></p>
                                <CustomSelect
                                    defaultText={this.state.year}
                                    optionsList={this.props.selectYear}
                                    handleData={this.handleYear}
                                    type={'revenue'}
                                />
                            </div>
                        </>
                    : <></>}
                    {this.state.type !== 'ONE_OFF' ?
                        <div className="row">
                            <div className="container__body-input">
                                <p id="varNextYear" className="container__body-text"><Trans>establishmentFlow.varNextYear</Trans></p>
                                <TextField label={''} variant={'outlined'} size={'small'} value={this.state.revenue.varianceNextYear} onChange={(e) => this.handleRevenue('varianceNextYear', e.target.value)} />
                            </div>
                            <div className="container__body-input">
                                <p id="varThirdYear" className="container__body-text"><Trans>establishmentFlow.varThirdYear</Trans></p>
                                <TextField label={''} variant={'outlined'} size={'small'} value={this.state.revenue.varianceThirdYear} onChange={(e) => this.handleRevenue('varianceThirdYear', e.target.value)} />
                            </div>
                        </div>
                    :
                        <></>
                    }
                    <div className="summary">
                        <div className="box">
                            <div id="firstYear" className="summary__header" data-value={0}>€0</div>
                            <div className="summary__subheader">Y1 <span>({this.state.firstYearMonths} <Trans>establishmentFlow.months</Trans>)</span></div>
                        </div>
                        <div className="box">
                            <div id="nextYear"  className="summary__header" data-value={0}>€0</div>
                            <div className="summary__subheader">Y2</div>
                        </div>
                        <div className="box">
                            <div id="thirdYear"  className="summary__header" data-value={0}>€0</div>
                            <div className="summary__subheader">Y3</div>
                        </div>
                    </div>
                    <div className="row">
                        <button className="btn btn--primary" onClick={() => this.save()}>{this.state.revenue.id !== null ? <Trans>establishmentFlow.update</Trans> : <Trans>establishmentFlow.add</Trans>}</button>
                    </div>
                </div>
                <Confirm active={this.state.confirm} handleConfirm={this.handleConfirm} handleRemove={this.remove} message={<Trans>establishmentFlow.delete</Trans>} only={false} />
            </>

        );
    }
}

export default Revenue;