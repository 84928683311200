export const customStyles = {

    // select menu
    menu: (provided, ) => ({
        ...provided,
        padding: '10px 0',
        color: '#444445',
        margin: '1px',
        border: '0.7px solid #4A5BCF',
        borderRadius: '13px',
        boxShadow: 'none',
        width: '99%',
        zIndex: '1111',
        "@media only screen and (max-width: 768px)": {
            borderColor: "#D6D6D6",
        }
    }),

    // select
    control: (provided) => ({
        ...provided,
        borderRadius: '13px',
        borderColor: '#4A5BCF',
        backgroundColor: 'white',
        boxShadow: 'none',
        cursor: 'pointer',
        minHeight: '36px',
        '&:hover': {
            borderColor: '#4A5BCF',
            boxShadow: 'none'
        },
        "@media only screen and (max-width: 1200px)": {
            minHeight: '30px',
        },
        "@media only screen and (max-width: 768px)": {
            borderColor: "#D6D6D6",
            fontSize: '12px',
            minHeight: '24px',
        }
    }),

    // option in menu
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#4A5BCF' : state.isFocused && 'rgba(55, 81, 126, 0.08)',
        cursor: 'pointer',
        paddingLeft: '15px',
        '&:hover': {
            backgroundColor: state.isSelected ? '#4A5BCF' : 'rgba(55, 81, 126, 0.08)',
            color: state.isSelected ? '#FFF' : '#444445'
        },
    }),

    // arrow icon
    indicatorSeparator: () => ({
        backgroundColor: '',
    }),

    // select value
    valueContainer: () => ({
        paddingLeft: '15px',
        '>div': {
            color: '#444445',
        },
        '.react-select__single-value': {
            maxWidth: 'calc(100% - 40px)',
        }
    }),
}