import React from 'react';
import './edit.scss';
import { TextField, Button } from "@material-ui/core";

function Edit(props) {

    const ignoreLetters = (num) => {
        let value = num.toString().replace(/[^\d,-]/g, '');
        return num.toString().replace(/[^\d,-]/g, '');
    }

    return (
        <div className={props.active === true ? "edit__wrapper active" : "edit__wrapper"}>
            <div className="edit__content">
                <div className="edit__content-header">{props.header}</div>
                <div className="container__body-input">
                    <p className="container__body-text">{props.inputName}</p>
                    <TextField
                        label={''}
                        variant={'outlined'}
                        size={'small'}
                        type={'text'}
                        placeholder={'...'}
                        value={ignoreLetters(props.inputValue.replace('.',','))}
                        onChange={e=>props.handleActive(true, props.inputName, ignoreLetters(e.target.value), props.index, props.type)}
                    />
                </div>
                <div className="row">
                    <Button
                        className="btn btn--secondary"
                        onClick={() => props.handleActive(false, '', '', 0, 'mainOccupation')}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn--primary"
                        onClick={() => props.handleSave(false)}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Edit;
