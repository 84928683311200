import React, { useState } from "react";
import * as Constants from './settingsConstants';
import './_upgradePlan.scss';
import { Trans } from 'react-i18next';

const UpgradePlan = (props) => {
    const [plan] = useState(props.request.plan);
    const isMobile = props.isMobile;
    const planOption = ['Lorem 1', 'Lorem 2', 'Lorem 3', 'Lorem 4', 'Lorem 5', 'Lorem 6', 'Lorem 7'];
    const planEssentialOption = [true, true, true, true, false, false, false];
    const planKingOption = [true, true, true, true, true, true, true];

    const planOptionList = planOption.map((option, index) => <div key={index} className="upgradePlan__row row--item row--categoryName">{option}</div>);
    const planEssential = planEssentialOption.map((option, index) =>
        <div key={index} className="upgradePlan__row row--item">
            {isMobile && <div>{planOption[index]}</div>}
            {option ? Constants.iconYes : Constants.iconNo}
        </div>
    );
    const planKing = planKingOption.map((option, index) =>
        <div key={index} className="upgradePlan__row row--item">
            {isMobile && <div>{planOption[index]}</div>}
            {option ? Constants.iconYes : Constants.iconNo}
        </div>
    );

    return (
        <>
            <h2 className="header__title"><Trans>plan.choose</Trans></h2>
            <div className="container__body container--upgrade">
                {!isMobile && <h2 className="container__title"><Trans>plan.choose</Trans></h2>}
                <div className="upgradePlan">
                    {!isMobile &&
                        (<div className="upgradePlan__col">
                            <div className="upgradePlan__row row--header" />
                            {planOptionList}
                        </div>)
                    }

                    <div className={plan === 'essentials' ? 'upgradePlan__col is-active' : 'upgradePlan__col'}>
                        <div className="upgradePlan__row row--header">
                            <div className="row--title"><Trans>plan.es</Trans></div>
                            <div className="row--price"><Trans>plan.es-price</Trans></div>
                            {!isMobile && (plan === 'essentials' ?
                                <button className="btn--primary row--button"><Trans>plan.current</Trans></button>
                                : <button className="btn--outline row--button"><Trans>plan.upgrade-es</Trans></button>)
                            }
                        </div>
                        {planEssential}

                        {isMobile && (plan === 'essentials' ?
                            <button className="btn--shadow row--button"><Trans>plan.current</Trans></button>
                            : <button className="btn--primary row--button"><Trans>plan.upgrade-es</Trans></button>)
                        }
                    </div>

                    <div className={plan === 'king' ? 'upgradePlan__col is-active' : 'upgradePlan__col'}>
                        <div className="upgradePlan__row row--header">
                            <div className="row--title"><Trans>plan.king</Trans></div>
                            <div className="row--price"><Trans>plan.king-price</Trans></div>
                            {!isMobile && (plan === 'king' ?
                                <button className="btn--primary row--button"><Trans>plan.current</Trans></button>
                                : <button className="btn--outline row--button"><Trans>plan.upgrade-king</Trans></button>)
                            }
                        </div>
                        {planKing}
                        {isMobile && (plan === 'king' ?
                            <button className="btn--shadow row--button"><Trans>plan.current</Trans></button>
                            : <button className="btn--primary row--button"><Trans>plan.upgrade-king</Trans></button>)
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default UpgradePlan;
