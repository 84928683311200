import React from 'react';
import './limitedCompany.scss';
import {Output} from "../companyType/output";

function LimitedCompany(props) {
    return (
        <div className={props.active === true ? "limitedCompany__wrapper active" : "limitedCompany__wrapper"}>
            <div className="limitedCompany__wrapper__content">
                <div className="limitedCompany__wrapper__content-header">Establishment flow</div>
                <Output type={'userEstablishment'} handleSave={props.handleSave} />
            </div>
        </div>
    )
}

export default LimitedCompany;