import React, {useState, useEffect, useCallback} from "react";
import './navbar.scss';
import { notifyIcon, switcherIcon, arrowDropdown } from "../login/loginConstants";
import { SelectCustom } from "../select/select";
import { languageOptions } from "../mainContainer/mainContainer";
import { LinearProgress } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { Connect } from "../connect/connect";
import * as Endpoint from "../../configFile";
import CustomSelect from "../customSelect/customSelect";
import {Trans} from "react-i18next";
import avatar from '../../assets/avatar.jpg';
import Loader from "../loader/loader";

function Navbar(props) {
    const [data, setData] = useState([]);
    const [language, setLanguage] = useState({ label: (typeof window !== 'undefined') ? localStorage.getItem('i18nextLng') == null ? 'en' : localStorage.getItem('i18nextLng') : 'en', value: (typeof window !== 'undefined') ? localStorage.getItem('i18nextLng') == null ? 'en' : localStorage.getItem('i18nextLng') : 'en' });
    const [clientsList, setClientList] = useState([]);
    const [client, setClient] = useState(0);
    const [defaultUser, setDefaultUser] = useState('');
    const [userAvatar, setUserAvatar] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchAndSetImage = (photoUrl) => {
        const token = (typeof window !== 'undefined') ? localStorage.getItem('token') : null;
        const headers = {
            'Authorization': token ? `Bearer ${token}` : '',
        };

        fetch(photoUrl, {
            method: 'GET',
            headers: headers,
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`Network response was not ok: ${res.status}`);
                }
                return res.blob();
            })
            .then(blob => {
                const imageUrl = URL.createObjectURL(blob);
                setUserAvatar(imageUrl);
                sessionStorage.setItem('avatar', imageUrl);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleNotify = useCallback((value) => {
        let notifyExtend = document.querySelector('.notify__extend');
        if (notifyExtend !== null) {
            if (value === true) {
                notifyExtend.classList.add('active');
                let extend = document.querySelector('.user__extend');
                if (extend !== null) {
                    extend.classList.remove('active');
                }
            } else {
                notifyExtend.classList.remove('active');
            }
        }
    }, []);

    const handleDropdown = useCallback((value) => {
        let extend = document.querySelector('.user__extend');
        if (extend !== null) {
            if (value === true) {
                extend.classList.add('active');
                handleNotify(false);
            } else {
                extend.classList.remove('active');
            }
        }
    }, [handleNotify]);

    const changeLanguage = useCallback((event) => {
        let newLang = event.value;
        props.i18n.changeLanguage(newLang);
        setLanguage(event);
        props.handleFiles && props.handleFiles();
        props.handleChecklist && props.handleChecklist();
        props.handleEstablishmentFlow && props.handleEstablishmentFlow();
        props.handleSurvey && props.handleSurvey();
        props.handleCompanyType && props.handleCompanyType();
        props.handleSupport && props.handleSupport();
        props.handleDashboard && props.handleDashboard();
        props.handleUserFiles && props.handleUserFiles();
        props.handleCheckup && props.handleCheckup();
        props.handleRevenue && props.handleRevenue();
    }, [props.i18n, setLanguage]);

    const handleData = useCallback((type, value) => {
        setClient(value[0].id);
        sessionStorage.setItem('userID', value[0].id);
        sessionStorage.setItem('userEmail', value[0].textKey);
        props.handleFiles();
        props.handleChecklist();
        props.handleEstablishmentFlow();
        props.handleSurvey();
        props.handleOutput();
        props.handleEditable && props.handleEditable(false);
        props.handleFullScreen && props.handleFullScreen(false);
        props.handlePdfUrl && props.handlePdfUrl('');
        props.handleDeleteDocument && props.handleDeleteDocument(0);
        props.handleDocumentUrl && props.handleDocumentUrl('');
        props.handleIsSignVisible && props.handleIsSignVisible(false);
        props.handleSignStatus && props.handleSignStatus(false);
    }, [props]);

    const handleOutside = useCallback((e) => {
        if (!e.target.closest('.notify__extend')) {
            handleNotify(false);
        }
        if (!e.target.closest('.user__extend') && !e.target.closest('.user')) {
            handleDropdown(false);
        }
    }, [handleNotify, handleDropdown]);

    const getData = useCallback(() => {
        Connect(`${Endpoint.getNotifications}`, 'GET').then(response => {
            setData(response);
        });
        if (JSON.parse(localStorage.getItem('user')).avatarUri !== null) {
            fetchAndSetImage(JSON.parse(localStorage.getItem('user')).avatarUri);
        }
        if (props.name === 'Admin panel') {
            Connect(`${Endpoint.userList}?page=0&size=100&sort=id%2Cdesc`, 'GET').then(response => {
                setClientList(response.content.map((data) => {
                    return { id: data.id, name: `${data.name} ${data.surname !== null ? data.surname : ''}`, value: `${data.name} ${data.surname !== null ? data.surname : ''}`, type: 'user', mail: data.mail }
                }))
                setClient(response.content[0].id);
                setDefaultUser(`${response.content[0].name} ${response.content[0].surname !== null ? response.content[0].surname : ''}`);
                if (sessionStorage.getItem('userID') === '0') {
                    sessionStorage.setItem('userID', response.content[0].id);
                    sessionStorage.setItem('userEmail', response.content[0].mail);
                    props.handleSurvey && props.handleSurvey();
                }
            });
        }
    }, [props.name, setData]);

    const outside = useCallback(() => {
        window.addEventListener('click', handleOutside);
    }, [handleOutside]);

    useEffect(() => {
        getData();
        outside();
        if (props.name === 'Admin panel' && !sessionStorage.getItem('userID')) {
            sessionStorage.setItem('userID', client);
            sessionStorage.setItem('userEmail', 'admin.bizantium@ultimate.systems');
        }

        setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => {
            window.removeEventListener('click', handleOutside);
        };
    }, []);

    return (
        <div className="navbar">
            <div className="navbar__header">
                <div className="navbar__header-title">
                    {props.name === 'Company type' ? <Trans>menu.clients</Trans> : props.name === 'Establishment flow' ? <Trans>menu.survey</Trans> : props.name === 'Dashboard' ? <Trans>menu.company</Trans> : props.name === 'Files' ? <Trans>menu.users</Trans> : props.name === 'Parameters' ? <Trans>menu.parameters</Trans> : props.name === 'All clients' ? <Trans>menu.all</Trans> : props.name === 'Profile' ? <Trans>profileMenu.profile</Trans> : props.name === 'Support' ? <Trans>menu.support</Trans> : <Trans>menu.panel</Trans>}
                    {props.name === 'Admin panel' ?
                        <div className="navbar__header-clients">
                            <CustomSelect
                                defaultText={defaultUser}
                                optionsList={clientsList}
                                handleData={handleData}
                                type={'user'}
                            />
                        </div>
                    :
                        <></>
                    }
                </div>
                <div className="navbar__header-user">
                    <div className="notify d-none" onMouseEnter={() => handleNotify(true)}>
                        {notifyIcon}
                        <div className="hover">
                            <div className="notify__extend">
                                <div className="notify__extend-header">
                                    <div className="text header">Notifications</div>
                                    <div className="text link">Mark all as read</div>
                                </div>
                                {data.map((data) => (
                                    <div className="notify__extend-item">
                                        <div className="box">
                                            <div className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                                    <circle cx="4.25" cy="4.25" r="3.75" fill="#4A5BCF" />
                                                </svg>
                                            </div>
                                            <div className="text award">{data.content}</div>
                                            <div className="text"></div>
                                            <div className="text date">{data.addDate}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="change-language">
                        <SelectCustom selectedValue={language}
                                      isEdit={true}
                                      options={languageOptions}
                                      onChange={(event) => changeLanguage(event)} />
                    </div>
                    <div className="user" onMouseEnter={() => handleDropdown(true)}>
                        <img src={userAvatar !== null ? userAvatar : avatar} alt={'avatar'} />
                        {arrowDropdown}
                        <div className="hover">
                            <div className="user__extend">
                                <div className="user__extend-header">{JSON.parse(localStorage.getItem('user')).name}</div>
                                <div className="user__extend-content">
                                    {JSON.parse(localStorage.getItem('user')).id !== 1 && <NavLink to={'/profile'} className="text">My profile</NavLink>}
                                    <NavLink to={'/profile'} className="text d-none">Legal information</NavLink>
                                    <NavLink to={'/profile'} className="text d-none">Manage license</NavLink>
                                </div>
                                <NavLink to={'/'} className="user__extend-footer">Log out</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="switcher" onClick={() => props.handleQuickstart(true)}>{switcherIcon}</div>
                </div>
            </div>
            {props.survey === true ?
                <>
                    <p className="navbar__subtitle">{props.desc}</p>
                    <div className={loading === true ? "navbar__menu blocked" : "navbar__menu"}>
                        {props.name === 'Company type' ?
                            <>
                                <div className={props.currentStep === 1 ? "navbar__item active" : "navbar__item"}><Trans>companyTypeMenu.personal</Trans></div>
                                <div className={props.currentStep === 2 ? "navbar__item active" : "navbar__item"}><Trans>companyTypeMenu.company</Trans></div>
                                <div className={props.currentStep === 3 ? "navbar__item active" : "navbar__item"}><Trans>companyTypeMenu.revenue</Trans></div>
                                <div className={props.currentStep === 4 ? "navbar__item active" : "navbar__item"}><Trans>companyTypeMenu.cost</Trans></div>
                            </>
                            :
                            <></>
                        }
                        {props.name === 'Admin company type' ?
                            <>
                                <div className={props.currentStep === 1 ? "navbar__item active" : "navbar__item"}><Trans>companyTypeMenu.personal</Trans></div>
                                <div className={props.currentStep === 2 ? "navbar__item active" : "navbar__item"}><Trans>companyTypeMenu.company</Trans></div>
                                <div className={props.currentStep === 3 ? "navbar__item active" : "navbar__item"}><Trans>companyTypeMenu.revenue</Trans></div>
                                <div className={props.currentStep === 4 ? "navbar__item active" : "navbar__item"}><Trans>companyTypeMenu.cost</Trans></div>
                                <div className={props.currentStep === 5 ? "navbar__item active" : "navbar__item"}><Trans>companyTypeMenu.output</Trans></div>
                            </>
                            :
                            <></>
                        }
                        {props.name === 'Establishment flow' ?
                            <>
                                <div className={props.currentStep === 1 ? "navbar__item active" : "navbar__item"}><Trans>establishmentFlowMenu.company</Trans></div>
                                {sessionStorage.getItem('legalForm') === 'LIMITED_COMPANY' ? <div className={props.currentStep === 2 ? "navbar__item active" : "navbar__item"}><Trans>establishmentFlowMenu.shareholder</Trans></div> : null }
                                {sessionStorage.getItem('legalForm') === 'LIMITED_COMPANY' ? <div className={props.currentStep === 3 ? "navbar__item active" : "navbar__item"}><Trans>establishmentFlowMenu.personal</Trans></div> : null }
                                <div className={props.currentStep === 4 ? "navbar__item active" : "navbar__item"}><Trans>establishmentFlowMenu.revenue</Trans></div>
                                {sessionStorage.getItem('legalForm') === 'LIMITED_COMPANY' ? <div className={props.currentStep === 5 ? "navbar__item active" : "navbar__item"}><Trans>establishmentFlowMenu.remuneration</Trans></div> : null }
                                <div className={props.currentStep === 6 ? "navbar__item active" : "navbar__item"}><Trans>establishmentFlowMenu.mobility</Trans></div>
                                <div className={props.currentStep === 7 ? "navbar__item active" : "navbar__item"}><Trans>establishmentFlowMenu.assets</Trans></div>
                                <div className={props.currentStep === 8 ? "navbar__item active" : "navbar__item"}><Trans>establishmentFlowMenu.loans</Trans></div>
                                <div className={props.currentStep === 9 ? "navbar__item active" : "navbar__item"}><Trans>establishmentFlowMenu.checkup</Trans></div>
                            </>
                            :
                            <></>
                        }
                        {props.name === 'Admin panel' ?
                            <>
                                <div className={props.currentStep === 1 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(1)}><Trans>adminMenu.survey</Trans></div>
                                <div className={props.currentStep === 2 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(2)}><Trans>adminMenu.establishment</Trans></div>
                                <div className={props.currentStep === 3 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(3)}><Trans>adminMenu.files</Trans></div>
                                <div className={props.currentStep === 4 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(4)}><Trans>adminMenu.checklist</Trans></div>
                                <div className={props.currentStep === 5 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(5)}><Trans>adminMenu.faq</Trans></div>
                            </>
                            :
                            <></>
                        }
                        {props.name === 'Parameters' ?
                            <>
                                <div className={props.currentStep === 1 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(1)}><Trans>parametersMenu.social</Trans></div>
                                <div className={props.currentStep === 2 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(2)}><Trans>parametersMenu.tax</Trans></div>
                                <div className={props.currentStep === 3 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(3)}><Trans>parametersMenu.daily</Trans></div>
                                <div className={props.currentStep === 4 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(4)}><Trans>parametersMenu.company</Trans></div>
                                {/*<div className={props.currentStep === 5 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(5)}><Trans>parametersMenu.vap</Trans></div>*/}
                                <div className={props.currentStep === 6 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(6)}><Trans>parametersMenu.default</Trans></div>
                            </>
                            :
                            <></>
                        }
                        {props.name === 'Profile' ?
                            <>
                                <div className={props.currentStep === 1 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(1)}><Trans>profileMenu.profile</Trans></div>
                                <div className={props.currentStep === 2 ? "navbar__item clickable active" : "navbar__item clickable"} onClick={() => props.handleStep(2)}><Trans>profileMenu.security</Trans></div>
                                <div className={props.currentStep === 3 ? "navbar__item clickable active" : "navbar__item clickable d-none"} onClick={() => props.handleStep(3)}><Trans>profileMenu.notifications</Trans></div>
                            </>
                            :
                            <></>
                        }
                    </div>
                    <div className="navbar__flow">
                        {props.progress !== undefined ?
                            <>
                                <p>{props.progressName === 'Establishment flow' ? <Trans>menu.survey</Trans> : props.progressName}</p>
                                <div className="navbar__progress">
                                    <LinearProgress
                                        variant={'determinate'}
                                        value={props.progress}
                                    />
                                    <p>{props.progress} %</p>
                                </div>
                            </>
                            :
                            <></>
                        }
                    </div>
                </>
                : <></>}
            <Loader />
        </div>
    );
}

export default Navbar;